import React from 'react';
import { useLocation } from 'react-router-dom';
import './Breadcrumb.css';

function Breadcrumb({ url }) {
  const { pathname } = useLocation();
  let paths = pathname.split(url + '/')[1];

  paths = paths ? paths.split('/') : [];

  return paths.length ? (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {paths.map((name, i, array) => {
          const length = pathname.indexOf(name) + name.length;
          const displayName = name.replace('-', ' ');
          const href = pathname.slice(0, length);

          return array.length === i + 1 ? (
            <li className="breadcrumb-item active" key={i} aria-current="page">
              {displayName}
            </li>
          ) : (
            <li className="breadcrumb-item" key={i}>
              <a href={href}>{displayName}</a>
            </li>
          );
        })}
      </ol>
    </nav>
  ) : null;
}

export default Breadcrumb;

import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";
import Checkbox from "components/Checkbox";
import FormikCheckbox from "components/FormikCheckbox";
import NumberInput from "components/NumberInput";
import Select from "components/Select";
import dispatchToast from "helpers/dispatchToast";
import useAgeGroup from "hooks/ageGroups/useAgeGroups";
import useCompetition from "hooks/competitions/useCompetition";
import useCreateBatchCompetitionEvents from "hooks/competitionEvents/useCreateBatchCompetitionEvents";
import useEvents from "hooks/events/useEvents";
import "./BatchEvents.scss";

function BatchEvents() {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { competitionId } = useParams();
  const { data: ageGroups } = useAgeGroup(competitionId);
  const { data: events } = useEvents();
  const { data: competition } = useCompetition(competitionId);
  const [createBatchCompetitionEvents] = useCreateBatchCompetitionEvents();
  const [isGroupEvent, setIsGroupEvent] = useState(false);
  const [ageGroupMultiplier, setAgeGroupMultiplier] = useState(0);
  const [genderMultiplier, setGenderMultiplier] = useState(0);
  const [eventsBeingAdded, setEventsBeingAdded] = useState({});
  const [totalEventsBeingAdded, setTotalEventsBeingAdded] = useState(0);
  const ringOptions = competition?.rings
    ? Object.values(competition?.rings).map(({ id, name }, i) => (
        <option key={i} value={id}>
          {name}
        </option>
      ))
    : [];

  useEffect(() => {
    const genderMult = genderMultiplier === 0 ? 1 : genderMultiplier;
    const ageGroupMult = ageGroupMultiplier === 0 ? 1 : ageGroupMultiplier;
    let eventsCount = Object.values(eventsBeingAdded).reduce(
      (acc, v) => (acc += v),
      0
    );

    eventsCount = eventsCount * ageGroupMult * genderMult;
    setTotalEventsBeingAdded(eventsCount);
  }, [ageGroupMultiplier, eventsBeingAdded, genderMultiplier]);

  return (
    <div className="BatchEvents">
      <h2 className="text-left">Batch Events</h2>
      <Formik
        initialValues={{
          events: [],
          cost: competition?.eventCost || 0,
          genders: [],
          ageGroups: [],
          groupMemberMax: 3,
          groupMemberMin: 2,
        }}
        validationSchema={Yup.object({
          cost: Yup.number(),
        })}
        onSubmit={async ({
          ageGroups = [null],
          cost,
          events,
          genders = [null],
          isExemptFromRegistrationFee,
          ringId,
          groupMemberMax,
          groupMemberMin,
        }) => {
          if (events.length < 1) {
            dispatchToast({
              message: "Please select at least 1 event.",
              type: "error",
            });
          } else if (!ringId) {
            dispatchToast({
              message: "Please select a ring to add the events to.",
              type: "error",
            });
          } else {
            events.sort((a, b) => {
              const [, aCode] = a.split("_");
              const [, bCode] = b.split("_");

              if (aCode === "null") {
                if (bCode === "null") {
                  return 0;
                }
                return 1;
              } else if (bCode === "null") {
                return -1;
              }

              return aCode - bCode;
            });

            ageGroups.sort((a, b) => {
              const [, aStart, aEnd] = a.split("-");
              const [, bStart, bEnd] = b.split("-");

              if (aStart === bStart) {
                return aEnd - bEnd;
              }

              return aStart - bStart;
            });

            createBatchCompetitionEvents({
              ageGroups: ageGroups.map((a) => {
                const [id] = a.split("-");

                return id;
              }),
              competitionId: +competitionId,
              cost,
              events: events.map((e) => {
                const [id] = e.split("_");

                return id;
              }),
              genders,
              isExemptFromRegistrationFee,
              ringId: +ringId,
              groupMemberMax: isGroupEvent ? groupMemberMax : null,
              groupMemberMin: isGroupEvent ? groupMemberMin : null,
              onSuccess: () => {
                const parentPath = url.split("/");

                parentPath.pop();
                history.push(parentPath.join("/"));
              },
            });
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="needs-validation" noValidate>
            <h5>Ring</h5>
            <div className="row">
              <Select name="ringId">
                <option value="">Select a ring</option>
                {ringOptions}
              </Select>
            </div>
            <hr></hr>
            <FieldArray
              name="genders"
              render={(arrayHelpers) => {
                return (
                  <>
                    <div className="d-flex justify-content-between">
                      <h5>Gender</h5>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <Checkbox
                          defaultValue="male"
                          id="genders-male"
                          label="Male"
                          name="genders"
                          onChange={(checked, value) => {
                            if (checked) {
                              arrayHelpers.push(value);
                              setGenderMultiplier(genderMultiplier + 1);
                            } else {
                              setFieldValue(
                                "genders",
                                values.genders.filter(
                                  (gender) => gender !== value
                                )
                              );
                              setGenderMultiplier(genderMultiplier - 1);
                            }
                          }}
                          useBootstrapClasses
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <Checkbox
                          className="form-check-input"
                          defaultValue="female"
                          id="genders-female"
                          label="Female"
                          name="genders"
                          onChange={(checked, value) => {
                            if (checked) {
                              arrayHelpers.push(value);
                              setGenderMultiplier(genderMultiplier + 1);
                            } else {
                              setFieldValue(
                                "genders",
                                values.genders.filter(
                                  (gender) => gender !== "female"
                                )
                              );
                              setGenderMultiplier(genderMultiplier - 1);
                            }
                          }}
                          useBootstrapClasses
                        />
                      </div>
                    </div>
                  </>
                );
              }}
            ></FieldArray>
            <hr></hr>
            <FieldArray
              name="ageGroups"
              render={(arrayHelpers) => {
                const ageGroupItems = ageGroups
                  ? ageGroups.map(({ id, rangeStart, rangeEnd }, i) => {
                      const label = `${rangeStart}-${rangeEnd}`;
                      const ageGroupHtmlId = `${id}-${label}`;

                      return (
                        <div className="col-lg-4 col-md-6 col-sm-12" key={i}>
                          <Checkbox
                            checked={values.ageGroups.includes(ageGroupHtmlId)}
                            defaultValue={ageGroupHtmlId}
                            id={ageGroupHtmlId}
                            name="ageGroups"
                            label={<span>{`${label} years`}</span>}
                            onChange={(checked) => {
                              if (checked) {
                                arrayHelpers.push(ageGroupHtmlId);
                                setAgeGroupMultiplier(ageGroupMultiplier + 1);
                              } else {
                                arrayHelpers.remove(
                                  values.ageGroups.indexOf(ageGroupHtmlId)
                                );
                                setAgeGroupMultiplier(ageGroupMultiplier - 1);
                              }
                            }}
                            useBootstrapClasses
                          />
                        </div>
                      );
                    })
                  : [];

                return (
                  <>
                    <div className="d-flex justify-content-between">
                      <h5>Age Groups</h5>
                      <Checkbox
                        id="allAgeGroups"
                        label={<b>Toggle All</b>}
                        onChange={(checked) => {
                          if (checked) {
                            ageGroups.forEach(
                              ({ id, rangeStart, rangeEnd }) => {
                                arrayHelpers.push(
                                  `${id}-${rangeStart}-${rangeEnd}`
                                );
                              }
                            );
                            setAgeGroupMultiplier(ageGroups.length);
                          } else {
                            setFieldValue("ageGroups", []);
                            setAgeGroupMultiplier(0);
                          }
                        }}
                        useBootstrapClasses
                      />
                    </div>
                    <div className="row">{ageGroupItems}</div>
                  </>
                );
              }}
            ></FieldArray>
            <hr></hr>
            <h5>Fees</h5>
            <div className="row">
              <NumberInput label="Cost" name="cost" />
              <div className="col">
                <FormikCheckbox name="isExemptFromRegistrationFee">
                  Exempt From Registration Fee
                </FormikCheckbox>
              </div>
            </div>
            <hr></hr>
            <h5>Group Member Info</h5>
            <div className="row">
              <div className="col">
                <Checkbox
                  id="isGroupEvent"
                  label="Is Group Event?"
                  onChange={() => setIsGroupEvent(!isGroupEvent)}
                  useBootstrapClasses
                />
              </div>
            </div>
            {isGroupEvent && (
              <div className="row">
                <NumberInput
                  label="Group Member Min"
                  name="groupMemberMin"
                  min="1"
                  step="1"
                />
                <NumberInput
                  label="Group Member Max"
                  name="groupMemberMax"
                  min="2"
                  step="1"
                />
              </div>
            )}
            <hr></hr>
            <FieldArray
              name="events"
              render={(arrayHelpers) => {
                let eventsByCategory = {};

                if (events) {
                  events.forEach((event) => {
                    const { eventCategoryName } = event;

                    if (!eventsByCategory[eventCategoryName]) {
                      eventsByCategory[eventCategoryName] = [];
                    }

                    eventsByCategory[eventCategoryName].push(event);
                  });
                }

                return (
                  <>
                    <div className="d-flex justify-content-between">
                      <h5>Events</h5>
                      <Checkbox
                        id="allEvents"
                        label={<b>Toggle All</b>}
                        onChange={(checked) => {
                          if (checked) {
                            const eventsMap = {};

                            Object.entries(eventsByCategory).forEach(
                              ([categoryName, events]) => {
                                events.forEach(({ id, code }) => {
                                  arrayHelpers.push(
                                    `${id}_${code}_${categoryName}`
                                  );

                                  if (!eventsMap[categoryName]) {
                                    eventsMap[categoryName] = 0;
                                  }

                                  eventsMap[categoryName] += 1;
                                });
                              }
                            );

                            setEventsBeingAdded(eventsMap);
                          } else {
                            setFieldValue("events", []);
                            setEventsBeingAdded({});
                          }
                        }}
                        useBootstrapClasses
                      />
                    </div>
                    {Object.entries(eventsByCategory).map(
                      ([categoryName, events], index) => (
                        <div key={index}>
                          <div className="d-flex justify-content-between">
                            <h6 className="title">{categoryName}</h6>
                            <Checkbox
                              id={`allEvents-${categoryName}`}
                              label={<b>Toggle All</b>}
                              onChange={(checked) => {
                                if (checked) {
                                  events.forEach(({ id, code }) => {
                                    arrayHelpers.push(
                                      `${id}_${code}_${categoryName}`
                                    );
                                  });

                                  const newEventsBeingAdded = {
                                    ...eventsBeingAdded,
                                  };

                                  if (!newEventsBeingAdded[categoryName]) {
                                    newEventsBeingAdded[categoryName] = 0;
                                  }

                                  newEventsBeingAdded[categoryName] =
                                    events.length;
                                  setEventsBeingAdded(newEventsBeingAdded);
                                } else {
                                  setFieldValue(
                                    "events",
                                    values.events.filter((e) => {
                                      const [, , category] = e.split("_");

                                      return categoryName !== category;
                                    })
                                  );
                                  setEventsBeingAdded({
                                    ...eventsBeingAdded,
                                    [categoryName]: 0,
                                  });
                                }
                              }}
                              useBootstrapClasses
                            />
                          </div>
                          <div className="row">
                            {events.map(({ id, code, name }, i) => {
                              const eventHtmlId = `${id}_${code}_${categoryName}`;

                              return (
                                <div
                                  className="col-lg-3 col-md-6 col-sm-12"
                                  key={i}
                                >
                                  <Checkbox
                                    checked={values.events.includes(
                                      eventHtmlId
                                    )}
                                    id={eventHtmlId}
                                    label={
                                      <span>
                                        {name} ({code})
                                      </span>
                                    }
                                    name="events"
                                    onChange={(checked) => {
                                      if (checked) {
                                        arrayHelpers.push(eventHtmlId);

                                        const newEventsBeingAdded = {
                                          ...eventsBeingAdded,
                                        };

                                        if (
                                          !newEventsBeingAdded[categoryName]
                                        ) {
                                          newEventsBeingAdded[categoryName] = 0;
                                        }

                                        newEventsBeingAdded[categoryName] += 1;
                                        setEventsBeingAdded(
                                          newEventsBeingAdded
                                        );
                                      } else {
                                        arrayHelpers.remove(
                                          values.events.indexOf(eventHtmlId)
                                        );
                                        setEventsBeingAdded({
                                          ...eventsBeingAdded,
                                          [categoryName]:
                                            eventsBeingAdded[categoryName] - 1,
                                        });
                                      }
                                    }}
                                    defaultValue={eventHtmlId}
                                    useBootstrapClasses
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )
                    )}
                  </>
                );
              }}
            ></FieldArray>
            <button type="submit" className="btn btn-primary float-right">
              Submit (Events Being Added: {totalEventsBeingAdded})
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default BatchEvents;

import { useMutation } from 'react-query';
import { CompetitionAPI } from 'api/admin/competition';
import dispatchToast from 'helpers/dispatchToast';

export default function useRemoveCompetition() {
  return useMutation(CompetitionAPI.removeCompetition, {
    onSuccess: (
      { message },
      { id, onSuccess = () => {}, handleUndoDelete = () => {} }
    ) => {
      dispatchToast({
        message,
        type: 'success',
      });
      onSuccess();
    },
  });
}

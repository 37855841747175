import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import NumberInput from "components/NumberInput";
import CONFIRM_DELETE from "helpers/confirmDelete";
import "./EventCategoryRestrictionItem.scss";

function EventCategoryRestrictionItem({
  deleteItem = () => {},
  eventCategoryName,
  max,
  updateItem = () => {},
}) {
  const [isHovering, setIsHovering] = useState();
  const [isUpdating, setIsUpdating] = useState();

  return (
    <li
      className="EventCategoryRestrictionItem list-group-item d-flex justify-content-between"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <span>
        Max <b>{max}</b> of <b>{eventCategoryName}</b>
      </span>
      {isUpdating && (
        <>
          <Formik
            initialValues={{
              max,
            }}
            validationSchema={Yup.object({
              max: Yup.number().required("Required"),
            })}
            onSubmit={async ({ max }) => {
              updateItem(max);
              setIsUpdating(false);
            }}
          >
            <Form className="needs-validation" noValidate>
              <div className="row">
                <NumberInput
                  label="Max"
                  name="max"
                  min="1"
                  step="1"
                  size="col-sm-12 col-md-6 col-lg-6"
                />
              </div>
              <button type="submit" className="btn btn-success mr-1">
                Submit
              </button>
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setIsUpdating(false);
                }}
              >
                Cancel
              </button>
            </Form>
          </Formik>
        </>
      )}
      {isHovering && !isUpdating && (
        <div>
          <button
            className="btn btn-warning mr-1"
            onClick={() => setIsUpdating(true)}
          >
            Update
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              CONFIRM_DELETE(() => {
                deleteItem();
              });
            }}
          >
            Delete
          </button>
        </div>
      )}
    </li>
  );
}

export default EventCategoryRestrictionItem;

import { GET, POST, DELETE, PUT } from "../config";

const ChampionshipAPI = {
  async getAllForCompetition(id) {
    const { championships } = await GET(`championships/${id}`);

    return championships;
  },
  async getAllParticipants(id) {
    const { participants } = await GET(`championships/${id}/participants`);

    return participants;
  },
  add(championshipInput) {
    return POST("championships", { championshipInput });
  },
  remove(id) {
    return DELETE(`championships/${id}`);
  },
  update({ id, championshipInput }) {
    return PUT(`championships/${id}`, { championshipInput });
  },
};

export { ChampionshipAPI };

import React from "react";
import useLocalize from "hooks/useLocalize";

function SubmitButton({ disabled, text, onClick }) {
  const localize = useLocalize();

  if (!text) {
    text = localize("Submit");
  }

  return (
    <button
      className="btn btn-success float-right"
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
}

export default SubmitButton;

import { GET, POST, DELETE, PUT } from '../config';

const RingAPI = {
  async getRings(competitionId) {
    const { rings } = await GET(`competitions/${competitionId}/rings`);

    return rings;
  },
  async getById(id) {
    const { ring } = await GET(`rings/${id}`);

    return ring;
  },
  async addRing(competitionId, ringInput) {
    return await POST(`rings`, { competitionId, ringInput });
  },
  async removeRing(id) {
    return await DELETE(`rings/${id}`);
  },
  async updateRing(id, ringInput) {
    return await PUT(`rings/${id}`, { ringInput });
  },
};

export { RingAPI };

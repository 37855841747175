import { useMutation, useQueryCache } from "react-query";
import { ChampionshipAgeGroupAPI } from "api/admin/championshipAgeGroup";
import { CHAMPIONSHIP_AGE_GROUPS } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useCreateChampionshipAgeGroups() {
  const queryCache = useQueryCache();

  return useMutation(ChampionshipAgeGroupAPI.add, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(CHAMPIONSHIP_AGE_GROUPS);
      dispatchToast({ message, type: "success" });

      onSuccess();
    },
  });
}

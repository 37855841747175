import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ModalTable from "components/admin/ModalTable";
import Table from "components/admin/Table";
import EventParticipantList from "components/EventParticipantList";
import CONFIRM from "helpers/confirm";
import useRemoveCompetitionEvents from "hooks/competitionEvents/useRemoveCompetitionEvents";
import useUpdateEventParticipationAllRemainingLotOrders from "hooks/eventParticipation/useUpdateEventParticipationAllRemainingLotOrders";

function MasterSheet({ competitionId, events, isVirtual }) {
  const { pathname } = useLocation();
  const [removeCompetitionEvents] = useRemoveCompetitionEvents();
  const [
    updateAllRemainingLotOrders,
    { isLoading: updateAllRemainingLotOrdersLoading },
  ] = useUpdateEventParticipationAllRemainingLotOrders();
  const [ageGroup, setAgeGroup] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [code, setCode] = useState("");
  const [cost, setCost] = useState("");
  const [eventName, setEventName] = useState("");
  const [gender, setGender] = useState("");
  const [maximumDuration, setMaximumDuration] = useState("");
  const [ringName, setRingName] = useState("");
  const tableData = {
    filters: ["categoryName", "ageGroup", "gender", "name", "code"],
    headingData: {
      eventName: "Name",
      categoryName: "Category",
      ageGroup: "Age Group",
      gender: "Gender",
      code: "Code",
      ringName: "Ring",
      participants: "Athletes",
    },
    items: events || [],
    itemName: "Competition Event",
    searchableColumns: [
      "eventName",
      "code",
      "ageGroup",
      "gender",
      "ringName",
      "categoryName",
    ],
    defaultSort: "participants",
    modalData: {
      fields: [
        {
          defaultValue: code || "",
          displayName: "Code",
          name: "code",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: eventName || "",
          displayName: "Event Name",
          name: "eventName",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: categoryName || "",
          displayName: "Category",
          name: "category",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: ageGroup || "",
          displayName: "Age Group",
          name: "ageGroup",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: gender || "",
          displayName: "Gender",
          name: "gender",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: cost || "",
          displayName: "Cost",
          name: "cost",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: maximumDuration || "",
          displayName: "Max Duration",
          name: "maxDuration",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: ringName || "",
          displayName: "Ring",
          name: "ring",
          type: "text",
          disabled: true,
        },
      ],
    },
  };
  const exportToCSVData = {
    options: {
      filename: "master-schedule",
    },
    data: events?.map(
      ({
        eventName,
        categoryName,
        ageGroup,
        gender,
        code,
        ringName,
        cost,
        participants,
      }) => ({
        Name: eventName,
        Category: categoryName,
        "Age Group": ageGroup,
        Gender: gender,
        Code: code,
        "Ring Name": ringName,
        Cost: cost,
        Athletes: participants,
      })
    ),
  };

  function setIsEditingItem({
    ageGroup,
    categoryName,
    code,
    cost,
    eventName,
    gender,
    maximumDuration,
    ringName,
  }) {
    setAgeGroup(ageGroup);
    setCategoryName(categoryName);
    setCode(code);
    setCost(cost);
    setEventName(eventName);
    setGender(gender);
    setMaximumDuration(maximumDuration);
    setRingName(ringName);
  }

  if (!isVirtual) {
    tableData.headingData.undrawnLots = "Undrawn Lots";
  }

  return (
    <div className="MasterSheet">
      {updateAllRemainingLotOrdersLoading ? (
        <div className="spinner-border mt-2" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <>
          <Link to={`${pathname}/batch-events`}>
            <button className="btn btn-success">
              Add Events to Master Sheet
            </button>
          </Link>
          {isVirtual ? (
            <Table
              data={tableData}
              exportToCSVData={exportToCSVData}
              isSelectable
              onDeleteSelected={(selected) => {
                if (selected.length) {
                  removeCompetitionEvents(selected);
                }
              }}
              setIsEditingItem={setIsEditingItem}
            />
          ) : (
            <>
              <button
                className="btn btn-warning float-right"
                onClick={() => {
                  CONFIRM(() => {
                    updateAllRemainingLotOrders(competitionId);
                  }, "Are you sure you want to draw ALL lots for ALL events?");
                }}
              >
                Draw All Remaining Lots
              </button>
              <ModalTable
                data={tableData}
                exportToCSVData={exportToCSVData}
                isSelectable
                onDeleteSelected={(selected) => {
                  if (selected.length) {
                    removeCompetitionEvents(selected);
                  }
                }}
                setIsEditingItem={setIsEditingItem}
                ExtraModalContent={EventParticipantList}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default MasterSheet;

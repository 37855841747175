import React, { useEffect, useState } from "react";
import CardTab from "components/CardTab";
import TabPane from "components/TabPane";
import useLocalize from "hooks/useLocalize";
import AllAroundResults from "scenes/AllAroundResults";
import AllResults from "scenes/AllResults";
import TeamTrialResults from "scenes/TeamTrialResults";

function AllResultsTabs({ isVirtual, competitionId }) {
  const [showTab, setShowTab] = useState();
  const [eventsTabId, setEventsTabId] = useState();
  const [trialsTabId, setTrialsTabId] = useState();
  const [championshipTabId, setChampionshipTabId] = useState();
  const localize = useLocalize();

  useEffect(() => {
    setEventsTabId(`events-tab-${competitionId}`);
    setTrialsTabId(`trials-tab-${competitionId}`);
    setChampionshipTabId(`championship-tab-${competitionId}`);
    setShowTab(`events-tab-${competitionId}`);
  }, [competitionId]);

  return (
    <div className="AllResultsTabs">
      {!isVirtual && (
        <ul className="nav nav-tabs nav-justified">
          <CardTab
            id={`#${eventsTabId}`}
            onClick={() => setShowTab(eventsTabId)}
            selected={showTab === eventsTabId}
            text={localize("All")}
          />
          <CardTab
            id={`#${trialsTabId}`}
            onClick={() => setShowTab(trialsTabId)}
            selected={showTab === trialsTabId}
            text={localize("Team Trials")}
          />
          <CardTab
            id={`#${championshipTabId}`}
            onClick={() => setShowTab(championshipTabId)}
            selected={showTab === championshipTabId}
            text={localize("All Around")}
          />
        </ul>
      )}
      <hr className="mb-3"></hr>
      <div className="tab-content">
        <TabPane id={eventsTabId} show={showTab === eventsTabId}>
          <AllResults competitionId={competitionId} isVirtual={isVirtual} />
        </TabPane>
        <TabPane id={trialsTabId} show={showTab === trialsTabId}>
          <TeamTrialResults competitionId={competitionId} />
        </TabPane>
        <TabPane id={championshipTabId} show={showTab === championshipTabId}>
          <AllAroundResults competitionId={competitionId} />
        </TabPane>
      </div>
    </div>
  );
}

export default AllResultsTabs;

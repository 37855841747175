import React from "react";
import classNames from "classnames";
import formatFileName from "helpers/formatFilename";
import "./Checkbox.scss";

type CheckboxProps = {
  checked: boolean;
  defaultValue: any;
  disabled: boolean;
  id: string;
  inputClassName: string;
  label: string;
  labelClassName: string;
  name: string;
  onChange: (checked: boolean, value: string) => void;
  onClick: React.MouseEventHandler<HTMLInputElement>;
  useBootstrapClasses: boolean;
  wrapperClassName: string;
};

export default React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const {
      checked,
      defaultValue,
      disabled,
      id,
      inputClassName,
      label,
      labelClassName,
      name,
      onChange,
      onClick,
      useBootstrapClasses,
      wrapperClassName,
    } = props;
    const wrapperClasses = classNames(
      "Checkbox",
      {
        "form-group": useBootstrapClasses,
        "form-check": useBootstrapClasses,
        disabled,
      },
      wrapperClassName
    );
    const inputClasses = classNames(
      { "form-check-input": useBootstrapClasses },
      inputClassName
    );
    const labelClasses = classNames(
      {
        "form-check-label": useBootstrapClasses,
      },
      labelClassName
    );
    const sanitizedId = formatFileName(id);

    return (
      <div className={wrapperClasses}>
        <input
          checked={checked}
          className={inputClasses}
          disabled={disabled}
          id={sanitizedId}
          name={name}
          onChange={({ target: { checked, value } }) =>
            onChange(checked, value)
          }
          onClick={onClick}
          ref={ref}
          type="checkbox"
          value={defaultValue}
        />
        {!!label && (
          <label className={labelClasses} htmlFor={sanitizedId}>
            {label}
          </label>
        )}
      </div>
    );
  }
);

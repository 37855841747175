import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CardCollapse from "components/CardCollapse";
import AddParticipant from "components/AddParticipant";
import Table from "components/admin/Table";
import useCreateParticipant from "hooks/participants/useCreateParticipant";

export default ({ participants, userId }) => {
  if (!participants) {
    return null;
  }
  const history = useHistory();
  const [isAdding, setIsAdding] = useState(false);
  const [createParticipant] = useCreateParticipant();
  const participantsTableData = {
    itemName: "Athlete",
    headingData: {
      name: "Name",
    },
    items: participants || [],
    searchableColumns: ["name"],
    defaultSort: "name",
  };

  function itemClick(participant) {
    const { id } = participant;
    const participantPage = `/adm/home/participant/${id}`;

    history.push(participantPage);
  }

  function onSubmit(values) {
    createParticipant({
      participantInput: values,
      userId,
      onSuccess: () => {
        history.go(0);
        setIsAdding(false);
      },
    });
  }

  return (
    <CardCollapse id="athletes" title="Athletes" show>
      {isAdding && (
        <>
          <div className="row addParticipant">
            <div className="col">
              <AddParticipant onSubmit={onSubmit} />
            </div>
          </div>
          <hr></hr>
        </>
      )}
      {isAdding ? (
        <button
          className="btn btn-danger float-right"
          onClick={() => setIsAdding(false)}
        >
          Cancel
        </button>
      ) : (
        <button
          className="btn btn-success float-right"
          onClick={() => setIsAdding(true)}
        >
          Add athlete
        </button>
      )}
      <Table data={participantsTableData} itemClick={itemClick}></Table>
    </CardCollapse>
  );
};

import { useMutation, useQueryCache } from 'react-query';
import { TransactionAPI } from 'api/admin/transaction';
import { TRANSACTIONS, CHECKOUT } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useCreateTransaction() {
  const queryCache = useQueryCache();

  return useMutation(TransactionAPI.add, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      dispatchToast({ message, type: 'success' });
      queryCache.invalidateQueries(TRANSACTIONS);
      queryCache.invalidateQueries(CHECKOUT);
      onSuccess();
    },
  });
}

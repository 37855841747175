import { useQuery } from 'react-query';
import { CompetitionEventAPI } from 'api/admin/competitionEvent';
import { COMPETITION_EVENTS } from 'helpers/constants';

export default function useCompetitionEventsForParticipant(
  competitionId,
  participantId
) {
  return useQuery(
    [COMPETITION_EVENTS, competitionId, participantId],
    () =>
      CompetitionEventAPI.getAllForCompetitionAndParticipant({
        competitionId,
        participantId,
      }),
    {
      enabled: !!competitionId && !!participantId,
    }
  );
}

import { useMutation, useQueryCache } from "react-query";
import { EventParticipationAPI } from "api/eventParticipation";
import { EVENT_PARTICIPATION, MASTER_SHEET } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useUpdateEventParticipationAllRemainingLotOrders() {
  const queryCache = useQueryCache();

  return useMutation(
    EventParticipationAPI.updateEventParticipationAllRemainingLotOrders,
    {
      onSuccess: ({ message }) => {
        queryCache.invalidateQueries(EVENT_PARTICIPATION);
        queryCache.invalidateQueries(MASTER_SHEET);
        dispatchToast({ message, type: "success" });
      },
    }
  );
}

import { useQuery } from "react-query";
import { EventFeedbackAPI } from "api/admin/eventFeedback";
import { EVENT_FEEDBACK } from "helpers/constants";

export default function useEventFeedbackDuplicates({ competitionId }) {
  return useQuery(
    [EVENT_FEEDBACK, "duplicates"],
    () => EventFeedbackAPI.getDuplicates(competitionId),
    { enabled: competitionId }
  );
}

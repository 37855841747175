import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import CompetitionManagerRouter from './CompetitionManager/CompetitionManagerRouter';
import TournamentManager from './TournamentManager';

function TournamentManagerRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <TournamentManager />
      </Route>
      <Route path={`${path}/:tournamentId`}>
        <CompetitionManagerRouter />
      </Route>
    </Switch>
  );
}

export default TournamentManagerRouter;
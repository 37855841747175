import React from 'react';
import './NanduScores.css';

function NanduScores({ validators }) {
  function showItemDescription(item) {
    if (item.description) {
      return item.description;
    }
    return item.validator ? item.successDescription : item.failDescription;
  }

  return (
    <div className="nanduScores">
      {
        validators.map((item, key) => {
          const { validator } = item;
          return (
            <div key={key} className={"score " + (validator ? "maxed" : "")}>
              {showItemDescription(item)}
              <span className={"validation-sign " + (validator ? "ion-checkmark-round" : "ion-alert-circled")}></span>
            </div>
          );
        })
      }
    </div>
  );
}

export default NanduScores;

import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { I18nSelectors } from "redux/i18n";
import Table from "components/admin/Table";
import useTeamTrialResults from "hooks/championships/useTeamTrialResults";
import useLocalize from "hooks/useLocalize";
import { AuthSelectors } from "redux/auth";
import formatPSTDate from "helpers/formatPSTDate";

function TeamTrialResults({ competitionId }) {
  const history = useHistory();
  const isAdmin = useSelector(AuthSelectors.isAdmin);
  // const language = useSelector(I18nSelectors.getLanguage);
  const localize = useLocalize();
  const { data: participants, isLoading } = useTeamTrialResults(competitionId);
  const headingData = {};

  // if (language === "en") {
  //   headingData.eventName = localize("Event");
  // } else {
  //   headingData.eventChineseName = localize("Event");
  // }

  headingData.id = localize("ID");
  headingData.name = localize("Name");
  headingData.championshipAgeGroup = localize("Age Group");
  headingData.gender = localize("Gender");
  headingData.teamName = localize("Team");
  // score: localize("Score"),
  // rank: localize("Rank"),

  if (isAdmin) {
    headingData.birthDate = "Birth Date";
  }

  const tableData = {
    itemName: "Athletes",
    filters: ["championshipAgeGroup", "gender"],
    headingData,
    items:
      participants?.map((p) => {
        p.birthDate = formatPSTDate(p.birthDate);

        return p;
      }) || [],
    searchableColumns: [
      "eventName",
      "eventChineseName",
      "gender",
      "participantName",
      "score",
      "award",
      "teamName",
    ],
    defaultSort: "votes",
    defaultSortAscending: false,
  };

  const exportToCSVData = {
    options: {
      filename: "team-trial-results",
    },
    data: participants?.map(({ id, name, gender, birthDate }) => ({
      ID: id,
      Name: name,
      Gender: gender,
      "Birth Date": birthDate,
    })),
  };

  return isLoading ? (
    <div className="spinner-border"></div>
  ) : (
    <div className="AllResults">
      <Table
        data={tableData}
        pageTotal={100}
        itemClick={({ id }) => {
          history.push(`/schedule/${id}/events`);
        }}
        exportToCSVData={exportToCSVData}
      />
    </div>
  );
}

export default TeamTrialResults;

import React from "react";
import { useParams } from "react-router-dom";
import CardCollapse from "components/CardCollapse";
import AllResultsTabs from "components/AllResultsTabs";
import useCompetition from "hooks/competitions/useCompetition";
import AgeGroups from "./components/AgeGroups";
import ChampionshipSetup from "./components/ChampionshipSetup";
import CompetitionEdit from "./components/CompetitionEdit";
import EventCategoryRestrictions from "./components/EventCategoryRestrictions";
import PrintingStation from "./components/PrintingStation";
import MasterSheet from "./components/MasterSheet";
import Reports from "./components/Reports";
import Rings from "./components/Rings";
import "./Competition.scss";

function Competition() {
  const { competitionId } = useParams();
  const { data: competition } = useCompetition(competitionId);
  const ageGroups = competition?.ageGroups;
  const restrictions = competition?.restrictions;
  const isVirtual = competition?.isVirtual;
  const events = competition?.events;
  const name = competition?.name;

  return competition ? (
    <div className="Competition">
      <h2 className="text-left">{name}</h2>
      <CardCollapse id="competitionEdit" title="Competition Info">
        <CompetitionEdit competition={competition} />
      </CardCollapse>
      <CardCollapse
        id="allAroundChampionSetup"
        title="All Around Champion/Team Trial Setup"
      >
        <ChampionshipSetup competitionId={competitionId} />
      </CardCollapse>
      <CardCollapse id="ageGroups" title="Age Groups">
        {!ageGroups.length && "No age groups yet."}
        <AgeGroups ageGroups={ageGroups} competitionId={competitionId} />
      </CardCollapse>
      <CardCollapse
        id="eventRestrictions"
        title="Event Registration Restrictions"
      >
        {!restrictions.length && "No event restrictions yet."}
        <EventCategoryRestrictions
          restrictions={restrictions}
          competitionId={competitionId}
        />
      </CardCollapse>
      <CardCollapse id="rings" title="Rings">
        <Rings competition={competition}></Rings>
      </CardCollapse>
      <CardCollapse
        id="competitionEvents"
        title={`Master Sheet (${events?.length})`}
      >
        <MasterSheet
          competitionId={competitionId}
          events={events}
          isVirtual={isVirtual}
        />
      </CardCollapse>
      <CardCollapse id="printing" title="Printing Station">
        <PrintingStation competitionId={competitionId} />
      </CardCollapse>
      <CardCollapse id="reports" title="Other Reports">
        <Reports competitionId={competitionId} />
      </CardCollapse>
      <CardCollapse id="awards" title="Awards">
        <AllResultsTabs competitionId={competitionId} isVirtual={isVirtual} />
      </CardCollapse>
    </div>
  ) : null;
}

export default Competition;

import React from "react";
import { useSelector } from "react-redux";
import { AuthSelectors } from "redux/auth";
import TicketsView from "components/TicketsView";

function Tickets() {
  const user = useSelector(AuthSelectors.getUser);

  return (
    <div className="Tickets">
      <TicketsView userId={user.id} />
    </div>
  );
}

export default Tickets;

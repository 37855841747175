import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import useTournaments from "hooks/tournament/useTournaments";
import useCreateTournament from "hooks/tournament/useCreateTournament";
import ModalAdminTable from "components/admin/ModalTable";
import formatPSTDate from "helpers/formatPSTDate";
import "./TournamentManager.css";

function TournamentList() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { data: tournaments } = useTournaments();
  const [createTournament] = useCreateTournament();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [location, setLocation] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [ticketCost, setTicketCost] = useState(0);

  function setIsAddingItem() {
    setIsActive(false);
    setStartDate("");
    setEndDate("");
    setLocation("");
    setTicketCost(0);
  }

  function itemClick(tournament) {
    const { id } = tournament;
    const tournamentPage = `${path}/${id}`;

    history.push(tournamentPage);
  }

  function addItem() {
    const tournament = {
      isActive: !!isActive,
      startDate: startDate || null,
      endDate: endDate || null,
      location,
      ticketCost,
    };

    createTournament(tournament);
  }

  const tableData = {
    headingData: {
      id: "ID",
      location: "Location",
      ticketCost: "Ticket Cost",
      isActive: "Active",
      competitionsLabel: "Competitions",
      startDate: "Start Date",
      endDate: "End Date",
    },
    items: tournaments
      ? tournaments.map((tournament) => {
          let { startDate, endDate, isActive } = tournament;
          const formattedStartDate = formatPSTDate(startDate);
          const formattedEndDate = formatPSTDate(endDate);

          return {
            ...tournament,
            isActive: isActive ? "Yes" : null,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          };
        })
      : [],
    itemName: "Tournament",
    modalData: {
      fields: [
        {
          defaultValue: startDate,
          displayName: "Start Date",
          name: "startDate",
          onChange: (value) => setStartDate(value),
          type: "date",
        },
        {
          defaultValue: endDate,
          displayName: "End Date",
          name: "endDate",
          onChange: (value) => setEndDate(value),
          type: "date",
        },
        {
          defaultValue: location,
          displayName: "Location",
          name: "location",
          onChange: (value) => setLocation(value),
          type: "text",
        },
        {
          defaultValue: ticketCost,
          displayName: "Ticket Cost",
          name: "ticketCost",
          onChange: (value) => setTicketCost(value),
          type: "number",
        },
        {
          defaultValue: isActive,
          displayName: "Is Active",
          name: "isActive",
          onChange: setIsActive,
          type: "checkbox",
        },
      ],
    },
    searchableColumns: ["id", "startDate", "endDate", "location"],
    defaultSort: "id",
  };

  return (
    <div className="tournamentManager">
      <h2 className="text-left">Tournaments</h2>
      <ModalAdminTable
        addItem={addItem}
        data={tableData}
        itemClick={itemClick}
        setIsAddingItem={setIsAddingItem}
      />
    </div>
  );
}

export default TournamentList;

import React, { useState } from 'react';
import classnames from 'classnames';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { UserAPI } from 'api/user';
import PasswordInput from 'components/PasswordInput';
import TextInput from 'components/TextInput';
import useLocalize from 'hooks/useLocalize';
import useUpdateEmail from 'hooks/users/useUpdateEmail';
import useUpdatePassword from 'hooks/users/useUpdatePassword';
import { AuthActions, AuthSelectors } from 'redux/auth';

function Account() {
  const dispatch = useDispatch();
  const { id, email } = useSelector(AuthSelectors.getUser);
  const [isDeleting, setIsDeleting] = useState(false);
  const [updatePassword] = useUpdatePassword();
  const [updateEmail] = useUpdateEmail();
  const buttonClasses = classnames(
    'btn',
    { 'btn-danger': !isDeleting },
    { 'btn-warning': isDeleting },
    'col-12',
    'form-group'
  );
  const localize = useLocalize();

  return (
    <div className="Account container">
      <h2 className="text-lg-left text-sm-center">
        {localize('Your Account')}
      </h2>
      <hr></hr>
      <h5>{localize('Change Email')}</h5>
      <Formik
        initialValues={{ email }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(localize('Must be a valid email'))
            .max(45, localize('Must be 45 characters or less'))
            .required(localize('Required')),
        })}
        onSubmit={async ({ email }) => updateEmail({ email, id })}
      >
        <Form className="needs-validation" noValidate>
          <div className="row">
            <TextInput
              placeholder={localize('Email')}
              name="email"
              size="col-sm col-md-5"
            />
            <div className="col-sm-12 col-md-2">
              <button type="submit" className="btn btn-primary col-12">
                {localize('Submit')}
              </button>
            </div>
          </div>
        </Form>
      </Formik>
      <hr></hr>
      <h5>{localize('Change Password')}</h5>
      <Formik
        initialValues={{
          password: '',
          confirmedPassword: '',
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .min(8, localize('Must be 8 characters or more'))
            .max(45, localize('Must be 45 characters or less'))
            .required(localize('Required')),
          confirmedPassword: Yup.string()
            .required(localize('Required'))
            .oneOf(
              [Yup.ref('password'), null],
              localize('Passwords must match')
            ),
        })}
        onSubmit={async ({ password, confirmedPassword }) => {
          updatePassword({
            password,
            confirmedPassword,
            id,
          });
        }}
      >
        <Form className="needs-validation" noValidate>
          <div className="row">
            <PasswordInput
              placeholder={localize('Password')}
              name="password"
              size="col-sm col-md-5"
            />
            <PasswordInput
              placeholder={localize('Confirm Password')}
              name="confirmedPassword"
              size="col-sm col-md-5"
            />
            <div className="col-sm-12 col-md-2">
              <button type="submit" className="btn btn-primary col-12">
                {localize('Submit')}
              </button>
            </div>
          </div>
        </Form>
      </Formik>
      <hr></hr>
      <h5>{localize('Delete Account')}</h5>
      <div className="row">
        <div className="col-sm-12 col-md-2 mb-3">
          <button
            type="submit"
            className={buttonClasses}
            data-toggle="collapse"
            href="#confirm-delete"
            onClick={() => setIsDeleting(!isDeleting)}
          >
            {isDeleting ? localize('Cancel') : localize('Delete')}
          </button>
        </div>
      </div>
      <div className="row collapse justify-content-between" id="confirm-delete">
        <div className="col-sm-12 col-md-5 mb-4">
          <h6>{localize('Are you sure you want to delete your account?')}</h6>
        </div>
        <div className="col-sm-12 col-md-3">
          <button
            type="submit"
            className="btn btn-danger col-12"
            onClick={() => {
              UserAPI.removeUser(id);
              dispatch(AuthActions.logout());
            }}
          >
            {localize('Confirm Delete')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Account;

import { useQuery } from "react-query";
import { ChampionshipEventCategoryRequirementAPI } from "api/admin/championshipEventCategoryRequirement";
import { CHAMPIONSHIP_EVENT_CATEGORY_REQUIREMENTS } from "helpers/constants";

export default function useChampionshipEventCategoryRequirements(
  championshipId
) {
  return useQuery(
    [CHAMPIONSHIP_EVENT_CATEGORY_REQUIREMENTS, championshipId],
    () =>
      ChampionshipEventCategoryRequirementAPI.getAllForChampionship(
        championshipId
      ),
    {
      enabled: !!championshipId,
    }
  );
}

import { GET, POST } from "api/config";

const BadgeAPI = {
  async generateBadgePDFs() {
    return await POST(`badges`);
  },
  async generateBadgesForTeam(id) {
    return await POST(`badges/team/${id}`);
  },
  async downloadTeamBadgesPDF({ competitionId, teamId }) {
    return await GET(
      `badges/competition/${competitionId}/team/${teamId}/download`
    );
  },
};

export { BadgeAPI };

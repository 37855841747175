import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import NanduForm from "scenes/NanduForm";
import NotFound from "scenes/NotFound";
import Account from "../../scenes/Account";
import Checkout from "../../scenes/Checkout";
import DrawLots from "../../scenes/DrawLots";
import MyResults from "../../scenes/MyResults";
import Participants from "../../scenes/Participants";
import Tickets from "../../scenes/Tickets";
import VideoSubmissions from "../../scenes/VideoSubmissions";

function HomeRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <Redirect to={`${path}participants`} />
      </Route>
      <Route path={`${path}account`}>
        <Account />
      </Route>
      <Route path={`${path}tickets`}>
        <Tickets />
      </Route>
      <Route path={`${path}lots`}>
        <DrawLots />
      </Route>
      <Route path={`${path}participants`}>
        <Participants />
      </Route>
      <Route path={`${path}nanduform`}>
        <NanduForm />
      </Route>
      <Route path={`${path}checkout`}>
        <Checkout />
      </Route>
      <Route path={`${path}video-submissions`}>
        <VideoSubmissions />
      </Route>
      <Route path={`${path}my-results`}>
        <MyResults />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

export default HomeRouter;

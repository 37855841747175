import { useMutation, useQueryCache } from "react-query";
import { EventParticipationAPI } from "api/eventParticipation";
import { EVENT_PARTICIPATION, EVENT_FEEDBACK } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useUpdateEventParticipationFeedback() {
  const queryCache = useQueryCache();

  return useMutation(EventParticipationAPI.updateEventParticipationFeedback, {
    onSuccess: ({ message }) => {
      queryCache.invalidateQueries(EVENT_FEEDBACK);
      queryCache.invalidateQueries(EVENT_PARTICIPATION);

      dispatchToast({ message, type: "success" });
    },
  });
}

import { useQuery } from "react-query";
import { EventParticipationAPI } from "api/eventParticipation";
import { EVENT_PARTICIPATION } from "helpers/constants";

export default function useOneEventParticipation(id) {
  return useQuery(
    [EVENT_PARTICIPATION, id],
    () => EventParticipationAPI.getOneForVideo(id),
    {
      enabled: !!id,
    }
  );
}

import { useMutation, useQueryCache } from "react-query";
import TicketPurchasesAPI from "api/ticketPurchase";
import { USER_TICKET_PURCHASES } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useRemoveTicketPurchases() {
  const queryCache = useQueryCache();

  return useMutation(TicketPurchasesAPI.remove, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(USER_TICKET_PURCHASES);
      dispatchToast({ message, type: "success" });
      onSuccess();
    },
  });
}

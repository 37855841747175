import { useMutation, useQueryCache } from "react-query";
import { EventFeedbackAPI } from "api/admin/eventFeedback";
import { EVENT_FEEDBACK } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useRemoveEventFeedback(hideToast) {
  const queryCache = useQueryCache();

  return useMutation(EventFeedbackAPI.remove, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries([EVENT_FEEDBACK, "duplicates"]);

      if (!hideToast) {
        dispatchToast({ message, type: "success" });
      }

      onSuccess();
    },
  });
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BackToTop from "components/BackToTop";
import RingSelect from "components/RingSelect";
import useEventParticipationForJudge from "hooks/eventParticipation/useEventParticipationForJudge";
import useRing from "hooks/rings/useRing";
import { AuthSelectors } from "redux/auth";
import InPersonJudging from "scenes/Judging/components/InPersonJudging";
import OnlineJudging from "../../components/OnlineJudging";
import "./JudgingHome.scss";

function JudgingHome() {
  // What's shown here is determined by the competition start date.
  // If there are two competitions running at the same time, both will show up for that judge, so be careful.
  const [ringAndCompetitionIds, setRingAndCompetitionIds] = useState();
  const [ringIds, setRingIds] = useState();
  const [competitionIds, setCompetitionIds] = useState();
  const { id } = useSelector(AuthSelectors.getUser);
  const { data: eventParticipation } = useEventParticipationForJudge(id);
  const ringId = ringAndCompetitionIds?.ringId;
  const competitionId = ringAndCompetitionIds?.competitionId;
  const epCompetition = eventParticipation?.[competitionId];
  const epRing = epCompetition?.[ringId];
  const isVirtual = epCompetition?.isVirtual;
  const judgeId = epRing?.judgeId;
  const ep = epRing?.categories;
  const { data: ring } = useRing(ringId);

  useEffect(() => {
    if (eventParticipation) {
      setRingIds(eventParticipation?.ringIds);
      setCompetitionIds(eventParticipation?.competitionIds);
    }
  }, [eventParticipation]);

  return (
    <div className="JudgingHome">
      <div className="content">
        <RingSelect
          id={"JudgingHome"}
          onChange={(value) => setRingAndCompetitionIds(value)}
          visibleCompetitionIds={competitionIds}
          visibleRingIds={ringIds}
        />
        {isVirtual ? (
          <OnlineJudging eventParticipation={ep} judgeId={judgeId} />
        ) : (
          <InPersonJudging
            ring={ring}
            eventParticipation={epRing?.ep}
            judgeId={judgeId}
          />
        )}
        <BackToTop />
      </div>
    </div>
  );
}

export default JudgingHome;

import React from "react";
import TextInput from "components/TextInput";
import useLocalize from "hooks/useLocalize";
import styles from "./USAWKFMembershipField.module.scss";

export default () => {
  const localize = useLocalize();

  return (
    <>
      <div className="row">
        <TextInput label={localize("USAWKF ID")} name="usawkfId" />
      </div>
      <p className="mb-5">
        {localize("Don't have a USAWKF Membership ID?")}{" "}
        <a
          className={styles.link}
          href="https://usawkf.org/membership-application/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {localize("Sign up here")}
        </a>
      </p>
    </>
  );
};

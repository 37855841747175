import React from 'react';
import debounce from 'debounce-async';
import AsyncSelect from 'react-select/async';

function Select({ name, disabled, displayName, fetchItems, onChange }) {
  const loadOptions = debounce(fetchItems, 300);

  return (
    <div className="form-group">
      <label htmlFor={name}>{displayName}</label>
      <AsyncSelect
        disabled={disabled}
        loadOptions={loadOptions}
        isSearchable
        onChange={onChange}
        placeholder="Start typing to search athletes"
      />
    </div>
  );
}

export default Select;

import { useQuery } from 'react-query';
import { CompetitionEventAPI } from 'api/admin/competitionEvent';
import { COMPETITION_EVENTS } from 'helpers/constants';

export default function useCompetitionEventsForRing(ringId) {
  return useQuery(
    [COMPETITION_EVENTS, ringId],
    () => CompetitionEventAPI.getAllForRing(ringId),
    {
      enabled: !!ringId,
    }
  );
}

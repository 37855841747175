import React, { useEffect, useState } from "react";
import CollapseGroup from "components/CollapseGroup";
import generateEventLabel from "helpers/generateEventLabel";
import useEventParticipationForCompetitionEventWithFeedback from "hooks/eventParticipation/useEventParticipationForCompetitionEventWithFeedback";
import ScoreTable from "./components/ScoreTable";
import ScoreTableRow from "./components/ScoreTableRow";
import "./InPersonJudging.scss";

function CollapseBody({
  ageGroup,
  ageGroupName,
  competitionEventId,
  gender,
  judgeId,
}) {
  const {
    data: participants,
  } = useEventParticipationForCompetitionEventWithFeedback(competitionEventId);
  const participantData = participants?.map((p, i) => {
    return (
      <ScoreTableRow
        competitionEventId={competitionEventId}
        eventParticipation={p}
        index={i + 1}
        key={p.id}
      />
    );
  });

  return (
    <div className="InPersonJudgingEvent">
      <div className="InPersonJudgingEventInfo d-flex justify-content-between mb-2">
        {gender && <span>Gender: {gender}</span>}
        {ageGroup && (
          <span>
            Ages: {ageGroup} ({ageGroupName})
          </span>
        )}
      </div>
      <ScoreTable>{participantData}</ScoreTable>
    </div>
  );
}

function InPersonJudging({ eventParticipation, judgeId, ring }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const tempItems = eventParticipation?.events.map((ep) => {
      const {
        code,
        gender,
        ageGroup,
        ageGroupName,
        eventName,
        competitionEventId,
      } = ep;
      const ageGroupString =
        ageGroup && ageGroupName ? `${ageGroup} (${ageGroupName})` : "";
      const label = generateEventLabel(gender, ageGroupString, eventName, code);

      return {
        content: (
          <CollapseBody
            ageGroup={ageGroup}
            ageGroupName={ageGroupName}
            competitionEventId={competitionEventId}
            gender={gender}
            judgeId={judgeId}
          />
        ),
        label,
        id: competitionEventId,
      };
    });

    setItems(tempItems);
  }, [eventParticipation]);

  return (
    <div className="InPersonJudging">
      {
        <div className="print-only">
          <h3>{ring?.name}</h3>
          <h5>{ring?.description}</h5>
        </div>
      }
      <CollapseGroup id={`in-person-judging-${ring?.id}`} items={items} />
    </div>
  );
}

export default InPersonJudging;

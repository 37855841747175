import React from "react";
import EventResultItem from "./EventResultItem";
import ChampionshipResultItem from "./ChampionshipResultItem";
import "./ResultCard.scss";

function ResultCard({ eventParticipation, isVirtual }) {
  return (
    <div className="ResultCard">
      <ul className="list-group">
        {eventParticipation?.map((ep) =>
          ep.isChampionship ? (
            <ChampionshipResultItem
              eventParticipation={ep}
              isVirtual={isVirtual}
            />
          ) : (
            <EventResultItem eventParticipation={ep} isVirtual={isVirtual} />
          )
        )}
      </ul>
    </div>
  );
}

export default ResultCard;

import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CompetitionSelect from "components/CompetitionSelect";
import useScheduleForParticipant from "hooks/schedules/useScheduleForParticipant";

export default (props) => {
  const history = useHistory();
  const [competitionId, setCompetitionId] = useState();
  const { pathname } = useLocation();
  const isStandalonePage = pathname.includes("schedule/");
  let { participantId } = useParams();

  if (!participantId) {
    participantId = props.participantId;
  }

  const { data: schedule } = useScheduleForParticipant(participantId);

  useEffect(() => {
    if (schedule && !competitionId) {
      setCompetitionId(schedule?.competitionIds[0]);
    }
  }, [schedule]);

  return schedule ? (
    <div className="ParticipantSchedule">
      {isStandalonePage && (
        <Button onClick={() => history.goBack()}>Back</Button>
      )}
      <CompetitionSelect
        id={"participant-schedule"}
        onChange={(values) => setCompetitionId(values)}
        visibleCompetitionIds={schedule?.competitionIds}
      />
      {schedule?.competitionIds.length > 1 && <hr></hr>}
      <h4>
        {schedule?.name} (ID: {participantId})
      </h4>
      <h5>{schedule?.teamName}</h5>
      {schedule?.competitions?.[competitionId]?.events?.map(
        (
          {
            code,
            name,
            ringName,
            lotOrder,
            ageGroupName,
            gender,
            groupMembers,
            rank,
            score,
          },
          id
        ) => (
          <Card key={id} className="mb-3">
            <Card.Body>
              <div className="d-flex justify-content-between">
                <h5>
                  {name} [{code}] {gender && `(${gender})`}
                </h5>
              </div>
              {ageGroupName && (
                <div className="mb-2">
                  <b>Age Group:</b> {ageGroupName}
                </div>
              )}
              <div className="d-flex justify-content-between mb-2">
                <span>
                  <b>Ring:</b> {ringName}
                </span>
                <span>
                  <b>Order:</b> {lotOrder}
                </span>
              </div>
              {groupMembers && (
                <div className="d-flex justify-content-between mb-2">
                  <span>
                    <b>Group Members:</b> {groupMembers}
                  </span>
                </div>
              )}
              {!!score && rank && (
                <div className="d-flex justify-content-between mb-2">
                  <span>
                    <b>Final Score:</b> {score}
                  </span>
                  <span>
                    <b>Rank:</b> {rank}
                  </span>
                </div>
              )}
            </Card.Body>
          </Card>
        )
      )}
    </div>
  ) : (
    // or, events not added to a ring yet
    <h5>No events registered for this athlete.</h5>
  );
};

import React from "react";
import ReactSelect from "react-select";

function Select({
  name,
  disabled,
  displayName,
  defaultValue,
  onChange,
  items,
}) {
  return (
    <div className="form-group">
      {displayName && <label htmlFor={name}>{displayName}</label>}
      <ReactSelect
        disabled={disabled}
        options={items}
        onChange={onChange}
        value={defaultValue}
        defaultValue={defaultValue}
      />
    </div>
  );
}

export default Select;

import { useMutation, useQueryCache } from 'react-query';
import { ParticipantsAPI } from 'api/participant';
import { PARTICIPANTS } from 'helpers/constants';

export default function useCreateParticipant() {
  const queryCache = useQueryCache();

  return useMutation(
    ParticipantsAPI.add,
    {
      onSuccess: (data, { onSuccess }) => {
        queryCache.invalidateQueries(PARTICIPANTS);
        onSuccess();
      },
    }
  );
}

import { useQuery } from "react-query";
import { EventParticipationAPI } from "api/eventParticipation";
import { EVENT_PARTICIPATION } from "helpers/constants";

export default function useEventParticipationForCompetitionEvent(
  competitionEventId
) {
  return useQuery(
    EVENT_PARTICIPATION,
    () => EventParticipationAPI.getAllForCompetitionEvent(competitionEventId),
    {
      enabled: !!competitionEventId,
    }
  );
}

import React, { useEffect, useState } from "react";
import CollapseGroup from "components/CollapseGroup";
import Select from "components/Modal/ModalFields/Select";
import useAllAroundResults from "hooks/championships/useAllAroundResults";
import useLocalize from "hooks/useLocalize";
import AllAroundCollapseContent from "./components/AllAroundCollapseContent";
import "./AllAroundResults.scss";

function AllAroundResults({ competitionId }) {
  const localize = useLocalize();
  const [championship, setChampionship] = useState();
  const [championshipOptions, setChampionshipOptions] = useState([]);
  const [items, setItems] = useState([]);
  const { data: championships, isLoading, isFetching } = useAllAroundResults(
    competitionId
  );

  useEffect(() => {
    if (championships?.[championship?.value]) {
      const tempItems = Object.entries(
        championships?.[championship.value]?.ageGroups
      ).map(([ageGroupName, genders]) => {
        return {
          content: (
            <AllAroundCollapseContent
              id={ageGroupName}
              championshipId={championship.value}
              genders={genders}
            />
          ),
          label: `Age Group: ${ageGroupName}`,
          id: ageGroupName,
        };
      });

      setItems(tempItems);
    }
  }, [championship, isFetching]);

  useEffect(() => {
    if (championships && !championship) {
      const opts = Object.entries(championships).map(([id, c]) => ({
        label: c.name,
        value: id,
      }));

      setChampionshipOptions(opts);
      setChampionship(opts[0]);
    }
  }, [championships]);

  return isLoading ? (
    <div className="spinner-border"></div>
  ) : (
    <div className="AllAroundResults">
      <Select
        displayName={localize("Select Championship")}
        items={championshipOptions}
        onChange={setChampionship}
        defaultValue={championship}
      />
      <CollapseGroup id={`all-around-results`} items={items} />
    </div>
  );
}

export default AllAroundResults;

import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import User from './User';

function UserRouter() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} exact>
        <User />
      </Route>
    </Switch>
  );
}

export default UserRouter;
import { GET, POST, PUT, DELETE } from "../config";

const TeamAPI = {
  async getAll() {
    const { teams } = await GET("teams");

    return teams;
  },
  async getById(id) {
    const { team } = await GET(`teams/${id}`);

    return team;
  },
  async getAllParticipants(id) {
    const { participants } = await GET(`teams/${id}/participants`);

    return participants;
  },
  async getAllParticipantsForCompetition(competitionId) {
    const { teams } = await GET(
      `teams/participants/competition/${competitionId}`
    );

    return teams;
  },
  async getAllMedalsForCompetition(competitionId) {
    const { teams } = await GET(`teams/medals/competition/${competitionId}`);

    return teams;
  },
  async add(teamInput) {
    return await POST(`teams`, { teamInput });
  },
  async update(teamInput) {
    const { id } = teamInput;

    return await PUT(`teams/${id}`, { teamInput });
  },
  async remove({ id }) {
    return await DELETE(`teams/${id}`);
  },
  async recover(id) {
    return await POST(`teams/${id}/recover`);
  },
};

export { TeamAPI };

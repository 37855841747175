import React from "react";
import { Badge } from "react-bootstrap";
import useRemoveChampionshipEventCategoryRequirements from "hooks/championshipEventCategoryRequirements/useRemoveChampionshipEventCategoryRequirements";
import "./EventCategoryRequirementBadge.scss";

export default function EventCategoryRequirementBadge({ id, name }) {
  const [
    removeChampionshipEventCategoryRequirement,
  ] = useRemoveChampionshipEventCategoryRequirements();
  return (
    <Badge
      className="EventCategoryRequirementBadge mb-1 mr-2"
      variant="primary"
    >
      {name}
      <span
        className="ion-close-circled ml-2"
        onClick={() => removeChampionshipEventCategoryRequirement(id)}
      ></span>
    </Badge>
  );
}

import { GET } from '../config';

const MasterSheetAPI = {
  async get() {
    const { list } = await GET('masterSheet');

    return list;
  },
};

export { MasterSheetAPI };

const NanduFormSelectors = {
  getFormType(store) {
    return store.nanduForm.nanduFormType;
  },
  getFormTypes(store) {
    return store.nanduForm.nanduFormTypes;
  },
  getWushuStyle(store) {
    return store.nanduForm.wushuStyle;
  },
  getWushuStyles(store) {
    return store.nanduForm.wushuStyles;
  },
  getSections(store) {
    return store.nanduForm.sections;
  },
  getNanduScores(store) {
    return store.nanduForm.nanduScores;
  },
  getIsValidNanduForm(store) {
    return store.nanduForm.nanduScores.isValidNanduForm;
  },
  getIsValidMovementForm(store) {
    return store.nanduForm.movementScores.isValidMovementForm;
  },
  getAllRequiredMovements(store) {
    return store.nanduForm.movementScores.allRequiredMovements;
  },
  getNanduPDF(store) {
    return store.nanduForm.nanduPDF.pdf;
  },
  getNanduPDFFilename(store) {
    return store.nanduForm.nanduPDF.filename;
  },
  getNanduPDFFirstName(store) {
    return store.nanduForm.nanduPDF.userInfo.firstName;
  },
  getNanduPDFLastName(store) {
    return store.nanduForm.nanduPDF.userInfo.lastName;
  },
  getNanduPDFAssociation(store) {
    return store.nanduForm.nanduPDF.userInfo.association;
  },
  getNanduPDFGender(store) {
    return store.nanduForm.nanduPDF.userInfo.gender;
  },
  getNanduPDFHeight(store) {
    return store.nanduForm.nanduPDF.userInfo.height;
  },
  getNanduPDFEmail(store) {
    return store.nanduForm.nanduPDF.userInfo.email;
  },
  getNanduPDFPhone(store) {
    return store.nanduForm.nanduPDF.userInfo.phone;
  },
  getConnections(store) {
    return store.nanduForm.connections;
  },
  getShowNanduModal(store) {
    return store.nanduForm.showNanduModal;
  },
  getMovements(store) {
    return store.nanduForm.movements;
  },
  getNandu(store) {
    return store.nanduForm.nandu;
  },
  getSelectedNandu(store) {
    return store.nanduForm.selectedNandu;
  },
};

export { NanduFormSelectors };
import React, { useState, useRef } from "react";
import classNames from "classnames";
import Checkbox from "components/Checkbox";
import useLocalize from "hooks/useLocalize";
import NestedTableItem from "../NestedTableItem";
import "./TableItem.scss";

function TableItem({
  item,
  itemClick,
  headingData,
  checkboxOnChange,
  childTableData,
  selected,
}) {
  const localize = useLocalize();
  const [showChildren, setShowChildren] = useState(false);
  const classes = classNames("TableItem", { selected });
  const checkboxRef = useRef();
  const html = [
    <tr
      key={"tr"}
      className={classes}
      onClick={(e) => {
        // child data trumps all
        if (childTableData) {
          setShowChildren(!showChildren);
        } else if (e.target !== checkboxRef.current) {
          // check for checkbox click
          if (itemClick) {
            itemClick(item);
          }
        }
      }}
    >
      {Object.keys(headingData).map((key) => {
        if (key === "select") {
          return (
            <td key={key} className="td-select-checkbox">
              <Checkbox
                checked={!!selected}
                ref={checkboxRef}
                onChange={() => checkboxOnChange(item)}
              />
            </td>
          );
        }

        const cellData = item[key] || "-";
        let cellDataMarkup = cellData;

        if (typeof cellData === "string") {
          cellDataMarkup = <span>{localize(cellData)}</span>;
        }

        if (cellData?.isCheckbox) {
          return (
            <td key={key} className="td-select-checkbox">
              <Checkbox
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                {...cellData.props}
              />
            </td>
          );
        }

        return <td key={key}>{cellDataMarkup}</td>;
      })}
    </tr>,
  ];

  if (childTableData && showChildren) {
    const childItems = item[childTableData.itemsKey];

    html.push(
      <NestedTableItem
        key={"nested-tr"}
        data={{ ...childTableData, items: childItems }}
        itemClick={childTableData.itemClick}
      />
    );
  }

  return html.map((i) => i);
}

export default TableItem;

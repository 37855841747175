import React from "react";
import Router from "./components/Router";
import "./Schedule.scss";

function Schedule() {
  return (
    <div className="Schedule">
      <div className="Schedule-content container">
        <Router />
      </div>
    </div>
  );
}

export default Schedule;

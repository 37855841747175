import React from 'react';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import { NanduFormActions } from 'redux/nanduForm';
import NanduInput from './NanduInput';
import './NanduSection.css';

function NanduSection({ section, sectionId, formType }) {
  const dispatch = useDispatch();
  const selectedFormTypeInput = section[formType.name.toLowerCase()];

  function addSectionInput() {
    dispatch(NanduFormActions.addSectionInput({
      sectionId,
      nanduIndex: selectedFormTypeInput.length,
    }));
  }

  function canMove({ data }) {
    return data.element.code === '+';
  }

  function dropSectionInput() {
    dispatch(NanduFormActions.dropSectionInput());
  }

  return (
    <div className="nanduSection">
      <hr className="break" />
      <div className="title">
        <h2>Section {section.id}</h2>
      </div>
      <Draggable handle=".nanduInputWrap" disabled={canMove} onStop={dropSectionInput}>
        <div className="nanduInputWrap">
          { selectedFormTypeInput.map((nandu, index) => <NanduInput nandu={nandu} nanduIndex={index} sectionId={sectionId} key={index}></NanduInput>) }
          <span className="addNandu" onClick={addSectionInput}><span className="ion-plus-circled"></span></span>
        </div>
      </Draggable>
    </div>
  );
}

export default NanduSection;

import { useMutation, useQueryCache } from "react-query";
import { ChampionshipAPI } from "api/admin/championship";
import { CHAMPIONSHIPS } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useUpdateChampionship() {
  const queryCache = useQueryCache();

  return useMutation(ChampionshipAPI.update, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(CHAMPIONSHIPS);
      dispatchToast({ message, type: "success" });
      onSuccess();
    },
  });
}

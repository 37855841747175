import { GET } from 'api/config';

const TournamentAPI = {
  async getCurrentTournament() {
    const { tournament } = await GET('tournaments/current');

    return tournament;
  },
};

export { TournamentAPI };

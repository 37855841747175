import { useMutation, useQueryCache } from 'react-query';
import { EventFeedbackAPI } from 'api/admin/eventFeedback';
import { EVENT_FEEDBACK, EVENT_PARTICIPATION } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useCreateEventFeedback(hideToast) {
  const queryCache = useQueryCache();

  return useMutation(EventFeedbackAPI.add, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(EVENT_FEEDBACK);
      queryCache.invalidateQueries(EVENT_PARTICIPATION);

      if (!hideToast) {
        dispatchToast({ message, type: 'success' });
      }

      onSuccess();
    },
  });
}

import React, { useState } from "react";
import Checkbox from "components/Checkbox";
import useChampionshipEventCategoryRequirements from "hooks/championshipEventCategoryRequirements/useChampionshipEventCategoryRequirements";
import useRegisterForChampionship from "hooks/championships/useRegisterForChampionship";
import useDeregisterForChampionship from "hooks/championships/useDeregisterForChampionship";
import useLocalize from "hooks/useLocalize";
import "./ChampionshipRegistration.scss";

export default function ChampionshipRegistration({
  championshipId,
  isPastDeadline,
  name,
  participantId,
  registered,
  requiresMembership,
}) {
  const localize = useLocalize();
  const { data: requirements } = useChampionshipEventCategoryRequirements(
    championshipId
  );
  const [showDetails, setShowDetails] = useState(false);
  const [registerForChampionship] = useRegisterForChampionship();
  const [deregisterForChampionship] = useDeregisterForChampionship();

  function onChange(checked) {
    if (!checked) {
      deregisterForChampionship({
        championshipId,
        participantId,
      });
    } else {
      registerForChampionship({
        championshipId,
        participantId,
      });
    }
  }

  return (
    <div className="ChampionshipRegistration">
      <div className="row">
        <label className="form-label col-8" htmlFor={name}>
          <b>
            {localize("Register for")}: {name}
          </b>
        </label>
        <div className="col-1">
          <Checkbox
            checked={registered}
            disabled={isPastDeadline}
            id={name}
            onChange={onChange}
            useBootstrapClasses
          />
        </div>
        <div className="col-3">
          <button className="link" onClick={() => setShowDetails(!showDetails)}>
            {showDetails ? localize("Hide Details") : localize("Show Details")}
          </button>
        </div>
      </div>
      {showDetails && requirements && (
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <h6>{localize("Requirements")}:</h6>
              {requiresMembership && (
                <div className="and-requirement">
                  1) Requires USAWKF Membership
                </div>
              )}
              {Object.values(requirements).map((requirement, i) => {
                const indexLabel = requiresMembership ? i + 1 : i;
                let requirementLines = [];

                requirement.forEach(({ name }, index) => {
                  let text = name;

                  if (index !== 0) {
                    text = `${"or "} ${text}`;
                  }

                  const content =
                    index === 0 ? (
                      <span key={index}>{text}</span>
                    ) : (
                      <div key={index} className="or-requirement">
                        {text}
                      </div>
                    );

                  requirementLines.push(content);
                });

                return (
                  <div key={indexLabel} className="and-requirement">
                    {indexLabel + 1}) {requirementLines}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthActions } from 'redux/auth';
import useLocalize from 'hooks/useLocalize';
import './Logout.scss';

function Logout({ className, children, to }) {
  const dispatch = useDispatch();
  const localize = useLocalize();
  const history = useHistory();
  const body = children || localize('Logout');
  const classNames = `Logout ${className}`;

  function logoutHandler(e) {
    e.preventDefault();
    dispatch(AuthActions.logout());

    if (to) {
      history.push(to);
    }
  }

  return (
    <span className={classNames} onClick={logoutHandler}>
      {body}
    </span>
  );
}

export default Logout;

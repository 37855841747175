import React, { useState } from "react";
import Select from "components/Modal/ModalFields/Select";
import useEventCategories from "hooks/eventCategories/useEventCategories";
import useChampionshipEventCategoryRequirements from "hooks/championshipEventCategoryRequirements/useChampionshipEventCategoryRequirements";
import useCreateChampionshipEventCategoryRequirements from "hooks/championshipEventCategoryRequirements/useCreateChampionshipEventCategoryRequirements";
import EventCategoryRequirementBadge from "../EventCategoryRequirementBadge";

function AddChampionshipRequirements({ championshipId }) {
  const { data: eventCategories } = useEventCategories();
  const { data: requirements } = useChampionshipEventCategoryRequirements(
    championshipId
  );
  const [
    createChampionshipEventCategoryRequirements,
  ] = useCreateChampionshipEventCategoryRequirements();
  const [eventCategoryIds, setEventCategoryIds] = useState([null]);
  const categories =
    eventCategories
      ?.sort((a, b) => a.order - b.order)
      .map(({ id, name }) => ({ value: id, label: name })) || [];

  function submitRequirement(e) {
    e.preventDefault();

    createChampionshipEventCategoryRequirements({
      championshipId,
      eventCategoryIds,
      onSuccess: () => {
        setEventCategoryIds([null]);
      },
    });
  }

  function addExtraRequirement(e) {
    e.preventDefault();
    setEventCategoryIds([...eventCategoryIds, null]);
  }

  return (
    <div className="row form-group">
      <div className="col">
        <label>Event Category Requirements</label>
        <div className="card card-body bg-light">
          {requirements && (
            <div className="mb-3">
              {Object.entries(requirements).map(([id, requirement], i) => {
                let badgeName = "";

                requirement.forEach(({ name }, index) => {
                  badgeName += name;

                  if (index < requirement.length - 1) {
                    badgeName += " OR ";
                  }
                });

                return (
                  <EventCategoryRequirementBadge
                    key={i}
                    id={id}
                    name={badgeName}
                  />
                );
              })}
            </div>
          )}
          {eventCategoryIds.map((item, index) => {
            return (
              <Select
                key={index}
                items={categories}
                onChange={(c) =>
                  setEventCategoryIds([
                    ...eventCategoryIds.slice(0, index),
                    c.value,
                    ...eventCategoryIds.slice(
                      index + 1,
                      eventCategoryIds.length
                    ),
                  ])
                }
              />
            );
          })}
          <div>
            <button onClick={addExtraRequirement} className="btn btn-primary">
              Add Alternative Category
            </button>
            <button
              onClick={submitRequirement}
              className="btn btn-success float-right"
            >
              Submit Category Requirement
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddChampionshipRequirements;

/* eslint-disable no-case-declarations */
import dotProp from 'dot-prop-immutable';
import clone from 'clone';
import * as actionTypes from './actionTypes';
import { calculateNanduScore } from './helpers/calculateScore';
import generatePDF from './helpers/generatePDF';

const nandus = {
  sections: [
    {
      id: 1,
      nandu: [],
      movement: [],
    },
    {
      id: 2,
      nandu: [],
      movement: [],
    },
    {
      id: 3,
      nandu: [],
      movement: [],
    },
    {
      id: 4,
      nandu: [],
      movement: [],
    },
  ],
  showNanduModal: false,
  selectedNandu: {
    sectionId: null,
    nanduIndex: null,
  },
  nanduScores: {
    totalScore: 0,
    totalScoreIsMaxed: false,
    jumpScore: 0,
    jumpScoreIsMaxed: false,
    connectionScore: 0,
    connectionScoreIsMaxed: false,
    threeSectionsNotEmpty: false,
    isValidNanduForm: false,
  },
  movementScores: {
    isValidMovementForm: false,
    allRequiredMovements: false,
  },
  nanduPDF: {
    filename: '',
    pdf: {},
    userInfo: {
      firstName: '',
      lastName: '',
      association: '',
      gender: '',
      height: '',
      email: '',
      phone: '',
    },
  },
  wushuStyle: '',
  wushuStyles: null,
  nanduFormType: '',
  nanduFormTypes: null,
  movements: null,
  movementCodes: null,
  nandu: null,
  connections: null,
};

function NanduFormReducer(state = nandus, action = {}) {
  switch (action.type) {
    case actionTypes.OPEN_NANDU_MODAL:
      return Object.assign({}, state, {
        showNanduModal: true,
        selectedNandu: {
          sectionId: action.sectionId,
          nanduIndex: action.nanduIndex,
        },
      });
    case actionTypes.CLOSE_NANDU_MODAL:
      return Object.assign({}, state, {
        showNanduModal: false,
        selectedNandu: {
          sectionId: nandus.selectedNandu.sectionId,
          nanduIndex: nandus.selectedNandu.nanduIndex,
        },
      });
    case actionTypes.ADD_SECTION_INPUT:
      return dotProp.set(state, `sections.${action.sectionId}.${state.nanduFormType.name.toLowerCase()}`, list => [...list, {}]);
    case actionTypes.REMOVE_SECTION_INPUT:
      return dotProp.delete(state, `sections.${action.sectionId}.${state.nanduFormType.name.toLowerCase()}.${action.nanduIndex}`);
    case actionTypes.CHANGE_MOVEMENT:
      return dotProp.set(
        state,
        `sections.${state.selectedNandu.sectionId}.${state.nanduFormType.name.toLowerCase()}.${state.selectedNandu.nanduIndex}`,
        action.movement,
      );
    case actionTypes.CALCULATE_NANDU_SCORE:
      const sectionsCopy = clone(state.sections);
      const calculatedScores = calculateNanduScore(sectionsCopy, state.connections, action.removedConnection);
      calculatedScores.nanduScores.isValidNanduForm = calculatedScores.nanduScores.jumpScoreIsMaxed
        && calculatedScores.nanduScores.connectionScoreIsMaxed
        && calculatedScores.nanduScores.totalScoreIsMaxed
        && calculatedScores.nanduScores.threeSectionsNotEmpty;
      return Object.assign({}, state, calculatedScores);
    case actionTypes.SAVE_PDF:
      return Object.assign({}, state, {
        nanduPDF: {
          filename: action.filename,
          pdf: generatePDF(state, action.userInfo),
          userInfo: action.userInfo,
        },
      });
    case actionTypes.CHANGE_WUSHU_STYLE:
      return Object.assign({}, state, {
        sections: nandus.sections,
        nanduScores: nandus.nanduScores,
        movementScores: nandus.movementScores,
        wushuStyle: action.wushuStyle,
      });
    case actionTypes.CHANGE_NANDU_FORM_TYPE:
      return Object.assign({}, state, {
        nanduFormType: action.nanduFormType,
      });
    case actionTypes.FETCH_NANDU_DATA:
      const connections = {};
      action.data.connections.forEach((connection) => {
        const { combo } = connection;
        connections[combo] = connection;
      });
      action.data.connections = connections;
      return Object.assign({}, state, action.data);
    case actionTypes.VERIFY_ALL_REQUIRED_MOVEMENTS:
      const movementScores = {
        allRequiredMovements: action.hasRequiredMovements,
        isValidMovementForm: action.hasRequiredMovements,
      };
      return dotProp.set(state, 'movementScores', movementScores);
    default:
      return state;
  }
}

export { NanduFormReducer };

import React from "react";
import CheckoutInfo from "components/CheckoutInfo";
import useLocalize from "hooks/useLocalize";
import useCheckout from "hooks/checkout/useCheckout";
import useMakePayment from "hooks/checkout/useMakePayment";
import useTournament from "hooks/tournament/useCurrentTournament";
import PaypalButton from "./components/PaypalButton";
import "./Checkout.scss";

function Checkout() {
  const localize = useLocalize();
  const [makePayment] = useMakePayment();
  const { data: checkoutInfo } = useCheckout();
  const { data: tournament } = useTournament();
  let amountOwed = checkoutInfo
    ? (checkoutInfo.total - checkoutInfo.paid).toFixed(2)
    : 0;

  return (
    <div className="Checkout container">
      <div className="row">
        <div className="col">
          <h2>{localize("Checkout")}</h2>
        </div>
      </div>
      <CheckoutInfo checkoutInfo={checkoutInfo} />
      <div className="row justify-content-center">
        <div className="col-md-3">
          {amountOwed > 0 && (
            <PaypalButton
              onSuccess={(validationId, amount) => {
                makePayment({
                  validationId,
                  amount,
                  tournamentId: tournament.id,
                });
              }}
              total={amountOwed}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Checkout;

import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthSelectors } from "redux/auth";
import LoginForm from "components/LoginForm";

function CheckInLogin() {
  const { path } = useRouteMatch();
  const isAdmin = useSelector(AuthSelectors.isAdmin);
  const isJudge = useSelector(AuthSelectors.isJudge);

  return (
    <LoginForm
      allowUsername
      isAuthenticated={isJudge || isAdmin}
      redirectTo={`${path}/home`}
    />
  );
}

export default CheckInLogin;

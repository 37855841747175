import React from "react";
import classnames from "classnames";

export default function TransactionItem({
  name,
  cost,
  isSubtotal,
  header = false,
  alternate = false,
}) {
  const classNames = classnames(
    "list-group-item",
    "d-flex",
    "justify-content-between",
    "align-items-center",
    { "list-item-header": header },
    { "list-item-alternate": alternate }
  );
  const textClassNames = classnames("card-text", {
    "font-weight-bold": isSubtotal,
  });
  const costClassNames = classnames("badge", { "badge-info": isSubtotal });

  return (
    <li className={classNames}>
      <span className={textClassNames}>{name}</span>
      <h5>
        <span className={costClassNames}>${cost}</span>
      </h5>
    </li>
  );
}

import React from "react";
import { useHistory } from "react-router-dom";
import Table from "components/admin/Table";
import CONFIRM from "helpers/confirm";
import useEventParticipationForCompetitionEvent from "hooks/eventParticipation/useEventParticipationForCompetitionEvent";
import useUpdateEventParticipationRemainingLotOrders from "hooks/eventParticipation/useUpdateEventParticipationRemainingLotOrders";

function EventParticipantList({ item }) {
  const { data: eventParticipation } = useEventParticipationForCompetitionEvent(
    item?.id
  );
  const [
    updateEventParticipationRemainingLotOrders,
  ] = useUpdateEventParticipationRemainingLotOrders();
  const data = {
    headingData: {
      lotOrder: "Lot Order",
      name: "Name",
      teamName: "School",
      age: "Age",
    },
    items: eventParticipation || [],
  };
  const history = useHistory();

  function goToAthlete(participant) {
    history.push(`/adm/home/participant/${participant.participantId}`);
  }

  return (
    <div className="CompetitionModalContent">
      <h4>Participants Lot Order</h4>
      <button
        className="btn btn-primary"
        onClick={() => {
          CONFIRM(
            () => updateEventParticipationRemainingLotOrders(item?.id),
            `Are you sure you want to draw the remaining lots for ${item.name}?`
          );
        }}
      >
        Draw Remaining Lots
      </button>
      <Table data={data} itemClick={goToAthlete} />
    </div>
  );
}

export default EventParticipantList;

import React from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { TeamAPI } from 'api/admin/team';
import { PARTICIPANT, TEAMS } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useRemoveTeam() {
  const queryCache = useQueryCache();

  return useMutation(TeamAPI.remove, {
    onSuccess: (
      { message },
      { id, onSuccess = () => {}, handleUndoDelete = () => {} }
    ) => {
      queryCache.invalidateQueries(TEAMS);
      queryCache.invalidateQueries(PARTICIPANT);

      const restoreMessage = (
        <>
          {message}{' '}
          <span className="undo-link" onClick={() => handleUndoDelete(id)}>
            Click here to undo.
          </span>
        </>
      );

      dispatchToast({
        message: restoreMessage,
        type: 'success',
        autohide: false,
      });
      onSuccess();
    },
  });
}

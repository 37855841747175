import React from "react";
import Accordion from "components/Accordion";
import hasActiveLotDraw from "helpers/hasActiveLotDraw";
import useParticipants from "hooks/participants/useParticipants";
import useEventParticipation from "hooks/eventParticipation/useEventParticipation";
import useTournament from "hooks/tournament/useCurrentTournament";
import useLocalize from "hooks/useLocalize";
import DrawLotPane from "./DrawLotPane";

function DrawLots() {
  let participantList = [];
  const { data: participants } = useParticipants();
  const {
    data: eventParticipationMap,
    isLoading: eventParticipationLoading,
  } = useEventParticipation();
  const { data: tournament } = useTournament();
  const competitions = tournament?.competitions || [];
  const localize = useLocalize();
  const hasValidDrawLotDate = tournament?.competitions.find(hasActiveLotDraw);

  if (!hasValidDrawLotDate) {
    return (
      <div className="container">
        <h4>Lot Draw Not Available</h4>
      </div>
    );
  }

  if (participants && participants.length) {
    participants.forEach((participant, index) => {
      const { id, firstName, lastName } = participant;
      const registeredForEventWithLotDraw = competitions.find((c) => {
        return c.lotDrawEndDate && eventParticipationMap?.[id]?.[c.id];
      });
      const show =
        !!registeredForEventWithLotDraw &&
        !eventParticipationLoading &&
        !!eventParticipationMap[id];

      if (show) {
        participantList.push(
          <Accordion
            id={id}
            key={index}
            title={`${firstName} ${lastName}`}
            isOpen={index === 0}
          >
            <DrawLotPane
              competitions={competitions}
              eventParticipationMap={eventParticipationMap}
              participantId={id}
            />
          </Accordion>
        );
      }
    });
  }

  return (
    <div className="DrawLots container">
      <div className="row">
        <div className="col">
          <h2>{localize("Draw Lots")}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">{participantList}</div>
      </div>
    </div>
  );
}

export default DrawLots;

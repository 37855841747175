import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { UserAPI } from 'api/user';
import dispatchToast from 'helpers/dispatchToast';
import { AuthActions } from 'redux/auth';

export default function useUpdateEmail() {
  const dispatch = useDispatch();

  return useMutation(
    UserAPI.updateEmail,
    {
      onSuccess: ({ message }, { email }) => {
        dispatchToast({ message, type: 'success' });
        dispatch(AuthActions.updateEmail(email));
      },
    }
  );
}

import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Breadcrumb from 'components/admin/Breadcrumb';
import SideNav from '../../components/SideNav';
import AthleteManager from '../AthleteManager';
import ErrorMessageManager from '../ErrorMessageManager';
import EventCategoryManager from '../EventCategoryManager';
import EventManager from '../EventManager';
import ParticipantView from '../ParticipantView';
import MasterSheet from '../MasterSheet';
import TeamManager from '../TeamManager/TeamManagerRouter';
import TournamentManagerRouter from '../TournamentManager/TournamentManagerRouter';
import UserManagerRouter from '../UserManager/UserManagerRouter';
import WebsiteMessageManager from '../WebsiteMessageManager';
import WushuStyleManager from '../WushuStyleManager';
import './AdminHome.css';

function AdminHome(props) {
  const { path, url } = useRouteMatch();

  return (
    <div className="AdminHome">
      <SideNav {...props} />
      <div className="AdminHome-content">
        <Breadcrumb url={url} />
        <Switch>
          <Route path={`${path}`} exact>
            <AthleteManager />
          </Route>
          <Route path={`${path}/tournaments`}>
            <TournamentManagerRouter />
          </Route>
          <Route path={`${path}/users`}>
            <UserManagerRouter />
          </Route>
          <Route path={`${path}/wushu-styles`}>
            <WushuStyleManager />
          </Route>
          <Route path={`${path}/website-messages`}>
            <WebsiteMessageManager />
          </Route>
          <Route path={`${path}/error-messages`}>
            <ErrorMessageManager />
          </Route>
          <Route path={`${path}/event-categories`}>
            <EventCategoryManager />
          </Route>
          <Route path={`${path}/events`}>
            <EventManager />
          </Route>
          <Route path={`${path}/teams`}>
            <TeamManager />
          </Route>
          <Route path={`${path}/participant/:id`}>
            <ParticipantView />
          </Route>
          <Route path={`${path}/masterSheet`}>
            <MasterSheet />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default AdminHome;

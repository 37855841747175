import React from 'react';
import NanduFormOptions from './components/NanduFormOptions';
import NanduHeader from './components/NanduHeader';
import Router from './components/Router';
import './NanduForm.css';

function NanduForm() {
  return (
    <>
      <script src='https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.31/pdfmake.js'></script>
      <script src='https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.31/vfs_fonts.js'></script>
      <NanduHeader />
      <div className="nanduForm">
        <NanduFormOptions />
        <Router />
      </div>
    </>
  )
}

export default NanduForm;

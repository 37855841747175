import React, { useState, useEffect } from "react";
import VideoSubmissionForm from "components/VideoSubmissionForm";
import formatPSTDate from "helpers/formatPSTDate";
import useLocalize from "hooks/useLocalize";
import useEventParticipation from "hooks/eventParticipation/useEventParticipation";

function SubmissionPane({ competitions = [], participantId }) {
  const localize = useLocalize();
  const [competition, setCompetition] = useState({});
  const { data: eventParticipationMap } = useEventParticipation();
  const competitionsWithSubmission = competitions.filter(
    ({ isVirtual, id }) => {
      const isRegistered = eventParticipationMap?.[participantId]?.[id];

      return isVirtual && isRegistered;
    }
  );
  const events = competition?.events || [];
  const submissionDeadline = competition?.submissionDeadline;
  const isPastDeadline = new Date(submissionDeadline) < new Date();

  function selectCompetition({ target: { value } }) {
    setCompetition(competitionsWithSubmission.find(({ id }) => id === +value));
  }

  useEffect(() => {
    setCompetition(competitionsWithSubmission[0]);
  }, [competitionsWithSubmission.length]);

  return (
    <div className="SubmissionPane">
      {competitionsWithSubmission.length > 1 && (
        <div className="form-group row">
          <label className="form-label col-4">
            <h5>{localize("Select Competition")}</h5>
          </label>
          <div className="col-8">
            <select className="form-control" onChange={selectCompetition}>
              {competitionsWithSubmission.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <div className="row">
        <label className="col-4">
          <h5>{localize("Deadline")}</h5>
        </label>
        <div className="col-8">
          <h6>{formatPSTDate(submissionDeadline)}</h6>
        </div>
      </div>
      <VideoSubmissionForm
        competitionId={competition?.id}
        events={events}
        eventParticipation={eventParticipationMap?.[participantId]}
        isPastDeadline={isPastDeadline}
        participantId={participantId}
      />
    </div>
  );
}

export default SubmissionPane;

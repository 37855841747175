import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import { AuthSelectors } from "redux/auth";
import NotFound from "scenes/NotFound";
import Header from "./components/Header";
import Login from "./components/JudgingLogin";
import AdminJudgingHome from "./scenes/AdminJudgingHome";
import JudgingHome from "./scenes/JudgingHome";

function JudgingRoute({ children, path }) {
  const isAdmin = useSelector(AuthSelectors.isAdmin);
  const isJudge = useSelector(AuthSelectors.isJudge);
  const isAuthorized = isAdmin || isJudge;

  return (
    <Route path={path}>
      {isAuthorized ? children : <Redirect to={{ pathname: "/judging" }} />}
    </Route>
  );
}

function Judging() {
  const isAdmin = useSelector(AuthSelectors.isAdmin);
  const { path } = useRouteMatch();

  return (
    <div className="Judging">
      <Header />
      <Switch>
        <Route path={`${path}`} exact>
          <Login />
        </Route>
        <JudgingRoute path={`${path}/home`}>
          {isAdmin ? <AdminJudgingHome /> : <JudgingHome />}
        </JudgingRoute>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default Judging;

import { GET, POST, DELETE, PUT } from '../config';

const EventCategoryAPI = {
  async getAll() {
    const { eventCategories } = await GET('eventCategories');

    return eventCategories;
  },
  add(eventCategoryInput) {
    return POST('eventCategories', eventCategoryInput);
  },
  remove(id) {
    return DELETE(`eventCategories/${id}`);
  },
  update({ id, name, order }) {
    return PUT(`eventCategories/${id}`, { name, order });
  },
};

export { EventCategoryAPI };

import { useMutation, useQueryCache } from "react-query";
import { CompetitionEventAPI } from "api/admin/competitionEvent";
import { COMPETITION_EVENTS } from "helpers/constants";

export default function useUpdateCompetitionEventRingOrders() {
  const queryCache = useQueryCache();

  return useMutation(CompetitionEventAPI.updateCompetitionEventRingOrders, {
    onSuccess: () => {
      queryCache.invalidateQueries(COMPETITION_EVENTS);
    },
  });
}

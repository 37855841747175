import { useMutation, useQueryCache } from "react-query";
import { EventParticipationAPI } from "api/eventParticipation";
import {
  CHECKOUT,
  COMPETITION_EVENT,
  COMPETITION_EVENTS,
  COMPETITIONS,
  EVENT_PARTICIPATION,
  SCHEDULE,
} from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useCreateEventParticipation() {
  const queryCache = useQueryCache();

  return useMutation(EventParticipationAPI.addEventParticipation, {
    onSuccess: ({ message }) => {
      queryCache.invalidateQueries(CHECKOUT);
      queryCache.invalidateQueries(COMPETITION_EVENT);
      queryCache.invalidateQueries(COMPETITION_EVENTS);
      queryCache.invalidateQueries(COMPETITIONS);
      queryCache.invalidateQueries(EVENT_PARTICIPATION);
      queryCache.invalidateQueries(SCHEDULE);
      dispatchToast({ message, type: "success" });
    },
  });
}

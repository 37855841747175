import React from "react";
import CompetitorCard from "components/CompetitorCard";
import TransactionItem from "components/TransactionItem";
import useLocalize from "hooks/useLocalize";

export default ({ checkoutInfo }) => {
  const localize = useLocalize();
  let amountOwed = 0;
  let amountPaid = 0;
  let amountTotal = 0;
  let totalText = (
    <span className="badge badge-success">{localize("All paid")}.</span>
  );
  let competitorCards = [];

  if (checkoutInfo) {
    const { total, paid, competitors } = checkoutInfo;

    amountTotal = total.toFixed(2);
    amountPaid = paid.toFixed(2);
    amountOwed = (total - paid).toFixed(2);
    if (amountOwed === 0 && !Object.values(competitors).length) {
      totalText = (
        <span className="badge badge-warning">{localize("No events")}.</span>
      );
    } else if (amountOwed > 0) {
      totalText = <span className="badge badge-danger">${amountOwed}</span>;
    } else if (amountOwed < 0) {
      totalText = (
        <span className="badge badge-warning">-${Math.abs(amountOwed)}</span>
      );
    }

    competitorCards = Object.values(competitors).map(
      ({ image, name, subtotal, competitions }, i) => {
        return (
          <CompetitorCard
            key={i}
            competitorName={name}
            image={image}
            subtotal={subtotal}
            competitions={competitions}
          />
        );
      }
    );
  }

  return (
    <>
      <div className="row">
        <div className="col">{competitorCards}</div>
      </div>
      {!!checkoutInfo?.tickets?.count && (
        <div className="row">
          <div className="col">
            <div className="CompetitorCard">
              <div className="card mb-2">
                <div className="card-body">
                  <ul className="list-group">
                    <TransactionItem
                      name={`${localize("Spectator Tickets")} x ${
                        checkoutInfo?.tickets?.count
                      }`}
                      cost={checkoutInfo?.tickets?.total}
                      isSubtotal
                    />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col">
          <div className="card mb-2">
            <div className="row no-gutters">
              <div className="card-body">
                <ul className="list-group">
                  {!!amountPaid && (
                    <>
                      <li className="list-group-item d-flex justify-content-between align-items-center skinny-group-item">
                        <h5 className="card-text font-weight-bold">
                          {localize("Subtotal")}
                        </h5>
                        <h5>
                          <span className="badge badge-info">
                            ${amountTotal}
                          </span>
                        </h5>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center skinny-group-item">
                        <h5 className="card-text font-weight-bold">
                          {localize("Paid")}
                        </h5>
                        <h5>
                          <span className="badge badge-success">
                            ${amountPaid}
                          </span>
                        </h5>
                      </li>
                    </>
                  )}
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <h4 className="card-text font-weight-bold">
                      {localize("Total")}
                    </h4>
                    <h5>{totalText}</h5>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function hasActiveLotDraw(competition) {
  const today = new Date();
  const testStartDatePassed =
    competition.lotDrawTestStartDate &&
    today > new Date(competition.lotDrawTestStartDate);
  const startDatePassed =
    competition.lotDrawStartDate &&
    today > new Date(competition.lotDrawStartDate);
  const endDateNotPassed =
    competition.lotDrawEndDate && today < new Date(competition.lotDrawEndDate);

  return (testStartDatePassed || startDatePassed) && endDateNotPassed;
}

import React, { useState } from "react";
import ModalTable from "components/admin/ModalTable";
import AddChampionshipRequirements from "./AddChampionshipRequirements";
import AddChampionshipAgeGroupRequirements from "./AddChampionshipAgeGroupRequirements";
import AddChampionshipGenderRequirements from "./AddChampionshipGenderRequirements";
import useChampionships from "hooks/championships/useChampionships";
import useCreateChampionship from "hooks/championships/useCreateChampionship";
import useRemoveChampionship from "hooks/championships/useRemoveChampionship";
import useUpdateChampionship from "hooks/championships/useUpdateChampionship";
import dispatchToast from "helpers/dispatchToast";

function extraModalContent(championshipId) {
  function ExtraModalContent() {
    return (
      <>
        <h4 className="mt-4">Other Championship Fields</h4>
        <AddChampionshipRequirements championshipId={championshipId} />
        <AddChampionshipAgeGroupRequirements championshipId={championshipId} />
        <AddChampionshipGenderRequirements championshipId={championshipId} />
      </>
    );
  }

  return ExtraModalContent;
}

function ChampionshipSetup({ competitionId }) {
  const { data: championships } = useChampionships(competitionId);
  const [championship, setChampionship] = useState();
  const [createChampionship] = useCreateChampionship();
  const [removeChampionship] = useRemoveChampionship();
  const [updateChampionship] = useUpdateChampionship();
  const [name, setName] = useState("");
  const [chineseName, setChineseName] = useState("");
  const [cost, setCost] = useState("");
  const [minEventScore, setMinEventScore] = useState("");
  const [minEventRank, setMinEventRank] = useState("");
  const [requiresMembership, setRequiresMembership] = useState(false);

  function setIsEditingItem(currentItem) {
    const {
      name,
      chineseName,
      cost,
      minEventScore,
      minEventRank,
      requiresMembership,
    } = currentItem;

    setName(name);
    setChineseName(chineseName);
    setCost(cost);
    setMinEventScore(minEventScore);
    setMinEventRank(minEventRank);
    setRequiresMembership(!!requiresMembership);
  }

  function setIsAddingItem() {
    setChampionship(null);
    setName("");
    setChineseName("");
    setCost("");
    setMinEventScore("");
    setMinEventRank("");
    setRequiresMembership(false);
  }

  function undoDelete({
    name,
    chineseName,
    cost,
    minEventScore,
    minEventRank,
    competitionId,
    requiresMembership,
  }) {
    createChampionship({
      name,
      chineseName,
      cost,
      minEventScore,
      minEventRank,
      competitionId,
      requiresMembership,
    });
  }

  function addItem() {
    if (name) {
      createChampionship({
        name,
        chineseName,
        cost,
        competitionId,
        requiresMembership: !!requiresMembership,
      });
    } else {
      dispatchToast({
        message: "Please provide name.",
        type: "error",
      });
    }
  }

  function removeItem({ id }) {
    removeChampionship(id);
  }

  function editItem({ id }) {
    if (name) {
      const championshipInput = {
        name,
        chineseName,
        cost,
        minEventScore,
        minEventRank,
        requiresMembership,
      };

      updateChampionship({ id, championshipInput });
    } else {
      dispatchToast({
        message: "Please provide name.",
        type: "error",
      });
    }
  }

  function processItem(item) {
    setChampionship(item);

    return item;
  }

  const tableData = {
    defaultSort: "name",
    searchableColumns: ["name"],
    headingData: {
      name: "Name",
      cost: "Cost",
    },
    items: championships || [],
    modalData: {
      fields: [
        {
          defaultValue: name,
          displayName: "Name",
          name: "name",
          onChange: (value) => setName(value),
          type: "text",
        },
        {
          defaultValue: chineseName || "",
          displayName: "Chinese Name",
          name: "chineseName",
          onChange: (value) => setChineseName(value),
          type: "text",
        },
        {
          defaultValue: cost || "",
          displayName: "Cost",
          name: "cost",
          onChange: (value) => setCost(value),
          type: "number",
        },
        {
          defaultValue: minEventScore || "",
          displayName: "Min Event Score",
          name: "minEventScore",
          onChange: (value) => setMinEventScore(value),
          type: "number",
        },
        {
          defaultValue: minEventRank || "",
          displayName: "Min Event Rank",
          name: "minEventRank",
          onChange: (value) => setMinEventRank(value),
          type: "number",
          step: 1,
        },
        {
          defaultValue: requiresMembership,
          displayName: "Requires USAWKF Membership",
          name: "requiresMembership",
          onChange: setRequiresMembership,
          type: "checkbox",
        },
      ],
    },
    itemName: "Championship",
  };

  return (
    <div className="ChampionshipSetup">
      <ModalTable
        addItem={addItem}
        data={tableData}
        processItem={processItem}
        editItem={editItem}
        removeItem={removeItem}
        setIsAddingItem={setIsAddingItem}
        setIsEditingItem={setIsEditingItem}
        undoDelete={undoDelete}
        ExtraModalContent={
          !!championship && extraModalContent(championship?.id)
        }
      />
    </div>
  );
}

export default ChampionshipSetup;

import React, { useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import useTournament from "hooks/tournament/useTournament";
import ModalAdminTable from "components/admin/ModalTable";
import dispatchToast from "helpers/dispatchToast";
import useCreateCompetition from "hooks/competitions/useCreateCompetition";
import TournamentEdit from "./TournamentEdit";
import TicketManager from "./TicketManager";
import "./CompetitionManager.css";

function CompetitionList() {
  const history = useHistory();
  const { tournamentId } = useParams();
  const { pathname } = useLocation();
  const { data: tournament } = useTournament(tournamentId);
  const [addCompetition] = useCreateCompetition();
  const [name, setName] = useState("");
  const [chineseName, setChineseName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [cost, setCost] = useState(0);
  const [eventCost, setEventCost] = useState(0);

  function setIsAddingItem() {
    setName("");
    setChineseName("");
    setStartDate("");
    setEndDate("");
    setStartTime("");
  }

  function itemClick(competition) {
    const { id } = competition;
    const competitionPage = `${pathname}/${id}`;

    history.push(competitionPage);
  }

  function addItem() {
    if (name && startDate) {
      let formattedStartTime = null;

      if (startTime) {
        formattedStartTime = `${startDate} ${startTime}:00`;
      } else {
        formattedStartTime = `${startDate} 00:00:00`;
      }

      addCompetition({
        tournamentId: +tournamentId,
        name,
        chineseName,
        startDate: startDate || null,
        endDate: endDate || null,
        startTime: formattedStartTime,
        cost: parseFloat(cost),
        eventCost: parseFloat(eventCost),
      });
    } else {
      dispatchToast({
        message: "Please fill out the name and start date.",
      });
    }
  }

  const tableData = {
    headingData: {
      name: "Name",
      chineseName: "Chinese Name",
      startDate: "Start Date",
      endDate: "End Date",
      startTime: "Start Time",
      cost: "Cost",
    },
    items: tournament
      ? tournament?.competitions.map((competition) => {
          let { startDate, endDate, startTime, cost } = competition;
          const item = {
            ...competition,
            startDate: startDate ? new Date(startDate).toDateString() : null,
            endDate: endDate ? new Date(endDate).toDateString() : null,
            startTime: startTime
              ? new Date(startTime).toLocaleTimeString()
              : null,
            cost: cost || 0,
          };

          return item;
        })
      : [],
    itemName: "Competition",
    modalData: {
      fields: [
        {
          defaultValue: name,
          displayName: "Name",
          name: "name",
          onChange: (value) => setName(value),
          type: "text",
        },
        {
          defaultValue: chineseName,
          displayName: "Chinese Name",
          name: "chineseName",
          onChange: (value) => setChineseName(value),
          type: "text",
        },
        {
          defaultValue: startDate,
          displayName: "Start Date",
          name: "startDate",
          onChange: (value) => setStartDate(value),
          type: "date",
        },
        {
          defaultValue: endDate,
          displayName: "End Date",
          name: "endDate",
          onChange: (value) => setEndDate(value),
          type: "date",
        },
        {
          defaultValue: startTime,
          displayName: "Start Time",
          name: "startTime",
          onChange: (value) => setStartTime(value),
          type: "time",
        },
        {
          defaultValue: cost,
          displayName: "Competition Cost",
          name: "cost",
          onChange: (value) => setCost(value),
          type: "number",
        },
        {
          defaultValue: eventCost,
          displayName: "Default Event Cost",
          name: "eventCost",
          onChange: (value) => setEventCost(value),
          type: "number",
        },
      ],
    },
    searchableColumns: ["name", "startDate", "endDate", "startTime", "cost"],
    defaultSort: "name",
  };

  return tournament ? (
    <div className="competitionManager">
      <h2 className="text-left">Update Tournament</h2>
      <TournamentEdit tournament={tournament} />
      <h2 className="text-left">Competitions</h2>
      <ModalAdminTable
        addItem={addItem}
        data={tableData}
        itemClick={itemClick}
        setIsAddingItem={setIsAddingItem}
      />
      <TicketManager tournamentId={tournamentId} />
    </div>
  ) : null;
}

export default CompetitionList;

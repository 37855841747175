import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "components/admin/Table";
import Select from "components/Modal/ModalFields/Select";
import useChampionshipParticipants from "hooks/championships/useChampionshipParticipants";
import useChampionships from "hooks/championships/useChampionships";

function ChampionshipParticipantsReport({ competitionId }) {
  const [championshipId, setChampionshipId] = useState(null);
  const [championshipOptions, setChampionshipOptions] = useState([]);
  const { data: participants, isLoading } = useChampionshipParticipants(
    championshipId
  );
  const { data: championships } = useChampionships(competitionId);
  const history = useHistory();
  const tableData = {
    searchableColumns: ["name", "gender", "championshipAgeGroup"],
    headingData: {
      id: "ID",
      name: "Name",
      gender: "Gender",
      championshipAgeGroup: "Age Group",
      paid: "Is Paid",
    },
    items: participants || [],
  };

  useEffect(() => {
    if (championships) {
      setChampionshipOptions(
        championships.map(({ id, name }) => ({ label: name, value: id }))
      );
    }
  }, [championships]);

  function itemClick({ id }) {
    history.push(`/adm/home/participant/${id}`);
  }

  return (
    <div className="ChampionshipParticipantsReport">
      <h3>Championship Athletes Report</h3>
      <Select
        items={championshipOptions}
        onChange={(c) => {
          setChampionshipId(c.value);
        }}
      />
      {isLoading ? (
        <div className="spinner-border mt-2" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <Table data={tableData} itemClick={itemClick} />
      )}
    </div>
  );
}

export default ChampionshipParticipantsReport;

import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  VKIcon,
  VKShareButton,
  WeiboShareButton,
  WeiboIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

function ShareButtons({ size = 32, url }) {
  return (
    <div className="ShareButtons">
      <FacebookShareButton url={url}>
        <FacebookIcon size={size} round />
      </FacebookShareButton>
      <EmailShareButton url={url}>
        <EmailIcon size={size} round />
      </EmailShareButton>
      <LineShareButton url={url}>
        <LineIcon size={size} round />
      </LineShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon size={size} round />
      </LinkedinShareButton>
      <RedditShareButton url={url}>
        <RedditIcon size={size} round />
      </RedditShareButton>
      <TelegramShareButton url={url}>
        <TelegramIcon size={size} round />
      </TelegramShareButton>
      <TwitterShareButton url={url}>
        <TwitterIcon size={size} round />
      </TwitterShareButton>
      <VKShareButton url={url}>
        <VKIcon size={size} round />
      </VKShareButton>
      <WeiboShareButton url={url}>
        <WeiboIcon size={size} round />
      </WeiboShareButton>
      <WhatsappShareButton url={url}>
        <WhatsappIcon size={size} round />
      </WhatsappShareButton>
    </div>
  );
}

export default ShareButtons;

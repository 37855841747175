import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import TextInput from "components/TextInput";
import useCreateRing from "hooks/rings/useCreateRing";
import Ring from "./components/Ring";
import TrelloCard from "./components/TrelloCard";
import "./Rings.scss";
import Modal from "components/Modal";

function Rings({ competition }) {
  const { pathname } = useLocation();
  const history = useHistory();
  const { id: competitionId, rings } = competition;
  const [isAdding, setIsAdding] = useState(false);
  const [stateRings, setStateRings] = useState(rings);
  const [createRing] = useCreateRing();

  useEffect(() => {
    setStateRings(rings);
  }, [rings]);

  return (
    <div className="Rings">
      {isAdding && (
        <Modal close={() => setIsAdding(false)}>
          <Formik
            initialValues={{ ringName: "" }}
            validationSchema={Yup.object({
              ringName: Yup.string()
                .max(45)
                .required("Please add a ring name."),
            })}
            onSubmit={async ({ ringName }) => {
              createRing({ competitionId, name: ringName });
              setIsAdding(false);
            }}
          >
            <Form className="needs-validation" noValidate>
              <h5>Add Ring</h5>
              <TextInput
                placeholder="Enter ring name..."
                name="ringName"
                size="mb-1"
                autoFocus
              />
              <button
                type="submit"
                className="btn btn-success float-right mt-3"
              >
                Submit
              </button>
            </Form>
          </Formik>
        </Modal>
      )}
      <div className="ring-container d-flex mb-3">
        {Object.values(stateRings).map((ring) => {
          const { id, events, name, description, judges } = ring;
          const isNoRing = name === "NO RING";

          return (
            <Ring key={id} name={name} id={id} description={description}>
              <TrelloCard
                text="Events"
                badgeText={events?.length}
                onClick={
                  isNoRing
                    ? null
                    : () => history.push(`${pathname}/rings/${id}/events`)
                }
              />
              {!isNoRing && (
                <TrelloCard
                  text="Judges"
                  badgeText={judges?.length}
                  onClick={() => history.push(`${pathname}/rings/${id}/judges`)}
                />
              )}
            </Ring>
          );
        })}
      </div>
      <div className="btn btn-primary" onClick={() => setIsAdding(true)}>
        <span className="ion-plus mr-2 ml-2"></span>
        Add a ring
      </div>
    </div>
  );
}

export default Rings;

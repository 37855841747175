import moment from "moment-timezone";

export default function formatPSTDate(date) {
  if (!date) {
    return null;
  }

  return (
    moment(date)?.tz("America/Los_Angeles")?.format("MM/DD/YYYY") ||
    new Date(date).toDateString()
  );
}

import { GET, POST, DELETE, PUT } from '../config';

const EventAPI = {
  async getAll() {
    const { events } = await GET('events');

    return events;
  },
  addEvent(eventInput) {
    return POST(`events`, { eventInput });
  },
  updateEvent({ id, eventInput }) {
    return PUT(`events/${id}`, { eventInput });
  },
  removeEvent(id) {
    return DELETE(`events/${id}`);
  },
};

export { EventAPI };

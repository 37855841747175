/* eslint-disable no-plusplus */
function fillBotRow(total) {
  const arr = [{}, {}];
  for (let i = 0; i < total; i++) {
    arr.push({ text: '', style: 'blankCell' });
  }
  return arr;
}
function generatePDF(state, userInfo) {
  let header = 'Application and Assessment Form for Degree of Difficulty of Optional Taolu';
  let tableHeader = 'Degree of difficulty for movements, connections and innovations';
  let widths = [18, 18, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25];
  const body = [];
  const prefixes = ['1st', '2nd', '3rd', '4th'];

  if (state.nanduFormType.formType === 'NANDU') {
    const NANDU_SECTION_LIMIT = 14;
    const botRow = fillBotRow(NANDU_SECTION_LIMIT);

    for (let i = 0; i < state.sections.length; i++) {
      const topRow = [
        {
          text: `${prefixes[i]} Segment`,
          colSpan: 2,
          rowSpan: 3,
          style: 'segmentTitle',
        }, {},
      ];
      const midRow = [{}, {}];

      for (let j = 0; j < NANDU_SECTION_LIMIT; j++) {
        let code = '';
        let value = '';
        if (state.sections[i].nandu[j] && state.sections[i].nandu[j].code) {
          code = state.sections[i].nandu[j].code;
          value = state.sections[i].nandu[j].formValue;
        }
        topRow.push({
          text: code || '',
          style: code ? 'cell' : 'blankCell',
        });
        midRow.push({
          text: value || '',
          style: value ? 'cell' : 'blankCell',
        });
      }
      body.push(topRow);
      body.push(midRow);
      body.push(botRow);
    }
    body.push([
      {
        text: 'Total Value',
        colSpan: 2,
        style: 'scoreHeader',
      }, {},
      {
        text: state.nanduScores.totalScore || 0,
        colSpan: 2,
        style: 'score',
      }, {},
      {
        text: 'For move-ments',
        colSpan: 2,
        style: 'scoreHeader',
      }, {},
      {
        text: state.nanduScores.jumpScore || 0,
        colSpan: 2,
        style: 'score',
      }, {},
      {
        text: 'For connec-tions',
        colSpan: 2,
        style: 'scoreHeader',
      }, {},
      {
        text: state.nanduScores.connectionScore || 0,
        colSpan: 2,
        style: 'score',
      }, {},
      {
        text: 'For innova-tions',
        colSpan: 2,
        style: 'scoreHeader',
      }, {},
      {
        text: state.nanduScores.innovationScore || 0,
        colSpan: 2,
        style: 'score',
      }, {},
    ]);
  } else {
    header = 'Application and Assessment Form for Compulsory Movements of Optional Taolu';
    tableHeader = 'Application for Compulsory Movements';
    widths = [16, 18, 22, 20, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25];
    const MOVEMENT_SECTION_LIMIT = 12;
    for (let i = 0; i < state.sections.length; i++) {
      const topRow = [
        {
          text: `${prefixes[i]} Segment`,
          colSpan: 2,
          rowSpan: 2,
          style: 'segmentTitle',
        }, {}, {
          text: 'Compulsory Movements',
          colSpan: 2,
          style: 'compulsoryMovementHeader',
        }, {},
      ];
      const botRow = [
        {}, {}, {
          text: 'Assessment',
          colSpan: 2,
          style: 'assessmentMovementHeader',
        }, {},
      ];

      const movements = [];
      for (let j = 0; j < state.sections[i].movement.length; j++) {
        let lastIndex = j;
        if (state.sections[i].movement[j] && state.sections[i].movement[j].code) {
          movements.push(state.sections[i].movement[j]);
          lastIndex = j;
        } else if (lastIndex !== j) {
          movements.splice(lastIndex, 0, state.sections[i].movement[j]);
        }
      }

      for (let j = 0; j < MOVEMENT_SECTION_LIMIT; j++) {
        let code = '';
        if (movements[j] && movements[j].code) {
          code = movements[j].code;
        }
        topRow.push({
          text: code || '',
          style: code ? 'cell' : 'blankCell',
          margin: [0, 10, 0, 10],
        });
        botRow.push({
          text: '',
          style: 'blankCell',
        });
      }
      body.push(topRow);
      body.push(botRow);
    }
    body.push([
      {
        text: 'Total Deduction for Choreography',
        colSpan: 4,
        style: 'deductionHeader',
      }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
    ]);
  }

  const docDefinition = {
    content: [
      {
        text: header,
        style: 'header',
        alignment: 'center',
      },
      {
        text: `Association: ${userInfo.association}`,
        style: 'input',
      },
      {
        alignment: 'justify',
        columns: [
          {
            text: `Name: ${userInfo.firstName}`,
            style: 'input',
          },
          {
            text: `Surname: ${userInfo.lastName}`,
            style: 'input',
          },
        ],
      },
      {
        alignment: 'justify',
        columns: [
          {
            text: `Gender: ${userInfo.gender}`,
            style: 'input',
            margin: [0, 0, 0, 10],
          },
          {
            text: `Height: ${userInfo.height}`,
            style: 'input',
          },
        ],
      },
      {
        table: {
          widths,
          body: [
            [
              {
                text: tableHeader,
                colSpan: 12,
                style: 'tableHeader',
                bold: true,
                border: [1, 1, 0, 1],
              }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
              {
                text: `Event: ${state.wushuStyle.name}`,
                colSpan: 4,
                style: 'tableHeader',
                border: [0, 1, 1, 1],
              }, {}, {}, {},
            ],
            ...body,
            [
              {
                text: 'Signature of coach',
                colSpan: 4,
                style: 'miscHeader',
              }, {}, {}, {},
              {
                text: '',
                colSpan: 4,
              }, {}, {}, {},
              {
                text: 'Contact Info',
                colSpan: 4,
                style: 'miscHeader',
              }, {}, {}, {},
              {
                text: `Email: ${userInfo.email}
                Phone: ${userInfo.phone}`,
                colSpan: 4,
                style: 'contactInfo',
              }, {}, {}, {},
            ],
          ],
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 15],
      },
      input: {
        margin: [0, 0, 0, 3],
      },
      tableHeader: {
        fontSize: 11,
        margin: [2, 5, 0, 15],
      },
      segmentTitle: {
        fontSize: 10,
        bold: true,
        alignment: 'center',
        margin: [0, 25, 0, 0],
      },
      cell: {
        fontSize: 10.5,
        alignment: 'center',
        margin: [0, 5, 0, 5],
      },
      blankCell: {
        fontSize: 10.2,
        alignment: 'center',
        margin: [0, 11, 0, 11],
      },
      scoreHeader: {
        fontSize: 10.9,
        alignment: 'center',
        margin: [0, 10, 0, 10],
      },
      score: {
        fontSize: 16,
        alignment: 'center',
        margin: [0, 15, 0, 0],
      },
      miscHeader: {
        fontSize: 10,
        alignment: 'center',
        bold: true,
        margin: [0, 20, 0, 20],
      },
      contactInfo: {
        fontSize: 9,
        margin: [0, 20, 0, 20],
      },
      compulsoryMovementHeader: {
        fontSize: 9,
        alignment: 'center',
        margin: [0, 5, 0, 5],
      },
      assessmentMovementHeader: {
        fontSize: 9,
        alignment: 'center',
        margin: [0, 10, 0, 10],
      },
      deductionHeader: {
        fontSize: 9,
        alignment: 'center',
        margin: [0, 5, 0, 5],
      },
    },
  };
  return docDefinition;
}

export default generatePDF;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CompetitionSelect from "components/CompetitionSelect";
import Select from "components/Modal/ModalFields/Select";
import useRings from "hooks/rings/useRings";
import { RingSelectActions, RingSelectSelectors } from "redux/ringSelect";

function RingSelect({
  id,
  visibleCompetitionIds,
  onChange,
  visibleRingIds,
  virtualOnly,
  inPersonOnly,
}) {
  const [competitionId, setCompetitionId] = useState();
  const [ring, setRing] = useState();
  const { data: rings } = useRings(competitionId);
  const ringSelectState = useSelector(RingSelectSelectors.getRingSelect)?.[id];
  const dispatch = useDispatch();
  const ringOptions = [];

  if (rings) {
    rings.forEach(({ id, name }) => {
      if (!visibleRingIds || (visibleRingIds && visibleRingIds.includes(id))) {
        ringOptions.push({
          label: name,
          value: id,
        });
      }
    });
  }

  const showDropdown =
    (competitionId && ringOptions?.length > 1) || visibleRingIds?.length > 1;

  useEffect(() => {
    const init = ringSelectState?.[competitionId] || ringOptions[0];

    setRing(init);

    if (init) {
      onChange({ competitionId, ringId: init.value });
    }
  }, [ringOptions.length]);

  return (
    <div className="RingSelect no-print">
      <CompetitionSelect
        id={`${id}-ringSelect`}
        visibleCompetitionIds={visibleCompetitionIds}
        onChange={({ value }) => setCompetitionId(value)}
        virtualOnly={virtualOnly}
        inPersonOnly={inPersonOnly}
      />
      {showDropdown ? (
        <Select
          displayName={"Select Ring"}
          items={ringOptions}
          onChange={(r) => {
            setRing(r);
            dispatch(RingSelectActions.setRing(competitionId, r, id));
            onChange({ competitionId, ringId: r.value });
          }}
          defaultValue={ring}
        />
      ) : (
        <h5>{ring?.label}</h5>
      )}
    </div>
  );
}

export default RingSelect;

import { useMutation, useQueryCache } from 'react-query';
import { TeamAPI } from 'api/admin/team';
import { TEAMS } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useCreateTeam() {
  const queryCache = useQueryCache();

  return useMutation(TeamAPI.add, {
    onSuccess: ({ message }) => {
      dispatchToast({ message, type: 'success' });
      queryCache.invalidateQueries(TEAMS);
    },
  });
}

import { useQuery } from 'react-query';
import { RingAPI } from 'api/admin/ring';
import { RINGS } from 'helpers/constants';

export default function useRings(competitionId) {
  return useQuery(
    [RINGS, competitionId],
    () => RingAPI.getRings(competitionId),
    { enabled: competitionId }
  );
}

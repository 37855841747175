import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "assets/logo.jpg";
import hasActiveLotDraw from "helpers/hasActiveLotDraw";
import useCurrentTournament from "hooks/tournament/useCurrentTournament";
import useLocalize from "hooks/useLocalize";
import { AuthSelectors } from "redux/auth";
import Logout from "../Logout";
import ChangeLanguageButton from "./ChangeLanguageButton";
import "./Header.scss";
import useClickOutside from "hooks/useClickOutside";

function Header() {
  const ref = useRef();
  const [collapseNav, setCollapseNav] = useState();
  const user = useSelector(AuthSelectors.getUser);
  const { data: tournament } = useCurrentTournament();
  const hasValidDrawLotDate = tournament?.competitions.find(hasActiveLotDraw);
  const localize = useLocalize();
  const onClick = () => setCollapseNav(true);
  let links;

  useClickOutside(ref, () => setCollapseNav(true));

  useEffect(() => {
    if (collapseNav) {
      // eslint-disable-next-line no-undef
      $(ref.current).collapse("hide");
    }

    setCollapseNav(false);
  }, [collapseNav]);

  if (user) {
    links = (
      <>
        <Link to="/" className="nav-link" onClick={onClick}>
          {localize("Athletes")}
        </Link>
        <Link to="/account" className="nav-link" onClick={onClick}>
          {localize("My Account")}
        </Link>
        <Link to="/tickets" className="nav-link">
          {localize("Tickets")}
        </Link>
        {!!hasValidDrawLotDate && (
          <Link to="/lots" className="nav-link" onClick={onClick}>
            {localize("Draw Lots")}
          </Link>
        )}
        <Link to="/checkout" className="nav-link" onClick={onClick}>
          {localize("Checkout")}
        </Link>
        <Link to="/video-submissions" className="nav-link" onClick={onClick}>
          {localize("Submit Video")}
        </Link>
        <Link to="/my-results" className="nav-link" onClick={onClick}>
          {localize("My Results")}
        </Link>
        <Link to="/all-results" className="nav-link" onClick={onClick}>
          {localize("All Results")}
        </Link>
        <Logout className="nav-link" />
      </>
    );
  } else {
    links = (
      <>
        <Link to="/login" className="nav-link" onClick={onClick}>
          {localize("Login")}
        </Link>
        <Link to="/register" className="nav-link" onClick={onClick}>
          {localize("Register")}
        </Link>
      </>
    );
  }

  return (
    <nav className="Header navbar navbar-dark navbar-expand-lg">
      <div className="container">
        <Link to="/" className="navbar-brand">
          <img className="Header-logo" src={logo} alt="logo"></img>
        </Link>
        <span className="row">
          <ChangeLanguageButton />
          <span className="col navbar-toggler-container">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbar"
              aria-controls="navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </span>
        </span>
        <div ref={ref} className="collapse navbar-collapse" id="navbar">
          <Link to="/schedule" className="nav-link" onClick={onClick}>
            {localize("Schedule")}
          </Link>
          {links}
        </div>
      </div>
    </nav>
  );
}

export default Header;

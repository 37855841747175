import { useMutation, useQueryCache } from 'react-query';
import { CompetitionVoteAPI } from 'api/competitionVote';
import { EVENT_PARTICIPATION } from 'helpers/constants';

export default function useRemoveCompetitionVote() {
  const queryCache = useQueryCache();

  return useMutation(CompetitionVoteAPI.remove, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(EVENT_PARTICIPATION);
      onSuccess();
    },
  });
}

import locales from 'locales';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18nSelectors } from 'redux/i18n';

export default function useLocalize() {
  const language = useSelector(I18nSelectors.getLanguage);
  function internalLocalize(text) {
    return locales?.[language]?.[text] || text;
  }
  const [localize, setLocalize] = useState(() => internalLocalize);

  useEffect(() => {
    setLocalize(() => internalLocalize);
  }, [language]);

  return localize;
}

import React from "react";
import useCurrentTournament from "hooks/tournament/useCurrentTournament";
import EventList from "./EventList";

function EventsList({
  competition,
  eventParticipationMap,
  isPastDeadline,
  participant,
}) {
  const { id: participantId, firstName, lastName } = participant;
  const events = competition?.events || [];
  const competitionId = competition?.id;
  const { data: tournament } = useCurrentTournament();
  const restrictions = tournament?.competitions.find(
    (c) => c.id === competitionId
  )?.restrictions;

  return (
    <div className="EventsList">
      {events.map(({ name, events, registered }, index) => {
        const restriction = restrictions?.find(
          (r) => r.eventCategoryName === name
        )?.max;

        return (
          <EventList
            key={index}
            category={name}
            competitionId={competitionId}
            disabled={isPastDeadline}
            eventParticipationMap={eventParticipationMap}
            events={events}
            participantId={participantId}
            participantName={`${firstName} ${lastName}`}
            registered={registered}
            restriction={restriction}
          />
        );
      })}
    </div>
  );
}

export default EventsList;

import React from "react";
import { useSelector } from "react-redux";
import defaultImage from "assets/default.gif";
import TransactionItem from "components/TransactionItem";
import useLocalize from "hooks/useLocalize";
import { I18nSelectors } from "redux/i18n";
import "./CompetitorCard.scss";

function CompetitorCard({
  competitorName,
  competitions = {},
  subtotal,
  image,
}) {
  const localize = useLocalize();
  const language = useSelector(I18nSelectors.getLanguage);
  const imageUrl = image ? `/public/images/${image}` : defaultImage;
  let transactionItems = [];

  Object.values(competitions).forEach(
    (
      {
        name,
        championships,
        chineseName,
        cost,
        events,
        isExemptFromRegistrationFee,
      },
      i
    ) => {
      const competitionName =
        language === "cn" && chineseName ? chineseName : name;

      transactionItems.push(
        <TransactionItem
          key={`competition-${competitorName}-${i}`}
          name={`${competitionName} (${localize("Registration Fee")})`}
          cost={isExemptFromRegistrationFee ? 0 : cost}
          header
        />
      );

      if (championships?.length) {
        championships.forEach(
          ({ championshipName, championshipChineseName, cost }, k) => {
            const championship =
              language === "cn" && championshipChineseName
                ? championshipChineseName
                : championshipName;

            transactionItems.push(
              <TransactionItem
                key={k}
                name={championship}
                cost={cost}
                alternate
              />
            );
          }
        );
      }

      events.forEach(
        ({ eventChineseName, eventName, cost, gender, ageGroup }, j) => {
          let name =
            language === "cn" && eventChineseName
              ? eventChineseName
              : eventName;

          if (gender && ageGroup) {
            name = `${name} (${gender}, ${ageGroup} yrs)`;
          }

          transactionItems.push(
            <TransactionItem
              key={`event-${competitorName}-${i}-${j}`}
              name={name}
              cost={cost}
            />
          );
        }
      );
    }
  );

  return (
    <div className="CompetitorCard">
      <div className="card mb-2">
        <div className="row no-gutters">
          <div className="col-md-2">
            <div className="CompetitorCard-left card-body">
              <img src={imageUrl} alt="default-user" className="card-img"></img>
              <p className="competitor-name text-center">{competitorName}</p>
            </div>
          </div>
          <div className="col-md-10">
            <div className="card-body">
              <ul className="list-group">
                {transactionItems}
                <TransactionItem
                  name={localize("Subtotal")}
                  cost={subtotal}
                  isSubtotal
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompetitorCard;

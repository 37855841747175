import { POST, DELETE, PUT } from '../config';

const EventCategoryRestrictionAPI = {
  add(eventCategoryRestrictionInput) {
    return POST('eventCategoryRestrictions', { eventCategoryRestrictionInput });
  },
  update({ id, max }) {
    return PUT(`eventCategoryRestrictions/${id}`, { max });
  },
  remove(id) {
    return DELETE(`eventCategoryRestrictions/${id}`);
  },
};

export { EventCategoryRestrictionAPI };

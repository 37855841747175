import React from "react";
import { useHistory } from "react-router-dom";
import Table from "components/admin/Table";
import useCompetitorsWithoutUSAWKFId from "hooks/competitors/useCompetitorsWithoutUSAWKFId";

function MissingUSAWKFIDReport({ competitionId }) {
  const { data: competitors, isLoading } = useCompetitorsWithoutUSAWKFId(
    competitionId
  );
  const history = useHistory();
  const tableData = {
    defaultSort: "name",
    searchableColumns: ["name", "email"],
    headingData: {
      id: "ID",
      name: "Name",
      email: "Email",
      championshipName: "Championship",
      usawkfId: "USAWKF ID",
    },
    items: competitors || [],
  };

  function itemClick({ id }) {
    history.push(`/adm/home/participant/${id}`);
  }

  return (
    <div className="MissingUSAWKFIDReport">
      <h3>Team Trials Athletes Missing USAWKFID</h3>
      {isLoading ? (
        <div className="spinner-border mt-2" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <Table data={tableData} itemClick={itemClick} />
      )}
    </div>
  );
}

export default MissingUSAWKFIDReport;

import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { AuthActions } from "redux/auth";
import PasswordInput from "components/PasswordInput";
import TextInput from "components/TextInput";
import useLocalize from "hooks/useLocalize";
import "./LoginForm.scss";

function LoginForm({
  allowUsername,
  isAuthenticated,
  redirectTo = "/",
  showForgotPassword,
  showRegister,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const localize = useLocalize();

  if (isAuthenticated) {
    return <Redirect to={redirectTo} noThrow />;
  }

  const validation = allowUsername
    ? Yup.string().required(localize("Required"))
    : Yup.string()
        .email(localize("Must be a valid email"))
        .required(localize("Required"));

  return (
    <div className="LoginForm container">
      <h1 className="LoginForm-title">{localize("Login")}</h1>
      <Formik
        initialValues={{
          emailOrUsername: "",
          password: "",
        }}
        validationSchema={Yup.object({
          emailOrUsername: validation,
          password: Yup.string().required(localize("Required")),
        })}
        onSubmit={async ({ emailOrUsername, password }) =>
          dispatch(
            AuthActions.login({
              emailOrUsername,
              isUsername: allowUsername,
              password,
            })
          )
        }
      >
        <Form className="needs-validation" noValidate>
          <TextInput placeholder={localize("Email")} name="emailOrUsername" />
          <PasswordInput placeholder={localize("Password")} name="password" />
          <div className="col">
            <button type="submit" className="btn btn-success">
              {localize("Login")}
            </button>
          </div>
        </Form>
      </Formik>
      {showRegister && (
        <div className="col mt-3">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => history.push("/register")}
          >
            {localize("Register")}
          </button>
        </div>
      )}
      {showForgotPassword && (
        <div className="col mt-3">
          <button
            type="submit"
            className="btn btn-info bg-info"
            onClick={() => history.push("/forgotPassword")}
          >
            {localize("Forgot Password")}?
          </button>
        </div>
      )}
    </div>
  );
}

export default LoginForm;

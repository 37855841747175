import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { AuthSelectors } from 'redux/auth';
import PasswordInput from 'components/PasswordInput';
import useLocalize from 'hooks/useLocalize';
import useResetPassword from 'hooks/users/useResetPassword';
import './ResetPassword.scss';

function ResetPassword() {
  const { token } = useParams();
  const history = useHistory();
  const isAuthenticated = useSelector(AuthSelectors.getAuthenticationStatus);
  const [resetPassword] = useResetPassword();
  const localize = useLocalize();

  if (isAuthenticated) {
    return <Redirect to="/" noThrow />;
  }

  return (
    <div className="ResetPassword container">
      <h1 className="ResetPassword-title">{localize('Reset Password')}</h1>
      <Formik
        initialValues={{
          password: '',
          confirmedPassword: '',
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .min(8, localize('Must be 8 characters or more'))
            .max(45, localize('Must be 45 characters or less'))
            .required(localize('Required')),
          confirmedPassword: Yup.string()
            .required(localize('Required'))
            .oneOf(
              [Yup.ref('password'), null],
              localize('Passwords must match')
            ),
        })}
        onSubmit={async ({ password, confirmedPassword }) => {
          resetPassword({
            password,
            confirmedPassword,
            token,
          });
          history.push('/');
        }}
      >
        <Form className="needs-validation" noValidate>
          <PasswordInput placeholder={localize('Password')} name="password" />
          <PasswordInput
            placeholder={localize('Confirm Password')}
            name="confirmedPassword"
          />
          <div className="col">
            <button type="submit" className="btn btn-primary">
              {localize('Reset Password')}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default ResetPassword;

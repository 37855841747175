import { useMutation, useQueryCache } from "react-query";
import TicketPurchasesAPI from "api/ticketPurchase";
import { USER_TICKET_PURCHASES } from "helpers/constants";

export default function useCreateTicketPurchases() {
  const queryCache = useQueryCache();

  return useMutation(TicketPurchasesAPI.add, {
    onSuccess: (data, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(USER_TICKET_PURCHASES);
      onSuccess();
    },
  });
}

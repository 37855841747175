import { GET, POST } from "api/config";

const ChampionshipAPI = {
  async getTeamTrialResultsForCompetition(competitionId) {
    const { participants } = await GET(
      `/championships/competition/${competitionId}/team-trial-results`
    );

    return participants;
  },
  async getAllAroundResultsForCompetition(competitionId) {
    const { championshipResults } = await GET(
      `/championships/competition/${competitionId}/all-around-results`
    );

    return championshipResults;
  },
  async register(championshipRegistrationInput) {
    return await POST(`/championships/register`, {
      championshipRegistrationInput,
    });
  },
  async deregister(championshipRegistrationInput) {
    return await POST(`/championships/deregister`, {
      championshipRegistrationInput,
    });
  },
  async lockInChampionshipResults(championshipInput) {
    const { championshipId } = championshipInput;

    return await POST(`/championships/${championshipId}/lockIn`, {
      championshipInput,
    });
  },
};

export { ChampionshipAPI };

import React from "react";

export default function GenderRadio({ id, onChange, value, label, checked }) {
  return (
    <div className="custom-control custom-radio custom-control-inline">
      <input
        type="radio"
        id={id}
        name={`genderRadio-${id}`}
        className="custom-control-input"
        onChange={({ target: { value } }) => onChange(value)}
        value={value}
        checked={checked}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

import React from "react";
import styles from "./LotStick.module.scss";

export default function Lot({ num }) {
  return (
    <div className={styles.LotStick}>
      <div className={styles.stick}>
        <h4 className="mb-0">{num}</h4>
      </div>
    </div>
  );
}

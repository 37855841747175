import { GET, POST, PUT, DELETE } from '../config';

const CompetitionAPI = {
  async fetchCompetitions() {
    const { competitions } = await GET('competitions');

    return competitions;
  },
  async getCompetition(id) {
    const { competition } = await GET(`competitions/${id}`);

    return competition;
  },
  async getCompetitors(id) {
    const { competitors } = await GET(`competitions/${id}/competitors`);

    return competitors;
  },
  addCompetition(competition) {
    return POST('competitions', { competition });
  },
  updateCompetition({ id, competition }) {
    return PUT(`competitions/${id}`, { competition });
  },
  removeCompetition(id) {
    return DELETE(`competitions/${id}`);
  },
};

export { CompetitionAPI };

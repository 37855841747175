import React from "react";
import Checkbox from "components/Checkbox";
import DateInput from "./DateInput";
import PasswordInput from "./PasswordInput";
import Select from "./Select";
import AsyncSelect from "./AsyncSelect";
import TextInput from "./TextInput";
import TimeInput from "./TimeInput";
import NumberInput from "./NumberInput";

function ModalFields({ fields }) {
  return (
    <div className="ModalFields">
      {fields.map((field, key) => {
        const {
          defaultValue,
          disabled,
          displayName,
          fetchItems,
          items,
          min,
          name,
          onChange,
          type,
          step,
        } = field;

        switch (type) {
          case "text":
            return (
              <TextInput
                key={key}
                name={name}
                disabled={disabled}
                displayName={displayName}
                defaultValue={defaultValue}
                onChange={onChange}
              />
            );
          case "number":
            return (
              <NumberInput
                key={key}
                min={min}
                name={name}
                disabled={disabled}
                displayName={displayName}
                defaultValue={defaultValue}
                onChange={onChange}
                step={step}
              />
            );
          case "select":
            return (
              <Select
                key={key}
                name={name}
                disabled={disabled}
                displayName={displayName}
                defaultValue={defaultValue}
                onChange={onChange}
                items={items}
              />
            );
          case "asyncSelect":
            return (
              <AsyncSelect
                key={key}
                name={name}
                disabled={disabled}
                displayName={displayName}
                fetchItems={fetchItems}
                onChange={onChange}
              />
            );
          case "date":
            return (
              <DateInput
                key={key}
                name={name}
                disabled={disabled}
                displayName={displayName}
                defaultValue={defaultValue}
                onChange={onChange}
              />
            );
          case "time":
            return (
              <TimeInput
                key={key}
                name={name}
                disabled={disabled}
                displayName={displayName}
                defaultValue={defaultValue}
                onChange={onChange}
              />
            );
          case "checkbox":
            return (
              <Checkbox
                key={key}
                id={name}
                checked={!!defaultValue}
                disabled={disabled}
                label={displayName}
                defaultValue={defaultValue}
                onChange={onChange}
                useBootstrapClasses
              />
            );
          case "password":
            return (
              <PasswordInput
                key={key}
                name={name}
                disabled={disabled}
                displayName={displayName}
                defaultValue={defaultValue}
                onChange={onChange}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
}

export default ModalFields;

import { useMutation } from "react-query";
import { BadgeAPI } from "api/admin/badge";
import dispatchToast from "helpers/dispatchToast";

export default function useGenerateBadgePDFsForTeam() {
  return useMutation(BadgeAPI.generateBadgesForTeam, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      dispatchToast({ message, type: "success" });
      onSuccess();
    },
  });
}

import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import BatchEvents from './BatchEvents';
import Competition from './Competition';
import CompetitionManager from './CompetitionManager';
import Judges from './Judges';
import Events from './Events';

function CompetitionManagerRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <CompetitionManager />
      </Route>
      <Route path={`${path}/:competitionId`} exact>
        <Competition />
      </Route>
      <Route path={`${path}/:competitionId/batch-events`}>
        <BatchEvents />
      </Route>
      <Route path={`${path}/:competitionId/rings/:ringId/judges`}>
        <Judges />
      </Route>
      <Route path={`${path}/:competitionId/rings/:ringId/events`}>
        <Events />
      </Route>
    </Switch>
  );
}

export default CompetitionManagerRouter;

import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFound from "scenes/NotFound";
import ParticipantSchedule from "../ParticipantSchedule";
import ScheduleHome from "../ScheduleHome";

function ScheduleRouter() {
  const { path } = useRouteMatch();

  return (
    <div className="ScheduleRouter">
      <Switch>
        <Route path={`${path}`} exact>
          <ScheduleHome />
        </Route>
        <Route path={`${path}/:participantId/events`}>
          <ParticipantSchedule />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default ScheduleRouter;

import React, { useState } from 'react';
import dispatchToast from 'helpers/dispatchToast';
import useWushuStyles from 'hooks/wushuStyles/useWushuStyles';
import useCreateWushuStyle from 'hooks/wushuStyles/useCreateWushuStyle';
import useRemoveWushuStyle from 'hooks/wushuStyles/useRemoveWushuStyle';
import useUpdateWushuStyle from 'hooks/wushuStyles/useUpdateWushuStyle';
import ModalTable from 'components/admin/ModalTable';

function WushuStyleManager() {
  const [name, setName] = useState('');
  const [abbreviation, setAbbreviation] = useState('');
  const { data: wushuStyles } = useWushuStyles();
  const [createWushuStyle] = useCreateWushuStyle();
  const [removeWushuStyle] = useRemoveWushuStyle();
  const [updateWushuStyle] = useUpdateWushuStyle();

  function setIsEditingItem(currentItem) {
    const { name, abbreviation } = currentItem;
    setName(name);
    setAbbreviation(abbreviation);
  }

  function setIsAddingItem() {
    setName('');
    setAbbreviation('');
  }

  function undoDelete({ name, abbreviation }) {
    createWushuStyle({
      name,
      abbreviation,
    });
  }

  function addItem() {
    if (name && abbreviation) {
      createWushuStyle({
        name,
        abbreviation,
      });
    } else {
      dispatchToast({
        message: 'Please provide name and abbreviation.',
        type: 'error',
      });
    }
  }

  function removeItem({ id }) {
    removeWushuStyle(id);
  }

  function editItem({ id }) {
    if (name && abbreviation) {
      const wushuStyleInput = {
        name,
        abbreviation,
      };

      updateWushuStyle({ id, wushuStyleInput });
    } else {
      dispatchToast({
        message: 'Please provide name and abbreviation.',
        type: 'error',
      });
    }
  }

  const tableData = {
    defaultSort: 'name',
    searchableColumns: ['name', 'abbreviation'],
    headingData: {
      name: 'Name',
      abbreviation: 'Abbreviation',
    },
    items: wushuStyles || [],
    modalData: {
      fields: [
        {
          defaultValue: name,
          displayName: 'Name',
          name: 'name',
          onChange: value => setName(value),
          type: 'text',
        },
        {
          defaultValue: abbreviation,
          displayName: 'Abbreviation',
          name: 'abbreviation',
          onChange: value => setAbbreviation(value),
          type: 'text',
        },
      ],
    },
    itemName: 'Wushu Style',
  };

  return (
    <div className="wushuStyleManager">
      <h2 className="text-left">Wushu Style Manager</h2>
      <ModalTable
        addItem={addItem}
        data={tableData}
        editItem={editItem}
        removeItem={removeItem}
        setIsAddingItem={setIsAddingItem}
        setIsEditingItem={setIsEditingItem}
        undoDelete={undoDelete}
      />
    </div>
  );
}

export default WushuStyleManager;

import { useEffect } from "react";

const useLoadRecaptcha = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default useLoadRecaptcha;

import { useMutation, useQueryCache } from 'react-query';
import { CheckoutAPI } from 'api/checkout';
import { CHECKOUT } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useMakePayment() {
  const queryCache = useQueryCache();

  return useMutation(CheckoutAPI.makePayment, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(CHECKOUT);
      dispatchToast({ message, type: 'success' });
      onSuccess();
    },
  });
}

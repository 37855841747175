import { useMutation, useQueryCache } from "react-query";
import { ChampionshipParticipationAPI } from "api/championshipParticipation";
import { CHAMPIONSHIPS } from "helpers/constants";

export default function useUpdateChampionshipParticipationClaimedAward() {
  const queryCache = useQueryCache();

  return useMutation(
    ChampionshipParticipationAPI.updateChampionshipParticipationClaimedAward,
    {
      onSuccess: ({ message }) => {
        queryCache.invalidateQueries(CHAMPIONSHIPS);
      },
    }
  );
}

import { useMutation, useQueryCache } from 'react-query';
import { EventCategoryAPI } from 'api/admin/eventCategory';
import { EVENT_CATEGORIES } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useRemoveEventCategory() {
  const queryCache = useQueryCache();

  return useMutation(EventCategoryAPI.remove, {
    onSuccess: ({ message }) => {
      dispatchToast({ message, type: 'success' });
      queryCache.invalidateQueries(EVENT_CATEGORIES);
    },
  });
}

import { useMutation, useQueryCache } from "react-query";
import { ErrorMessageAPI } from "api/errorMessage";
import { ERROR_MESSAGES } from "helpers/constants";

export default function useRemoveErrorMessages() {
  const queryCache = useQueryCache();

  return useMutation(ErrorMessageAPI.removeMultiple, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(ERROR_MESSAGES);
      onSuccess();
    },
  });
}

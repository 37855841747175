import { useMutation, useQueryCache } from "react-query";
import { CheckInAPI } from "api/judge/checkIn";
import { CHECK_IN } from "helpers/constants";

export default function useRemoveCheckIn() {
  const queryCache = useQueryCache();

  return useMutation(CheckInAPI.removeCheckIn, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(CHECK_IN);
      onSuccess();
    },
  });
}

import { useQuery } from "react-query";
import TicketPurchasesAPI from "api/ticketPurchase";
import { USER_TICKET_PURCHASES } from "helpers/constants";

export default function useUserTicketPurchases({ userId, tournamentId }) {
  return useQuery(
    [USER_TICKET_PURCHASES, userId, tournamentId],
    () => TicketPurchasesAPI.getAllForUser({ userId, tournamentId }),
    { enabled: tournamentId && userId }
  );
}

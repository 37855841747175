import React, { useEffect, useState } from "react";
import { VideoSubmissionAPI } from "api/videoSubmission";
import SubmitButton from "components/SubmitButton";
import CONFIRM_DELETE from "helpers/confirmDelete";
import useLocalize from "hooks/useLocalize";
import useCreateVideoSubmission from "hooks/videoSubmissions/useCreateVideoSubmission";
import useRemoveVideoSubmission from "hooks/videoSubmissions/useRemoveVideoSubmission";
import useUploadVideoSubmission from "hooks/videoSubmissions/useUploadVideoSubmission";
import useVideoSubmission from "hooks/videoSubmissions/useVideoSubmission";
import FileInput from "./FileInput";
import "./VideoSubmissionForm.scss";

function VideoSubmissionForm({
  competitionId,
  events,
  eventParticipation,
  isPastDeadline,
  participantId,
}) {
  const [video, setVideo] = useState();
  const localize = useLocalize();
  const [isUploading, setIsUploading] = useState(false);
  const [competitionEventId, setCompetitionEventId] = useState();
  const defaultLabel = localize("Choose file");
  const [label, setLabel] = useState(defaultLabel);
  const [streamUrl, setStreamUrl] = useState();
  const [showVideo, setShowVideo] = useState();
  const { data: videoSubmission } = useVideoSubmission({
    competitionId,
    participantId,
    competitionEventId,
  });
  const videoUrl = videoSubmission?.videoUrl;
  const [createVideoSubmission] = useCreateVideoSubmission();
  const [uploadVideoSubmission] = useUploadVideoSubmission();
  const [removeVideoSubmission] = useRemoveVideoSubmission();
  let defaultEventOption;

  function selectCompetitionEvent({ target: { value } }) {
    hideVideo();
    setCompetitionEventId(value);
  }

  function processFile(file) {
    const name = file?.name;
    const newLabel = name || defaultLabel;

    setLabel(newLabel);
    setVideo(file);
  }

  function onRemove(e) {
    e.preventDefault();

    CONFIRM_DELETE(() => {
      removeVideoSubmission({
        participantId,
        competitionEventId,
        competitionId,
      });
    });
  }

  function onSubmit(e) {
    e.preventDefault();
    setIsUploading(true);

    const data = {
      video,
      participantId,
      competitionEventId,
      competitionId,
    };

    if (process.env.REACT_APP_SUBMISSION_TYPE === "upload") {
      uploadVideoSubmission({
        ...data,
        onSettled: () => {
          setIsUploading(false);
        },
      });
    } else {
      createVideoSubmission(data);
    }

    hideVideo();
    setLabel(defaultLabel);
    setVideo(null);
  }

  function hideVideo() {
    setStreamUrl(null);
    setShowVideo(false);
  }

  useEffect(() => {
    if (showVideo) {
      const videoStreamUrl = VideoSubmissionAPI.getVideoStream({
        participantId,
        competitionEventId,
        competitionId,
      });

      setStreamUrl(videoStreamUrl);
    }
  }, [showVideo, competitionEventId, video]);

  const eventOptions = events.map(({ id, code, name }) => {
    const registered = eventParticipation[competitionId]?.[id] || false;

    if (!defaultEventOption && !competitionEventId && registered) {
      defaultEventOption = true;
      setCompetitionEventId(id);
    }

    return registered ? (
      <option value={id} key={id}>
        {`${name} (${code})`}
      </option>
    ) : null;
  });

  return (
    <div className="VideoSubmissionForm">
      <div className="form-group row">
        <label className="form-label col-4">
          <h5>{localize("Select Event")}</h5>
        </label>
        <div className="col-8">
          <select
            className="form-control"
            disabled={isUploading}
            onChange={selectCompetitionEvent}
          >
            {eventOptions}
          </select>
        </div>
      </div>
      {!isPastDeadline && (
        <>
          <div className="form-group row">
            <label className="form-label col-4">
              <h5>{localize("Upload Video")}</h5>
            </label>
            <div className="col-8">
              {process.env.REACT_APP_SUBMISSION_TYPE === "external" && (
                <input
                  type="text"
                  className="form-control"
                  defaultValue={video}
                  onChange={({ target: { value } }) => setVideo(value)}
                  max="255"
                />
              )}
              {process.env.REACT_APP_SUBMISSION_TYPE === "upload" && (
                <>
                  <p>{localize("File size limit")}: 500MB</p>
                  <FileInput
                    isUploading={isUploading}
                    processFile={processFile}
                    label={label}
                    uid={`${participantId}${competitionEventId}`}
                    max={800000000}
                  />
                  <p>
                    {localize(
                      "*Depending on video size and server bandwith, please allow 5-15 minutes for the upload to complete."
                    )}
                  </p>
                  <p>
                    {localize(
                      "**Your video file can be .mov or .mp4, and the file size is limited to less than 500MB. When the name of the video file you upload appears on the page below, it is confirmation that you have completed the upload."
                    )}
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <SubmitButton onClick={onSubmit} disabled={!video} />
            </div>
          </div>
        </>
      )}
      {videoUrl && (
        <>
          <hr />
          <div className="form-group row">
            <label className="form-label col-4">
              <h5>{localize("Current Submission")}</h5>
            </label>
            <div className="col-8 d-flex justify-content-between">
              <p className="video-url">{videoUrl}</p>
              <button
                className="btn btn-danger"
                disabled={isUploading}
                onClick={onRemove}
              >
                {localize("Delete")}
              </button>
            </div>
          </div>
          <div className="form-group row">
            <label className="form-label col-4">
              <h5>{localize("Preview")}</h5>
            </label>
            <div className="col-8">
              {!showVideo && (
                <button
                  className="btn btn-primary"
                  onClick={() => setShowVideo(true)}
                >
                  {localize("Show Video")}
                </button>
              )}
              {showVideo && streamUrl && (
                <>
                  <div className="mb-2">
                    <button
                      className="btn btn-warning"
                      onClick={() => setShowVideo(false)}
                    >
                      {localize("Hide Video")}
                    </button>
                  </div>
                  <video
                    width="100%"
                    controls
                    src={streamUrl}
                    type="video/mp4"
                  ></video>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default VideoSubmissionForm;

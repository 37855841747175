import { useQuery } from "react-query";
import { EventParticipationAPI } from "api/eventParticipation";
import { EVENT_PARTICIPATION } from "helpers/constants";

export default function useEventParticipationForEventTotalAthletes(
  competitionEventId
) {
  return useQuery(
    [EVENT_PARTICIPATION, competitionEventId, "total"],
    () =>
      EventParticipationAPI.getTotalCountForCompetitionEvent(
        competitionEventId
      ),
    {
      enabled: !!competitionEventId,
    }
  );
}

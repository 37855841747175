import React, { useState } from 'react';
import ModalTable from 'components/admin/ModalTable';
import useEventCategories from 'hooks/eventCategories/useEventCategories';
import useCreateEventCategory from 'hooks/eventCategories/useCreateEventCategory';
import useUpdateEventCategory from 'hooks/eventCategories/useUpdateEventCategory';
import useRemoveEventCategory from 'hooks/eventCategories/useRemoveEventCategory';
import dispatchToast from 'helpers/dispatchToast';

function EventCategoryManager() {
  const [name, setName] = useState('');
  const [order, setOrder] = useState(0);
  const { data: eventCategories } = useEventCategories();
  const [createEventCategory] = useCreateEventCategory();
  const [updateEventCategory] = useUpdateEventCategory();
  const [removeEventCategory] = useRemoveEventCategory();

  function setIsEditingItem({ name, order }) {
    setName(name);
    setOrder(order);
  }

  function setIsAddingItem() {
    setName('');
    setOrder(0);
  }

  function undoDelete({ name, order }) {
    createEventCategory({ name, order });
  }

  function addItem() {
    if (name) {
      createEventCategory({ name, order });
    } else {
      dispatchToast({ message: 'Please add a name.', type: 'error' });
    }
  }

  function removeItem({ id }) {
    removeEventCategory(id);
  }

  function editItem({ id }) {
    if (name) {
      updateEventCategory({ id, name, order });
    } else {
      dispatchToast({ message: 'Please add a name.', type: 'error' });
    }
  }

  const tableData = {
    defaultSort: 'order',
    searchableColumns: ['name'],
    headingData: { name: 'Name', order: 'Display Order' },
    items: eventCategories || [],
    modalData: {
      fields: [
        {
          defaultValue: name,
          displayName: 'Name',
          name: 'name',
          onChange: value => setName(value),
          type: 'text',
        },
        {
          defaultValue: order,
          displayName: 'Display Order',
          name: 'order',
          onChange: value => setOrder(value),
          type: 'number',
          step: 1,
        },
      ],
    },
    itemName: 'Event Category',
  };

  return (
    <div className="eventCategoryManager">
      <h2 className="text-left">Event Category Manager</h2>
      <ModalTable
        addItem={addItem}
        data={tableData}
        editItem={editItem}
        removeItem={removeItem}
        setIsAddingItem={setIsAddingItem}
        setIsEditingItem={setIsEditingItem}
        undoDelete={undoDelete}
      />
    </div>
  );
}

export default EventCategoryManager;

import { useMutation, useQueryCache } from "react-query";
import { CompetitionEventAPI } from "api/admin/competitionEvent";
import { COMPETITIONS } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useCreateBatchCompetitionEvents() {
  const queryCache = useQueryCache();

  return useMutation(CompetitionEventAPI.addBatchCompetitionEvents, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      dispatchToast({ message, type: "success" });
      queryCache.invalidateQueries(COMPETITIONS);
      onSuccess();
    },
  });
}

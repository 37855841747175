export function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function toHTMLDate(date) {
  return date && date !== '0000-00-00'
    ? new Date(date).toISOString().substr(0, 10)
    : '';
}

export function toHTMLTime(time) {
  return time ? new Date(time).toISOString().split('T')[1].substr(0, 8) : '';
}

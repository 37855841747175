import { createStore, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import throttle from 'lodash.throttle';
import rootReducer from "./reducers";
import { loadReduxState, saveState } from '../helpers/localStorage';

const persistedState = loadReduxState();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(
    applyMiddleware(thunk)
  ),
);

store.subscribe(throttle(() => {
  saveState(store.getState());
}, 1000));

export default store;
import { GET } from './config';

const NanduFormAPI = {
  async getNanduFormData() {
    const { nandus } = await GET('nanduFormData');

    return nandus;
  },
}

export { NanduFormAPI };

import { useQuery } from "react-query";
import { EventAPI } from 'api/admin/event';
import { EVENTS } from 'helpers/constants';

export default function useEvents() {
  return useQuery(
    EVENTS,
    EventAPI.getAll,
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ucFirst } from 'helpers';
import { NANDU } from 'helpers/constants';
import { NanduFormActions, NanduFormSelectors } from 'redux/nanduForm';
import NanduFormSelect from '../NanduFormSelect';
import './PrintNanduForm.css';

function PrintNanduForm() {
  const dispatch = useDispatch();
  const formType = useSelector(NanduFormSelectors.getFormType);
  const nanduPDF = useSelector(NanduFormSelectors.getNanduPDF);
  const pdfFilename = useSelector(NanduFormSelectors.getNanduPDFFilename);
  const firstName = useSelector(NanduFormSelectors.getNanduPDFFirstName);
  const lastName = useSelector(NanduFormSelectors.getNanduPDFLastName);
  const association = useSelector(NanduFormSelectors.getNanduPDFAssociation);
  const gender = useSelector(NanduFormSelectors.getNanduPDFGender);
  const height = useSelector(NanduFormSelectors.getNanduPDFHeight);
  const email = useSelector(NanduFormSelectors.getNanduPDFEmail);
  const phone = useSelector(NanduFormSelectors.getNanduPDFPhone);
  const genders = [
    {
      name: 'Male',
    },
    {
      name: 'Female',
    },
  ];
  const userInfo = {
    firstName,
    lastName,
    association,
    gender,
    height,
    email,
    phone,
  };
  
  function saveGender(name) {
    userInfo.gender = name;
    dispatch(NanduFormActions.savePDF(userInfo, pdfFilename));
  }

  function savePDF({ target }) {
    const { value, name } = target;
    userInfo[name] = value;
    dispatch(NanduFormActions.savePDF(userInfo, pdfFilename));
  }

  function saveFilename({ target }) {
    const { value } = target;
    dispatch(NanduFormActions.savePDF(userInfo, value));
  }

  function openNanduPDF() {
    // pdfMake is downloaded on the index
    // it needs dependencies and it was easier to just drop it there
    pdfMake.createPdf(nanduPDF).open({}, window); // eslint-disable-line no-undef
  }

  function printNanduPDF() {
    pdfMake.createPdf(nanduPDF).print({}, window); // eslint-disable-line no-undef
  }

  function downloadNanduPDF() {
    const filename = pdfFilename || (formType.name === NANDU ? 'Nandu Form' : 'Movement Form');
    pdfMake.createPdf(nanduPDF).download(filename); // eslint-disable-line no-undef
  }

  return (
    <div className="printNanduForm">
      <div className="back-link">
        <Link to="/nanduform">Back to nandu form</Link>
      </div>
      <div className="userInfoInputGroup">
        <input type="text" placeholder="First Name" value={firstName} className="userInfoInput" name="firstName" onChange={savePDF} />
      </div>
      <div className="userInfoInputGroup">
        <input type="text" placeholder="Last Name" value={lastName} className="userInfoInput" name="lastName" onChange={savePDF} />
      </div>
      <div className="userInfoInputGroup">
        <input type="text" placeholder="Association" value={association} className="userInfoInput" name="association" onChange={savePDF} />
      </div>
      <div className="userInfoInputGroup small">
        <NanduFormSelect
          displayProperty="name"
          defaultValue={gender || 'Gender'}
          callback={saveGender}
          options={genders}
        />
      </div>
      <div className="userInfoInputGroup small">
        <input type="number" placeholder="Height (cm)" value={height} className="userInfoInput" name="height" onChange={savePDF} min="50" max="280" />
      </div>
      <div className="userInfoInputGroup">
        <input type="email" placeholder="Email" value={email} className="userInfoInput" name="email" onChange={savePDF} />
      </div>
      <div className="userInfoInputGroup">
        <input type="text" placeholder="Phone" value={phone} className="userInfoInput" name="phone" onChange={savePDF} />
      </div>
      <div className="userInfoInputGroup">
        <input type="text" placeholder="Custom Filename" value={pdfFilename} className="userInfoInput" name="filename" onChange={saveFilename} />
      </div>

      <div className="actions">
        <div className="action printPDF" onClick={printNanduPDF}>Print {ucFirst(formType.name)} Form</div>
        <div className="action openPDF" onClick={openNanduPDF}>Open {ucFirst(formType.name)} Form</div>
        <div className="action downloadPDF" onClick={downloadNanduPDF}>Download {ucFirst(formType.name)} Form</div>
      </div>
    </div>
  )
}

export default PrintNanduForm;

import { useMutation, useQueryCache } from "react-query";
import { ChampionshipEventCategoryRequirementAPI } from "api/admin/championshipEventCategoryRequirement";
import { CHAMPIONSHIP_EVENT_CATEGORY_REQUIREMENTS } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useRemoveChampionshipEventCategoryRequirement() {
  const queryCache = useQueryCache();

  return useMutation(ChampionshipEventCategoryRequirementAPI.remove, {
    onSuccess: ({ message }) => {
      dispatchToast({ message, type: "success" });
      queryCache.invalidateQueries(CHAMPIONSHIP_EVENT_CATEGORY_REQUIREMENTS);
    },
  });
}

import { CHANGE_LANGUAGE } from './actionTypes';

const initialState = {
  language: 'en',
};

function I18nReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      state.language = action.language;

      return state;
    default:
      return state;
  }
}

export { I18nReducer };

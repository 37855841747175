import React from 'react';

function PasswordInput({
  name,
  disabled,
  displayName,
  defaultValue,
  onChange,
}) {
  return (
    <div className="form-group">
      <label htmlFor={name}>{displayName}</label>
      <input
        type="password"
        id={name}
        disabled={disabled}
        className="form-control"
        value={defaultValue}
        onChange={({ target: { value } }) => onChange(value)}
      />
    </div>
  );
}

export default PasswordInput;

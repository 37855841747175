import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import Select from "components/Select";
import TextInput from "components/TextInput";
import useLocalize from "hooks/useLocalize";
import { I18nSelectors } from "redux/i18n";
import "./GroupEventList.scss";

function GroupEventList({
  category,
  groupEvents = [],
  onSave,
  participantName,
}) {
  const localize = useLocalize();
  const language = useSelector(I18nSelectors.getLanguage);
  const [showGroupEvents, setShowGroupEvents] = useState(false);

  return (
    <div className="GroupEventList">
      {showGroupEvents && (
        <>
          <Formik
            initialValues={{
              groupName: "",
              groupMembers: [],
              competitionEventId: "",
            }}
            validationSchema={Yup.object({
              groupName: Yup.string()
                .max(100, localize("Must be 100 characters or less"))
                .required(localize("Required")),
              groupMembers: Yup.array().of(
                Yup.string()
                  .max(100, localize("Must be 100 characters or less"))
                  .required(localize(`Please enter the group member's name.`))
              ),
              competitionEventId: Yup.string().required(localize("Required")),
            })}
            onSubmit={(values, { resetForm }) => {
              onSave(values);
              resetForm();
            }}
          >
            {({ values }) => {
              const { competitionEventId, groupMembers } = values;
              const selectedEvent = groupEvents.find(
                ({ id }) => id === +competitionEventId
              );
              const min = selectedEvent?.groupMemberMin || 1;
              const max = selectedEvent?.groupMemberMax || 2;

              return (
                <Form className="needs-validation" noValidate>
                  <div className="row">
                    <Select name="competitionEventId">
                      <option>
                        {localize("Select")} {category} {localize("Event")}
                      </option>
                      {groupEvents.map(({ code, id, name, chineseName }) => {
                        let eventName = name;

                        if (language === "cn" && chineseName) {
                          eventName = chineseName;
                        }

                        if (code) {
                          eventName += ` (${code})`;
                        }

                        return (
                          <option value={id} key={id}>
                            {eventName}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="row">
                    <TextInput
                      name="groupName"
                      placeholder={localize("Group Name")}
                    />
                  </div>
                  {!!competitionEventId && (
                    <div className="row">
                      <div className="col">
                        <p>
                          <b>
                            {localize("Group Members")} ({localize("Min")}:{" "}
                            {min}, {localize("Max")}: {max})
                          </b>
                        </p>
                      </div>
                    </div>
                  )}
                  <FieldArray name="groupMembers">
                    {(arrayHelpers) => {
                      if (competitionEventId && groupMembers.length === 0) {
                        arrayHelpers.push(participantName);
                      }

                      return (
                        <>
                          {groupMembers.map((member, index) => (
                            <div
                              className="row d-flex justify-content-between"
                              key={index}
                            >
                              <TextInput
                                size="col-10 group-member-text-input"
                                name={`groupMembers.${index}`}
                                disabled={index === 0}
                                placeholder={localize("Member Name")}
                              />
                              <div className="col-2 group-member-button-wrapper">
                                <button
                                  type="button"
                                  disabled={index === 0}
                                  className="btn btn-danger group-member-button"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  &#x2715;
                                </button>
                              </div>
                            </div>
                          ))}
                          {!!competitionEventId &&
                            !!min &&
                            groupMembers.length !== max && (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => arrayHelpers.push("")}
                              >
                                {localize("Add new member")}
                              </button>
                            )}
                        </>
                      );
                    }}
                  </FieldArray>
                  <div className="row">
                    <div className="col d-flex justify-content-between flex-row-reverse">
                      <button
                        className="btn btn-success float-right"
                        type="submit"
                      >
                        {localize("Save")}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <Button
            onClick={() => setShowGroupEvents(false)}
            className="hideGroupEvents"
          >
            {localize("Hide Group Events")}
          </Button>
        </>
      )}
      {!showGroupEvents && (
        <Button
          onClick={() => setShowGroupEvents(true)}
          className="showGroupEvents"
        >
          {localize(`Register For ${category} Events`)}
        </Button>
      )}
    </div>
  );
}

export default GroupEventList;

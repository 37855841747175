import store from "redux/store";
import { AuthActions } from "redux/auth";
import handleError from "helpers/handleError";

function getAuthToken() {
  let authToken;
  const state = store.getState();
  if (state && state.auth) {
    authToken = state.auth.authToken;
  }

  return authToken;
}

async function baseHTTPRequest(url, body, onError) {
  const authToken = getAuthToken();

  if (authToken) {
    body.headers = {
      ...body.headers,
      Authorization: `Bearer ${authToken}`,
    };
  }

  const response = await fetch(`/v1/${url}`, body);

  if (!response.ok) {
    let message = await response.text();

    if (message === "jwt expired" && response.status === 401) {
      message = "Your session has expired, please try logging in again.";
      store.dispatch(AuthActions.logout());
    }

    if (onError) {
      onError(message);
    } else {
      handleError(message);
    }

    throw Error(message);
  }

  const json = await response.json();

  return json;
}

export async function POSTFILE(url, data) {
  const response = await baseHTTPRequest(url, {
    method: "POST",
    body: data,
  });

  return response;
}

export async function POST(url, data, onError) {
  const response = await baseHTTPRequest(
    url,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    },
    onError
  );

  return response;
}

export async function PUTFILE(url, data) {
  const response = await baseHTTPRequest(url, {
    method: "PUT",
    body: data,
  });

  return response;
}

export async function PUT(url, data) {
  const response = await baseHTTPRequest(url, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
}

export async function GET(url) {
  const response = await baseHTTPRequest(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
}

export async function DELETE(url, data) {
  const response = await baseHTTPRequest(url, {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
}

import { useMutation, useQueryCache } from 'react-query';
import { CompetitionEventAPI } from 'api/admin/competitionEvent';
import { COMPETITION_EVENTS } from 'helpers/constants';

export default function useAddCompetitionEventsToRing() {
  const queryCache = useQueryCache();

  return useMutation(CompetitionEventAPI.addToRing, {
    onSuccess: () => {
      queryCache.invalidateQueries(COMPETITION_EVENTS);
    },
  });
}

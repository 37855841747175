import React from "react";
import { useField } from "formik";

function Checkbox({ children, ...props }) {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: "checkbox" });

  return (
    <div className="form-group">
      <div className="form-check">
        <label className="form-check-label">
          <input
            className="form-check-input"
            type="checkbox"
            {...field}
            {...props}
          />
          {children}
        </label>
        {meta.touched && meta.error ? (
          <div className="invalid-feedback">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
}

export default Checkbox;

import React from "react";
import { Switch } from 'react-router-dom';
import PrintNanduForm from '../PrintNanduForm';
import EditNanduForm from '../EditNanduForm';

function NanduFormRouter() {
  return (
    <Switch>
      <EditNanduForm path="/*" />
      <PrintNanduForm path="print" />
    </Switch>
  );
}

export default NanduFormRouter;
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Modal from 'components/Modal';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import getCountries from 'helpers/countries';
import useLocalize from 'hooks/useLocalize';
import useCreateTeam from 'hooks/teams/useCreateTeam';

function AddTeamModal({ close }) {
  const localize = useLocalize();
  const [createTeam] = useCreateTeam();
  const countries = getCountries();
  const countryOptions = countries.map((country, i) => (
    <option key={i} value={country}>
      {country}
    </option>
  ));

  return (
    <div className="AddTeamModal">
      <Modal close={close}>
        <h3>{localize('Add New Team')}</h3>
        <Formik
          initialValues={{
            name: '',
            city: '',
            state: '',
            country: '',
            leader: '',
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .max(45, localize('Must be 45 characters or less'))
              .required(localize('Required')),
            city: Yup.string()
              .max(45, localize('Must be 45 characters or less'))
              .required(localize('Required')),
            state: Yup.string()
              .max(45, localize('Must be 45 characters or less'))
              .required(localize('Required')),
            country: Yup.string()
              .oneOf(countries, localize('Please select a country.'))
              .required(localize('Required')),
            leader: Yup.string()
              .max(45, localize('Must be 45 characters or less'))
              .required(localize('Required')),
          })}
          onSubmit={async ({ name, city, state, country, leader }) => {
            createTeam({
              name,
              city,
              state,
              country,
              leader,
            });
            close();
          }}
        >
          <Form className="needs-validation" noValidate>
            <div className="row">
              <TextInput label={`${localize('Team Name')}*`} name="name" />
              <TextInput label={`${localize('Coach')}*`} name="leader" />
            </div>
            <div className="row">
              <TextInput label={`${localize('City')}*`} name="city" />
              <TextInput label={`${localize('State')}*`} name="state" />
              <Select label={`${localize('Country')}*`} name="country">
                <option value="">{localize('Select a country')}</option>
                {countryOptions}
              </Select>
            </div>
            <button type="submit" className="btn btn-success float-right">
              {localize('Submit')}
            </button>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
}

export default AddTeamModal;

export default function generateEventLabel(gender, ageGroup, eventName, code) {
  let items = [];

  if (gender) {
    items.push(gender);
  }

  if (ageGroup) {
    items.push(ageGroup);
  }

  if (items.length) {
    items = `[${items.join(", ")}]`;
  }

  if (!eventName) {
    return items;
  }

  if (!code) {
    return `${eventName} ${items}`;
  }

  return `${code}: ${eventName} ${items}`;
}

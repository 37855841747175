import { useMutation, useQueryCache } from 'react-query';
import { ParticipantsAPI } from 'api/participant';
import { PARTICIPANTS } from 'helpers/constants';

export default function useRecoverParticipant() {
  const queryCache = useQueryCache();

  return useMutation(ParticipantsAPI.recover, {
    onSuccess: () => queryCache.invalidateQueries(PARTICIPANTS),
  });
}

import React, { Fragment, useEffect, useState } from 'react';
import { VideoSubmissionAPI } from 'api/videoSubmission';
import useJudge from 'hooks/judges/useJudge';
import useCreateErrorMessage from 'hooks/errorMessages/useCreateErrorMessage';
import ScoreItem from './components/ScoreItem';
import ScoreTable from './components/ScoreTable';
import './JudgingEventCard.scss';

function JudgingEventCard({ eventMap, judgeId }) {
  const [video, setVideo] = useState();
  const [streamUrl, setStreamUrl] = useState();
  const [epId, setEventParticipationId] = useState();
  const [displayName, setDisplayName] = useState();
  const [createErrorMessage] = useCreateErrorMessage();
  const { data: judge } = useJudge(judgeId);
  let firstVideo;

  useEffect(() => {
    const {
      eventParticipationId,
      listItemDisplayName,
      videoUrl,
      videoStreamUrl,
    } = firstVideo;

    setEventParticipationId(eventParticipationId);
    setDisplayName(listItemDisplayName);
    setVideo(videoUrl);
    setStreamUrl(videoStreamUrl);
  }, [firstVideo]);

  return (
    <div className="JudgingEventCard d-flex">
      <div className="sidebar-container">
        {Object.entries(eventMap).map(([ageGroup, ageGroupMap], k) => (
          <Fragment key={k}>
            <h4 className="age-title">
              {ageGroup === 'null' ? 'All Ages' : ageGroup}
            </h4>
            {Object.entries(ageGroupMap).map(([gender, participants], g) => (
              <div className="gender-group" key={g}>
                {gender !== 'null' && <h5>{gender}</h5>}
                <ol className="list-group">
                  {participants.map(
                    (
                      {
                        award,
                        competitionEventId,
                        competitionId,
                        eventParticipationId,
                        finalScore,
                        groupName,
                        participantId,
                        participantName,
                        rank,
                        score,
                        teamName,
                        videoUrl,
                      },
                      h
                    ) => {
                      let success = judge?.isHeadJudge ? finalScore : score;
                      let listItemDisplayName = `${participantName} (ID: ${participantId})`;

                      if (groupName) {
                        listItemDisplayName = `${groupName} - ${listItemDisplayName}`;
                        if (teamName) {
                          listItemDisplayName = `${teamName} - ${listItemDisplayName}`;
                        }
                      }

                      if (rank && judge?.isHeadJudge) {
                        listItemDisplayName = `${listItemDisplayName} [Rank: ${rank}]`;

                        if (award) {
                          listItemDisplayName = `${listItemDisplayName} (${award})`;
                        }
                      }

                      const listItemColor = success
                        ? 'list-group-item-success'
                        : 'list-group-item-danger';

                      if (!firstVideo && h === 0) {
                        const videoStreamUrl = VideoSubmissionAPI.getVideoStream(
                          {
                            participantId,
                            competitionEventId,
                            competitionId,
                          }
                        );

                        firstVideo = {
                          eventParticipationId,
                          listItemDisplayName,
                          videoUrl,
                          videoStreamUrl,
                        };
                      }

                      return (
                        <li
                          className={`list-group-item list-group-item-action ${listItemColor}`}
                          key={h}
                          onClick={() => {
                            const videoStreamUrl = VideoSubmissionAPI.getVideoStream(
                              {
                                participantId,
                                competitionEventId,
                                competitionId,
                              }
                            );

                            setEventParticipationId(eventParticipationId);
                            setDisplayName(listItemDisplayName);
                            setVideo(videoUrl);
                            setStreamUrl(videoStreamUrl);
                          }}
                        >
                          {listItemDisplayName}
                        </li>
                      );
                    }
                  )}
                </ol>
              </div>
            ))}
          </Fragment>
        ))}
      </div>
      <div className="video-container">
        {streamUrl ? (
          <>
            <video
              id={video?.split(' ').join('-')}
              width="100%"
              controls
              src={streamUrl}
              type="video/mp4"
            >
              <p className="video-url">{video}</p>
            </video>
            <h4>{displayName}</h4>
            <div className="feedback-container">
              {judge?.isHeadJudge ? (
                <ScoreTable judgeId={judgeId} eventParticipationId={epId} />
              ) : (
                <ScoreItem judgeId={judgeId} eventParticipationId={epId} />
              )}
            </div>
            <div className="report-issue-container">
              <button
                className="btn btn-info float-right mr-1"
                onClick={() => {
                  Object.values(eventMap).forEach(ageGroupMap =>
                    Object.values(ageGroupMap).forEach(participants => {
                      const [participant] = participants.filter(
                        ({ eventParticipationId }) =>
                          eventParticipationId === epId
                      );

                      if (participant) {
                        const {
                          ageGroup,
                          categoryName,
                          competitionEventId,
                          competitionId,
                          eventFeedbackId,
                          eventParticipationId,
                          gender,
                          participantId,
                          participantName,
                          videoUrl,
                        } = participant;

                        createErrorMessage(
                          JSON.stringify({
                            judgeId,
                            ageGroup,
                            categoryName,
                            competitionEventId,
                            competitionId,
                            eventFeedbackId,
                            eventParticipationId,
                            gender,
                            participantId,
                            participantName,
                            videoUrl,
                          })
                        );
                      }
                    })
                  );
                }}
              >
                Report Issue
              </button>
            </div>
          </>
        ) : (
          <h4 className="text-center">Click an athlete to see their video</h4>
        )}
      </div>
    </div>
  );
}

export default JudgingEventCard;

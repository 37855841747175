import React from "react";
import CardCollapse from "components/CardCollapse";
import CheckoutInfo from "components/CheckoutInfo";

export default ({ checkoutInfo }) => {
  return (
    <CardCollapse id="checkout" title="Checkout Info" show>
      <CheckoutInfo checkoutInfo={checkoutInfo} />
    </CardCollapse>
  );
};

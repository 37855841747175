import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import Table from "components/admin/Table";
import Modal from "components/Modal";
import TextInput from "components/TextInput";
import CONFIRM_DELETE from "helpers/confirmDelete";
import useLocalize from "hooks/useLocalize";
import useCreateEventParticipation from "hooks/eventParticipation/useCreateEventParticipation";
import useRemoveEventParticipation from "hooks/eventParticipation/useRemoveEventParticipation";
import useUpdateEventParticipation from "hooks/eventParticipation/useUpdateEventParticipation";
import { I18nSelectors } from "redux/i18n";
import EventListItem from "./EventListItem";
import GroupEventList from "./GroupEventList";
import "./EventList.scss";

function EventList({
  category,
  competitionId,
  disabled,
  eventParticipationMap,
  events = [],
  participantId,
  participantName,
  registered,
  restriction,
}) {
  const localize = useLocalize();
  const language = useSelector(I18nSelectors.getLanguage);
  const [isEditing, setIsEditing] = useState(false);
  const [editEvent, setEditEvent] = useState();
  const [createEventParticipation] = useCreateEventParticipation();
  const [removeEventParticipation] = useRemoveEventParticipation();
  const [updateEventParticipation] = useUpdateEventParticipation();
  const regularEvents = [];
  const groupEvents = [];
  const registeredGroupEvents = [];
  let groupEventsData;

  events.forEach((event) => {
    if (event.groupMemberMax) {
      if (eventParticipationMap?.[competitionId]?.[event.id]) {
        let name = event.name;

        if (language === "cn" && event.chineseName) {
          name = event.chineseName;
        }

        if (event.code) {
          name += ` (${event.code})`;
        }

        registeredGroupEvents.push({
          ...event,
          ...eventParticipationMap[competitionId][event.id],
          name,
        });
      } else {
        groupEvents.push(event);
      }
    } else {
      regularEvents.push(event);
    }
  });

  if (registeredGroupEvents.length) {
    groupEventsData = {
      defaultSort: "name",
      headingData: {
        name: localize("Event Name"),
        groupName: localize("Group Name"),
        groupMembers: localize("Group Members"),
      },
      items: registeredGroupEvents || [],
    };
  }

  return (
    <div className="EventList mb-2">
      {isEditing && (
        <Modal close={() => setIsEditing(false)}>
          <Formik
            initialValues={{
              groupName: editEvent.groupName,
              groupMembers: editEvent.groupMembers.split(", "),
            }}
            validationSchema={Yup.object({
              groupName: Yup.string()
                .max(100, localize("Must be 100 characters or less"))
                .required(localize("Required")),
              groupMembers: Yup.array().of(
                Yup.string()
                  .max(100, localize("Must be 100 characters or less"))
                  .required(localize(`Please enter the group member's name.`))
              ),
            })}
            onSubmit={({ groupName, groupMembers }) => {
              updateEventParticipation({
                participantId,
                competitionId,
                competitionEventId: editEvent.id,
                groupName,
                groupMembers,
              });
              setIsEditing(false);
            }}
          >
            {({ values }) => {
              const { groupMembers } = values;
              const min = editEvent.groupMemberMin || 1;
              const max = editEvent.groupMemberMax || 2;

              return (
                <Form className="needs-validation" noValidate>
                  <h3>
                    {localize("Edit Group Event")} - {editEvent.name}
                  </h3>
                  <hr />
                  <div className="row">
                    <TextInput
                      name="groupName"
                      label={localize("Group Name")}
                    />
                  </div>
                  <label>{localize("Group Members")}</label>
                  <FieldArray name="groupMembers">
                    {(arrayHelpers) => (
                      <>
                        {groupMembers.map((member, index) => (
                          <div
                            className="row d-flex justify-content-between"
                            key={index}
                          >
                            <TextInput
                              size="col-10 group-member-text-input"
                              name={`groupMembers.${index}`}
                              value={member}
                              disabled={index === 0}
                              placeholder={localize("Member Name")}
                            />
                            <div className="col-2 group-member-button-wrapper">
                              <button
                                type="button"
                                disabled={index === 0}
                                className="btn btn-danger group-member-button"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                &#x2715;
                              </button>
                            </div>
                          </div>
                        ))}
                        {!!min && groupMembers.length !== max && (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              let member = "";

                              if (groupMembers.length === 0) {
                                member = participantName;
                              }

                              arrayHelpers.push(member);
                            }}
                          >
                            {localize("Add new member")}
                          </button>
                        )}
                      </>
                    )}
                  </FieldArray>
                  <hr />
                  <div className="row">
                    <div className="col d-flex justify-content-between">
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={() => {
                          CONFIRM_DELETE(() => {
                            removeEventParticipation({
                              participantId,
                              competitionId,
                              competitionEventId: editEvent.id,
                            });
                            setIsEditing(false);
                          });
                        }}
                      >
                        {localize("Delete")}
                      </button>
                      <button
                        className="btn btn-success float-right"
                        type="submit"
                      >
                        {localize("Save")}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      )}
      <h6 className="title">
        {category} {restriction && `(${localize("Max")}: ${restriction})`}
      </h6>
      <div className="row">
        {regularEvents.map(
          ({
            chineseName,
            code,
            name,
            id: competitionEventId,
            registered: eventRegistered,
          }) => {
            const checked =
              eventParticipationMap?.[competitionId]?.[competitionEventId] ||
              false;
            const restricted = registered >= restriction && !checked;
            let eventName = name;

            if (language === "cn" && chineseName) {
              eventName = chineseName;
            }

            if (code) {
              eventName += ` (${code})`;
            }

            return (
              <EventListItem
                checked={!!checked || !!eventRegistered}
                competitionEventId={competitionEventId}
                competitionId={competitionId}
                disabled={disabled}
                key={competitionEventId}
                name={eventName}
                participantId={participantId}
                restricted={restricted}
              />
            );
          }
        )}
      </div>
      {!!registeredGroupEvents.length && (
        <Table
          data={groupEventsData}
          itemClick={(event) => {
            if (!disabled) {
              setEditEvent(event);
              setIsEditing(true);
            }
          }}
        ></Table>
      )}
      {!!groupEvents.length && !disabled && (
        <GroupEventList
          groupEvents={groupEvents}
          category={category}
          participantName={participantName}
          onSave={({ competitionEventId, groupName, groupMembers }) =>
            createEventParticipation({
              participantId,
              competitionId,
              competitionEventId,
              groupName,
              groupMembers,
            })
          }
        />
      )}
    </div>
  );
}

export default EventList;

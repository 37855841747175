import { DELETE, GET, POST, PUT } from "api/config";

const CheckInAPI = {
  async getCheckIns(ringId) {
    const { checkIns } = await GET(`checkIn/ring/${ringId}`);

    return checkIns;
  },
  async addCheckIn({ eventParticipationId, count }) {
    return await POST(`checkIn/${eventParticipationId}/${count}`);
  },
  async removeCheckIn({ eventParticipationId, count }) {
    return await DELETE(`checkIn/${eventParticipationId}/${count}`);
  },
  async updateCheckInNote({ eventParticipationId, note }) {
    return await PUT(`eventParticipation/${eventParticipationId}/checkInNote`, {
      note,
    });
  },
};

export { CheckInAPI };

import React from 'react';
import { useSelector } from 'react-redux';
import { NanduFormSelectors } from 'redux/nanduForm';
import NanduModal from './NanduModal';
import NanduSection from './NanduSection';

function EditNanduForm() {
  const sections = useSelector(NanduFormSelectors.getSections);
  const formType = useSelector(NanduFormSelectors.getFormType);
  const wushuStyle = useSelector(NanduFormSelectors.getWushuStyle);
  const showNanduModal = useSelector(NanduFormSelectors.getShowNanduModal);
  const selectedTypeAndStyle = formType && wushuStyle;

  return (
    <div className="editNanduForm">
      { selectedTypeAndStyle && sections.map((section, index) => <NanduSection formType={formType} section={section} sectionId={index} key={section.id}></NanduSection>) }
      { showNanduModal && <NanduModal sections={sections}/> }
    </div>
  );
}

export default EditNanduForm;

import { GET, POST, PUT, DELETE } from "api/config";

const BASE_ENDPOINT = "championshipAgeGroups";
const ChampionshipAgeGroupAPI = {
  async getAll(championshipId) {
    const { ageGroups } = await GET(`${BASE_ENDPOINT}/${championshipId}`);

    return ageGroups;
  },
  async add(ageGroupInput) {
    return await POST(BASE_ENDPOINT, { ageGroupInput });
  },
  async remove(id) {
    return await DELETE(`${BASE_ENDPOINT}/${id}`);
  },
  async update(ageGroupInput) {
    const { id } = ageGroupInput;

    return await PUT(`${BASE_ENDPOINT}/${id}`, { ageGroupInput });
  },
};

export { ChampionshipAgeGroupAPI };

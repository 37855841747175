import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import "./CardCollapse.scss";

function CardCollapse({ children, id, title, show, onClickHeader = () => {} }) {
  const ref = useRef();
  const [chevronDown, setChevronDown] = useState(show);
  const chevronDirection = chevronDown ? "down" : "up";
  const cardId = `card-body-${id}`;
  const cardBodyClasses = classnames("collapse");

  useEffect(() => {
    // TODO: when upgrading bootstrap, will have to update this function
    // eslint-disable-next-line no-undef
    const collapse = $(ref?.current);

    if (collapse) {
      if (show) {
        collapse.collapse("show");
      } else {
        collapse.collapse("hide");
      }
    }

    setChevronDown(show);
  }, [show]);

  return (
    <div className="CardCollapse card">
      <div
        className="card-header d-flex justify-content-between"
        data-toggle="collapse"
        data-target={`#${cardId}`}
        onClick={() => {
          onClickHeader(!chevronDown);
          setChevronDown(!chevronDown);
        }}
      >
        <h5 className="card-title mb-0">{title}</h5>
        <span className={`ion-chevron-${chevronDirection} no-print`}></span>
      </div>
      <div id={cardId} className={cardBodyClasses} ref={ref}>
        <div className="card-body">
          {!!chevronDown && <div className="tab-content">{children}</div>}
        </div>
      </div>
    </div>
  );
}

export default CardCollapse;

import { DELETE, GET, POST, PUT } from '../config';

const JudgeAPI = {
  async getJudges(competitionId) {
    const { judges } = await GET(`judges/competition/${competitionId}`);

    return judges;
  },
  async getJudgesForRing(ringId) {
    const { judges } = await GET(`judges/ring/${ringId}`);

    return judges;
  },
  async getOneById(id) {
    const { judge } = await GET(`judges/${id}`);

    return judge;
  },
  async add(judgeInput) {
    return await POST(`judges`, { judgeInput });
  },
  async remove({ id }) {
    return await DELETE(`judges/${id}`);
  },
  async toggleHeadJudge({ id }) {
    return await PUT(`judges/${id}`);
  },
  async updateRing({ id, ringId }) {
    return await PUT(`judges/${id}/ring`, { ringId });
  },
};

export { JudgeAPI };

import React, { useState } from "react";
import Select from "components/Modal/ModalFields/Select";
import ChampionshipParticipantsReport from "./ChampionshipParticipantsReport";
import DuplicateScoresReport from "./DuplicateScoresReport";
import MissingUSAWKFIDReport from "./MissingUSAWKFIDReport";
import TeamParticipantsReport from "./TeamParticipantsReport";
import TeamMedalsReport from "./TeamMedalsReport";

function Reports({ competitionId }) {
  const [report, setReport] = useState("teamParticipants");
  const competitions = [
    { label: "Team Athlete Report", value: "teamParticipants" },
    { label: "Team Medal Report", value: "teamMedals" },
    { label: "Championship Athlete Report", value: "championshipParticipants" },
    { label: "Missing USAWKFID", value: "missingUSAWKFID" },
    { label: "Duplicate Scores", value: "duplicateScores" },
  ];

  return (
    <div className="Reports">
      <h5>Select Report</h5>
      <Select
        items={competitions}
        onChange={(c) => {
          setReport(c.value);
        }}
      />
      <hr />
      {report === "missingUSAWKFID" && (
        <MissingUSAWKFIDReport competitionId={competitionId} />
      )}
      {report === "teamParticipants" && (
        <TeamParticipantsReport competitionId={competitionId} />
      )}
      {report === "championshipParticipants" && (
        <ChampionshipParticipantsReport competitionId={competitionId} />
      )}
      {report === "teamMedals" && (
        <TeamMedalsReport competitionId={competitionId} />
      )}
      {report === "duplicateScores" && (
        <DuplicateScoresReport competitionId={competitionId} />
      )}
    </div>
  );
}

export default Reports;

import { useMutation, useQueryCache } from 'react-query';
import { WebsiteMessageAPI } from 'api/websiteMessage';
import { WEBSITE_MESSAGES } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useCreateWebsiteMessage() {
  const queryCache = useQueryCache();

  return useMutation(WebsiteMessageAPI.add, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      dispatchToast({ message, type: 'success' });
      queryCache.invalidateQueries(WEBSITE_MESSAGES);
      onSuccess();
    },
  });
}

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Table from "components/admin/Table";
import useLockInChampionshipResults from "hooks/championships/useLockInChampionshipResults";
import useUpdateChampionshipParticipationClaimedAward from "hooks/championshipParticipation/useUpdateChampionshipParticipationClaimedAward";
import useLocalize from "hooks/useLocalize";
import { AuthSelectors } from "redux/auth";
import GenderRadio from "../GenderRadio";

export default function AllAroundCollapseContent({
  championshipId,
  id,
  genders,
}) {
  const isAdmin = useSelector(AuthSelectors.isAdmin);
  const [
    updateChampionshipParticipationClaimedAward,
  ] = useUpdateChampionshipParticipationClaimedAward();
  const [lockInChampionshipResults] = useLockInChampionshipResults();
  const localize = useLocalize();
  const history = useHistory();
  const [gender, setGender] = useState();
  const [tableContent, setTableContent] = useState();
  const headingData = {
    rank: localize("Rank"),
    id: localize("ID"),
    name: localize("Name"),
    totalScore: localize("Total Score"),
    teamName: localize("Team"),
  };

  useEffect(() => {
    if (isAdmin) {
      headingData.claimed = "Claimed Award";
    }

    const data = {
      itemName: "Athletes",
      headingData,
      items:
        genders[gender]?.map((p) => {
          if (isAdmin) {
            p.claimed = {
              isCheckbox: true,
              props: {
                onChange: () => {
                  updateChampionshipParticipationClaimedAward({
                    id: p.championshipParticipationId,
                    claimedAward: !p.hasClaimed,
                  });
                },
                checked: !!p.hasClaimed,
              },
            };
          }

          return p;
        }) || [],
      searchableColumns: ["name", "teamName"],
    };

    setTableContent(
      <Table
        data={data}
        disableSort
        pageTotal={100}
        itemClick={({ id }) => {
          history.push(`/schedule/${id}/events`);
        }}
      />
    );
  }, [genders, gender]);

  useEffect(() => {
    if (genders) {
      setGender(Object.keys(genders)[0]);
    }
  }, [genders]);

  return (
    <div className="ChampionshipAgeGroup">
      {isAdmin && (
        <Button
          className="btn-success"
          onClick={() =>
            lockInChampionshipResults({
              championshipId,
              ageGroupName: id[0],
              gender,
            })
          }
        >
          Lock In Results
        </Button>
      )}
      <div className="d-flex justify-content-end mb-4">
        <GenderRadio
          id={`${id}-M`}
          onChange={setGender}
          value={"male"}
          checked={gender === "male"}
          label={"Male"}
        />
        <GenderRadio
          id={`${id}-F`}
          onChange={setGender}
          value={"female"}
          checked={gender === "female"}
          label={"Female"}
        />
      </div>
      {tableContent}
    </div>
  );
}

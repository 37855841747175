import { useQuery } from "react-query";
import { ChampionshipAPI } from "api/championship";
import { CHAMPIONSHIPS } from "helpers/constants";

export default function useTeamTrialResults(competitionId) {
  return useQuery(
    [CHAMPIONSHIPS, competitionId, "teamTrials"],
    () => ChampionshipAPI.getTeamTrialResultsForCompetition(competitionId),
    { enabled: !!competitionId }
  );
}

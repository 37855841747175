import React, { useState } from 'react';
import classnames from 'classnames';
import useLocalize from 'hooks/useLocalize';
import './FileInput.scss';

function FileInput({ disabled, isUploading, processFile, uid, label, max }) {
  const localize = useLocalize();
  const [exceedsMax, setExceedsMax] = useState(false);
  const labelClasses = classnames('custom-file-label', { disabled });
  const inputGroupClasses = classnames('FileInput', 'input-group', {
    'is-invalid': exceedsMax,
  });

  return (
    <>
      <div className={inputGroupClasses}>
        <div className="custom-file">
          {isUploading && <div className="spinner-border"></div>}
          {!isUploading && (
            <>
              <input
                disabled={disabled}
                id={`inputGroupFile-${uid}`}
                type="file"
                accept="video/*"
                onChange={({ target: { files } }) => {
                  const [file] = files;

                  if (max && file.size > max) {
                    setExceedsMax(true);
                  } else {
                    setExceedsMax(false);
                    processFile(file);
                  }
                }}
              />
              <label className={labelClasses} htmlFor={`inputGroupFile-${uid}`}>
                {label}
              </label>
            </>
          )}
        </div>
      </div>
      {exceedsMax && (
        <div className="FileInput invalid-feedback">
          {localize('File must be smaller than 200MB.')}
        </div>
      )}
    </>
  );
}

export default FileInput;

import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import UserRouter from './User/UserRouter';
import UserManager from './UserManager';

function UserManagerRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <UserManager />
      </Route>
      <Route path={`${path}/:userId`}>
        <UserRouter />
      </Route>
    </Switch>
  );
}

export default UserManagerRouter;
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import useEventParticipationForEventTotalAthletes from "hooks/eventParticipation/useEventParticipationForEventTotalAthletes";
import useUpdateEventParticipationLotOrder from "hooks/eventParticipation/useUpdateEventParticipationLotOrder";
import { useMediaQuery } from "hooks/useMediaQuery";
import useLocalize from "hooks/useLocalize";
import styles from "./DrawLotModalContent.module.scss";

function Lot({
  isRight = false,
  trialSelection = false,
  rotate = 0,
  translate = -100,
  onClick = () => {},
  eventParticipationId,
}) {
  const defaultHolderStyle = {
    transform: `rotate(${rotate}deg) translateY(${translate}px)`,
  };
  const isMobile = useMediaQuery("(max-width: 450px)");
  const [holderStyle, setHolderStyle] = useState(defaultHolderStyle);
  const [cardStyle, setCardStyle] = useState();
  const [
    updateEventParticipationLotOrder,
  ] = useUpdateEventParticipationLotOrder();
  const [lotOrder, setLotOrder] = useState(null);

  // having drawLot be in the Lot component may be necessary due to possible race conditions in the animation vs api call
  async function drawLot() {
    if (trialSelection) {
      const newLotOrder = trialSelection;

      setLotOrder(newLotOrder);
    } else {
      const newLotOrder = await updateEventParticipationLotOrder(
        eventParticipationId
      );
      setLotOrder(newLotOrder);
    }
  }

  return (
    <div
      className={classnames(
        styles.cardHolder,
        isRight ? styles.transformRight : styles.transformLeft
      )}
      style={holderStyle}
      onClick={() => {
        if (!lotOrder) {
          setHolderStyle({
            position: "absolute",
            top: isMobile ? "60%" : "25%",
            left: "50%",
            transform: `rotate(90deg) rotateY(180deg) translateX(${Math.abs(
              translate
            )}px)`,
            transition: "1s",
            transformStyle: "preserve-3d",
          });
          setCardStyle({
            width: isMobile ? "40px" : "50px",
            height: isMobile ? "220px" : "360px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            transform: "none",
            boxShadow: "0px 0px 15px gold",
            cursor: "default",
          });
          drawLot();
          onClick();
        }
      }}
    >
      <div className={styles.card} style={cardStyle}>
        {lotOrder && (
          <h4 className={classnames(styles.number, "mb-0")}>{lotOrder}</h4>
        )}
      </div>
    </div>
  );
}

export default function DrawLotModalContent({
  competitionEventId,
  eventParticipationId,
  showFinalDraw,
}) {
  const localize = useLocalize();
  const { data: totalAthletes } = useEventParticipationForEventTotalAthletes(
    competitionEventId
  );
  const [selected, setSelected] = useState(null);
  const [lotComponents, setLotComponents] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isTrialRun, setIsTrialRun] = useState(false);

  useEffect(() => {
    const lots = [];
    const trialSelection = Math.floor(Math.random() * totalAthletes) + 1;
    let rotateBy = 10;

    if (180 / totalAthletes < rotateBy) {
      rotateBy = 180 / totalAthletes;
    }

    let mid = totalAthletes;

    // if its 1, mid is 1
    if (totalAthletes > 1) {
      // if its even, the midway point is .5 + total/2
      // if its odd, the midway point is a whole number
      mid = totalAthletes / 2 + 0.5;
    }

    for (let i = 1; i <= totalAthletes; i += 1) {
      const isRight = i < mid;
      let rotate = 0;

      if (i !== mid) {
        rotate = (i - mid) * rotateBy;
      }

      lots.push(
        <Lot
          key={i}
          rotate={rotate}
          isRight={isRight}
          trialSelection={isTrialRun ? trialSelection : false}
          onClick={() => {
            setSelected(i - 1);
          }}
          eventParticipationId={eventParticipationId}
        />
      );
    }

    setLotComponents(lots);
  }, [totalAthletes, isTrialRun, selected]);

  useEffect(() => {
    if (typeof selected === "number") {
      setLotComponents([lotComponents[selected]]);
    }
  }, [selected]);

  return (
    <div className="DrawLotModalContent">
      <h1 className={styles.title}>{localize("Draw Your Lot")}</h1>
      {isDrawing && <div className={styles.cardHand}>{lotComponents}</div>}
      {selected !== null && isTrialRun && (
        <button
          className="btn btn-primary"
          onClick={() => {
            setIsTrialRun(false);
            setIsDrawing(false);
            setSelected(null);
          }}
        >
          {localize("Reset")}
        </button>
      )}
      {!isDrawing && (
        <div
          className={classnames(
            "d-flex",
            "justify-content-around",
            styles.buttonContainer
          )}
        >
          <button
            className="btn btn-primary"
            onClick={() => {
              setIsTrialRun(true);
              setIsDrawing(true);
            }}
          >
            {localize("Trial Run")}
          </button>
          {showFinalDraw && (
            <button
              className="btn btn-danger"
              onClick={() => setIsDrawing(true)}
            >
              {localize("Final Draw")}
            </button>
          )}
        </div>
      )}
    </div>
  );
}

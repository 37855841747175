import React from "react";
import Checkbox from "components/Checkbox";
import useGenders from "hooks/genders/useGenders";
import useChampionshipGenders from "hooks/championshipGenders/useChampionshipGenders";
import useCreateChampionshipGenders from "hooks/championshipGenders/useCreateChampionshipGenders";
import useRemoveChampionshipGenders from "hooks/championshipGenders/useRemoveChampionshipGenders";

function AddChampionshipGenderRequirements({ championshipId }) {
  const { data: genders } = useGenders();
  const { data: championshipGenders } = useChampionshipGenders(championshipId);
  const [createChampionshipGender] = useCreateChampionshipGenders();
  const [removeChampionshipGender] = useRemoveChampionshipGenders();

  return (
    <div className="row form-group">
      <div className="col">
        <label>Eligible Genders</label>
        <div className="card card-body bg-light">
          {genders?.map(({ id, name }, index) => (
            <Checkbox
              key={index}
              checked={
                !!championshipGenders?.find(({ genderId }) => genderId === id)
              }
              id={`eligibleGenders-${id}`}
              label={name}
              onChange={async (checked) => {
                if (checked) {
                  createChampionshipGender({
                    championshipId,
                    genderId: id,
                  });
                } else {
                  const championshipGenderId = championshipGenders?.find(
                    ({ genderId }) => genderId === id
                  ).championshipGenderId;
                  removeChampionshipGender(championshipGenderId);
                }
              }}
              useBootstrapClasses
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AddChampionshipGenderRequirements;

export default function calculateFinalScore(scores, adjustment = 0) {
  if (!scores.length) return 0;

  const sum = scores.reduce((acc, score) => acc + score, 0);
  let finalScore;
  let max = 0;
  let min = 0;
  let length = scores.length;

  if (scores.length > 3) {
    min = Math.min(...scores);
    max = Math.max(...scores);
    length -= 2;
  }

  finalScore = (sum - max - min) / length;

  return Math.round(finalScore * 100) / 100 + adjustment;
}

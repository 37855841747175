import { GET, POST, DELETE } from "api/config";

const BASE_ENDPOINT = "championshipGenders";
const ChampionshipGenderAPI = {
  async getAll(championshipId) {
    const { genders } = await GET(`${BASE_ENDPOINT}/${championshipId}`);

    return genders;
  },
  async add(input) {
    return await POST(BASE_ENDPOINT, { input });
  },
  async remove(id) {
    return await DELETE(`${BASE_ENDPOINT}/${id}`);
  },
};

export { ChampionshipGenderAPI };

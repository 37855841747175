import React, { useState } from 'react';
import dispatchToast from 'helpers/dispatchToast';
import useWebsiteMessages from 'hooks/websiteMessages/useWebsiteMessages';
import useCreateWebsiteMessage from 'hooks/websiteMessages/useCreateWebsiteMessage';
import useRemoveWebsiteMessage from 'hooks/websiteMessages/useRemoveWebsiteMessage';
import useUpdateWebsiteMessage from 'hooks/websiteMessages/useUpdateWebsiteMessage';
import ModalTable from 'components/admin/ModalTable';

function WebsiteMessageManager() {
  const [text, setText] = useState('');
  const { data: websiteMessages } = useWebsiteMessages();
  const [createWebsiteMessage] = useCreateWebsiteMessage();
  const [removeWebsiteMessage] = useRemoveWebsiteMessage();
  const [updateWebsiteMessage] = useUpdateWebsiteMessage();

  function setIsEditingItem({ text }) {
    setText(text);
  }

  function setIsAddingItem() {
    setText('');
  }

  function undoDelete({ text }) {
    createWebsiteMessage({ text });
  }

  function addItem() {
    if (text) {
      createWebsiteMessage({ text });
    } else {
      dispatchToast({
        message: 'Please provide message text.',
        type: 'error',
      });
    }
  }

  function removeItem({ id }) {
    removeWebsiteMessage(id);
  }

  function editItem({ id }) {
    if (text) {
      updateWebsiteMessage({ id, text });
    } else {
      dispatchToast({
        message: 'Please provide text and abbreviation.',
        type: 'error',
      });
    }
  }

  const tableData = {
    searchableColumns: ['text'],
    headingData: {
      text: 'Text',
    },
    items: websiteMessages || [],
    modalData: {
      fields: [
        {
          defaultValue: text,
          displayName: 'Text',
          text: 'text',
          onChange: value => setText(value),
          type: 'text',
        },
      ],
    },
    itemName: 'Website Message',
  };

  return (
    <div className="websiteMessageManager">
      <h2 className="text-left">Website Message Manager</h2>
      <ModalTable
        addItem={addItem}
        data={tableData}
        editItem={editItem}
        removeItem={removeItem}
        setIsAddingItem={setIsAddingItem}
        setIsEditingItem={setIsEditingItem}
        undoDelete={undoDelete}
      />
    </div>
  );
}

export default WebsiteMessageManager;

import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Modal from 'components/Modal';
import TextInput from 'components/TextInput';
import CONFIRM_DELETE from 'helpers/confirmDelete';
import useRemoveRing from 'hooks/rings/useRemoveRing';
import useUpdateRing from 'hooks/rings/useUpdateRing';
import './Ring.scss';

function Ring({ children, id, name, description }) {
  const [showModal, setShowModal] = useState(false);
  const [updateRing] = useUpdateRing();
  const [removeRing] = useRemoveRing();

  function closeModal() {
    setShowModal(false);
  }

  function openEditRingModal() {
    setShowModal(true);
  }

  function remove() {
    removeRing(id);
    closeModal();
  }

  return (
    <div className="Ring">
      {showModal && (
        <Modal close={closeModal}>
          <h3>Edit Ring</h3>
          <Formik
            initialValues={{
              name: name || '',
              description: description || '',
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Required'),
              description: Yup.string(),
            })}
            onSubmit={async ({ name, description }) => {
              updateRing({ id, description, name });
              closeModal();
            }}
          >
            <Form className="needs-validation" noValidate>
              <div className="row">
                <TextInput placeholder="Name" name="name" />
              </div>
              <div className="row">
                <TextInput placeholder="Description" name="description" />
              </div>
              <button type="submit" className="btn btn-primary float-right">
                Submit
              </button>
            </Form>
          </Formik>
          <button
            className="btn btn-danger"
            onClick={() => CONFIRM_DELETE(remove)}
          >
            Delete
          </button>
        </Modal>
      )}
      <div
        className="header d-flex justify-content-between align-items-center"
        onClick={() => {
          if (id > 0) {
            openEditRingModal();
          }
        }}
      >
        <div className="title">{name}</div>
        {id > 0 && (
          <button className="btn btn-outline-light">
            <span className="ion-more"></span>
          </button>
        )}
      </div>
      <div className="subtitle">{description}</div>
      {children}
    </div>
  );
}

export default Ring;

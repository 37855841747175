import qs from 'qs';
import { DELETE, GET, POST, POSTFILE, PUT } from 'api/config';

const VideoSubmissionAPI = {
  getVideoSubmission({ participantId, competitionEventId, competitionId }) {
    const queryParams = qs.stringify({
      competitionId,
      participantId,
      competitionEventId,
      type: process.env.REACT_APP_SUBMISSION_TYPE,
    });
    const url = `videoSubmissions?${queryParams}`;

    return GET(url);
  },
  getVideoStream({ participantId, competitionEventId, competitionId }) {
    const queryParams = qs.stringify({
      competitionId,
      participantId,
      competitionEventId,
      type: process.env.REACT_APP_SUBMISSION_TYPE,
    });

    return `/v1/videoSubmissions/stream?${queryParams}`;
  },
  addVideoSubmission({
    video,
    participantId,
    competitionEventId,
    competitionId,
  }) {
    return POST('videoSubmissions', {
      video,
      participantId,
      competitionEventId,
      competitionId,
    });
  },
  uploadVideoSubmission({
    video,
    participantId,
    competitionEventId,
    competitionId,
  }) {
    const formData = new FormData();

    formData.append('video', video);
    formData.append('participantId', participantId);
    formData.append('competitionEventId', competitionEventId);
    formData.append('competitionId', competitionId);

    return POSTFILE('videoSubmissions/upload', formData);
  },
  removeVideoSubmission({ participantId, competitionEventId, competitionId }) {
    const queryParams = qs.stringify({
      competitionId,
      participantId,
      competitionEventId,
      type: process.env.REACT_APP_SUBMISSION_TYPE,
    });
    const url = `videoSubmissions?${queryParams}`;

    return DELETE(url);
  },
  updateVideoSubmissionDetails({ id, competitionEventId, videoUrl }) {
    return PUT(`videoSubmissions/${id}/details`, {
      competitionEventId,
      videoUrl,
    });
  },
};

export { VideoSubmissionAPI };

import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import getCountries from 'helpers/countries';
import dispatchToast from 'helpers/dispatchToast';
import useTeams from 'hooks/teams/useTeams';
import useCreateTeam from 'hooks/teams/useCreateTeam';
import ModalTable from 'components/admin/ModalTable';

function TeamManager() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [leader, setLeader] = useState('');
  const { data: teams } = useTeams();
  const [createTeam] = useCreateTeam();
  const countries = getCountries().map(c => ({
    label: c,
    value: c,
  }));

  function setIsAddingItem() {
    setName('');
    setCity('');
    setState('');
    setCountry('');
    setLeader('');
  }

  function addItem() {
    if (name && city && state && country && leader) {
      createTeam({
        name,
        city,
        state,
        country: country.value,
        leader,
      });
    } else {
      dispatchToast({
        message: 'Please fill out all fields.',
      });
    }
  }

  function itemClick(item) {
    const { id } = item;

    history.push(`${path}/${id}`);
  }

  const tableData = {
    defaultSort: 'name',
    searchableColumns: ['name', 'city', 'state', 'country', 'leader'],
    headingData: {
      name: 'Name',
      members: 'Members',
      city: 'City',
      state: 'State',
      country: 'Country',
      leader: 'Leader',
    },
    items: teams || [],
    modalData: {
      fields: [
        {
          defaultValue: name,
          displayName: 'Name',
          name: 'name',
          onChange: value => setName(value),
          type: 'text',
        },
        {
          defaultValue: city,
          displayName: 'City',
          name: 'city',
          onChange: value => setCity(value),
          type: 'text',
        },
        {
          defaultValue: state,
          displayName: 'State',
          name: 'state',
          onChange: value => setState(value),
          type: 'text',
        },
        {
          defaultValue: country,
          displayName: 'Country',
          name: 'country',
          onChange: value => setCountry(value),
          type: 'select',
          items: countries,
        },
        {
          defaultValue: leader,
          displayName: 'Leader',
          name: 'leader',
          onChange: value => setLeader(value),
          type: 'text',
        },
      ],
    },
    itemName: 'Team',
  };

  return (
    <div className="TeamManager">
      <h2 className="text-left">Team Manager</h2>
      <ModalTable
        addItem={addItem}
        data={tableData}
        itemClick={itemClick}
        setIsAddingItem={setIsAddingItem}
      />
    </div>
  );
}

export default TeamManager;

import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import TeamManager from '.';
import Team from './Team';

function TeamManagerRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <TeamManager />
      </Route>
      <Route path={`${path}/:id`}>
        <Team />
      </Route>
    </Switch>
  );
}

export default TeamManagerRouter;

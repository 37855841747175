import React from 'react';
import { useDispatch } from 'react-redux';
import { NanduFormActions } from 'redux/nanduForm';
import './NanduInput.css';

function NanduInput({ nandu, nanduIndex, sectionId }) {
  const dispatch = useDispatch();
  const isConnection = nandu.code === '+' || (nandu.nanduType && nandu.nanduType === 'CONNECTION');

  function openNanduModal() {
    const payload = {
      nanduIndex,
      sectionId,
    };
    dispatch(NanduFormActions.openNanduModal(payload));
  }

  function removeSectionInput(e) {
    e.stopPropagation();
    const payload = {
      nanduIndex,
      sectionId,
      nandu,
    };
    dispatch(NanduFormActions.removeSectionInput(payload));
  }

  return (
    <div className={"nanduInput " + (isConnection ? "connection" : "")} onClick={openNanduModal}>
      <div className="nanduInputRemove ion-close-circled" onClick={removeSectionInput}></div>
      <div>
        <div className="code">{nandu.code || 'Select a nandu'}</div>
        <div className="score">{nandu.formValue || ''}</div>
      </div>
    </div>
  );
}

export default NanduInput;

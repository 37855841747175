import { GET, POST, PUT, DELETE } from "api/config";

const BASE_ENDPOINT = "ticketPurchases";
const TicketPurchasesAPI = {
  async getAllForTournament(tournamentId) {
    const { ticketPurchases } = await GET(
      `${BASE_ENDPOINT}/tournament/${tournamentId}`
    );

    return ticketPurchases;
  },
  async getAllForUser({ userId, tournamentId }) {
    const { ticketPurchases } = await GET(
      `${BASE_ENDPOINT}/${userId}/${tournamentId}`
    );

    return ticketPurchases;
  },
  async add(ticketPurchaseInput) {
    return await POST(BASE_ENDPOINT, { ticketPurchaseInput });
  },
  async remove(ticketPurchaseInput) {
    return await DELETE(BASE_ENDPOINT, { ticketPurchaseInput });
  },
  async removeByIds({ ids }) {
    return await DELETE(`${BASE_ENDPOINT}/ids`, { ids });
  },
  async update({ ticketPurchases }) {
    return await PUT(BASE_ENDPOINT, { ticketPurchases });
  },
};

export default TicketPurchasesAPI;

import { useMutation, useQueryCache } from "react-query";
import { CompetitionEventAPI } from "api/admin/competitionEvent";
import { COMPETITION_EVENTS } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useRemoveCompetitionEventsFromRing() {
  const queryCache = useQueryCache();

  return useMutation(CompetitionEventAPI.removeCompetitionEventsFromRing, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(COMPETITION_EVENTS);

      dispatchToast({ message, type: "success" });
      onSuccess();
    },
  });
}

import React, { useState } from "react";
import AllResultsTabs from "components/AllResultsTabs";
import CompetitionSelect from "components/CompetitionSelect";
import useCompetition from "hooks/competitions/useCompetition";
import useLocalize from "hooks/useLocalize";
import "./AllResultsHome.scss";

function AllResultsHome() {
  const [competitionId, setCompetitionId] = useState();
  const { data: competition } = useCompetition(competitionId);
  const localize = useLocalize();

  return (
    <div className="AllResultsHome container-fluid">
      <h2 className="text-left">{localize("All Results")}</h2>
      <CompetitionSelect
        id={"all-results"}
        onChange={({ value }) => setCompetitionId(value)}
      />
      <AllResultsTabs
        competitionId={competitionId}
        isVirtual={competition?.isVirtual}
      />
    </div>
  );
}

export default AllResultsHome;

export const OPEN_NANDU_MODAL = 'OPEN_NANDU_MODAL';
export const CLOSE_NANDU_MODAL = 'CLOSE_NANDU_MODAL';
export const CHANGE_MOVEMENT = 'CHANGE_MOVEMENT';
export const ADD_SECTION_INPUT = 'ADD_SECTION_INPUT';
export const REMOVE_SECTION_INPUT = 'REMOVE_SECTION_INPUT';
export const CALCULATE_NANDU_SCORE = 'CALCULATE_NANDU_SCORE';
export const SAVE_PDF = 'SAVE_PDF';
export const CHANGE_WUSHU_STYLE = 'CHANGE_WUSHU_STYLE';
export const CHANGE_NANDU_FORM_TYPE = 'CHANGE_NANDU_FORM_TYPE';
export const FETCH_NANDU_DATA = 'FETCH_NANDU_DATA';
export const VERIFY_ALL_REQUIRED_MOVEMENTS = 'VERIFY_ALL_REQUIRED_MOVEMENTS';

import qs from "qs";
import { GET, POST, PUT, DELETE } from "api/config";

const EventParticipationAPI = {
  addEventParticipation({
    participantId,
    competitionId,
    competitionEventId,
    groupName,
    hasMusic,
    groupMembers,
  }) {
    return POST("eventParticipation", {
      participantId,
      competitionId,
      competitionEventId,
      groupName,
      hasMusic,
      groupMembers,
    });
  },
  updateEventParticipation({
    participantId,
    competitionId,
    competitionEventId,
    groupName,
    hasMusic,
    groupMembers,
  }) {
    return PUT("eventParticipation", {
      participantId,
      competitionId,
      competitionEventId,
      groupName,
      hasMusic,
      groupMembers,
    });
  },
  updateEventParticipationFeedback({ id, comment, score, adjustment, time }) {
    return PUT(`eventParticipation/${id}/feedback`, {
      comment,
      score,
      adjustment,
      time,
    });
  },
  async updateEventParticipationLotOrder(id) {
    const { lotOrder } = await PUT(`eventParticipation/${id}/lotOrder`);

    return lotOrder;
  },
  async getAllForUser() {
    const { eventParticipation } = await GET(`eventParticipation`);

    return eventParticipation;
  },
  async getAllForParticipant(id) {
    const { eventParticipation } = await GET(
      `eventParticipation/participant/${id}`
    );

    return eventParticipation;
  },
  async getAllForJudge(judgeId) {
    const { eventParticipation } = await GET(
      `eventParticipation/judging/${judgeId}`
    );

    return eventParticipation;
  },
  async getAllForCompetitionEvent(competitionEventId) {
    const { eventParticipation } = await GET(
      `eventParticipation/competitionEvent/${competitionEventId}`
    );

    return eventParticipation;
  },
  async getAllForCompetitionEventWithFeedback(competitionEventId) {
    const { eventParticipation } = await GET(
      `eventParticipation/competitionEvent/${competitionEventId}/feedback`
    );

    return eventParticipation;
  },
  async getTotalCountForCompetitionEvent(competitionEventId) {
    const { total } = await GET(
      `eventParticipation/competitionEvent/${competitionEventId}/total`
    );

    return total;
  },
  async getAllForMyResults(competitionId) {
    const { eventParticipation } = await GET(
      `eventParticipation/my-results/${competitionId}`
    );

    return eventParticipation;
  },
  async getAllForResults(competitionId) {
    const { eventParticipation } = await GET(
      `eventParticipation/results/${competitionId}`
    );

    return eventParticipation;
  },
  async getOneForVideo(id) {
    const { eventParticipation } = await GET(`eventParticipation/video/${id}`);

    return eventParticipation;
  },
  removeEventParticipation({
    participantId,
    competitionId,
    competitionEventId,
  }) {
    const queryParams = qs.stringify({
      participantId,
      competitionId,
      competitionEventId,
    });
    const url = `eventParticipation?${queryParams}`;

    return DELETE(url);
  },
  updateEventParticipationRemainingLotOrders(competitionEventId) {
    return PUT(`eventParticipation/${competitionEventId}/lotOrder/remaining`);
  },
  updateEventParticipationAllRemainingLotOrders(competitionId) {
    return PUT(`eventParticipation/${competitionId}/lotOrder/remaining/all`);
  },
  updateEventParticipationClaimedAward({ id, claimedAward }) {
    return PUT(`eventParticipation/${id}/claimedAward`, {
      claimedAward,
    });
  },
  updateEventParticipationScores({ id, feedbackInput }) {
    return PUT(`eventParticipation/${id}/scores`, {
      feedbackInput,
    });
  },
};

export { EventParticipationAPI };

import React from "react";
import { useSelector } from "react-redux";
import Logout from "components/Logout";
import { AuthSelectors } from "redux/auth";
import "./Header.scss";

function Header() {
  const user = useSelector(AuthSelectors.getUser);

  return (
    <nav className="Header navbar navbar-dark navbar-expand-lg no-print">
      <div className="container-fluid">
        <h1 className="navbar-brand">Judging</h1>
        {user && (
          <div className="d-flex justify-content-between align-items-center">
            <Logout className="nav-link" to="/judging" />
            <span className="welcome">Welcome, {user.email}</span>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Header;

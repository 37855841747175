import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ModalTable from "components/admin/ModalTable";
import CardCollapse from "components/CardCollapse";
import EventParticipantList from "components/EventParticipantList";
import useAddCompetitionEventsToRing from "hooks/competitionEvents/useAddCompetitionEventsToRing";
import useCompetitionEventsForRing from "hooks/competitionEvents/useCompetitionEventsForRing";
import useCompetitionEventsWithoutRing from "hooks/competitionEvents/useCompetitionEventsWithoutRing";
import useRemoveCompetitionEventsFromRing from "hooks/competitionEvents/useRemoveCompetitionEventsFromRing";
import useUpdateCompetitionEventRingOrders from "hooks/competitionEvents/useUpdateCompetitionEventRingOrders";
import useRing from "hooks/rings/useRing";

function Events() {
  const { competitionId, ringId } = useParams();
  const { data: ring } = useRing(ringId);
  const { data: events } = useCompetitionEventsForRing(ringId);
  const { data: unlistedEvents } = useCompetitionEventsWithoutRing(
    competitionId
  );
  const [
    removeCompetitionEventsFromRing,
  ] = useRemoveCompetitionEventsFromRing();
  const [
    updateCompetitionEventRingOrders,
  ] = useUpdateCompetitionEventRingOrders();
  const [addCompetitionEventToRing] = useAddCompetitionEventsToRing();
  const [ageGroup, setAgeGroup] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [code, setCode] = useState("");
  const [cost, setCost] = useState("");
  const [eventName, setEventName] = useState("");
  const [gender, setGender] = useState("");
  const [maximumDuration, setMaximumDuration] = useState("");
  const [ringName, setRingName] = useState("");
  const tableData = {
    filters: ["categoryName", "ageGroup", "gender", "name", "code"],
    headingData: {
      ringOrder: "Order",
      name: "Name",
      categoryName: "Category",
      ageGroup: "Age Group",
      gender: "Gender",
      code: "Code",
      participants: "Athletes",
      undrawnLots: "Undrawn Lots",
    },
    items: events || [],
    itemName: "Competition Event",
    searchableColumns: [
      "name",
      "code",
      "ageGroup",
      "gender",
      "cost",
      "categoryName",
    ],
    defaultSort: "ringOrder",
    defaultSortAscending: true,
    modalData: {
      fields: [
        {
          defaultValue: code || "",
          displayName: "Code",
          name: "code",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: eventName || "",
          displayName: "Event Name",
          name: "eventName",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: categoryName || "",
          displayName: "Category",
          name: "category",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: ageGroup || "",
          displayName: "Age Group",
          name: "ageGroup",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: gender || "",
          displayName: "Gender",
          name: "gender",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: cost || "",
          displayName: "Cost",
          name: "cost",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: maximumDuration || "",
          displayName: "Max Duration",
          name: "maxDuration",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: ringName || "",
          displayName: "Ring",
          name: "ring",
          type: "text",
          disabled: true,
        },
      ],
    },
  };
  const unlistedTableData = {
    filters: ["categoryName", "ageGroup", "gender", "name", "code"],
    headingData: {
      name: "Name",
      categoryName: "Category",
      ageGroup: "Age Group",
      gender: "Gender",
      code: "Code",
      participants: "Athletes",
      undrawnLots: "Undrawn Lots",
    },
    items: unlistedEvents || [],
    itemName: "Competition Event",
    searchableColumns: [
      "name",
      "code",
      "ageGroup",
      "gender",
      "cost",
      "categoryName",
    ],
    defaultSort: "participants",
    modalData: {
      fields: [
        {
          defaultValue: code || "",
          displayName: "Code",
          name: "code",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: eventName || "",
          displayName: "Event Name",
          name: "eventName",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: categoryName || "",
          displayName: "Category",
          name: "category",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: ageGroup || "",
          displayName: "Age Group",
          name: "ageGroup",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: gender || "",
          displayName: "Gender",
          name: "gender",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: cost || "",
          displayName: "Cost",
          name: "cost",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: maximumDuration || "",
          displayName: "Max Duration",
          name: "maxDuration",
          type: "text",
          disabled: true,
        },
        {
          defaultValue: ringName || "",
          displayName: "Ring",
          name: "ring",
          type: "text",
          disabled: true,
        },
      ],
    },
  };
  const exportToCSVData = {
    options: {
      filename: `${ring?.name}-event-schedule`,
    },
    data: events?.map(
      ({
        ringOrder,
        name,
        categoryName,
        ageGroup,
        gender,
        code,
        cost,
        isExemptFromRegistrationFee,
        participants,
      }) => ({
        Order: ringOrder,
        Name: name,
        Category: categoryName,
        "Age Group": ageGroup,
        Gender: gender,
        Code: code,
        Cost: cost,
        "Exempt From Reg Fee": isExemptFromRegistrationFee,
        Athletes: participants,
      })
    ),
  };

  function setIsEditingItem({
    ageGroup,
    categoryName,
    code,
    cost,
    eventName,
    gender,
    maximumDuration,
    ringName,
  }) {
    setAgeGroup(ageGroup);
    setCategoryName(categoryName);
    setCode(code);
    setCost(cost);
    setEventName(eventName);
    setGender(gender);
    setMaximumDuration(maximumDuration);
    setRingName(ringName);
  }

  return (
    <div className="Events">
      <CardCollapse
        id="ringEvents"
        title={`${ring?.name} Events (${events?.length})`}
      >
        {events?.length ? (
          <ModalTable
            data={tableData}
            exportToCSVData={exportToCSVData}
            isSelectable
            onDeleteSelected={(selected) => {
              if (selected.length) {
                removeCompetitionEventsFromRing(selected);
              }
            }}
            onReorder={(items) => {
              const competitionEvents = items.map(({ id }, index) => ({
                id,
                order: index + 1,
              }));

              updateCompetitionEventRingOrders(competitionEvents);
            }}
            setIsEditingItem={setIsEditingItem}
            ExtraModalContent={EventParticipantList}
          />
        ) : (
          "No events for ring."
        )}
      </CardCollapse>
      <CardCollapse
        id="unlistedEvents"
        title={`Master Sheet Events (${unlistedEvents?.length})`}
      >
        {unlistedEvents?.length ? (
          <ModalTable
            data={unlistedTableData}
            id="ring-events"
            isSelectable
            onAddSelected={(events) => {
              if (events.length) {
                addCompetitionEventToRing({ events, ringId });
              }
            }}
            setIsEditingItem={setIsEditingItem}
            ExtraModalContent={EventParticipantList}
          />
        ) : (
          "No more events."
        )}
      </CardCollapse>
    </div>
  );
}

export default Events;

import { useMutation, useQueryCache } from 'react-query';
import { EventAPI } from 'api/admin/event';
import { EVENTS } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useRemoveEvent() {
  const queryCache = useQueryCache();

  return useMutation(EventAPI.removeEvent, {
    onSuccess: ({ message }) => {
      dispatchToast({ message, type: 'success' });
      queryCache.invalidateQueries(EVENTS);
    },
  });
}

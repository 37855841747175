import React, { useState } from 'react';
import './CertificateDownloadLink.scss';

function CertificateDownloadLink({ onClick, text }) {
  const [showSpinner, setShowSpinner] = useState();

  return (
    <span
      className="CertificateDownloadLink mr-3"
      onClick={async () => {
        setShowSpinner(true);
        await onClick();
        setShowSpinner(false);
      }}
    >
      {showSpinner ? (
        <div className="spinner-border mt-2" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        text
      )}
    </span>
  );
}

export default CertificateDownloadLink;

import { useQuery } from "react-query";
import { ChampionshipAPI } from "api/admin/championship";
import { CHAMPIONSHIPS } from "helpers/constants";

export default function useChampionshipParticipants(id) {
  return useQuery(
    [CHAMPIONSHIPS, id, "participants"],
    () => ChampionshipAPI.getAllParticipants(id),
    { enabled: id }
  );
}

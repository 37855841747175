import React from "react";
import CardCollapse from "components/CardCollapse";
import TicketsView from "components/TicketsView";

export default ({ userId }) => {
  return (
    <CardCollapse id="tickets" title="Tickets" show>
      <TicketsView userId={userId} />
    </CardCollapse>
  );
};

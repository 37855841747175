import React from 'react';
import Accordion from 'components/Accordion';
import useLocalize from 'hooks/useLocalize';
import useParticipants from 'hooks/participants/useParticipants';
import useEventParticipation from 'hooks/eventParticipation/useEventParticipation';
import useTournament from 'hooks/tournament/useCurrentTournament';
import SubmissionPane from './SubmissionPane';
import './VideoSubmissions.scss';

function VideoSubmissions() {
  let participantList = [];
  const { data: participants } = useParticipants();
  const {
    data: eventParticipationMap,
    isLoading: eventParticipationLoading,
  } = useEventParticipation();
  const { data: tournament } = useTournament();
  const competitions = tournament?.competitions || [];
  const localize = useLocalize();

  if (participants && participants.length) {
    participants.forEach((participant, index) => {
      const { id, firstName, lastName } = participant;
      const registeredForEventWithSubmissions = competitions.find(c => {
        return c.isVirtual && eventParticipationMap?.[id]?.[c.id];
      });
      const show =
        !!registeredForEventWithSubmissions &&
        !eventParticipationLoading &&
        !!eventParticipationMap[id];

      if (show) {
        participantList.push(
          <Accordion
            id={`video-submissions-${id}`}
            key={index}
            title={`${firstName} ${lastName}`}
          >
            <SubmissionPane competitions={competitions} participantId={id} />
          </Accordion>
        );
      }
    });
  }

  return (
    <div className="VideoSubmissions container">
      <div className="row">
        <div className="col">
          <h2>{localize('Video Submissions')}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">{participantList}</div>
      </div>
    </div>
  );
}

export default VideoSubmissions;

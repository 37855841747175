import React from 'react';
import { useField } from 'formik';

function TextInput({ label, size, ...props }) {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  const bootstrapSize = size || 'col-sm-12 col-lg';
  const isInvalid = meta.touched && meta.error ? 'is-invalid' : '';

  return (
    <div className={`${bootstrapSize} form-group`}>
      {label && (
        <label className="form-label" htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <input
        className={`form-control ${isInvalid}`}
        type="text"
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </div>
  );
}

export default TextInput;

import React from "react";
import { useSelector } from "react-redux";
import {
  createRankCertificate,
  createAwardCertificate,
} from "helpers/createCertificate";
import useLocalize from "hooks/useLocalize";
import { I18nSelectors } from "redux/i18n";
import CertificateDownloadLink from "../CertificateDownloadLink";

export default function EventResultItem({ eventParticipation, isVirtual }) {
  const language = useSelector(I18nSelectors.getLanguage);
  const localize = useLocalize();
  const {
    ageGroupName,
    award,
    eventName,
    eventChineseName,
    eventParticipationId,
    code,
    groupName,
    groupMembers,
    participantId,
    participantName,
    score,
    rank,
    videoUrl,
  } = eventParticipation;
  const color = award?.toLowerCase() || null;
  const videoLink = videoUrl ? (
    <a
      href={`/video/${eventParticipationId}`}
      className="video-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      Watch Video
    </a>
  ) : null;
  let rankCertificateLink;
  let awardCertificateLink;

  if (rank < 4) {
    rankCertificateLink = (
      <CertificateDownloadLink
        onClick={() =>
          createRankCertificate({
            achievement: rank,
            ageGroup: ageGroupName,
            eventName,
            participantName: groupMembers.length
              ? groupMembers
              : participantName,
            isVirtual,
          })
        }
        text={localize("Download Rank Certificate")}
      />
    );
  }

  if (award && isVirtual) {
    awardCertificateLink = (
      <CertificateDownloadLink
        onClick={() =>
          createAwardCertificate({
            achievement: award,
            ageGroup: ageGroupName,
            eventName,
            participantName: groupMembers.length
              ? groupMembers
              : participantName,
            isVirtual,
          })
        }
        text={localize("Download Award Certificate")}
      />
    );
  }

  return (
    <div
      className="EventResultItem event-item"
      key={`${participantId}-${code}`}
    >
      <div className="d-flex align-items-center justify-content-between">
        <h6 className="event-name">
          {language === "en" ? eventName : eventChineseName} ({code})
        </h6>
      </div>
      <div className="group-content">
        {groupName && (
          <h6>
            {localize("Group Name")}: {groupName}
          </h6>
        )}
        {groupName && (
          <h6>
            {localize("Group Members")}: {groupMembers}
          </h6>
        )}
      </div>
      <li className="list-group-item d-flex justify-content-between">
        <span className="d-flex">
          <span className="mr-4">
            {localize("Rank")}: <span className="event-item-label">{rank}</span>
          </span>
          <span>
            {localize("Score")}:{" "}
            <span className="event-item-label">{score}</span>
          </span>
        </span>
        {award && (
          <span className="d-flex align-items-center">
            {localize("Award")}:{" "}
            <span className={`ml-1 event-item-label badge badge-pill ${color}`}>
              {localize(award)}
            </span>
          </span>
        )}
      </li>
      {rankCertificateLink}
      {awardCertificateLink}
      {videoLink}
    </div>
  );
}

import { useMutation, useQueryCache } from "react-query";
import { EventParticipationAPI } from "api/eventParticipation";
import { EVENT_PARTICIPATION } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useUpdateEventParticipationScores({
  competitionEventId,
}) {
  const queryCache = useQueryCache();

  return useMutation(EventParticipationAPI.updateEventParticipationScores, {
    onSuccess: ({ message }) => {
      queryCache.invalidateQueries([
        EVENT_PARTICIPATION,
        competitionEventId,
        "feedback",
      ]);

      dispatchToast({ message, type: "success" });
    },
  });
}

import { useQuery } from "react-query";
import { TeamAPI } from "api/admin/team";
import { TEAM_MEDALS } from "helpers/constants";

export default function useTeamMedalsForCompetition(competitionId) {
  return useQuery(
    [TEAM_MEDALS, "competition", competitionId],
    () => TeamAPI.getAllMedalsForCompetition(competitionId),
    { enabled: competitionId }
  );
}

import React, { useCallback, useEffect, useRef } from 'react';
import clone from 'clone';
import { useDispatch, useSelector } from 'react-redux';
import { NanduFormSelectors, NanduFormActions } from 'redux/nanduForm';
import { NANDU, CONNECTION } from 'helpers/constants';
import NanduFormSelect from '../../NanduFormSelect';
import './NanduModal.css';

function NanduModal() {
  const dispatch = useDispatch();
  const node = useRef();
  const sections = useSelector(NanduFormSelectors.getSections);
  const connections = useSelector(NanduFormSelectors.getConnections);
  const movements = useSelector(NanduFormSelectors.getMovements);
  const nandu = useSelector(NanduFormSelectors.getNandu);
  const formType = useSelector(NanduFormSelectors.getFormType);
  let wushuStyle = useSelector(NanduFormSelectors.getWushuStyle);
  wushuStyle = wushuStyle.id;
  const selectedNandu = useSelector(NanduFormSelectors.getSelectedNandu);
  const maxInputWarning = 'Due to paper width constraints, each section will only show the first 14 nandu. If you really have more than 14 nandu in a section, please contact me because that\'s crazy and I\'d like to see your form.';
  let filteredJumps;
  let filteredConnections;
  let filteredMovements;

  if (formType.name === NANDU) {
    filteredJumps = getFilteredJumps();
    filteredConnections = getFilteredConnections();
  } else {
    filteredMovements = getFilteredMovements();
  }

  function getFilteredMovements() {
    const filtered = movements.filter((movement) => {
      movement.displayName = `${movement.code} - ${movement.name}`;
      const hasStyle = movement.styles.indexOf(wushuStyle) > -1;
      let isNotDuplicate = true;
      for (let i = 0; i < sections.length && isNotDuplicate; i++) {
        for (let j = 0; j < sections[i].movement.length && isNotDuplicate; j++) {
          if (sections[i].movement[j].code === movement.code) {
            isNotDuplicate = false;
          }
        }
      }
      return hasStyle && isNotDuplicate;
    });
    return filtered;
  }

  function getFilteredJumps() {
    const filtered = nandu.filter((movement) => {
      movement.displayName = `${movement.code} - ${movement.description}`;
      const hasStyle = movement.styles.indexOf(wushuStyle) > -1;
      const isNotConnection = movement.nanduType !== CONNECTION;
      return hasStyle && isNotConnection;
    });
    return filtered;
  }

  function getFilteredConnections() {
    const prevMoveCode = getPreviousMoveCode();
    const filteredArray = [];
    for (let i = 0; i < nandu.length; i++) {
      const movement = clone(nandu[i]);
      movement.displayName = `${movement.code} - ${movement.description}`;
      const hasStyle = movement.styles.indexOf(wushuStyle) > -1;
      const prevMoveConnection = connections[`${prevMoveCode} ${movement.code}`];
      if (prevMoveConnection && prevMoveConnection.styles && prevMoveConnection.styles.indexOf(wushuStyle) > -1 && hasStyle) {
        movement.isSelectedAsConnection = true;
        filteredArray.push(movement);
      }
    }
    return filteredArray;
  }

  function selectNandu(movement) {
    if (formType.name === NANDU) {
      dispatch(NanduFormActions.changeNandu(movement));
    } else {
      dispatch(NanduFormActions.changeMovement(movement));
    }
  }

  function hasMaxInputs() {
    if (formType.name === NANDU) {
      return sections[selectedNandu.sectionId].nandu.length > 14;
    }
    return false;
  }

  function getDefaultNanduOption() {
    const current = sections[selectedNandu.sectionId].nandu[selectedNandu.nanduIndex];
    return (current.nanduType && current.nanduType !== CONNECTION) ? current.displayName : 'Select a difficulty';
  }

  function getDefaultConnectionOption() {
    const current = sections[selectedNandu.sectionId].nandu[selectedNandu.nanduIndex];
    return (current.nanduType && current.nanduType === CONNECTION) ? current.displayName : 'Select a connection';
  }

  function getDefaultMovementOption() {
    return sections[selectedNandu.sectionId].movement[selectedNandu.nanduIndex].displayName || 'Select a required movement';
  }

  const closeNanduModal = useCallback(() => {
    dispatch(NanduFormActions.closeNanduModal());
  }, [dispatch]);

  useEffect(() => {
    function closeModal(e) {
      if (node.current && node.current.contains(e.target)) {
        return;
      }
      closeNanduModal();
    }

    document.addEventListener('mousedown', closeModal);
    return () => {
      document.removeEventListener("mousedown", closeModal);
    };
  }, [closeNanduModal]);

  function getPreviousMoveCode() {
    let prevMove = null;
    if (selectedNandu.nanduIndex !== 0) {
      prevMove = sections[selectedNandu.sectionId].nandu[selectedNandu.nanduIndex - 1];
      if (prevMove.code === '+') {
        prevMove = sections[selectedNandu.sectionId].nandu[selectedNandu.nanduIndex - 2];
      }
    }
    return prevMove ? prevMove.code : '';
  }

  let nanduModalSelects = [];
  if (formType.name === NANDU) {
    if (filteredJumps.length > 0) {
      nanduModalSelects.push(
        <div key={'nanduModalJumps'} className="nanduModalSelectItem nanduModalJumps">
          <NanduFormSelect formLabel={"Difficulties"} displayProperty="displayName" defaultValue={getDefaultNanduOption()} callback={selectNandu} options={filteredJumps} />
        </div>
      );
    }
    if (filteredConnections.length > 0) {
      nanduModalSelects.push(
        <div key={'nanduModalConnections'} className="nanduModalSelectItem nanduModalConnections">
          <NanduFormSelect formLabel={"Connections"} displayProperty="displayName" defaultValue={getDefaultConnectionOption()} callback={selectNandu} options={filteredConnections} />
        </div>
      );
    }
  } else {
    nanduModalSelects.push(
      <div className="nanduModalSelectItem nanduModalMovements">
        <NanduFormSelect formLabel={"Movements"} displayProperty="displayName" defaultValue={getDefaultMovementOption()} callback={selectNandu} options={filteredMovements} />
      </div>
    );
  }

  return (
    <div className="nanduModal">
      <div className="nanduModalContent" ref={node}>
        <div className="nanduModalClose ion-close-circled" onClick={closeNanduModal}></div>
        {
          hasMaxInputs() &&
          <div className="nanduModalWarning">{maxInputWarning}</div>
        }
        <div className="nanduModalSelect">
          {nanduModalSelects}
        </div>
      </div>
    </div>
  );
}

export default NanduModal;

import React from "react";

export default function TextArea({ onChange, text }) {
  return (
    <textarea
      className="form-control no-print"
      onChange={({ target: { value } }) => onChange(value)}
      type="text"
      value={text}
    />
  );
}

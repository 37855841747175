import { useMutation, useQueryCache } from "react-query";
import { ParticipantsAPI } from "api/participant";
import { PARTICIPANT, TEAM_PARTICIPANTS } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useRemoveParticipantsFromTeam() {
  const queryCache = useQueryCache();

  return useMutation(ParticipantsAPI.removeParticipantsFromTeam, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(PARTICIPANT);
      queryCache.invalidateQueries(TEAM_PARTICIPANTS);
      dispatchToast({ message, type: "success" });
      onSuccess();
    },
  });
}

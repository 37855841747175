import { useMutation, useQueryCache } from 'react-query';
import { WushuStyleAPI } from 'api/admin/wushuStyle';
import { WUSHU_STYLES } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useCreateWushuStyle() {
  const queryCache = useQueryCache();

  return useMutation(WushuStyleAPI.add, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      dispatchToast({ message, type: 'success' });
      queryCache.invalidateQueries(WUSHU_STYLES);
      onSuccess();
    },
  });
}

import { useMutation, useQueryCache } from 'react-query';
import { TeamAPI } from 'api/admin/team';
import { PARTICIPANT, TEAMS } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useRecoverTeam() {
  const queryCache = useQueryCache();

  return useMutation(TeamAPI.recover, {
    onSuccess: ({ message }) => {
      dispatchToast({ message, type: 'success' });
      queryCache.invalidateQueries(TEAMS);
      queryCache.invalidateQueries(PARTICIPANT);
    },
  });
}

import { GET } from '../config';

const BASE_ENDPOINT = 'genders';
const GenderAPI = {
  async getAll() {
    const { genders } = await GET(BASE_ENDPOINT);

    return genders;
  },
  // async getById(id) {
  //   const { gender } = await GET(`genders/${id}`);

  //   return gender;
  // },
  // async getAllParticipants(id) {
  //   const { participants } = await GET(`genders/${id}/participants`);

  //   return participants;
  // },
  // async add(genderInput) {
  //   return await POST(`genders`, { genderInput });
  // },
  // async update(genderInput) {
  //   const { id } = genderInput;

  //   return await PUT(`genders/${id}`, { genderInput });
  // },
  // async remove({ id }) {
  //   return await DELETE(`genders/${id}`);
  // },
  // async recover(id) {
  //   return await POST(`genders/${id}/recover`);
  // },
};

export { GenderAPI };

import React from "react";
import ReactDOM from "react-dom";

const PayPalButton = window?.paypal?.Buttons?.driver(
  "react",
  { React, ReactDOM },
  { label: "Checkout" }
);

function PaypalButton({ onSuccess, total = 0 }) {
  function createOrder(data, actions) {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: total.toString(),
          },
        },
      ],
    });
  }

  function onApprove(data, actions) {
    return actions.order.capture().then((response) => {
      // purchase_units[0].payments.captures[0].id maybe?
      const { purchase_units, payer } = response;
      const amount = purchase_units.reduce((aggregator, currentVal) => {
        return (aggregator += +currentVal?.amount?.value);
      }, 0);

      onSuccess(payer?.email_address, amount);
    });
  }

  return <PayPalButton createOrder={createOrder} onApprove={onApprove} />;
}

export default PaypalButton;

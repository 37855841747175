import React from 'react';
import { Link } from 'react-router-dom';

function SideNavItem({ icon, isRouteMatch, title, url }) {
  return (
    <div className="SideNavItem">
      <Link to={url}>
        <li
          className={
            'sideNav-item ' + (isRouteMatch ? 'sideNav-item-selected' : '')
          }
        >
          <span className={`sideNav-icon ${icon}`}></span>
          {title}
        </li>
      </Link>
    </div>
  );
}

export default SideNavItem;

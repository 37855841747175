import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { AuthSelectors } from 'redux/auth';
import TextInput from 'components/TextInput';
import useLocalize from 'hooks/useLocalize';
import useForgotPassword from 'hooks/users/useForgotPassword';
import './ForgotPassword.scss';

function ForgotPassword() {
  const isAuthenticated = useSelector(AuthSelectors.getAuthenticationStatus);
  const [forgotPassword] = useForgotPassword();
  const history = useHistory();
  const localize = useLocalize();

  if (isAuthenticated) {
    return <Redirect to="/" noThrow />;
  }

  return (
    <div className="ForgotPassword container">
      <h1 className="ForgotPassword-title">{localize('Forgot Password')}</h1>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(localize('Must be a valid email'))
            .required(localize('Required')),
        })}
        onSubmit={async ({ email }) => {
          forgotPassword(email);
          history.push('/');
        }}
      >
        <Form className="needs-validation" noValidate>
          <TextInput placeholder={localize('Email')} name="email" />
          <div className="col">
            <button type="submit" className="btn btn-primary">
              {localize('Reset Password')}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default ForgotPassword;

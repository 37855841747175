import { GET, POST } from 'api/config';

const TransactionAPI = {
  async getAll(userId, tournamentId) {
    const { transactions } = await GET(
      `transactions/user/${userId}/tournament/${tournamentId}`
    );

    return transactions;
  },
  add(transactionInput) {
    return POST(`transactions`, { transactionInput });
  },
};

export { TransactionAPI };

import { GET, POST, DELETE } from "../config";

const BASE_ENDPOINT = "championshipEventCategoryRequirements";
const ChampionshipEventCategoryRequirementAPI = {
  async getAllForChampionship(championshipId) {
    const { requirements } = await GET(
      `${BASE_ENDPOINT}/championship/${championshipId}`
    );

    return requirements;
  },
  add(input) {
    return POST(BASE_ENDPOINT, { input });
  },
  remove(id) {
    return DELETE(`${BASE_ENDPOINT}/${id}`);
  },
};

export { ChampionshipEventCategoryRequirementAPI };

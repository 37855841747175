import { useMutation, useQueryCache } from 'react-query';
import { VideoSubmissionAPI } from 'api/videoSubmission';
import { VIDEO_SUBMISSIONS } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useUploadVideoSubmission() {
  const queryCache = useQueryCache();

  return useMutation(VideoSubmissionAPI.uploadVideoSubmission, {
    onSuccess: ({ message }) => {
      dispatchToast({ message, type: 'success' });
      queryCache.invalidateQueries(VIDEO_SUBMISSIONS);
    },
    onSettled: (data, error, { onSettled = () => {} }) => {
      onSettled();
    },
  });
}

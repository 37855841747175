import { useMutation } from 'react-query';
import { UserAPI } from 'api/user';
import dispatchToast from 'helpers/dispatchToast';

export default function useUpdateRole() {
  return useMutation(
    UserAPI.updateRole,
    {
      onError: (err, { onError=()=>{} }) => {
        onError();
      },
      onSuccess: ({ message, onSuccess=()=>{} }) => {
        dispatchToast({ message, type: 'success' });
        onSuccess();
      },
    }
  );
}

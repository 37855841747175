import fontkit from "@pdf-lib/fontkit";
import download from "downloadjs";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import dispatchToast from "./dispatchToast";
import splitSentence from "./splitSentence";
import stripBadCharacters from "./stripBadCharacters";

// pdf dimensions: 3300x2550
// https://docupub.com/pdfresize/
const nameLineStart = 1190;
const nameLineEnd = 2125;
const nameLineSize = nameLineEnd - nameLineStart;
const nameLineMid = nameLineStart + nameLineSize / 2;
const nameTextSize = 80;
const nameLineY = 80;
const eventLineStart = 670;
const eventLineEnd = 1345;
const eventLineSize = eventLineEnd - eventLineStart;
const eventLineMid = eventLineStart + eventLineSize / 2;
const eventTextSize = 45;
const eventLineY = 640;
let helveticaFont;
let chFont;

function drawTextWithFallback({ page, text, x, y, size }) {
  let settings = {
    x,
    y,
    size,
    font: helveticaFont,
    color: rgb(0.23, 0.27, 0.58),
  };

  try {
    page.drawText(text, settings);
  } catch (e) {
    settings.font = chFont;
    page.drawText(text, settings);
  }
}

async function createCertificate({
  achievement,
  ageGroup,
  eventName,
  participantName,
  fileName,
  isVirtual,
}) {
  if (!ageGroup) {
    ageGroup = "N/A";
  }

  try {
    const pdfUrl = isVirtual ? "-virtual" : "";
    const existingPdfBytes = await fetch(
      `${process.env.PUBLIC_URL}/certificate${pdfUrl}.pdf`
    ).then((res) => res.arrayBuffer());
    const chineseFont = await fetch(
      `${process.env.PUBLIC_URL}/SourceHanSerifHC-VF.ttf`
      // `${process.env.PUBLIC_URL}/FZHei-B01S.ttf`
    ).then((res) => res.arrayBuffer());

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    pdfDoc.registerFontkit(fontkit);

    // Embed the Helvetica font
    helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    chFont = await pdfDoc.embedFont(chineseFont);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const page = pages[0];

    // Get the width and height of the first page
    const { width, height } = page.getSize();

    // Name line
    const nameTextWidth = chFont.widthOfTextAtSize(
      participantName,
      nameTextSize
    );
    if (nameTextWidth > nameLineSize) {
      const [first, second] = splitSentence(participantName);
      const textHeight = chFont.heightAtSize(eventTextSize);
      const firstWidth = chFont.widthOfTextAtSize(first, eventTextSize);
      const secondWidth = chFont.widthOfTextAtSize(second, eventTextSize);

      drawTextWithFallback({
        page,
        size: eventTextSize,
        text: first,
        x: nameLineMid - firstWidth / 2,
        y: height / 2 - nameLineY + textHeight,
      });

      drawTextWithFallback({
        page,
        size: eventTextSize,
        text: second,
        x: nameLineMid - secondWidth / 2,
        y: height / 2 - nameLineY,
      });
    } else {
      drawTextWithFallback({
        page,
        size: nameTextSize,
        text: participantName,
        x: nameLineMid - nameTextWidth / 2,
        y: height / 2 - nameLineY,
      });
    }

    // Event Line
    const eventTextWidth = chFont.widthOfTextAtSize(eventName, eventTextSize);

    if (eventTextWidth > eventLineSize) {
      const [first, second] = splitSentence(eventName);
      const textHeight = chFont.heightAtSize(eventTextSize);
      const firstWidth = chFont.widthOfTextAtSize(first, eventTextSize);
      const secondWidth = chFont.widthOfTextAtSize(second, eventTextSize);

      drawTextWithFallback({
        page,
        size: eventTextSize,
        text: first,
        x: eventLineMid - firstWidth / 2,
        y: height / 2 - eventLineY + textHeight + 2,
      });

      drawTextWithFallback({
        page,
        size: eventTextSize,
        text: second,
        x: eventLineMid - secondWidth / 2,
        y: height / 2 - eventLineY,
      });
    } else {
      drawTextWithFallback({
        page,
        size: eventTextSize,
        text: eventName,
        x: eventLineMid - eventTextWidth / 2,
        y: height / 2 - eventLineY,
      });
    }

    // Age Group Line
    drawTextWithFallback({
      page,
      size: 60,
      text: ageGroup,
      x: width / 2 + 350,
      y: height / 2 - eventLineY,
    });

    // Achievement Line
    drawTextWithFallback({
      page,
      size: 60,
      text: achievement.toString(),
      x: width / 2 + 950,
      y: height / 2 - eventLineY,
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdf = await pdfDoc.save();

    download(pdf, fileName);
  } catch (e) {
    dispatchToast({ message: e.message });
  }
}

export async function createAwardCertificate(data) {
  data.achievement += " Medal";
  data.fileName = `${stripBadCharacters(data.eventName)}-award.pdf`;

  await createCertificate(data);
}

export async function createRankCertificate(data) {
  const { achievement, eventName, extendedAwards } = data;

  if (achievement === 1) {
    data.achievement = "1st Place";
  } else if (achievement === 2) {
    data.achievement = "2nd Place";
  } else if (achievement === 3) {
    data.achievement = "3rd Place";
  } else if (extendedAwards) {
    if (achievement === 4) {
      data.achievement = "4th Place";
    } else if (achievement === 5) {
      data.achievement = "5th Place";
    } else if (achievement === 6) {
      data.achievement = "6th Place";
    }
  }

  data.fileName = `${stripBadCharacters(eventName)}-rank.pdf`;

  await createCertificate(data);
}

import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Checkbox from "components/FormikCheckbox";
import PasswordInput from "components/PasswordInput";
import TextInput from "components/TextInput";
import useRingJudges from "hooks/judges/useRingJudges";
import useCreateJudge from "hooks/judges/useCreateJudge";
import useRemoveJudge from "hooks/judges/useRemoveJudge";
import useRing from "hooks/rings/useRing";
import JudgeItem from "./JudgeItem";

function Judges() {
  const { competitionId, ringId } = useParams();
  const [isAdding, setIsAdding] = useState();
  const [createJudge] = useCreateJudge();
  const [removeJudge] = useRemoveJudge();
  const { data: ring } = useRing(ringId);
  const { data: judges } = useRingJudges(ringId);

  function cancelAdding(e) {
    e.preventDefault();
    setIsAdding(false);
  }

  return (
    <div className="Judges">
      <h5>{ring?.name} Judges</h5>
      <ul className="list-group">
        {judges?.map(({ id, email, isHeadJudge }, index) => (
          <JudgeItem
            deleteItem={() => removeJudge({ id })}
            email={email}
            id={id}
            isHeadJudge={isHeadJudge}
            key={index}
          />
        ))}
      </ul>
      <hr></hr>
      <div className="row">
        <div className="col d-flex justify-content-between">
          {!isAdding && (
            <button
              className="btn btn-primary"
              onClick={() => setIsAdding(true)}
            >
              Add New Judge
            </button>
          )}
          {isAdding && (
            <button className="btn btn-danger" onClick={cancelAdding}>
              Cancel
            </button>
          )}
        </div>
      </div>
      {isAdding && (
        <>
          <hr></hr>
          <div className="row">
            <div className="col">
              <Formik
                initialValues={{
                  emailOrUsername: "",
                  isHeadJudge: false,
                }}
                validationSchema={Yup.object({
                  emailOrUsername: Yup.string().required("Required"),
                  password: Yup.string(),
                  isHeadJudge: Yup.bool(),
                })}
                onSubmit={async ({
                  emailOrUsername,
                  isHeadJudge,
                  password,
                }) => {
                  const isUsername = emailOrUsername.indexOf("@") === -1;

                  createJudge({
                    competitionId,
                    emailOrUsername,
                    isHeadJudge,
                    isUsername,
                    onSuccess: () => setIsAdding(false),
                    password,
                    ringId,
                  });
                }}
              >
                <Form className="needs-validation" noValidate>
                  <div className="row">
                    <TextInput
                      placeholder="Email Or Username (existing or new)"
                      name="emailOrUsername"
                    />
                    <PasswordInput
                      placeholder="Password (only required for new accounts)"
                      name="password"
                    />
                  </div>
                  <div className="row">
                    <div className="col">
                      <Checkbox name="isHeadJudge">Head Judge</Checkbox>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-success float-right">
                    Submit
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Judges;

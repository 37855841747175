import { useMutation, useQueryCache } from "react-query";
import { ChampionshipAPI } from "api/championship";
import { CHAMPIONSHIP_PARTICIPATION } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useRegisterForChampionship() {
  const queryCache = useQueryCache();

  return useMutation(ChampionshipAPI.register, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      dispatchToast({ message, type: "success" });
      queryCache.invalidateQueries(CHAMPIONSHIP_PARTICIPATION);
      onSuccess();
    },
  });
}

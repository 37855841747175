import React from "react";
import Table from "../Table";
import "./NestedTableItem.scss";

function NestedTableItem({ itemClick, data }) {
  return (
    <tr className="NestedTableItem">
      <td colSpan="100%">
        <Table data={data} itemClick={itemClick} />
      </td>
    </tr>
  );
}

export default NestedTableItem;

import React from 'react';
import './NotFound.css';

function NotFound() {
  return (
    <div className="notFound">
      <div className="notFoundMessage">
        <h2>404 - Oops. We couldn't find that page.</h2>
      </div>
    </div>
  );
}

export default NotFound;

import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AddTeamModal from "components/AddTeamModal";
import Checkbox from "components/FormikCheckbox";
import Date from "components/Date";
import ImageInput from "components/ImageInput";
import Select from "components/Select";
import TextInput from "components/TextInput";
import USAWKFMembershipField from "components/USAWKFMembershipField";
import getCountries from "helpers/countries";
import useLocalize from "hooks/useLocalize";
import useTeams from "hooks/teams/useTeams";

function AddParticipant({ onSubmit }) {
  const localize = useLocalize();
  const [isAddingTeam, setIsAddingTeam] = useState();
  const { data: teams } = useTeams();
  const countries = getCountries();
  const countryOptions = countries.map((country, i) => (
    <option key={i} value={country}>
      {country}
    </option>
  ));
  const teamOptions = teams
    ? teams.map(({ name, id }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))
    : [];
  const defaultLabel = localize("Choose athlete image");
  const [label, setLabel] = useState(defaultLabel);

  return (
    <>
      {isAddingTeam && <AddTeamModal close={() => setIsAddingTeam(false)} />}
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          gender: "",
          birthDate: "",
          country: "",
          address: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
          mobilePhone: "",
          usawkfId: "",
          teamId: "",
          image: "",
          email: "",
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .max(45, localize("Must be 45 characters or less"))
            .required(localize("Required")),
          lastName: Yup.string()
            .max(45, localize("Must be 45 characters or less"))
            .required(localize("Required")),
          gender: Yup.string()
            .oneOf(["male", "female"], localize("Please select a gender."))
            .required(localize("Required")),
          birthDate: Yup.date().required(localize("Required")),
          country: Yup.string()
            .oneOf(countries, localize("Please select a country."))
            .required(localize("Required")),
          address: Yup.string().max(
            100,
            localize("Must be 100 characters or less")
          ),
          address2: Yup.string().max(
            100,
            localize("Must be 100 characters or less")
          ),
          city: Yup.string().max(45, localize("Must be 45 characters or less")),
          state: Yup.string().max(
            45,
            localize("Must be 45 characters or less")
          ),
          zip: Yup.string().max(10, localize("Must be 10 characters or less")),
          phone: Yup.string().max(
            45,
            localize("Must be 45 characters or less")
          ),
          mobilePhone: Yup.string().max(
            45,
            localize("Must be 45 characters or less")
          ),
          usawkfId: Yup.string().max(
            45,
            localize("Must be 45 characters or less")
          ),
          teamId: Yup.string(),
          image: Yup.mixed(),
          email: Yup.string()
            .email(localize("Must be a valid email"))
            .max(45, localize("Must be 45 characters or less")),
        })}
        onSubmit={async (values) => {
          onSubmit(values);
        }}
      >
        {({ setFieldValue }) => (
          <Form className="needs-validation" noValidate>
            <div className="row">
              <div className="col">
                <ImageInput
                  label={label}
                  footnote="For best results, please use .png or .jpg files."
                  processFile={(image) => {
                    setLabel(image?.name);
                    setFieldValue("image", image);
                  }}
                  name="image"
                  uid="newParticipant"
                />
              </div>
            </div>
            <div className="row">
              <TextInput
                label={`${localize("First Name")}*`}
                name="firstName"
              />
              <TextInput label={`${localize("Last Name")}*`} name="lastName" />
            </div>
            <div className="row">
              <Select label={`${localize("Gender")}*`} name="gender">
                <option value="">{localize("Select a gender")}</option>
                <option value="male">{localize("Male")}</option>
                <option value="female">{localize("Female")}</option>
              </Select>
              <Date label={`${localize("Birth Date")}*`} name="birthDate" />
              <Select label={`${localize("Country")}*`} name="country">
                <option value="">{localize("Select a country")}</option>
                {countryOptions}
              </Select>
            </div>
            <div className="row">
              <TextInput
                label={localize("Address")}
                name="address"
                size="col-sm-12 col-lg-9"
              />
              <TextInput label={localize("Address 2")} name="address2" />
            </div>
            <div className="row">
              <TextInput label={localize("City")} name="city" />
              <TextInput label={localize("State")} name="state" />
              <TextInput label={localize("Zip")} name="zip" />
            </div>
            <div className="row">
              <TextInput label={localize("Phone")} name="phone" />
              <TextInput label={localize("Mobile")} name="mobilePhone" />
              <TextInput label={localize("Email")} name="email" />
            </div>
            <USAWKFMembershipField />
            <div className="row">
              <Select
                label={`${localize("Team")} (${localize(
                  "If you are competing as an individual, registering under a team is optional."
                )})`}
                name="teamId"
              >
                <option value="">{localize("Select a team")}</option>
                {teamOptions}
              </Select>
            </div>
            <div className="row">
              <div className="col d-flex justify-content-between">
                <span>
                  {localize(`Don't see your team? Click to add a new team.`)}
                </span>
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsAddingTeam(true);
                  }}
                >
                  {localize("Add Team")}
                </button>
              </div>
            </div>
            <Checkbox name="firstTimeCompetitor">
              {localize("I am competing for the first time.")}
            </Checkbox>
            <hr />
            <button type="submit" className="btn btn-success float-right">
              {localize("Submit")}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AddParticipant;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Table from "components/admin/Table";
import RingSelect from "components/RingSelect";
import CollapseGroup from "components/CollapseGroup";
import BackToTop from "components/BackToTop";
import generateEventLabel from "helpers/generateEventLabel";
import useSchedule from "hooks/schedules/useSchedule";
import useRing from "hooks/rings/useRing";

export default () => {
  const history = useHistory();
  const [ringId, setRingId] = useState();
  const { data: schedule } = useSchedule(ringId);
  const { data: ring } = useRing(ringId);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const tempItems = schedule?.events.map(
      ({
        code,
        gender,
        ageGroup,
        ageGroupName,
        eventName,
        competitionEventId,
        participants,
      }) => {
        const data = {
          headingData: {
            participantId: "ID",
            name: "Name",
            teamName: "Group",
          },
          items: participants || [],
        };
        const ageGroupString =
          ageGroup && ageGroupName ? `${ageGroup} (${ageGroupName})` : "";
        const label = generateEventLabel(
          gender,
          ageGroupString,
          eventName,
          code
        );

        return {
          content: (
            <div className="ScheduleEvent">
              <div className="ScheduleEventInfo d-flex justify-content-between mb-2">
                {gender && <span>Gender: {gender}</span>}
                {ageGroup && (
                  <span>
                    Ages: {ageGroup} ({ageGroupName})
                  </span>
                )}
              </div>
              <Table
                data={data}
                disableSort
                itemClick={({ participantId }) => {
                  const participantPage = `/schedule/${participantId}/events`;

                  history.push(participantPage);
                }}
              />
            </div>
          ),
          label,
          id: competitionEventId,
        };
      }
    );

    setItems(tempItems);
  }, [schedule]);

  return (
    <div className="ScheduleHome">
      <div className="content">
        <RingSelect
          id={"ScheduleHome"}
          onChange={({ ringId }) => setRingId(ringId)}
          inPersonOnly
        />
        <b>{ring?.description}</b>
        <hr />
        <CollapseGroup id={`schedule-${ringId}`} items={items} />
        <BackToTop />
      </div>
    </div>
  );
};

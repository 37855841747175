import { GET, POST, DELETE, PUT } from "../config";

const CompetitionEventAPI = {
  async getAllForCompetitionAndParticipant({ competitionId, participantId }) {
    const { competition } = await GET(
      `competitionEvents/${competitionId}/participant/${participantId}`
    );

    return competition;
  },
  async getAllForRing(ringId) {
    const { competitionEvents } = await GET(`rings/${ringId}/events`);

    return competitionEvents;
  },
  async getAllWithoutRing(competitionId) {
    const { competitionEvents } = await GET(
      `competitionEvents/${competitionId}/noRing`
    );

    return competitionEvents;
  },
  async getById(id) {
    const { competitionEvent } = await GET(`competitionEvents/${id}`);

    return competitionEvent;
  },
  async addCompetitionEvent(competitionEventInput) {
    return await POST(`competitionEvents`, { competitionEventInput });
  },
  addBatchCompetitionEvents(competitionEventInput) {
    return POST(`competitionEvents/batch`, { competitionEventInput });
  },
  async addToRing({ events, ringId }) {
    return await PUT(`competitionEvents/ring/${ringId}`, { events });
  },
  async removeCompetitionEvent(id) {
    return await DELETE(`competitionEvents/${id}`);
  },
  async removeCompetitionEvents(events) {
    return await DELETE(`competitionEvents`, { events });
  },
  async removeCompetitionEventsFromRing(events) {
    return await DELETE(`competitionEvents/ring`, { events });
  },
  async updateCompetitionEventRingOrder({ id, ringId, fromIndex, toIndex }) {
    return await PUT(`competitionEvents/${id}/ringOrder`, {
      ringId,
      fromIndex,
      toIndex,
    });
  },
  async updateCompetitionEventRingSwitch({
    id,
    fromRing,
    toRing,
    fromIndex,
    toIndex,
  }) {
    return await PUT(`competitionEvents/${id}/ringSwitch`, {
      fromRing,
      toRing,
      fromIndex,
      toIndex,
    });
  },
  async updateCompetitionEventRingOrders(competitionEvents) {
    return await PUT(`competitionEvents/ringOrders`, { competitionEvents });
  },
};

export { CompetitionEventAPI };

import React from 'react';
import Message from './components/Message';
import useWebsiteMessages from 'hooks/websiteMessages/useWebsiteMessages';
import './MessageCenter.scss';

function MessageCenter() {
  const { data: websiteMessages } = useWebsiteMessages();

  return websiteMessages && websiteMessages.length ? (
    <div className="MessageCenter container">
      {websiteMessages.map(({ text }, i) => (
        <Message key={i} text={text} />
      ))}
    </div>
  ) : null;
}

export default MessageCenter;

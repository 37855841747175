import { GET } from "../config";

const CompetitorAPI = {
  async getAllWithoutUSAWKFIdButNeedIt(competitionId) {
    const { competitors } = await GET(
      `competitors/missing-usawkfid/${competitionId}`
    );

    return competitors;
  },
};

export { CompetitorAPI };

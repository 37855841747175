import React from 'react';
import classnames from 'classnames';

function TabPane({ children, id, show }) {
  const classNames = classnames('tab-pane', 'fade', { show }, { active: show });

  return (
    <div className={classNames} id={id}>
      {show && children}
    </div>
  );
}

export default TabPane;

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "components/Header";
import About from "scenes/About";
import AllResultsHome from "scenes/AllResultsHome";
import ForgotPassword from "scenes/ForgotPassword";
import Home from "scenes/Home";
import Login from "scenes/Login";
import NanduForm from "scenes/NanduForm";
import Register from "scenes/Register";
import ResetPassword from "scenes/ResetPassword";
import Schedule from "scenes/Schedule";
import VideoPlayer from "scenes/VideoPlayer";
import useLoadRecaptcha from "hooks/useLoadRecaptcha";

function AppRouter() {
  useLoadRecaptcha();

  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/forgotPassword">
          <ForgotPassword />
        </Route>
        <Route path="/resetPassword/:token">
          <ResetPassword />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/schedule">
          <Schedule />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/nanduform">
          <NanduForm />
        </Route>
        <Route path="/video/:eventParticipationId">
          <VideoPlayer />
        </Route>
        <Route path="/all-results">
          <AllResultsHome />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default AppRouter;

import { countries } from 'countries-list';

function getCountries() {
  let list = [];

  for (const country in countries) {
    if (countries.hasOwnProperty(country)) {
      const { name } = countries[country];

      if (name !== 'United States') {
        list.push(name);
      }
    }
  }
  list.sort();
  list.unshift('United States');

  return list;
}

export default getCountries;

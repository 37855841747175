import React from "react";
import { useField } from 'formik';

function Date({ label, size, ...props }) {
  const [field, meta] = useField(props);
  const bootstrapSize = size || 'col-sm-12 col-lg';
  const isInvalid = meta.touched && meta.error ? 'is-invalid' : '';

  return (
    <div className={`${bootstrapSize} form-group`}>
      <label className="form-label" htmlFor={props.id || props.name}>{label}</label>
      <input className={`form-control text-input ${isInvalid}`} type="date" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default Date;

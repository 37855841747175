import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import { AuthSelectors } from "redux/auth";
import NotFound from "scenes/NotFound";
import Header from "./components/Header";
import Login from "./components/CheckInLogin";
import CheckInHome from "./scenes/CheckInHome";

function CheckInRoute({ children, path }) {
  const isAdmin = useSelector(AuthSelectors.isAdmin);
  const isJudge = useSelector(AuthSelectors.isJudge);
  const isAuthorized = isAdmin || isJudge;

  return (
    <Route path={path}>
      {isAuthorized ? children : <Redirect to={{ pathname: "/" }} />}
    </Route>
  );
}

function CheckIn() {
  const { path } = useRouteMatch();
  const isAdmin = useSelector(AuthSelectors.isAdmin);

  return (
    <div className="CheckIn">
      <Header />
      <Switch>
        <Route path={`${path}`} exact>
          <Login />
        </Route>
        <CheckInRoute path={`${path}/home`} exact>
          <CheckInHome isAdmin={isAdmin} />
        </CheckInRoute>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default CheckIn;

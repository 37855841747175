import React, { Fragment, useEffect, useState } from "react";
import Checkbox from "components/Checkbox";
import CardCollapse from "components/CardCollapse";
import stripBadCharacters from "helpers/stripBadCharacters";
import JudgingEventCard from "../JudgingEventCard";

function OnlineJudging({ eventParticipation, judgeId }) {
  const [hideCompleted, setHideCompleted] = useState(false);
  const [visibleEventParticipation, setVisibleEventParticipation] = useState();
  const categoryList = [];

  if (visibleEventParticipation) {
    visibleEventParticipation.forEach(({ name, events }, i) => {
      categoryList.push(
        <Fragment key={i}>
          <h4>Category: {name}</h4>
          {Object.entries(events).map(([eventName, eventMap], j) => {
            return (
              <CardCollapse
                id={`${stripBadCharacters(eventName)}-${j}`}
                key={j}
                title={eventName}
              >
                <JudgingEventCard eventMap={eventMap} judgeId={judgeId} />
              </CardCollapse>
            );
          })}
          <hr></hr>
        </Fragment>
      );
    });
  }

  useEffect(() => {
    if (eventParticipation && hideCompleted) {
      const map = eventParticipation.reduce((acc, { name, events }) => {
        const map = Object.entries(events).reduce(
          (acc, [eventName, eventMap]) => {
            const map = Object.entries(eventMap).reduce(
              (acc, [ageGroup, ageGroupMap]) => {
                const map = Object.entries(ageGroupMap).reduce(
                  (acc, [gender, participants]) => {
                    const filtered = participants.filter(({ score }) => !score);

                    if (filtered.length) {
                      acc[gender] = filtered;
                    }

                    return acc;
                  },
                  {}
                );

                if (Object.values(map).length) {
                  acc[ageGroup] = map;
                }

                return acc;
              },
              {}
            );

            if (Object.values(map).length) {
              acc[eventName] = map;
            }

            return acc;
          },
          {}
        );

        if (Object.values(map).length) {
          acc.push({
            name,
            events: map,
          });
        }

        return acc;
      }, []);

      setVisibleEventParticipation(map);
    } else {
      setVisibleEventParticipation(eventParticipation);
    }
  }, [hideCompleted, eventParticipation]);

  return (
    <div className="OnlineJudging text-right">
      <Checkbox
        checked={hideCompleted}
        id="flexSwitchCheckChecked"
        label={<h5>Hide Completed Events</h5>}
        onChange={() => setHideCompleted(!hideCompleted)}
        useBootstrapClasses
      />
      {categoryList}
    </div>
  );
}

export default OnlineJudging;

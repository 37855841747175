import React from "react";
import "./Number.scss";

export default function Number({ onChange, text, step = 0.05 }) {
  return (
    <span className="Number">
      <input
        className="form-control no-print"
        type="number"
        min="0"
        max="10"
        step={step}
        value={text}
        onChange={({ target: { value } }) => onChange(value)}
      />
    </span>
  );
}

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { I18nSelectors } from "redux/i18n";
import EventsList from "components/EventsList";
import useCompetitionEventsForParticipant from "hooks/competitionEvents/useCompetitionEventsForParticipant";
import useEventParticipation from "hooks/eventParticipation/useEventParticipation";
import useLocalize from "hooks/useLocalize";
import useChampionshipParticipation from "hooks/championshipParticipation/useChampionshipParticipation";
import ChampionshipRegistration from "./ChampionshipRegistration";
import "./EventsPane.scss";

function EventsPane({
  competitions = [],
  participant,
  adminEventParticipation,
}) {
  const localize = useLocalize();
  const language = useSelector(I18nSelectors.getLanguage);
  const [competition, setCompetition] = useState();
  const numOfCompetitions = competitions.length;
  const { id: participantId } = participant;
  const { data: competitionWithEvents } = useCompetitionEventsForParticipant(
    competition?.id,
    participantId
  );
  const { data: championshipParticipation } = useChampionshipParticipation(
    participantId
  );
  const { data: eventParticipationMap } = useEventParticipation(
    !adminEventParticipation
  );
  const registrationDeadline = competition?.registrationDeadline;
  const isPastDeadline =
    !adminEventParticipation && registrationDeadline
      ? new Date(registrationDeadline) < new Date()
      : false;

  function selectCompetition({ target: { value } }) {
    setCompetition(competitions.find(({ id }) => id === +value));
  }

  useEffect(() => {
    // show the first competition automatically for admins
    if (adminEventParticipation) {
      setCompetition(competitions[0]);
    }
  }, []);

  useEffect(() => {
    // show the competition they've already registered for first
    if (eventParticipationMap && !competition) {
      const registeredCompetition = competitions.find(
        ({ id }) => !!eventParticipationMap?.[participantId]?.[id]
      );

      setCompetition(registeredCompetition);
    }
  }, [eventParticipationMap, competition]);

  return numOfCompetitions ? (
    <>
      {numOfCompetitions > 1 && (
        <div className="form-group row">
          <label className="form-label col-4">
            <h5>{localize("Select Competition")}</h5>
          </label>
          <div className="col-8">
            <select
              className="form-control"
              onChange={selectCompetition}
              value={competition?.id}
            >
              {!adminEventParticipation && !competition && (
                <option>Select Competition</option>
              )}
              {competitions.map(({ chineseName, id, name }) => {
                const competitionName =
                  language === "cn" && chineseName ? chineseName : name;

                return (
                  <option value={id} key={id}>
                    {competitionName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      )}
      {!!competition?.championships.length && (
        <>
          <h6 className="championships-title">
            {localize("Championship/Team Trials Registration")}
          </h6>
          <div className="form-group row">
            <div className="col">
              {competition.championships.map(
                ({ id, chineseName, name, requiresMembership }) => {
                  const registered =
                    championshipParticipation?.[competition?.id]?.[id] || false;
                  const championshipName =
                    language === "cn" && chineseName ? chineseName : name;

                  return (
                    <ChampionshipRegistration
                      key={id}
                      name={championshipName}
                      championshipId={id}
                      isPastDeadline={isPastDeadline}
                      participantId={participantId}
                      registered={!!registered}
                      requiresMembership={!!requiresMembership}
                    />
                  );
                }
              )}
            </div>
          </div>
        </>
      )}
      <EventsList
        competition={competitionWithEvents}
        eventParticipationMap={
          adminEventParticipation || eventParticipationMap?.[participantId]
        }
        participant={participant}
        isPastDeadline={isPastDeadline}
      />
    </>
  ) : null;
}

export default EventsPane;

import { useMutation, useQueryCache } from 'react-query';
import { AgeGroupAPI } from 'api/admin/ageGroup';
import { COMPETITIONS } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useRemoveAgeGroup() {
  const queryCache = useQueryCache();

  return useMutation(AgeGroupAPI.remove, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(COMPETITIONS);

      dispatchToast({ message, type: 'success' });
      onSuccess();
    },
  });
}

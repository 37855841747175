import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthSelectors } from "redux/auth";
import LoginForm from "components/LoginForm";

function AdminLogin() {
  const { path } = useRouteMatch();
  const userAuthenticated = useSelector(AuthSelectors.getAuthenticationStatus);
  const isAuthorized = useSelector(AuthSelectors.isAdmin);
  const adminAuthenticated = userAuthenticated && isAuthorized;

  return (
    <LoginForm
      isAuthenticated={userAuthenticated || adminAuthenticated}
      redirectTo={`${path}/home`}
      showForgotPassword
    />
  );
}

export default AdminLogin;

import { useMutation, useQueryCache } from "react-query";
import { ChampionshipEventCategoryRequirementAPI } from "api/admin/championshipEventCategoryRequirement";
import { CHAMPIONSHIP_EVENT_CATEGORY_REQUIREMENTS } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useCreateChampionshipEventCategoryRequirements() {
  const queryCache = useQueryCache();

  return useMutation(ChampionshipEventCategoryRequirementAPI.add, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      dispatchToast({ message, type: "success" });
      queryCache.invalidateQueries(CHAMPIONSHIP_EVENT_CATEGORY_REQUIREMENTS);
      onSuccess();
    },
  });
}

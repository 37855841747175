import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { AuthActions, AuthSelectors } from 'redux/auth';
import PasswordInput from 'components/PasswordInput';
import TextInput from 'components/TextInput';
import useLocalize from 'hooks/useLocalize';
import './Register.scss';

function Register() {
  const isAuthenticated = useSelector(AuthSelectors.getAuthenticationStatus);
  const dispatch = useDispatch();
  const history = useHistory();
  const localize = useLocalize();

  if (isAuthenticated) {
    return <Redirect to="/" noThrow />;
  }

  return (
    <div className="Register container">
      <h1 className="Register-title">{localize('Register')}</h1>
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmedPassword: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(localize('Must be a valid email'))
            .max(45, localize('Must be 45 characters or less'))
            .required(localize('Required')),
          password: Yup.string()
            .min(8, localize('Must be 8 characters or more'))
            .max(45, localize('Must be 45 characters or less'))
            .required(localize('Required')),
          confirmedPassword: Yup.string()
            .required(localize('Required'))
            .oneOf(
              [Yup.ref('password'), null],
              localize('Passwords must match')
            ),
        })}
        onSubmit={async ({ email, password, confirmedPassword }) => {
          // eslint-disable-next-line no-undef
          const recaptchaToken = await grecaptcha.execute(
            process.env.REACT_APP_RECAPTCHA_SITE_KEY,
            {
              action: 'register',
            }
          );

          dispatch(
            AuthActions.register({
              email,
              password,
              confirmedPassword,
              recaptchaToken,
            })
          );
        }}
      >
        <Form className="needs-validation" noValidate>
          <TextInput placeholder={localize('Email')} name="email" />
          <PasswordInput placeholder={localize('Password')} name="password" />
          <PasswordInput
            placeholder={localize('Confirm Password')}
            name="confirmedPassword"
          />
          <div className="col">
            <button type="submit" className="btn btn-success">
              {localize('Register')}
            </button>
          </div>
        </Form>
      </Formik>
      <div className="col mt-3">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={() => history.push('/login')}
        >
          {localize('Back To Login')}
        </button>
      </div>
    </div>
  );
}

export default Register;

import React from "react";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Admin from "scenes/Admin";
import Judging from "scenes/Judging";
import CheckIn from "scenes/CheckIn";
import NotFound from "scenes/NotFound";
import WebRouter from "../WebRouter";
import ToastContainer from "./ToastContainer";

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      retry: 1,
    },
  },
});

function App() {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ToastContainer />
      <Router>
        <Switch>
          <Route path="/adm">
            <Admin />
          </Route>
          <Route path="/judging">
            <Judging />
          </Route>
          <Route path="/check-in">
            <CheckIn />
          </Route>
          <Route path="/">
            <WebRouter />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </ReactQueryCacheProvider>
  );
}

export default App;

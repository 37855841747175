import React from "react";
import PropTypes from "prop-types";
import Checkbox from "components/Checkbox";
import useCreateEventParticipation from "hooks/eventParticipation/useCreateEventParticipation";
import useRemoveEventParticipation from "hooks/eventParticipation/useRemoveEventParticipation";

const EventListItem = React.memo(function EventListItem({
  checked,
  competitionEventId,
  competitionId,
  disabled,
  name,
  participantId,
  restricted,
}) {
  const [createEventParticipation] = useCreateEventParticipation();
  const [removeEventParticipation] = useRemoveEventParticipation();

  return (
    <div className="EventListItem event col-lg-4 col-md-6 col-sm-12">
      <Checkbox
        checked={checked}
        disabled={disabled || restricted}
        id={`${participantId}-${competitionEventId}`}
        label={name}
        onChange={async (checked) => {
          if (checked) {
            await createEventParticipation({
              participantId,
              competitionId,
              competitionEventId,
            });
          } else {
            await removeEventParticipation({
              participantId,
              competitionId,
              competitionEventId,
            });
          }
        }}
        useBootstrapClasses
      />
    </div>
  );
});

export default EventListItem;

EventListItem.propTypes = {
  /**
   * Is checked or not.
   */
  checked: PropTypes.bool,
  /**
   * Competition event id.
   */
  competitionEventId: PropTypes.number.isRequired,
  /**
   * Competition id.
   */
  competitionId: PropTypes.number.isRequired,
  /**
   * Is disabled or not.
   */
  disabled: PropTypes.bool,
  /**
   * Label for the checkbox.
   */
  name: PropTypes.string.isRequired,
  /**
   * Participant id.
   */
  participantId: PropTypes.number.isRequired,
  /**
   * Is restricted or not.
   */
  restricted: PropTypes.bool,
};

EventListItem.defaultProps = {};

import { combineReducers } from "redux";
import { LOGOUT } from "./auth/actionTypes";
import { AuthReducer as auth } from "./auth";
import { CollapseGroupReducer as collapseGroup } from "./collapseGroup";
import { CompetitionSelectReducer as competitionSelect } from "./competitionSelect";
import admin from "./admin/reducers";
import { I18nReducer as i18n } from "./i18n";
import { NanduFormReducer as nanduForm } from "./nanduForm";
import { RingSelectReducer as ringSelect } from "./ringSelect";
import { ToastReducer as toasts } from "./toast";

const appReducer = combineReducers({
  auth,
  admin,
  collapseGroup,
  competitionSelect,
  i18n,
  nanduForm,
  ringSelect,
  toasts,
});

function rootReducer(state, action) {
  /**
   * Reset the root redux state. If in the case that we need some piece of the state to persist, we should instead explicitly clear
   * individual reducers rather than clear all but keep some.
   * Reference: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
   */
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
}

export default rootReducer;

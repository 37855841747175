import { useMutation, useQueryCache } from "react-query";
import { EventParticipationAPI } from "api/eventParticipation";
import { EVENT_PARTICIPATION } from "helpers/constants";

export default function useUpdateEventParticipationClaimedAward() {
  const queryCache = useQueryCache();

  return useMutation(
    EventParticipationAPI.updateEventParticipationClaimedAward,
    {
      onSuccess: ({ message }) => {
        queryCache.invalidateQueries(EVENT_PARTICIPATION);
      },
    }
  );
}

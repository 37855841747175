import { useMutation, useQueryCache } from "react-query";
import { ChampionshipGenderAPI } from "api/admin/championshipGender";
import { CHAMPIONSHIP_GENDERS } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useCreateChampionshipGenders() {
  const queryCache = useQueryCache();

  return useMutation(ChampionshipGenderAPI.add, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(CHAMPIONSHIP_GENDERS);
      dispatchToast({ message, type: "success" });

      onSuccess();
    },
  });
}

import { useQuery } from "react-query";
import { UserRoleAPI } from 'api/admin';

export default function useTournament(isSuperUser) {
  return useQuery(
    'userRoles',
    UserRoleAPI.getAll,
    { enabled: isSuperUser }
  );
}

import { useMutation, useQueryCache } from 'react-query';
import { EventCategoryRestrictionAPI } from 'api/admin/eventCategoryRestriction';
import { COMPETITIONS } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useUpdateEventCategoryRestriction() {
  const queryCache = useQueryCache();

  return useMutation(EventCategoryRestrictionAPI.update, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      queryCache.invalidateQueries(COMPETITIONS);
      dispatchToast({ message, type: 'success' });
      onSuccess();
    },
  });
}

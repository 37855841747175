import React from "react";
import { useHistory } from "react-router-dom";
import Table from "components/admin/Table";
import useTeamMedalsForCompetition from "hooks/teams/useTeamMedalsForCompetition";

function TeamMedalsReport({ competitionId }) {
  const { data: teams, isLoading } = useTeamMedalsForCompetition(competitionId);
  const history = useHistory();
  const tableData = {
    defaultSort: "teamName",
    searchableColumns: ["teamName"],
    headingData: {
      teamName: "Name",
      gold: "Gold",
      silver: "Silver",
      bronze: "Bronze",
    },
    items: teams || [],
    childTableData: {
      defaultSort: "name",
      searchableColumns: ["name"],
      headingData: {
        id: "ID",
        name: "Name",
        gold: "Gold",
        silver: "Silver",
        bronze: "Bronze",
      },
      itemsKey: "participants",
      itemClick,
    },
  };

  function itemClick({ id }) {
    history.push(`/adm/home/participant/${id}`);
  }

  return (
    <div className="TeamMedalsReport">
      <h3>Team Medal Report</h3>
      {isLoading ? (
        <div className="spinner-border mt-2" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <Table data={tableData} />
      )}
    </div>
  );
}

export default TeamMedalsReport;

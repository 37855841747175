import { useMutation, useQueryCache } from 'react-query';
import { VideoSubmissionAPI } from 'api/videoSubmission';
import { VIDEO_SUBMISSIONS } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useRemoveVideoSubmission() {
  const queryCache = useQueryCache();

  return useMutation(VideoSubmissionAPI.removeVideoSubmission, {
    onSuccess: ({ message }) => {
      dispatchToast({ message, type: 'success' });
      queryCache.invalidateQueries(VIDEO_SUBMISSIONS);
    },
  });
}

/* eslint-disable no-plusplus */
/* eslint-disable no-case-declarations */
/* eslint-disable no-lonely-if */
import mapMovementCodesToObject from './mapMovementCodesToObject';

function calculateScore(score, defaultScore, currTotal, maxTotal) {
  let retScore = score;
  if (score >= defaultScore) {
    retScore = defaultScore;
  }
  if (currTotal + retScore > maxTotal) {
    retScore = maxTotal - +(currTotal.toFixed(2));
  }
  retScore = +(retScore);
  return +(retScore.toFixed(2));
}

/* removedPlusConnection is only present when the last operation was removing a + connection */
export function calculateNanduScore(sections, connections, removedPlusConnection) {
  const JUMP_SCORE_MAX = 0.3;
  const CONNECTION_SCORE_MAX = 0.15;
  const sectionsCounter = [0, 0, 0, 0];
  const comboList = {};
  const singleMoveList = {};
  let jumpScore = 0;
  let connectionScore = 0;
  let errorMessage = '';
  let hasThrowCatch = false;

  function checkDuplicateMove(currentMove) {
    // if current move is unique by itself
    if (!singleMoveList[currentMove.code] || singleMoveList[currentMove.code] < 1) {
      const calculatedJumpScore = calculateScore(currentMove.score, JUMP_SCORE_MAX, jumpScore, 1.4);
      currentMove.formValue = calculatedJumpScore;
      jumpScore += calculatedJumpScore;
      singleMoveList[currentMove.code] = singleMoveList[currentMove.code] ? (singleMoveList[currentMove.code] + 1) : 1;
    } else {
      singleMoveList[currentMove.code]++;
      errorMessage = 'NON_UNIQUE_JUMP';
    }
  }

  function removePrevMoveFromSingleMoveList(prevMove) {
    // if prevMove wasn't unique, add it to the jump total
    if (singleMoveList[prevMove.code] > 1) {
      const prevCalculatedJumpScore = calculateScore(prevMove.score, JUMP_SCORE_MAX, jumpScore, 1.4);
      prevMove.formValue = prevCalculatedJumpScore;
      jumpScore += prevCalculatedJumpScore;
    }
    singleMoveList[prevMove.code]--;
  }

  function calculateConnectionScore(combo, jumpConnection) {
    const calculatedConnectionScore = calculateScore(connections[combo].score, CONNECTION_SCORE_MAX, connectionScore, 0.6);
    jumpConnection.formValue = calculatedConnectionScore;
    connectionScore += calculatedConnectionScore;
  }

  for (let i = 0; i < sections.length; i++) {
    for (let j = 0; j < sections[i].nandu.length; j++) {
      const jumpConnection = {
        code: '+',
      };
      const currentMove = sections[i].nandu[j];
      // have to check inside the inner loop to ensure nandus[j] is not an empty object
      if (currentMove.code) {
        const prevMove = j === 0 ? undefined : sections[i].nandu[j - 1];
        let prevComboMove = prevMove;
        let combo = '';
        let isPartOfCombo = false;
        let isUniqueCombo = false;
        if (prevMove) { // if it's not the first move in the whole form
          // if the prevMove is a '+', we can assume there is another move behind prevMove
          if (prevMove.code === '+') { prevComboMove = sections[i].nandu[j - 2]; }
          combo = `${prevComboMove.code} ${currentMove.code}`;
          isPartOfCombo = connections[combo];
          isUniqueCombo = !comboList[combo];
        }
        currentMove.formValue = 0; // the calculated value of the move
        sectionsCounter[i] = 1;

        if (currentMove.isSelectedAsConnection && (isPartOfCombo && isUniqueCombo)) {
          const removedPlusConnectionIndex = removedPlusConnection && removedPlusConnection.sectionId === i && removedPlusConnection.nanduIndex === j;
          if (removedPlusConnectionIndex) {
            if (currentMove.nanduType === 'JUMP') {
              currentMove.isSelectedAsConnection = false;
            } else if (currentMove.nanduType === 'CONNECTION') {
              sections[i].nandu.splice(j, 1); // remove unused connection
              j--;
            }
          } else {
            const isThrowCatch = currentMove.code === '8A' || currentMove.code === '8B';
            if (!isThrowCatch || !hasThrowCatch) {
              if (isThrowCatch) { hasThrowCatch = true; }
              if (prevMove.code !== '+') {
                // add jumpConnection between prev and current move and increment counter to the appropriate index
                sections[i].nandu.splice(j, 0, jumpConnection);
                j++;
              }
              if (currentMove.nanduType === 'JUMP') {
                currentMove.formValue = calculateScore(currentMove.score, JUMP_SCORE_MAX, jumpScore, 1.4);
                jumpScore += currentMove.formValue;
              }
              calculateConnectionScore(combo, jumpConnection);
            }
            removePrevMoveFromSingleMoveList(prevComboMove);
            comboList[combo] = comboList[combo] ? (comboList[combo] + 1) : 1;
          }
        } else if (currentMove.code !== '+' && currentMove.nanduType !== 'CONNECTION') {
          checkDuplicateMove(currentMove);
        } else if (currentMove.code === '+' || currentMove.nanduType === 'CONNECTION') {
          sections[i].nandu.splice(j, 1); // remove unused connections
          j--;
        }
      }
    }
  }

  if (jumpScore > 1.4) { jumpScore = 1.4; }
  if (connectionScore > 0.6) { connectionScore = 0.6; }
  jumpScore = +(jumpScore.toFixed(2));
  connectionScore = +(connectionScore.toFixed(2));
  let totalScore = jumpScore + connectionScore;
  totalScore = totalScore === 2 ? totalScore.toFixed(1) : totalScore.toFixed(2);
  const threeSectionsNotEmpty = (sectionsCounter[0] + sectionsCounter[1] + sectionsCounter[2] + sectionsCounter[3]) >= 3;
  const calculated = {
    sections,
    nanduScores: {
      totalScore,
      totalScoreIsMaxed: totalScore >= 2,
      jumpScore,
      jumpScoreIsMaxed: jumpScore >= 1.4,
      connectionScore,
      connectionScoreIsMaxed: connectionScore >= 0.6,
      threeSectionsNotEmpty,
      errorMessage,
    },
  };
  return calculated;
}

export function hasDuplicates({ movement, sections }) {
  let bool = false;

  for (let i = 0; i < sections.length; i++) {
    for (let j = 0; j < sections[i].movement.length; j++) {
      if (sections[i].movement[j].code === movement.code) {
        bool = true;
      }
    }
  }

  return bool;
}

export function hasAllRequiredMovements({ movementCodes, sections }) {
  const codeMap = mapMovementCodesToObject(movementCodes);
  let bool = true;

  for (let i = 0; i < sections.length; i++) {
    for (let j = 0; j < sections[i].movement.length; j++) {
      codeMap[sections[i].movement[j].code]++;
    }
  }
  for (const key in codeMap) { // eslint-disable-line no-restricted-syntax
    if (codeMap.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
      if (codeMap[key] < 1) {
        bool = false;
      }
    }
  }
  return bool;
}

import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import NumberInput from "components/NumberInput";
import TextInput from "components/TextInput";
import useAgeGroups from "hooks/championshipAgeGroups/useChampionshipAgeGroups";
import useCreateAgeGroup from "hooks/championshipAgeGroups/useCreateChampionshipAgeGroups";
import useRemoveAgeGroup from "hooks/championshipAgeGroups/useRemoveChampionshipAgeGroups";
import useUpdateAgeGroup from "hooks/championshipAgeGroups/useUpdateChampionshipAgeGroups";
import AgeGroupItem from "../../AgeGroups/AgeGroupItem";

function AddChampionshipAgeGroupRequirements({ championshipId }) {
  const { data: ageGroups } = useAgeGroups(championshipId);
  const [isAdding, setIsAdding] = useState();
  const [createAgeGroup] = useCreateAgeGroup();
  const [removeAgeGroup] = useRemoveAgeGroup();
  const [updateAgeGroup] = useUpdateAgeGroup();

  function cancelAddAgeGroup(e) {
    e.preventDefault();
    setIsAdding(false);
  }

  return (
    <div className="row form-group">
      <div className="col">
        <label>Age Group Requirements</label>
        <div className="card card-body bg-light">
          {ageGroups?.length > 0 && (
            <>
              <ul className="list-group">
                {ageGroups.map(({ id, rangeStart, rangeEnd, name }, index) => (
                  <AgeGroupItem
                    key={index}
                    deleteItem={() => removeAgeGroup(id)}
                    updateItem={(ageGroupInput) =>
                      updateAgeGroup({
                        id,
                        championshipId: +championshipId,
                        ...ageGroupInput,
                      })
                    }
                    rangeStart={rangeStart}
                    rangeEnd={rangeEnd}
                    name={name}
                  />
                ))}
              </ul>
              <hr></hr>
            </>
          )}
          <div className="row">
            <div className="col d-flex justify-content-between">
              {!isAdding && (
                <button
                  className="btn btn-primary"
                  onClick={() => setIsAdding(true)}
                >
                  Add New Age Group
                </button>
              )}
              {isAdding && (
                <button className="btn btn-danger" onClick={cancelAddAgeGroup}>
                  Cancel
                </button>
              )}
            </div>
          </div>
          {isAdding && (
            <>
              <hr></hr>
              <div className="row">
                <div className="col">
                  <Formik
                    initialValues={{
                      rangeStart: 0,
                      rangeEnd: 1,
                      name: "",
                    }}
                    validationSchema={Yup.object({
                      rangeStart: Yup.number().required("Required"),
                      rangeEnd: Yup.number().required("Required"),
                      name: Yup.string(),
                    })}
                    onSubmit={async ({ rangeStart, rangeEnd, name }) => {
                      createAgeGroup({
                        rangeStart,
                        rangeEnd,
                        name,
                        championshipId,
                      });
                      setIsAdding(false);
                    }}
                  >
                    <Form className="needs-validation" noValidate>
                      <div className="row">
                        <NumberInput
                          label="Min"
                          name="rangeStart"
                          min="0"
                          step="1"
                          size="col-sm-12 col-md-6 col-lg-4"
                        />
                        <NumberInput
                          label="Max"
                          name="rangeEnd"
                          min="1"
                          step="1"
                          size="col-sm-12 col-md-6 col-lg-4"
                        />
                        <TextInput label="Name" name="name" />
                      </div>
                      <button type="submit" className="btn btn-success">
                        Submit
                      </button>
                    </Form>
                  </Formik>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddChampionshipAgeGroupRequirements;

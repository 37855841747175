import React from 'react';
import './TrelloCard.scss';

function TrelloCard({ badgeText, onClick, text }) {
  return (
    <div className="TrelloCard">
      <div className="card" onClick={onClick}>
        <div className="top-row d-flex justify-content-between align-items-center">
          <span>{text}</span>
          {!!badgeText && <span className="badge bg-info">{badgeText}</span>}
        </div>
      </div>
    </div>
  );
}

export default TrelloCard;

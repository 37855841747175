import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { toHTMLDate, toHTMLTime } from "helpers";
import Checkbox from "components/Checkbox";
import CONFIRM_DELETE from "helpers/confirmDelete";
import useUpdateCompetition from "hooks/competitions/useUpdateCompetition";
import useRemoveCompetition from "hooks/competitions/useRemoveCompetition";
import "./CompetitionEdit.scss";

function Competition({ competition }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [updateCompetition] = useUpdateCompetition();
  const [removeCompetition] = useRemoveCompetition();
  const [name, setName] = useState(competition.name || "");
  const [chineseName, setChineseName] = useState(competition.chineseName || "");
  const [startDate, setStartDate] = useState(toHTMLDate(competition.startDate));
  const [endDate, setEndDate] = useState(toHTMLDate(competition.endDate));
  const [startTime, setStartTime] = useState(toHTMLTime(competition.startTime));
  const [cost, setCost] = useState(competition.cost || 0);
  const [eventCost, setEventCost] = useState(competition.eventCost || 0);
  const [isVirtual, setIsVirtual] = useState(!!competition.isVirtual);
  const [isComplete, setIsComplete] = useState(!!competition.isComplete);
  const [votingEnabled, setVotingEnabled] = useState(
    !!competition.votingEnabled
  );
  const [submissionDeadline, setSubmissionDeadline] = useState(
    toHTMLDate(competition.submissionDeadline)
  );
  const [registrationDeadline, setRegistrationDeadline] = useState(
    toHTMLDate(competition.registrationDeadline)
  );
  const [lotDrawStartDate, setLotDrawStartDate] = useState(
    toHTMLDate(competition.lotDrawStartDate)
  );
  const [lotDrawEndDate, setLotDrawEndDate] = useState(
    toHTMLDate(competition.lotDrawEndDate)
  );
  const [lotDrawTestStartDate, setLotDrawTestStartDate] = useState(
    toHTMLDate(competition.lotDrawTestStartDate)
  );

  function edit() {
    const { id, tournamentId } = competition;
    let formattedStartTime = null;
    if (startDate) {
      if (startTime) {
        formattedStartTime = `${startDate} ${startTime}`;
      } else {
        formattedStartTime = `${startDate} 00:00:00`;
      }
    }
    const updatedCompetition = {
      tournamentId,
      name,
      chineseName,
      startDate: startDate || null,
      endDate: endDate || null,
      startTime: formattedStartTime,
      cost: parseFloat(cost),
      eventCost: parseFloat(eventCost),
      isVirtual: isVirtual || null,
      submissionDeadline: submissionDeadline || null,
      registrationDeadline: registrationDeadline || null,
      isComplete: isComplete || null,
      votingEnabled: votingEnabled || null,
      lotDrawStartDate: lotDrawStartDate || null,
      lotDrawEndDate: lotDrawEndDate || null,
      lotDrawTestStartDate: lotDrawTestStartDate || null,
    };

    updateCompetition({ id, competition: updatedCompetition });
  }

  function remove() {
    const { id } = competition;
    const parentPath = url.split("/");

    parentPath.pop();

    removeCompetition(id, {
      onSuccess: () => history.push(parentPath.join("/")),
    });
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="competitionName">Competition Name</label>
            <input
              type="text"
              className="form-control"
              id="competitionName"
              value={name}
              onChange={({ target: { value } }) => setName(value)}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="competitionChineseName">Chinese Name</label>
            <input
              type="text"
              className="form-control"
              id="competitionChineseName"
              value={chineseName}
              onChange={({ target: { value } }) => setChineseName(value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="startDate">Start Date</label>
            <input
              type="date"
              className="form-control"
              id="startDate"
              value={startDate}
              onChange={({ target: { value } }) => setStartDate(value)}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="endDate">End Date</label>
            <input
              type="date"
              className="form-control"
              id="endDate"
              value={endDate}
              onChange={({ target: { value } }) => setEndDate(value)}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="startTime">Start Time</label>
            <input
              type="time"
              className="form-control"
              id="startTime"
              value={startTime}
              onChange={({ target: { value } }) => setStartTime(value)}
              disabled={!startDate}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="cost">Competition Cost</label>
            <input
              type="number"
              className="form-control"
              id="cost"
              value={cost}
              onChange={({ target: { value } }) => setCost(value)}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="cost">Event Cost</label>
            <input
              type="number"
              className="form-control"
              id="cost"
              value={eventCost}
              onChange={({ target: { value } }) => setEventCost(value)}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="registrationDeadline">Registration Deadline</label>
            <input
              type="date"
              className="form-control"
              id="registrationDeadline"
              value={registrationDeadline}
              onChange={({ target: { value } }) =>
                setRegistrationDeadline(value)
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Checkbox
            checked={!!isVirtual}
            defaultValue={isVirtual}
            id="isVirtual"
            label={"Is Virtual"}
            onChange={setIsVirtual}
            useBootstrapClasses
          />
        </div>
        {isVirtual && (
          <div className="col">
            <div className="form-group">
              <label htmlFor="submissionDeadline">
                Video Submission Deadline
              </label>
              <input
                type="date"
                className="form-control"
                id="submissionDeadline"
                value={submissionDeadline}
                onChange={({ target: { value } }) =>
                  setSubmissionDeadline(value)
                }
              />
            </div>
          </div>
        )}
        {!isVirtual && (
          <>
            <div className="col">
              <div className="form-group">
                <label htmlFor="lotDrawTestStartDate">
                  Lot Draw Test Start Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="lotDrawTestStartDate"
                  value={lotDrawTestStartDate}
                  onChange={({ target: { value } }) =>
                    setLotDrawTestStartDate(value)
                  }
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="lotDrawStartDate">Lot Draw Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="lotDrawStartDate"
                  value={lotDrawStartDate}
                  onChange={({ target: { value } }) =>
                    setLotDrawStartDate(value)
                  }
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="lotDrawEndDate">Lot Draw End Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="lotDrawEndDate"
                  value={lotDrawEndDate}
                  onChange={({ target: { value } }) => setLotDrawEndDate(value)}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="row">
        <div className="col">
          <Checkbox
            checked={!!isComplete}
            defaultValue={isComplete}
            id="isComplete"
            label="Show Results"
            onChange={setIsComplete}
            useBootstrapClasses
          />
          <Checkbox
            checked={!!votingEnabled}
            defaultValue={votingEnabled}
            id="votingEnabled"
            label="Enable Voting"
            onChange={setVotingEnabled}
            useBootstrapClasses
          />
        </div>
      </div>
      <hr></hr>
      <div className="row">
        <div className="col d-flex justify-content-between">
          <button
            className="btn btn-danger"
            onClick={() => CONFIRM_DELETE(remove)}
          >
            Delete
          </button>
          <button className="btn btn-success" onClick={edit}>
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default Competition;

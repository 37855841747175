import { SET_RING_ID } from "./actionTypes";

// const state = {
//   "ringSelect-1": {
//      "competitionId": {
//         label: 'ring name',
//         value: id,
//      }
//    }
// };

function RingSelectReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case SET_RING_ID: {
      const { competitionId, ringSelectId, ring } = action;

      return {
        ...state,
        [ringSelectId]: {
          ...state[ringSelectId],
          [competitionId]: ring,
        },
      };
    }
    default:
      return state;
  }
}

export { RingSelectReducer };

import { useQuery } from "react-query";
import { EventParticipationAPI } from "api/eventParticipation";
import { EVENT_PARTICIPATION } from "helpers/constants";

export default function useEventParticipationForJudge(judgeId) {
  return useQuery(
    [EVENT_PARTICIPATION, judgeId],
    () => EventParticipationAPI.getAllForJudge(judgeId),
    {
      enabled: !!judgeId,
    }
  );
}

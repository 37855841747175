import React, { useState, useEffect, useCallback } from "react";
import Modal from "../../Modal";
import AdminTable from "../Table";
import ModalFields from "components/Modal/ModalFields";
import CONFIRM_DELETE from "helpers/confirmDelete";
import "./ModalTable.css";

/**
 * Table that has uses a modal to add and/or edit items. Also deletes items and has an undo delete function.
 *
 * @param {Object} props - Props object.
 * @param {Function} props.addItem - Callback to add an item, usually containing an API call.
 * @param {Function} [props.editItem] - Callback to edit an item; passes the item being edited. Trumped by itemClick.
 * @param {Function} [props.itemClick] - Callback to handle clicking on a row.
 * @param {Function} [props.onClose] - Callback to run after closing the modal.
 * @param {Function} [props.processItem] - Callback to process an item before opening the edit modal.
 * @param {Function} [props.removeItem] - Callback to remove an item, usually containing an API call.
 * @param {Function} props.setIsAddingItem - Callback to set the modal values when adding an item.
 * @param {Function} [props.setIsEditingItem] - Callback to set the modal values when editing an item.
 * @param {Function} [props.undoDelete] - Callback to handle undoing deletes.
 * @param {Component} [props.extraModalContent] - Extra modal content. Goes below modal fields.
 */
function ModalTable({
  addItem,
  data,
  editItem,
  exportToCSVData,
  itemClick,
  isIndexed,
  isSelectable,
  onClose = () => {},
  onAddSelected,
  onDeleteSelected,
  onReorder,
  processItem,
  removeItem,
  setIsAddingItem = () => {},
  setIsEditingItem = () => {},
  undoDelete,
  ExtraModalContent,
}) {
  const { itemName } = data;
  const fields = data?.modalData?.fields;
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [canUndo, setCanUndo] = useState(false);
  const [undoEvent, setUndoEvent] = useState(false);

  setIsAddingItem = useCallback(setIsAddingItem, []);
  setIsEditingItem = useCallback(setIsEditingItem, []);

  let title = isAdding ? "Add " : "Edit ";
  title = title + itemName;

  useEffect(() => {
    if (isEditing) {
      setIsEditingItem(currentItem);
    } else if (isAdding) {
      setIsAddingItem();
    }
  }, [isEditing, isAdding, currentItem, setIsEditingItem, setIsAddingItem]);

  function handleAddItem(e) {
    e.preventDefault();
    addItem();
    closeModal();
  }

  function handleRemoveItem(e) {
    e.preventDefault();
    CONFIRM_DELETE(() => {
      removeItem(currentItem);
      setCanUndo(!!undoDelete);
      setUndoEvent(currentItem);
      closeModal();
    });
  }

  function handleUndoDelete() {
    undoDelete(undoEvent);
    setCanUndo(false);
  }

  function handleEditItem(e) {
    e.preventDefault();
    editItem(currentItem);
    closeModal();
  }

  function closeModal() {
    setIsEditing(false);
    setIsAdding(false);
    setShowModal(false);
    onClose();
  }

  function defaultItemClick(item) {
    setIsEditing(true);
    setShowModal(true);
    let curr = item;
    if (processItem) {
      curr = processItem(item);
    }
    setCurrentItem(curr);
  }

  function addItemClick() {
    setIsAdding(true);
    setShowModal(true);
    setCurrentItem(null);
  }

  return (
    <>
      {showModal && (
        <Modal close={closeModal}>
          <h3>{title}</h3>
          <form>
            {fields && <ModalFields fields={fields} />}
            {isAdding && (
              <button
                className="btn btn-success float-right"
                onClick={handleAddItem}
              >
                Add
              </button>
            )}
            {isEditing && (
              <>
                <button
                  className="btn btn-success float-right"
                  onClick={handleEditItem}
                  type="submit"
                >
                  Submit
                </button>
                {removeItem && (
                  <button className="btn btn-danger" onClick={handleRemoveItem}>
                    Delete
                  </button>
                )}
              </>
            )}
          </form>
          {ExtraModalContent && <ExtraModalContent item={currentItem} />}
        </Modal>
      )}
      {canUndo && (
        <div className="alert alert-warning alert-dismissible">
          {itemName} deleted,{" "}
          <span className="undo-link" onClick={handleUndoDelete}>
            click here to undo
          </span>
          <button
            type="button"
            className="close"
            onClick={() => setCanUndo(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      <AdminTable
        data={data}
        exportToCSVData={exportToCSVData}
        itemClick={(item) =>
          itemClick ? itemClick(item) : defaultItemClick(item)
        }
        isSelectable={isSelectable ? { columnCheckbox: true } : false}
        isIndexed={isIndexed}
        addItemClick={addItem ? addItemClick : null}
        onAddSelected={onAddSelected}
        onDeleteSelected={onDeleteSelected}
        onReorder={onReorder}
      />
    </>
  );
}

export default ModalTable;

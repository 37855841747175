import React from "react";
import Table from "components/admin/Table";
import useMasterSheet from "hooks/masterSheet/useMasterSheet";

function MasterSheet() {
  const { data: items } = useMasterSheet();

  const tableData = {
    itemName: "Item",
    filters: ["categoryName", "ageGroup", "gender", "hasPaid", "award"],
    headingData: {
      categoryName: "Category",
      eventName: "Event",
      code: "Code",
      ageGroup: "Age Group",
      gender: "Gender",
      score: "Score",
      rank: "Rank",
      award: "Award",
      participantName: "Name",
      email: "Email",
      phone: "Phone",
      amountDue: "Amount Due",
      hasPaid: "Has Paid",
      groupMembers: "Group Members",
      teamName: "Team",
      leader: "Team Leader",
      videoUrl: "Video",
    },
    items: items || [],
    searchableColumns: [
      "award",
      "categoryName",
      "eventName",
      "code",
      "ageGroup",
      "gender",
      "participantName",
      "email",
      "phone",
      "hasPaid",
      "groupMembers",
      "teamName",
      "leader",
    ],
    defaultSort: "participantName",
  };

  return (
    <div className="MasterSheet">
      <h2 className="text-left">Master Sheet</h2>
      <Table data={tableData} />
    </div>
  );
}

export default MasterSheet;

import { useMutation, useQueryCache } from 'react-query';
import { JudgeAPI } from 'api/admin/judge';
import { JUDGES } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useToggleHeadJudge() {
  const queryCache = useQueryCache();

  return useMutation(JudgeAPI.toggleHeadJudge, {
    onSuccess: async ({ message }) => {
      queryCache.invalidateQueries(JUDGES);
      dispatchToast({ message, type: 'success' });
    },
  });
}

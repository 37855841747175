import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Formik, Form } from "formik";
import { useSelector } from "react-redux";
import { Redirect, useParams, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { UserAPI } from "api/user";
import PasswordInput from "components/PasswordInput";
import TextInput from "components/TextInput";
import Select from "components/Select";
import CONFIRM_DELETE from "helpers/confirmDelete";
import useCheckoutForUser from "hooks/checkout/useCheckoutForUser";
import useAdminUpdateEmail from "hooks/users/useAdminUpdateEmail";
import useUserParticipants from "hooks/users/useUserParticipants";
import useAdminUpdatePassword from "hooks/users/useAdminUpdatePassword";
import useUpdateRole from "hooks/users/useUpdateRole";
import useUserRoles from "hooks/useUserRoles";
import { AuthSelectors } from "redux/auth";
import TransactionsCard from "./TransactionsCard";
import CheckoutInfoCard from "./CheckoutInfoCard";
import ParticipantsCard from "./ParticipantsCard";
import TicketsCard from "./TicketsCard";

function User() {
  const isSuperUser = useSelector(AuthSelectors.isSuperUser);
  const location = useLocation();
  const [user, setUser] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [updatePassword] = useAdminUpdatePassword();
  const [updateEmail] = useAdminUpdateEmail();
  const [updateRole] = useUpdateRole();
  const { data: userRoles } = useUserRoles(isSuperUser);
  const [removed, setRemoved] = useState(false);
  const { userId } = useParams();
  const { data: participants } = useUserParticipants(userId);
  const { data: checkoutInfo } = useCheckoutForUser(userId);
  const buttonClasses = classnames(
    "btn",
    { "btn-danger": !isDeleting },
    { "btn-warning": isDeleting },
    "col-12",
    "form-group"
  );
  let userRoleMarkup;

  if (user) {
    if (isSuperUser) {
      const roles = userRoles
        ? userRoles.map(({ name }, index) => (
            <option key={index} value={name}>
              {name}
            </option>
          ))
        : [];

      userRoleMarkup = (
        <>
          <hr></hr>
          <h5>Change Role</h5>
          <Formik
            initialValues={{ role: user.role }}
            validationSchema={Yup.object({
              role: Yup.string()
                .max(45, "Must be 45 characters or less")
                .required("Required"),
            })}
            onSubmit={async ({ role }) => {
              const { id } = userRoles.find(({ name }) => role === name);
              updateRole({ roleId: id, id: user.id });
            }}
          >
            <Form className="needs-validation" noValidate>
              <div className="row">
                <Select name="role" size="col-sm col-md-5">
                  {roles}
                </Select>
                <div className="col-sm-12 col-md-2">
                  <button type="submit" className="btn btn-primary col-12">
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </>
      );
    } else {
      userRoleMarkup = (
        <div className="role">
          <hr></hr>
          <h5>Role</h5>
          <p>{user.role}</p>
        </div>
      );
    }
  }

  useEffect(() => {
    async function fetchUser() {
      const u = await UserAPI.getUserById(+userId);

      setUser(u);
      setRemoved(u.deletedAt);
    }

    fetchUser();
  }, [userId]);

  async function removeUser() {
    const isRemoved = await UserAPI.adminRemoveUser(+userId);

    setRemoved(isRemoved);
  }

  if (removed) {
    const parentRoute = location.pathname.split("/").slice(0, -1).join("/");

    return <Redirect push to={parentRoute} />;
  }

  return (
    <div className="User">
      <h2 className="title text-lg-left text-sm-center">User View</h2>
      {user && (
        <div className="account-information">
          <hr></hr>
          <h5>Change Email</h5>
          <Formik
            initialValues={{ email: user.email }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Must be a valid email")
                .max(45, "Must be 45 characters or less")
                .required("Required"),
            })}
            onSubmit={async ({ email }) => updateEmail({ email, id: user.id })}
          >
            <Form className="needs-validation" noValidate>
              <div className="row">
                <TextInput
                  placeholder="Email"
                  name="email"
                  size="col-sm col-md-5"
                />
                <div className="col-sm-12 col-md-2">
                  <button type="submit" className="btn btn-primary col-12">
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
          {isSuperUser && (
            <>
              <hr></hr>
              <h5>Change Password</h5>
              <Formik
                initialValues={{
                  password: "",
                  confirmedPassword: "",
                }}
                validationSchema={Yup.object({
                  password: Yup.string()
                    .min(8, "Must be 8 characters or more")
                    .max(45, "Must be 45 characters or less")
                    .required("Required"),
                  confirmedPassword: Yup.string()
                    .required("Required")
                    .oneOf([Yup.ref("password"), null], "Passwords must match"),
                })}
                onSubmit={async (
                  { password, confirmedPassword },
                  { resetForm }
                ) => {
                  updatePassword({
                    password,
                    confirmedPassword,
                    id: user.id,
                  });
                  resetForm();
                }}
              >
                <Form className="needs-validation" noValidate>
                  <div className="row">
                    <PasswordInput
                      placeholder="Password"
                      name="password"
                      size="col-sm col-md-5"
                    />
                    <PasswordInput
                      placeholder="Confirm Password"
                      name="confirmedPassword"
                      size="col-sm col-md-5"
                    />
                    <div className="col-sm-12 col-md-2">
                      <button type="submit" className="btn btn-primary col-12">
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </>
          )}
          {userRoleMarkup}
        </div>
      )}
      {isSuperUser && (
        <>
          <hr></hr>
          <div className="row">
            <div className="col-sm-12 col-md-2 mt-3">
              <button
                type="submit"
                className={buttonClasses}
                data-toggle="collapse"
                href="#confirm-delete"
                onClick={() => setIsDeleting(!isDeleting)}
              >
                {isDeleting ? "Cancel" : "Delete"}
              </button>
            </div>
          </div>
          <div
            className="row collapse justify-content-between"
            id="confirm-delete"
          >
            <div className="col-sm-12 col-md-5 mb-2">
              <h6>Please confirm deletion of this account.</h6>
            </div>
            <div className="col-sm-12 col-md-2">
              <button
                type="submit"
                className="btn btn-danger col-12"
                onClick={() => CONFIRM_DELETE(removeUser)}
              >
                Confirm Delete
              </button>
            </div>
          </div>
        </>
      )}
      <hr></hr>
      <ParticipantsCard participants={participants} userId={userId} />
      <CheckoutInfoCard checkoutInfo={checkoutInfo} />
      <TicketsCard userId={userId} />
      <TransactionsCard userId={userId} checkoutInfo={checkoutInfo} />
    </div>
  );
}

export default User;

import React, { useEffect, useState } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import Table from "components/admin/Table";
import Select from "components/Modal/ModalFields/Select";
import useUsers from "hooks/users/useUsers";

function UserManager() {
  const { path } = useRouteMatch();
  const [redirect, setRedirect] = useState(null);
  const defaultHeadingData = {
    id: "ID",
    email: "Email",
    amountOwed: "Total Owed",
    amountPaid: "Paid",
    amountDue: "Amount Due",
    hasPaid: "Has Paid",
  };
  const defaultSearchableColumns = ["email", "id", "hasPaid"];
  const defaultDefaultSort = "hasPaid";
  const [filter, setFilter] = useState("all");
  const [headingData, setHeadingData] = useState(defaultHeadingData);
  const [searchableColumns, setSearchableColumns] = useState(
    defaultSearchableColumns
  );
  const { data: users } = useUsers(filter);
  const [defaultSort, setDefaultSort] = useState(defaultDefaultSort);
  const options = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Payment Status",
      value: "payment",
    },
    {
      label: "Missing Video Submissions",
      value: "videoSubmissions",
    },
  ];

  useEffect(() => {
    if (filter === "payment") {
      setHeadingData(defaultHeadingData);
      setSearchableColumns(defaultSearchableColumns);
      setDefaultSort(defaultDefaultSort);
    } else if (filter === "videoSubmissions") {
      setHeadingData({
        id: "ID",
        email: "Email",
        participantName: "Athlete Name",
        competitionEventId: "Competition Event ID",
        eventName: "Event Name",
        code: "Event Code",
      });
      setSearchableColumns([
        "email",
        "id",
        "participantName",
        "eventName",
        "code",
      ]);
    } else {
      setHeadingData({
        email: "Email",
        role: "Role",
      });
      setSearchableColumns(["email", "role"]);
      setDefaultSort("role");
    }
  }, [filter]);

  function itemClick(user) {
    const { id } = user;
    const userPage = `${path}/${id}`;
    setRedirect(userPage);
  }

  const tableData = {
    itemName: "User",
    headingData,
    items: users || [],
    searchableColumns,
    defaultSort,
  };

  return redirect ? (
    <Redirect push to={redirect} />
  ) : (
    <div className="UserManager">
      <h2 className="text-left">User Manager</h2>
      <Select
        displayName="Filter Users"
        items={options}
        name="user-filter"
        onChange={({ value }) => setFilter(value)}
      />
      <Table data={tableData} itemClick={itemClick} pageTotal={100} />
    </div>
  );
}

export default UserManager;

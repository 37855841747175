import React, { useState } from "react";
import { useQueryCache } from "react-query";
import Modal from "components/Modal";
import SubmitButton from "components/SubmitButton";
import { EVENT_PARTICIPATION } from "helpers/constants";
import useLocalize from "hooks/useLocalize";
import DrawLotModalContent from "./DrawLotModalContent";
import LotStick from "./LotStick";
import "./DrawLotForm.scss";

function DrawLotForm({ events, eventParticipationMap, showFinalDraw }) {
  const [showModal, setShowModal] = useState(false);
  const localize = useLocalize();
  const [competitionEventId, setCompetitionEventId] = useState();
  const lotOrder = eventParticipationMap?.[competitionEventId]?.lotOrder;
  const queryCache = useQueryCache();
  let defaultEventOption;

  function selectCompetitionEvent({ target: { value } }) {
    setCompetitionEventId(value);
  }

  const eventOptions = events.map(({ id, code, name }) => {
    const ep = eventParticipationMap?.[id];
    const registered = ep || false;

    if (!defaultEventOption && !competitionEventId && registered) {
      defaultEventOption = true;
      setCompetitionEventId(id);
    }

    return registered ? (
      <option value={id} key={id}>
        {`${name} (${code})`}
      </option>
    ) : null;
  });

  return (
    <div className="DrawLotForm">
      {!lotOrder && showModal && (
        <Modal
          close={() => {
            queryCache.invalidateQueries(EVENT_PARTICIPATION);
            setShowModal(false);
          }}
        >
          <DrawLotModalContent
            competitionEventId={competitionEventId}
            eventParticipationId={
              // TODO: fix this in the backend, it should not return 2 different types
              eventParticipationMap?.[competitionEventId]
                ?.eventParticipationId ||
              eventParticipationMap?.[competitionEventId]
            }
            showFinalDraw={showFinalDraw}
          />
        </Modal>
      )}
      <div className="form-group row">
        <label className="form-label col-4">
          <h5>{localize("Select Event")}</h5>
        </label>
        <div className="col-8">
          <select className="form-control" onChange={selectCompetitionEvent}>
            {eventOptions}
          </select>
        </div>
      </div>
      {!lotOrder && (
        <div className="form-group row">
          <label className="form-label col-4">
            <h5>{localize("Draw Lot")}</h5>
          </label>
          <div className="col-8">
            <SubmitButton
              text={localize("Draw Lot")}
              onClick={() => setShowModal(true)}
            />
          </div>
        </div>
      )}
      {lotOrder && (
        <div className="form-group row">
          <label className="form-label col-4">
            <h5>{localize("Your Lot Order")}</h5>
          </label>
          <div className="col-8 d-flex justify-content-between">
            <LotStick num={lotOrder} />
          </div>
        </div>
      )}
    </div>
  );
}

export default DrawLotForm;

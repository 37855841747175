import { useQuery } from "react-query";
import { ScheduleAPI } from "api/schedule";
import { SCHEDULE } from "helpers/constants";

export default function useScheduleForParticipant(participantId) {
  return useQuery(
    [SCHEDULE, participantId],
    () => ScheduleAPI.getScheduleForParticipant(participantId),
    {
      enabled: !!participantId,
    }
  );
}

import React from "react";
import Table from "components/admin/Table";
import CONFIRM_DELETE from "helpers/confirmDelete";
import useEventFeedbackDuplicates from "hooks/eventFeedback/useEventFeedbackDuplicates";
import useRemoveEventFeedback from "hooks/eventFeedback/useRemoveEventFeedback";

function DuplicateScoresReport({ competitionId }) {
  const { data: duplicates, isLoading } = useEventFeedbackDuplicates({
    competitionId,
  });
  const [removeEventFeedback] = useRemoveEventFeedback();
  const tableData = {
    headingData: {
      ringName: "Ring",
      eventName: "Event",
      gender: "Gender",
      ageGroupName: "Age",
      participantName: "Name",
      judgeEmail: "Judge",
    },
    items: duplicates || [],
    childTableData: {
      defaultSort: "id",
      headingData: {
        id: "ID",
        judgeId: "Judge ID",
        score: "Score",
      },
      itemsKey: "feedbacks",
      itemClick,
    },
  };

  function itemClick({ id }) {
    CONFIRM_DELETE(() => {
      removeEventFeedback({ id });
    });
  }

  return (
    <div className="DuplicateScoresReport">
      <h3>Duplicate Scores Report</h3>
      {isLoading ? (
        <div className="spinner-border mt-2" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <Table data={tableData} />
      )}
    </div>
  );
}

export default DuplicateScoresReport;

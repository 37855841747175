export const ADMIN = "ADMIN";
export const CHAMPIONSHIPS = "CHAMPIONSHIPS";
export const CHAMPIONSHIP_AGE_GROUPS = "CHAMPIONSHIP_AGE_GROUPS";
export const CHAMPIONSHIP_GENDERS = "CHAMPIONSHIP_GENDERS";
export const CHAMPIONSHIP_EVENT_CATEGORY_REQUIREMENTS =
  "CHAMPIONSHIP_EVENT_CATEGORY_REQUIREMENTS";
export const CHAMPIONSHIP_PARTICIPATION = "CHAMPIONSHIP_PARTICIPATION";
export const CHECK_IN = "CHECK_IN";
export const CHECKOUT = "CHECKOUT";
export const COMPETITIONS = "COMPETITIONS";
export const COMPETITION_EVENT = "COMPETITION_EVENT";
export const COMPETITION_EVENTS = "COMPETITION_EVENTS";
export const COMPETITORS = "COMPETITIONS";
export const CONNECTION = "CONNECTION";
export const CURRENT_TOURNAMENT = "CURRENT_TOURNAMENT";
export const ERROR_MESSAGES = "ERROR_MESSAGES";
export const EVENTS = "EVENTS";
export const EVENT_CATEGORIES = "EVENT_CATEGORIES";
export const EVENT_FEEDBACK = "EVENT_FEEDBACK";
export const EVENT_PARTICIPATION = "EVENT_PARTICIPATION";
export const GENDERS = "GENDERS";
export const JUDGE = "JUDGE";
export const JUDGES = "JUDGES";
export const MASTER_SHEET = "MASTER_SHEET";
export const NANDU = "NANDU";
export const NO_RING = "NO_RING";
export const PARTICIPANT = "PARTICIPANT";
export const PARTICIPANTS = "PARTICIPANTS";
export const RING = "RING";
export const RINGS = "RINGS";
export const SCHEDULE = "SCHEDULE";
export const SUPERUSER = "SUPERUSER";
export const TEAMS = "TEAMS";
export const TEAM_PARTICIPANTS = "TEAM_PARTICIPANTS";
export const TEAM_MEDALS = "TEAM_MEDALS";
export const TOURNAMENT = "TOURNAMENT";
export const TOURNAMENTS = "TOURNAMENTS";
export const TOURNAMENT_TICKET_PURCHASES = "TOURNAMENT_TICKET_PURCHASES";
export const TRANSACTIONS = "TRANSACTIONS";
export const USERS = "USERS";
export const USER_TICKET_PURCHASES = "USER_TICKET_PURCHASES";
export const VIDEO_SUBMISSIONS = "VIDEO_SUBMISSIONS";
export const WEBSITE_MESSAGES = "WEBSITE_MESSAGES";
export const WUSHU_STYLES = "WUSHU_STYLES";

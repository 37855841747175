import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthSelectors } from 'redux/auth';
import MessageCenter from './components/MessageCenter';
import Router from './components/Router';
import './Home.scss';

function Home() {
  const isAuthenticated = useSelector(AuthSelectors.getAuthenticationStatus);

  if (!isAuthenticated) {
    return <Redirect to="/login" noThrow />;
  }

  return (
    <div className="Home">
      <div className="Home-content">
        <MessageCenter />
        <Router />
      </div>
    </div>
  );
}

export default Home;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardCollapse from "components/CardCollapse";
import {
  CollapseGroupActions,
  CollapseGroupSelectors,
} from "redux/collapseGroup";

// function HidableCollapse(props) {
//   const [show, setShow] = useState(true);
//   const { children, onHide, ...rest } = props;

//   return show ? (
//     <CardCollapse {...rest}>
//       {children}
//       <div className="d-flex justify-content-start mb-2">
//         <button
//           className="btn btn-primary float-right no-print"
//           onClick={() => {
//             onHide();
//             setShow(false);
//           }}
//         >
//           Hide Group
//         </button>
//       </div>
//     </CardCollapse>
//   ) : null;
// }

function CollapseGroup({ id, items }) {
  const [showButton, setShowButton] = useState(false);
  const [collapses, setCollapses] = useState([]);
  const collapseGroupState = useSelector(
    CollapseGroupSelectors.getCollapseGroups
  )?.[id];
  const dispatch = useDispatch();

  useEffect(() => {
    if (items) {
      const arr = [];

      items.forEach(({ content, id: itemId, label }) => {
        const itemState = collapseGroupState?.[itemId];

        if (!itemState?.h) {
          const show =
            itemState?.e !== undefined
              ? itemState.e
              : collapseGroupState?.EXPAND_ALL;

          arr.push(
            // <HidableCollapse
            //   key={itemId}
            //   id={itemId}
            //   title={label}
            //   show={show}
            //   onClickHeader={(isExpanding) => {
            //     if (isExpanding) {
            //       dispatch(CollapseGroupActions.expandCollapse(id, itemId));
            //     } else {
            //       dispatch(CollapseGroupActions.collapseCollapse(id, itemId));
            //     }
            //   }}
            //   onHide={() => {
            //     dispatch(CollapseGroupActions.hideCollapse(id, itemId));
            //     setShowButton(true);
            //   }}
            // >
            //   {content}
            // </HidableCollapse>
            <CardCollapse
              key={itemId}
              id={itemId}
              title={label}
              show={show}
              onClickHeader={(isExpanding) => {
                if (isExpanding) {
                  dispatch(CollapseGroupActions.expandCollapse(id, itemId));
                } else {
                  dispatch(CollapseGroupActions.collapseCollapse(id, itemId));
                }
              }}
            >
              {content}
            </CardCollapse>
          );
        } else {
          setShowButton(true);
        }
      });

      setCollapses(arr);
    }
  }, [items, collapseGroupState]);

  return (
    <div className="CollapseGroup">
      <div className="d-flex justify-content-around mb-2">
        {showButton && (
          <button
            className="btn btn-primary no-print"
            onClick={() => {
              dispatch(CollapseGroupActions.showAll(id));
              setShowButton(false);
            }}
          >
            Unhide All
          </button>
        )}
        <button
          className="btn btn-primary no-print"
          onClick={() => {
            dispatch(CollapseGroupActions.collapseAll(id));
          }}
        >
          Collapse All
        </button>
        <button
          className="btn btn-primary no-print"
          onClick={() => {
            dispatch(CollapseGroupActions.expandAll(id));
          }}
        >
          Expand All
        </button>
      </div>
      {collapses}
    </div>
  );
}

export default CollapseGroup;

import { GET, POST, DELETE, PUT } from '../config';

const WushuStyleAPI = {
  async getAll() {
    const { wushuStyles } = await GET('wushuStyles');

    return wushuStyles;
  },
  add(wushuStyleInput) {
    return POST('wushuStyles', { wushuStyleInput });
  },
  remove(id) {
    return DELETE(`wushuStyles/${id}`);
  },
  update({ id, wushuStyleInput }) {
    return PUT(`wushuStyles/${id}`, { wushuStyleInput });
  },
};

export { WushuStyleAPI };

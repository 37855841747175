import React, { useEffect, useRef } from "react";
import "./BackToTop.scss";

function BackToTop() {
  const ref = useRef();

  function scrollTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    function scrollFunction() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        ref.current.style.display = "block";
      } else {
        ref.current.style.display = "none";
      }
    }

    window.addEventListener("scroll", scrollFunction);

    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  }, [ref]);

  return (
    <button
      className="BackToTop no-print btn-warning"
      ref={ref}
      onClick={scrollTop}
    >
      Top
    </button>
  );
}

export default BackToTop;

import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toHTMLDate } from "helpers";
import { useHistory, useParams } from "react-router-dom";
import defaultImage from "assets/default.gif";
import ModalTable from "components/admin/ModalTable";
import CardCollapse from "components/CardCollapse";
import Date from "components/Date";
import EventsPane from "components/EventsPane";
import ImageInput from "components/ImageInput";
import StandaloneSelect from "components/Modal/ModalFields/Select";
import Select from "components/Select";
import TextInput from "components/TextInput";
import VideoSubmissionForm from "components/VideoSubmissionForm";
import CONFIRM_DELETE from "helpers/confirmDelete";
import getCountries from "helpers/countries";
import dispatchToast from "helpers/dispatchToast";
import useParticipantEventParticipation from "hooks/eventParticipation/useParticipantEventParticipation";
import useParticipant from "hooks/participants/useParticipant";
import useRemoveEventParticipation from "hooks/eventParticipation/useRemoveEventParticipation";
import useRemoveParticipant from "hooks/participants/useRemoveParticipant";
import useUpdateParticipant from "hooks/participants/useUpdateParticipant";
import useRecoverParticipant from "hooks/participants/useRecoverParticipant";
import useTeams from "hooks/teams/useTeams";
import useCurrentTournament from "hooks/tournament/useCurrentTournament";
import useUpdateVideoSubmissionDetails from "hooks/videoSubmissions/useUpdateVideoSubmissionDetails";
import ParticipantSchedule from "scenes/Schedule/components/ParticipantSchedule";
import "./ParticipantView.scss";

function ParticipantView() {
  const history = useHistory();
  const { id } = useParams();
  const [competition, setCompetition] = useState();
  const [competitionEventId, setCompetitionEventId] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [hasShownBirthdateError, setHasShownBirthdateError] = useState(false);
  const { data: participant } = useParticipant(id);
  const { data: teams } = useTeams();
  const { data: eventParticipation } = useParticipantEventParticipation(id);
  const { data: tournament } = useCurrentTournament();
  const [removeParticipant] = useRemoveParticipant();
  const [updateParticipant] = useUpdateParticipant();
  const [recoverParticipant] = useRecoverParticipant();
  const [updateVideoSubmissionDetails] = useUpdateVideoSubmissionDetails();
  const [removeEventParticipation] = useRemoveEventParticipation();
  const countries = getCountries();
  const countryOptions = countries.map((country, i) => (
    <option key={i} value={country}>
      {country}
    </option>
  ));
  const teamOptions = teams
    ? teams.map(({ name, id }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))
    : [];
  const defaultLabel = "Choose athlete image";
  const [label, setLabel] = useState(defaultLabel);
  const imageSource = participant?.image
    ? `/public/images/${participant.image}`
    : defaultImage;
  const competitions = tournament
    ? tournament.competitions.map(({ id, name }) => ({
        label: name,
        value: id,
      }))
    : [];
  let registeredEvents = [];
  let isCompetitorInSelectedCompetition = false;

  if (
    competition &&
    eventParticipation &&
    eventParticipation[competition?.value]
  ) {
    isCompetitorInSelectedCompetition = true;
    registeredEvents = Object.values(eventParticipation[competition.value]);
  }

  const tableData = {
    itemName: "Event",
    headingData: {
      id: "Competition Event ID",
      name: "Name",
      gender: "Gender",
      ageRange: "Age Range",
      rank: "Rank",
      score: "Score",
      award: "Award",
      videoUrl: "Video URL",
    },
    items: registeredEvents,
    modalData: {
      fields: [
        {
          defaultValue: competitionEventId,
          displayName: "Competition Event ID",
          name: "id",
          onChange: (value) => setCompetitionEventId(value),
          type: "text",
        },
        {
          defaultValue: videoUrl,
          displayName: "Video URL",
          name: "videoUrl",
          onChange: (value) => setVideoUrl(value),
          type: "text",
        },
      ],
    },
    searchableColumns: ["name", "gender", "ageRange", "videoUrl"],
    defaultSort: "name",
  };
  const hasMultipleCompetitions =
    !!competitions.length && competitions.length > 1;

  function goToUser() {
    history.push(`/adm/home/users/${participant.userId}`);
  }

  function competitionSelect(value) {
    setCompetition(value);
  }

  useEffect(() => {
    setCompetition(competitions[0]);
  }, [competitions.length]);

  if (participant?.birthDate === "0000-00-00" && !hasShownBirthdateError) {
    setHasShownBirthdateError(true);
    dispatchToast({
      message: `Invalid birth date for ${participant.firstName} ${participant.lastName}. Please contact your administrator.`,
      type: "error",
    });
  }

  return participant ? (
    <div className="ParticipantView">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="mb-3">{participant.name}</h2>
        <button className="btn btn-primary" onClick={goToUser}>
          Go to user
        </button>
      </div>

      <CardCollapse id={`info-${id}`} title="Athlete Info">
        <div className="row">
          <div className="col">
            <Formik
              enableReinitialize
              initialValues={{
                firstName: participant.firstName,
                lastName: participant.lastName,
                gender: participant.gender,
                birthDate: toHTMLDate(participant.birthDate),
                country: participant.country,
                address: participant.address,
                address2: participant.address2,
                city: participant.city,
                state: participant.state,
                zip: participant.zip,
                phone: participant.phone,
                mobilePhone: participant.mobilePhone,
                usawkfId: participant.usawkfId,
                // image,
                teamId: participant.teamId || "",
                email: participant.email,
              }}
              validationSchema={Yup.object({
                firstName: Yup.string()
                  .max(45, "Must be 45 characters or less")
                  .required("Required"),
                lastName: Yup.string()
                  .max(45, "Must be 45 characters or less")
                  .required("Required"),
                gender: Yup.string()
                  .oneOf(["male", "female"], "Please select a gender.")
                  .required("Required"),
                birthDate: Yup.date().required("Required"),
                country: Yup.string()
                  .oneOf(countries, "Please select a country.")
                  .required("Required"),
                address: Yup.string().max(
                  100,
                  "Must be 100 characters or less"
                ),
                address2: Yup.string().max(
                  100,
                  "Must be 100 characters or less"
                ),
                city: Yup.string().max(45, "Must be 45 characters or less"),
                state: Yup.string().max(45, "Must be 45 characters or less"),
                zip: Yup.string().max(10, "Must be 10 characters or less"),
                phone: Yup.string().max(45, "Must be 45 characters or less"),
                mobilePhone: Yup.string().max(
                  45,
                  "Must be 45 characters or less"
                ),
                usawkfId: Yup.string().max(45, "Must be 45 characters or less"),
                teamId: Yup.string(),
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(45, "Must be 45 characters or less"),
              })}
              onSubmit={async (values) => {
                values.id = id;
                updateParticipant({
                  participantInput: values,
                  onSuccess: () => {
                    setLabel(defaultLabel);
                  },
                });
              }}
            >
              {({ setFieldValue }) => (
                <Form className="needs-validation" noValidate>
                  <div className="row">
                    <div className="col-md-2">
                      <img
                        src={imageSource}
                        alt="default-user"
                        className="card-img"
                      ></img>
                    </div>
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-6">
                          <ImageInput
                            label={label}
                            footnote="For best results, please use .png or .jpg files."
                            processFile={(image) => {
                              setLabel(image?.name);
                              setFieldValue("image", image);
                            }}
                            name="image"
                            uid={id}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <TextInput label="First Name*" name="firstName" />
                        <TextInput label="Last Name*" name="lastName" />
                      </div>
                      <div className="row">
                        <Select label="Gender*" name="gender">
                          <option value="">Select a gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </Select>
                        <Date
                          label={`Birthdate* (Age: ${participant.age})`}
                          name="birthDate"
                        />
                        <Select label="Country*" name="country">
                          <option value="">Select a country</option>
                          {countryOptions}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <TextInput
                      label="Address"
                      size="col-sm-12 col-lg-9"
                      name="address"
                    />
                    <TextInput label="Address 2" name="address2" />
                  </div>
                  <div className="row">
                    <TextInput label="City" name="city" />
                    <TextInput label="State" name="state" />
                    <TextInput label="Zip" name="zip" />
                  </div>
                  <div className="row">
                    <TextInput label="Phone" name="phone" />
                    <TextInput label="Mobile" name="mobilePhone" />
                    <TextInput label="Email" name="email" />
                    <TextInput label="USAWKF ID" name="usawkfId" />
                  </div>
                  <div className="row">
                    <Select label="Team" name="teamId">
                      <option value="">Select a team</option>
                      {teamOptions}
                    </Select>
                  </div>
                  <button type="submit" className="btn btn-success float-right">
                    Update
                  </button>
                </Form>
              )}
            </Formik>
            <button
              className="btn btn-danger float-left"
              onClick={() =>
                CONFIRM_DELETE(() => {
                  removeParticipant({
                    id,
                    onSuccess: () => history.goBack(),
                    handleUndoDelete: (id) => {
                      recoverParticipant(id);
                      history.go(0);
                    },
                  });
                })
              }
            >
              Delete Athlete
            </button>
          </div>
        </div>
      </CardCollapse>
      <CardCollapse id={`events-${id}`} title="Event Info">
        <EventsPane
          adminEventParticipation={eventParticipation}
          competitions={tournament?.competitions}
          participant={participant}
        />
      </CardCollapse>
      <CardCollapse id={`videos-${id}`} title="Video Submissions">
        {hasMultipleCompetitions && (
          <>
            <h5>Select Competition</h5>
            <StandaloneSelect
              items={competitions}
              onChange={competitionSelect}
              defaultValue={competition}
            />
            <hr />
          </>
        )}
        {isCompetitorInSelectedCompetition && (
          <>
            <VideoSubmissionForm
              competitionId={competition?.value}
              events={registeredEvents}
              eventParticipation={eventParticipation}
              participantId={id}
            />
            <hr />
          </>
        )}
        <h5>Current Events</h5>
        <ModalTable
          data={tableData}
          setIsEditingItem={({ id, videoUrl }) => {
            setCompetitionEventId(id);
            setVideoUrl(videoUrl);
          }}
          onClose={() => {
            setCompetitionEventId("");
            setVideoUrl("");
          }}
          editItem={({
            eventParticipationId,
            competitionEventId: oldCompetitionEventId,
            videoUrl: oldVideoUrl,
          }) => {
            if (!competitionEventId && !videoUrl) {
              dispatchToast({
                message:
                  "Please enter a new competition event ID or a new video URL.",
                type: "error",
              });
            } else {
              updateVideoSubmissionDetails({
                id: eventParticipationId,
                competitionEventId: competitionEventId || oldCompetitionEventId,
                videoUrl: videoUrl || oldVideoUrl,
              });
            }
          }}
          removeItem={({
            participantId,
            competitionId,
            competitionEventId,
          }) => {
            removeEventParticipation({
              participantId,
              competitionId,
              competitionEventId,
            });
          }}
        />
      </CardCollapse>
      <CardCollapse id={`schedule-${id}`} title="Event Schedule">
        <ParticipantSchedule participantId={participant.id} />
      </CardCollapse>
    </div>
  ) : null;
}

export default ParticipantView;

import React from 'react';

function TableHeading({ text, up, showArrow, onClick }) {
  const arrow = <span className={up ? "ion-arrow-up-b" : "ion-arrow-down-b"}></span>;
  return (
    <th scope="col" onClick={onClick}>
      { text } { showArrow && arrow }
    </th>
  );
}

export default TableHeading;
import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { ParticipantsAPI } from "api/participant";
import Select from "components/Select";
import TextInput from "components/TextInput";
import CardCollapse from "components/CardCollapse";
import CONFIRM_DELETE from "helpers/confirmDelete";
import getCountries from "helpers/countries";
import useTeam from "hooks/teams/useTeam";
import useTeamParticipants from "hooks/teams/useTeamParticipants";
import useRecoverTeam from "hooks/teams/useRecoverTeam";
import useRemoveTeam from "hooks/teams/useRemoveTeam";
import useRemoveParticipantsFromTeam from "hooks/participants/useRemoveParticipantsFromTeam";
import useUpdateParticipantTeam from "hooks/participants/useUpdateParticipantTeam";
import useUpdateTeam from "hooks/teams/useUpdateTeam";
import ModalTable from "components/admin/ModalTable";

function Team() {
  const { id } = useParams();
  const history = useHistory();
  const { data: team } = useTeam(id);
  const { data: teamParticipants } = useTeamParticipants(id);
  const [removeParticipantsFromTeam] = useRemoveParticipantsFromTeam();
  const [updateParticipantTeam] = useUpdateParticipantTeam();
  const [updateTeam] = useUpdateTeam();
  const [recoverTeam] = useRecoverTeam();
  const [removeTeam] = useRemoveTeam();
  const [participantId, setParticipantId] = useState();
  const countries = getCountries();
  const countryOptions = countries.map((country, i) => (
    <option key={i} value={country}>
      {country}
    </option>
  ));
  const tableData = {
    headingData: { name: "Name" },
    items: teamParticipants || [],
    itemName: "Athlete",
    modalData: {
      fields: [
        {
          displayName: "Athlete",
          name: "participantId",
          onChange: ({ value }) => setParticipantId(value),
          fetchItems: async (inputValue) => {
            if (inputValue.length > 1) {
              const participantsByName =
                (await ParticipantsAPI.getAllByName(inputValue)) || [];
              const participantOptions = [];

              participantsByName.forEach(({ id, name, email }) => {
                const alreadyRegistered = teamParticipants?.find(
                  (p) => p.id === id
                );

                if (!alreadyRegistered) {
                  participantOptions.push({
                    label: `${name} (${email})`,
                    value: id,
                  });
                }
              });

              return participantOptions;
            }
          },
          type: "asyncSelect",
        },
      ],
    },
    searchableColumns: ["name"],
    defaultSort: "name",
  };

  function setIsAddingItem() {
    setParticipantId(null);
  }

  function addItem() {
    if (participantId) {
      updateParticipantTeam({ id: participantId, teamId: id });
    }
  }

  return team ? (
    <div className="Team">
      <CardCollapse id={`${id}-info`} title="Team Info" show>
        <div className="row">
          <div className="col">
            <Formik
              initialValues={{
                name: team.name,
                city: team.city,
                state: team.state,
                country: team.country,
                leader: team.leader,
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .max(150, "Must be 150 characters or less")
                  .required("Required"),
                city: Yup.string()
                  .max(100, "Must be 100 characters or less")
                  .required("Required"),
                state: Yup.string()
                  .max(100, "Must be 100 characters or less")
                  .required("Required"),
                country: Yup.string()
                  .oneOf(countries, "Please select a country.")
                  .required("Required"),
                leader: Yup.string()
                  .max(100, "Must be 100 characters or less")
                  .required("Required"),
              })}
              onSubmit={async (values) => {
                values.id = id;
                updateTeam(values);
              }}
            >
              <Form className="needs-validation" noValidate>
                <div className="row">
                  <TextInput label="Name*" name="name" />
                  <TextInput label="Leader*" name="leader" />
                </div>
                <div className="row">
                  <TextInput label="City" name="city" />
                  <TextInput label="State" name="state" />
                  <Select label="Country*" name="country">
                    <option value="">Select a country</option>
                    {countryOptions}
                  </Select>
                </div>
                <button type="submit" className="btn btn-success float-right">
                  Update
                </button>
              </Form>
            </Formik>
            <button
              className="btn btn-danger float-left"
              onClick={() =>
                CONFIRM_DELETE(() => {
                  removeTeam({
                    id,
                    onSuccess: () => history.goBack(),
                    handleUndoDelete: (id) => {
                      recoverTeam(id);
                      history.go(0);
                    },
                  });
                })
              }
            >
              Delete Team
            </button>
          </div>
        </div>
      </CardCollapse>
      <CardCollapse id={`${id}-athletes`} title="Athletes" show>
        <ModalTable
          data={tableData}
          setIsAddingItem={setIsAddingItem}
          addItem={addItem}
          itemClick={({ id }) => {
            const participantPage = `/adm/home/participant/${id}`;

            history.push(participantPage);
          }}
          isSelectable={{ columnCheckbox: true }}
          onDeleteSelected={(participants) => {
            const ids = participants.map(({ id }) => id);

            removeParticipantsFromTeam({ ids });
          }}
        ></ModalTable>
      </CardCollapse>
    </div>
  ) : null;
}

export default Team;

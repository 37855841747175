import { useMutation, useQueryCache } from "react-query";
import { ChampionshipAPI } from "api/admin/championship";
import { CHAMPIONSHIPS } from "helpers/constants";
import dispatchToast from "helpers/dispatchToast";

export default function useRemoveChampionship() {
  const queryCache = useQueryCache();

  return useMutation(ChampionshipAPI.remove, {
    onSuccess: ({ message }) => {
      dispatchToast({ message, type: "success" });
      queryCache.invalidateQueries(CHAMPIONSHIPS);
    },
  });
}

import React from "react";
import Table from "components/admin/Table";
import useErrorMessages from "hooks/errorMessages/useErrorMessages";
import useRemoveErrorMessages from "hooks/errorMessages/useRemoveErrorMessages";

function ErrorMessageManager() {
  const { data: errorMessages } = useErrorMessages();
  const [removeErrorMessages] = useRemoveErrorMessages();

  function removeItems(errorMessages) {
    const ids = errorMessages.map(({ id }) => id);

    removeErrorMessages(ids);
  }

  const tableData = {
    searchableColumns: ["message"],
    headingData: {
      message: "Message",
    },
    items: errorMessages || [],
    itemName: "Error Message",
  };

  return (
    <div className="ErrorMessageManager">
      <h2 className="text-left">Error Message Manager</h2>
      <Table
        data={tableData}
        isSelectable={{ columnCheckbox: true }}
        onDeleteSelected={removeItems}
      />
    </div>
  );
}

export default ErrorMessageManager;

import React, { useState } from "react";
import useEventParticipationForJudge from "hooks/eventParticipation/useEventParticipationForJudge";
import BackToTop from "components/BackToTop";
import RingSelect from "components/RingSelect";
import useRingJudges from "hooks/judges/useRingJudges";
import useRing from "hooks/rings/useRing";
import InPersonJudging from "scenes/Judging/components/InPersonJudging";
import OnlineJudging from "../../components/OnlineJudging";
import "./AdminJudgingHome.scss";

function AdminJudgingHome() {
  const [ringAndCompetitionIds, setRingAndCompetitionIds] = useState();
  const { data: judges } = useRingJudges(ringAndCompetitionIds?.ringId);
  const [judge] = judges?.filter(({ isHeadJudge }) => isHeadJudge) || [];
  const { data: eventParticipation } = useEventParticipationForJudge(
    judge?.userId
  );
  const ringId = ringAndCompetitionIds?.ringId;
  const competitionId = ringAndCompetitionIds?.competitionId;
  const epCompetition = eventParticipation?.[competitionId];
  const epRing = epCompetition?.[ringId];
  const isVirtual = epCompetition?.isVirtual;
  const judgeId = epRing?.judgeId;
  const ep = epRing?.categories;
  const { data: ring } = useRing(ringId);
  const judgingContent = judge ? (
    isVirtual ? (
      <OnlineJudging eventParticipation={ep} judgeId={judgeId} />
    ) : (
      <InPersonJudging
        ring={ring}
        eventParticipation={epRing?.ep}
        judgeId={judgeId}
      />
    )
  ) : null;

  return (
    <div className="AdminJudgingHome">
      <div className="content">
        <RingSelect
          id={"AdminJudgingHome"}
          onChange={(value) => setRingAndCompetitionIds(value)}
        />
        {judgingContent}
        <BackToTop />
      </div>
    </div>
  );
}

export default AdminJudgingHome;

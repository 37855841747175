import { GET, POST, DELETE } from "api/config";

const ErrorMessageAPI = {
  async getAll() {
    const { errorMessages } = await GET(`errorMessages`);

    return errorMessages;
  },
  async add(message) {
    return await POST(`errorMessages`, { message });
  },
  async remove(id) {
    return await DELETE(`errorMessages/${id}`);
  },
  async removeMultiple(ids) {
    return await DELETE(`errorMessages`, { ids });
  },
};

export { ErrorMessageAPI };

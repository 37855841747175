import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Table from "components/admin/Table";
import Select from "components/Modal/ModalFields/Select";
import useCompetitor from "hooks/competitors/useCompetitors";
import useCurrentTournament from "hooks/tournament/useCurrentTournament";
import "./AthleteManager.scss";

function AthleteManager() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { data: tournament } = useCurrentTournament();
  const [competition, setCompetition] = useState();
  const competitions =
    tournament?.competitions.map(({ id, name }) => ({
      label: name,
      value: id,
    })) || [];
  const { data: competitors } = useCompetitor(competition?.value);

  function competitionSelect(value) {
    setCompetition(value);
  }

  function itemClick({ id }) {
    history.push(`${path}/participant/${id}`);
  }

  const tableData = {
    itemName: "Athlete",
    headingData: {
      name: "Name",
      gender: "Gender",
      age: "Age",
      hasPaid: "Paid",
      email: "Email",
      usawkfId: "USAWKF ID",
      teamName: "Team Name",
    },
    items: competitors || [],
    searchableColumns: ["name", "email", "teamName", "age", "usawkfId"],
    defaultSort: "name",
  };

  useEffect(() => {
    setCompetition(competitions[0]);
  }, [competitions.length]);

  return (
    <div className="AthleteManager">
      <h2 className="text-left">
        Athletes {!!competitors && `(Total: ${competitors.length})`}
      </h2>
      <hr></hr>
      <h5>Select Competition</h5>
      {!!competitions?.length && (
        <Select
          items={competitions}
          onChange={competitionSelect}
          defaultValue={competition}
        />
      )}
      <Table data={tableData} itemClick={itemClick} pageTotal={100} />
    </div>
  );
}

export default AthleteManager;

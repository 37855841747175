import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import NumberInput from "components/NumberInput";
import Select from "components/Select";
import useEventCategories from "hooks/eventCategories/useEventCategories";
import useCreateEventCategoryRestriction from "hooks/eventCategoryRestrictions/useCreateEventCategoryRestriction";
import useRemoveEventCategoryRestriction from "hooks/eventCategoryRestrictions/useRemoveEventCategoryRestriction";
import useUpdateEventCategoryRestriction from "hooks/eventCategoryRestrictions/useUpdateEventCategoryRestriction";
import EventCategoryRestrictionItem from "./EventCategoryRestrictionItem";

function EventCategoryRestrictions({ restrictions = [], competitionId }) {
  const [isAdding, setIsAdding] = useState();
  const { data: eventCategories } = useEventCategories();
  const [createEventCategoryRestriction] = useCreateEventCategoryRestriction();
  const [removeEventCategoryRestriction] = useRemoveEventCategoryRestriction();
  const [updateEventCategoryRestriction] = useUpdateEventCategoryRestriction();
  const eventCategoryOptions = [];

  if (eventCategories) {
    eventCategories.forEach(({ id, name }) => {
      if (!restrictions.find((r) => r.eventCategoryId === id)) {
        eventCategoryOptions.push(
          <option key={id} value={id}>
            {name}
          </option>
        );
      }
    });
  }

  function cancelAddEventCategoryRestriction(e) {
    e.preventDefault();
    setIsAdding(false);
  }

  return (
    <div className="EventCategoryRestrictions">
      <ul className="list-group">
        {restrictions.map(({ id, max, eventCategoryName }, index) => (
          <EventCategoryRestrictionItem
            key={index}
            deleteItem={() => removeEventCategoryRestriction(id)}
            eventCategoryName={eventCategoryName}
            max={max}
            updateItem={(max) => updateEventCategoryRestriction({ id, max })}
          />
        ))}
      </ul>
      {eventCategoryOptions.length > 0 && (
        <>
          <hr></hr>
          <div className="row">
            <div className="col d-flex justify-content-between">
              {!isAdding && (
                <button
                  className="btn btn-primary"
                  onClick={() => setIsAdding(true)}
                >
                  Add New Restriction
                </button>
              )}
              {isAdding && (
                <button
                  className="btn btn-danger"
                  onClick={cancelAddEventCategoryRestriction}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
          {isAdding && (
            <>
              <hr></hr>
              <div className="row">
                <div className="col">
                  <Formik
                    initialValues={{
                      max: 1,
                      eventCategory: "",
                    }}
                    validationSchema={Yup.object({
                      max: Yup.number().required("Required"),
                      eventCategory: Yup.number().required("Required"),
                    })}
                    onSubmit={async ({ max, eventCategory }) => {
                      createEventCategoryRestriction({
                        competitionId,
                        eventCategoryId: eventCategory,
                        max,
                      });
                      setIsAdding(false);
                    }}
                  >
                    <Form className="needs-validation" noValidate>
                      <div className="row">
                        <NumberInput
                          label="Max*"
                          name="max"
                          min="1"
                          step="1"
                          size="col-sm-12 col-md-6 col-lg-4"
                        />
                        <Select label="Event Category*" name="eventCategory">
                          <option value="">Select a category</option>
                          {eventCategoryOptions}
                        </Select>
                      </div>
                      <button type="submit" className="btn btn-success">
                        Submit
                      </button>
                    </Form>
                  </Formik>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default EventCategoryRestrictions;

import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import NumberInput from "components/NumberInput";
import TextArea from "components/TextArea";
import useEventFeedback from "hooks/eventFeedback/useEventFeedback";
import useCreateEventFeedback from "hooks/eventFeedback/useCreateEventFeedback";
import useUpdateEventFeedback from "hooks/eventFeedback/useUpdateEventFeedback";
import useLocalize from "hooks/useLocalize";

function ScoreItem({ judgeId, eventParticipationId }) {
  const { data: eventFeedback } = useEventFeedback({
    judgeId,
    eventParticipationId,
  });
  const [createEventFeedback] = useCreateEventFeedback();
  const [updateEventFeedback] = useUpdateEventFeedback();
  const localize = useLocalize();

  return (
    <div className="ScoreItem">
      <Formik
        enableReinitialize
        initialValues={{
          comment: eventFeedback?.comment || "",
          score: eventFeedback?.score || "",
        }}
        validationSchema={Yup.object({
          comment: Yup.string(),
          score: Yup.number().min(0).max(10).required(),
        })}
        onSubmit={async (values) => {
          const id = eventFeedback?.id;
          const { score, comment } = values;

          if (id) {
            updateEventFeedback({
              id,
              score,
              comment,
            });
          } else {
            createEventFeedback({
              judgeId,
              eventParticipationId,
              score,
              comment,
            });
          }
        }}
      >
        <Form className="needs-validation" noValidate>
          <div className="row">
            <NumberInput
              label={`${localize("Score")}*`}
              name="score"
              min="0"
              max="10"
              step="0.05"
            />
            <TextArea label={`${localize("Notes")}`} name="comment" />
          </div>
          <button type="submit" className="btn btn-success float-right">
            {localize("Save")}
          </button>
        </Form>
      </Formik>
    </div>
  );
}

export default ScoreItem;

import { useMutation, useQueryCache } from 'react-query';
import { RingAPI } from 'api/admin/ring';
import { COMPETITIONS } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useRemoveRing() {
  const queryCache = useQueryCache();

  return useMutation(RingAPI.removeRing, {
    onSuccess: ({ message }) => {
      queryCache.invalidateQueries(COMPETITIONS);
      dispatchToast({ message, type: 'success' });
    },
  });
}

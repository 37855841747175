import React from 'react';
import classnames from 'classnames';
import './CardTab.scss';

function CardTab({ id, onClick, selected, text }) {
  const classNames = classnames('nav-link', { active: selected });

  return (
    <li className="CardTab nav-item" onClick={onClick}>
      <a
        className={classNames}
        data-toggle="tab"
        href={id}
      >
        { text }
      </a>
    </li>
  );
}

export default CardTab;

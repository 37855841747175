import React from 'react';

function NumberInput({
  name,
  disabled,
  displayName,
  defaultValue,
  min,
  onChange,
  step = '0.01',
}) {
  return (
    <div className="form-group">
      <label htmlFor={name}>{displayName}</label>
      <input
        disabled={disabled}
        type="number"
        id={name}
        step={step}
        className="form-control"
        value={defaultValue}
        onChange={({ target: { value } }) => onChange(value)}
        min={min}
      />
    </div>
  );
}

export default NumberInput;

import React from "react";
import { useHistory } from "react-router-dom";
import Table from "components/admin/Table";
import CardCollapse from "components/CardCollapse";
import useTournamentTicketPurchases from "hooks/ticketPurchases/useTournamentTicketPurchases";

export default ({ tournamentId }) => {
  const history = useHistory();
  const { data: ticketPurchases } = useTournamentTicketPurchases(tournamentId);
  const tableData = {
    defaultSort: "email",
    searchableColumns: ["email"],
    headingData: { email: "Email", number: "# of Tickets", claimed: "Claimed" },
    items: ticketPurchases || [],
    itemName: "Spectator Tickets",
  };
  const exportToCSVData = {
    options: {
      filename: "Ticket_Purchases",
    },
    data: ticketPurchases?.map(({ email, number, claimed }) => ({
      Email: email,
      Number: number,
      Claimed: claimed,
    })),
  };

  function itemClick({ userId }) {
    history.push(`/adm/home/users/${userId}`);
  }

  return (
    <CardCollapse id={"ticketManager"} title="Ticket Manager" show>
      <Table
        data={tableData}
        exportToCSVData={exportToCSVData}
        itemClick={itemClick}
      />
    </CardCollapse>
  );
};

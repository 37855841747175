import { useMutation, useQueryCache } from 'react-query';
import { RingAPI } from 'api/admin/ring';
import { COMPETITIONS } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useCreateRing() {
  const queryCache = useQueryCache();

  return useMutation(
    ({ competitionId, name }) => RingAPI.addRing(competitionId, { name }),
    {
      onSuccess: ({ message }) => {
        queryCache.invalidateQueries(COMPETITIONS);
        dispatchToast({ message, type: 'success' });
      },
    }
  );
}

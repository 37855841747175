import { DELETE, GET, POST, PUT } from "../config";

const EventFeedbackAPI = {
  async getOne({ judgeId, eventParticipationId }) {
    const { eventFeedback } = await GET(
      `eventFeedback/judge/${judgeId}/eventParticipation/${eventParticipationId}`
    );

    return eventFeedback;
  },
  async getAllForEventParticipant({ judgeId, eventParticipationId }) {
    const { eventFeedback } = await GET(
      `eventFeedback/all/judge/${judgeId}/eventParticipation/${eventParticipationId}`
    );

    return eventFeedback;
  },
  async getDuplicates(competitionId) {
    const { data } = await GET(`eventFeedback/duplicates/${competitionId}`);

    return data;
  },
  add({ judgeId, eventParticipationId, score, comment }) {
    return POST("eventFeedback", {
      judgeId,
      eventParticipationId,
      score,
      comment,
    });
  },
  update({ id, comment, score }) {
    return PUT(`eventFeedback/${id}`, { comment, score });
  },
  remove({ id }) {
    return DELETE(`eventFeedback/${id}`);
  },
};

export { EventFeedbackAPI };

import React from "react";
import { useSelector } from "react-redux";
import { AuthSelectors } from "redux/auth";
import LoginForm from "components/LoginForm";

function Login() {
  const isAuthenticated = useSelector(AuthSelectors.getAuthenticationStatus);

  return (
    <LoginForm
      isAuthenticated={isAuthenticated}
      showRegister
      showForgotPassword
    />
  );
}

export default Login;

import React, { useState } from "react";
import { Formik, Form } from "formik";
import moment from "moment";
import * as Yup from "yup";
import CardCollapse from "components/CardCollapse";
import Modal from "components/Modal";
import NumberInput from "components/NumberInput";
import ModalTable from "components/admin/ModalTable";
import TextInput from "components/TextInput";
import useTransactions from "hooks/transactions/useTransactions";
import useCreateTransaction from "hooks/transactions/useCreateTransaction";

export default ({ checkoutInfo, userId }) => {
  const { data: transactions } = useTransactions({ userId });
  const [isRefunding, setIsRefunding] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isFullAmount, setIsFullAmount] = useState(false);
  const [validationId, setValidationId] = useState("");
  const [createTransaction] = useCreateTransaction();
  const transactionTableData = {
    itemName: "Payment",
    headingData: {
      createdAt: "Created At",
      amount: "Amount",
      validationId: "Validation ID",
    },
    items: transactions
      ? transactions.map((t) => {
          return {
            ...t,
            amount: `$${t.amount.toString()}`,
            createdAt: moment(t.createdAt).format("YYYY/MM/DD HH:mm:ss"),
          };
        })
      : [],
    modalData: {
      fields: [
        {
          defaultValue: amount,
          displayName: "Amount*",
          name: "amount",
          onChange: (value) => setAmount(value),
          type: "number",
          min: 0,
        },
        {
          defaultValue: isFullAmount,
          displayName: "Pay Full Amount",
          name: "isFullAmount",
          onChange: (checked) => {
            const amount = checked
              ? (checkoutInfo.total - checkoutInfo.paid).toFixed(2)
              : 0;

            setIsFullAmount(checked);
            setAmount(amount);
          },
          type: "checkbox",
        },
        {
          defaultValue: validationId,
          displayName: "Validation ID",
          name: "validationId",
          onChange: (value) => setValidationId(value),
          type: "text",
        },
      ],
    },
    searchableColumns: ["createdAt"],
    defaultSort: "createdAt",
  };

  return transactions ? (
    <CardCollapse id="payments" title="Payments" show>
      {isRefunding && (
        <Modal close={() => setIsRefunding(false)}>
          <h3>Issue Refund</h3>
          <Formik
            initialValues={{ amount: 0, validationId: "" }}
            validationSchema={Yup.object({
              amount: Yup.number()
                .min(0, "Refund value must be more than 0.")
                .required(),
              validationId: Yup.string().max(
                120,
                "Must be 120 characters or less"
              ),
            })}
            onSubmit={async ({ amount, validationId }) => {
              createTransaction({
                amount: amount * -1,
                userId,
                validationId,
              });
              setIsRefunding(false);
            }}
          >
            <Form className="needs-validation" noValidate>
              <div className="row">
                <NumberInput name="amount" label="Amount*" min="0" />
              </div>
              <div className="row">
                <TextInput
                  label="Validation ID (Paypal or other)"
                  name="validationId"
                />
              </div>
              <button type="submit" className="btn btn-success float-right">
                Submit
              </button>
            </Form>
          </Formik>
        </Modal>
      )}
      <ModalTable
        onClose={() => {
          setAmount(0);
          setValidationId("");
          setIsFullAmount(false);
        }}
        addItem={() => {
          createTransaction({
            amount,
            userId,
            validationId,
          });
          setAmount(0);
          setValidationId("");
          setIsFullAmount(false);
        }}
        data={transactionTableData}
        itemClick={() => {}}
      ></ModalTable>
      <hr></hr>
      <div className="row">
        <div className="col">
          <button
            className="btn btn-danger float-right"
            onClick={() => setIsRefunding(true)}
          >
            Issue Refund
          </button>
        </div>
      </div>
    </CardCollapse>
  ) : null;
};

import React, { useState } from "react";
import ModalTable from "components/admin/ModalTable";
import dispatchToast from "helpers/dispatchToast";
import useEvents from "hooks/events/useEvents";
import useEventCategories from "hooks/eventCategories/useEventCategories";
import useCreateEvent from "hooks/events/useCreateEvent";
import useRemoveEvent from "hooks/events/useRemoveEvent";
import useUpdateEvent from "hooks/events/useUpdateEvent";
import useWushuStyles from "hooks/wushuStyles/useWushuStyles";

function EventManager() {
  const [name, setName] = useState("");
  const [chineseName, setChineseName] = useState("");
  const [description, setDescription] = useState("");
  const [createEvent] = useCreateEvent();
  const [removeEvent] = useRemoveEvent();
  const [updateEvent] = useUpdateEvent();
  const { data: events } = useEvents();
  const { data: eventCategories } = useEventCategories();
  const { data: wushuStyles } = useWushuStyles();
  const [wushuStyle, setWushuStyle] = useState();
  const [eventCategory, setEventCategory] = useState();
  const [code, setCode] = useState();

  function setIsEditingItem(item) {
    const {
      chineseName,
      code,
      description,
      eventCategory,
      name,
      wushuStyle,
    } = item;
    setName(name);
    setCode(code);
    setChineseName(chineseName);
    setDescription(description);
    setWushuStyle(wushuStyle);
    setEventCategory(eventCategory);
  }

  function setIsAddingItem() {
    setName("");
    setCode("");
    setChineseName("");
    setDescription("");
    setWushuStyle("");
    setEventCategory("");
  }

  function addItem() {
    if (name) {
      createEvent({
        code,
        chineseName,
        description,
        eventCategoryId: eventCategory.value,
        name,
        wushuStyleId: wushuStyle.value,
      });
    } else {
      dispatchToast({ message: "Please enter a name.", type: "error" });
    }
  }

  function removeItem({ id }) {
    removeEvent(id);
  }

  function undoDelete({
    code,
    chineseName,
    description,
    eventCategory,
    name,
    wushuStyle,
  }) {
    createEvent({
      code,
      chineseName,
      description,
      eventCategoryId: eventCategory.value,
      name,
      wushuStyleId: wushuStyle.value,
    });
  }

  function editItem({ id }) {
    if (name) {
      const eventInput = {
        code,
        chineseName,
        description,
        eventCategoryId: eventCategory?.value,
        name,
        wushuStyleId: wushuStyle?.value,
      };

      updateEvent({ id, eventInput });
    } else {
      dispatchToast({ message: "Please enter a name.", type: "error" });
    }
  }

  function processItem(item) {
    const clickedWushuStyle = wushuStyles.find(
      ({ name }) => item.wushuStyle === name
    );
    const clickedEventCategory = eventCategories.find(
      ({ name }) => item.eventCategoryName === name
    );
    let wushuStyle = null;
    let eventCategory = null;

    if (clickedWushuStyle) {
      wushuStyle = {
        value: clickedWushuStyle?.id,
        label: clickedWushuStyle?.name,
      };
    }

    if (clickedEventCategory) {
      eventCategory = {
        value: clickedEventCategory?.id,
        label: clickedEventCategory?.name,
      };
    }

    return {
      ...item,
      eventCategory,
      wushuStyle,
    };
  }

  const tableData = {
    defaultSort: "code",
    searchableColumns: [
      "name",
      "code",
      "chineseName",
      "description",
      "eventCategoryName",
    ],
    headingData: {
      name: "Name",
      code: "Code",
      eventCategoryName: "Category",
      chineseName: "Chinese Name",
      description: "Description",
      wushuStyle: "Wushu Style",
    },
    items: events || [],
    modalData: {
      fields: [
        {
          defaultValue: name,
          displayName: "Name",
          name: "name",
          onChange: (value) => setName(value),
          type: "text",
        },
        {
          defaultValue: code || "",
          displayName: "Code",
          name: "code",
          onChange: (value) => setCode(value),
          type: "text",
        },
        {
          defaultValue: chineseName,
          displayName: "Chinese Name",
          name: "chineseName",
          onChange: (value) => setChineseName(value),
          type: "text",
        },
        {
          defaultValue: description,
          displayName: "Description",
          name: "description",
          onChange: (value) => setDescription(value),
          type: "text",
        },
        {
          defaultValue: eventCategory,
          displayName: "Event Category",
          name: "eventCategory",
          onChange: (value) => setEventCategory(value),
          type: "select",
          items: eventCategories
            ? eventCategories.map(({ id, name }) => {
                return { label: name, value: id };
              })
            : [],
        },
        {
          defaultValue: wushuStyle,
          displayName: "Wushu Style",
          name: "wushuStyle",
          onChange: (value) => setWushuStyle(value),
          type: "select",
          items: wushuStyles
            ? wushuStyles.map(({ id, name }) => {
                return { label: name, value: id };
              })
            : [],
        },
      ],
    },
    itemName: "Event",
  };

  return (
    <div className="eventManager">
      <h2 className="text-left">Event Manager</h2>
      <ModalTable
        addItem={addItem}
        data={tableData}
        editItem={editItem}
        processItem={processItem}
        removeItem={removeItem}
        setIsAddingItem={setIsAddingItem}
        setIsEditingItem={setIsEditingItem}
        undoDelete={undoDelete}
      />
    </div>
  );
}

export default EventManager;

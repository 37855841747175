import React, { useState, useEffect, useRef } from 'react';
import './NanduFormSelect.css';

function NanduFormSelect({ formLabel, options, displayProperty, callback, defaultValue }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const node = useRef();

  useEffect(() => {
    function closeDropdown(e) {
      if (node.current && node.current.contains(e.target)) {
        callback(options[e.target.attributes.option.value]);
      }
      setShowDropdown(false);
    }

    document.addEventListener('mousedown', closeDropdown);
    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [callback, options]);

  return (
    <div className="nanduFormSelect">
      <span className="caret ion-chevron-down"></span>
      <div className="select-dropdown" onClick={() => setShowDropdown(true)}>{defaultValue}</div>
      <label className="option-label">{formLabel}</label>
      { showDropdown &&
        <ul className="dropdown-content" ref={node}>
          {
            options.map((option, i) => {
              return <li key={i}><span option={i}>{option[displayProperty]}</span></li>;
            })
          }
        </ul>
      }
    </div>
  );
}

export default NanduFormSelect;

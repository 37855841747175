import { useQuery } from 'react-query';
import { VideoSubmissionAPI } from 'api/videoSubmission';
import { VIDEO_SUBMISSIONS } from 'helpers/constants';

export default function useVideoSubmission({
  participantId,
  competitionEventId,
  competitionId,
}) {
  return useQuery(
    [VIDEO_SUBMISSIONS, participantId, competitionEventId, competitionId],
    () =>
      VideoSubmissionAPI.getVideoSubmission({
        participantId,
        competitionEventId,
        competitionId,
      }),
    {
      enabled: competitionId && competitionEventId,
    }
  );
}

import React from "react";
import Toast from "react-bootstrap/Toast";
import { useDispatch, useSelector } from "react-redux";
import { ToastActions, ToastSelectors } from "redux/toast";
import "./ToastContainer.scss";

function ToastMessage({
  children,
  id,
  type = "error",
  autohide,
  delay = 1200,
}) {
  const dispatch = useDispatch();
  const map = {
    error: {
      headerText: "Error",
      headerClasses: "text-danger",
    },
    success: {
      headerText: "Success",
      headerClasses: "text-success",
    },
    warning: {
      headerText: "Notice",
      headerClasses: "text-warning",
    },
  };
  const { headerClasses, headerText } = map[type];
  if (!autohide) {
    autohide = type === "error" ? false : true;
  }

  return (
    <Toast
      onClose={() => dispatch(ToastActions.removeToast(id))}
      autohide={autohide}
      delay={delay}
    >
      <Toast.Header>
        <span className={`mr-auto ${headerClasses}`}>{headerText}</span>
      </Toast.Header>
      <Toast.Body>{children}</Toast.Body>
    </Toast>
  );
}

function ToastContainer() {
  const toastMessages = useSelector(ToastSelectors.getToasts);

  return (
    <div className="ToastContainer">
      <div className="inner-container">
        {toastMessages.map(({ message, type, autohide }, id) => (
          <ToastMessage key={id} id={id} type={type} autohide={autohide}>
            {message}
          </ToastMessage>
        ))}
      </div>
    </div>
  );
}

export default ToastContainer;

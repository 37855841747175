import { GET, POST, PUT, DELETE } from 'api/config';

const AgeGroupAPI = {
  async getAll(competitionId) {
    const { ageGroups } = await GET(`competitions/${competitionId}/ageGroups`);

    return ageGroups;
  },
  async add(ageGroupInput) {
    return await POST(`ageGroups`, { ageGroupInput });
  },
  async remove(id) {
    return await DELETE(`ageGroups/${id}`);
  },
  async update(ageGroupInput) {
    const { id } = ageGroupInput;

    return await PUT(`ageGroups/${id}`, { ageGroupInput });
  },
};

export { AgeGroupAPI };

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NANDU } from 'helpers/constants';
import { NanduFormSelectors } from 'redux/nanduForm';
import PDFActions from './PDFActions';
import './NanduHeader.css';

function NanduHeader() {
  const title = 'Nandu Hub';
  const [deactivatedBlink, setDeactivatedBlink] = useState(false);
  const formType = useSelector(NanduFormSelectors.getFormType);
  const wushuStyle = useSelector(NanduFormSelectors.getWushuStyle);
  const isValidNanduForm = useSelector(NanduFormSelectors.getIsValidNanduForm);
  const isValidMovementForm = useSelector(NanduFormSelectors.getIsValidMovementForm);
  // const isNanduFormPage = pathname === '/nanduform'; // TODO: fix
  const isNanduFormPage = true; // TODO: fix
  const selectedStyleAndFormType = formType && wushuStyle;
  const isValidForm = formType.name === NANDU ? isValidNanduForm : isValidMovementForm;
  let isBlinking = isValidForm && !deactivatedBlink;

  return (
    <div className="nanduHeader" data-0="height:192px" data-128="height: 64px">
      <div className="content-width">
        <div id="actions">
          <div className="spacer"></div>
          { isNanduFormPage && selectedStyleAndFormType &&
            <div className={"icon print ion-android-print " + (isBlinking ? "blink" : "")} onMouseOver={() => setDeactivatedBlink(true)}>
              <PDFActions isValidForm={isValidForm} />
            </div>
          }
          <div className="icon menu ion-navicon-round">
            <div className="appActions menu-display">
              <Link to="/nanduform" className="action">Nandu Form</Link>
              <Link to="/about" className="action">About</Link>
            </div>
          </div>
        </div>
        <Link to="/" className="headerTitle">{title}</Link>
      </div>
    </div>
  );
}

export default NanduHeader;

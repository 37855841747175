import React from "react";
import "./ScoreTable.scss";

function ScoreTable({ children }) {
  return (
    <div className="ScoreTable">
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th>#</th>
            <th>ID</th>
            <th>Name</th>
            <th>Judge 1</th>
            <th>Judge 2</th>
            <th>Judge 3</th>
            <th>Judge 4</th>
            <th>Head Judge</th>
            <th>Head Judge +/-</th>
            <th>Time</th>
            <th>Total</th>
            <th>Rank</th>
            <th>Note</th>
            <th className="no-print">Save</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}

export default ScoreTable;

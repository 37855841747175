import { useQuery } from 'react-query';
import { EventParticipationAPI } from 'api/eventParticipation';
import { EVENT_PARTICIPATION } from 'helpers/constants';

export default function useEventParticipationForResults(competitionId) {
  return useQuery(
    [EVENT_PARTICIPATION, 'results', competitionId],
    () => EventParticipationAPI.getAllForResults(competitionId),
    {
      enabled: !!competitionId,
    }
  );
}

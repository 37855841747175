import React, { useEffect, useRef } from "react";
import "./Modal.scss";

function Modal({ children, close }) {
  const node = useRef();

  useEffect(() => {
    function closeModal(e) {
      if (node.current && node.current.contains(e.target)) {
        return;
      }
      close(false);
    }

    document.addEventListener("mousedown", closeModal);
    return () => {
      document.removeEventListener("mousedown", closeModal);
    };
  }, [close]);

  return (
    <div className="genericModal">
      <div className="modalDiv">
        <div className="modalContent clearfix" ref={node}>
          <div
            className="modalClose ion-close-circled"
            onClick={() => close(false)}
          ></div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;

import React from "react";
import { useSelector } from "react-redux";
import { createRankCertificate } from "helpers/createCertificate";
import useLocalize from "hooks/useLocalize";
import { I18nSelectors } from "redux/i18n";
import CertificateDownloadLink from "../CertificateDownloadLink";

export default function ChampionshipResultItem({
  eventParticipation,
  isVirtual,
}) {
  const language = useSelector(I18nSelectors.getLanguage);
  const localize = useLocalize();
  const {
    name,
    chineseName,
    rank,
    totalScore,
    ageGroupName,
    participantName,
    participantId,
  } = eventParticipation;
  const rankCertificateLink = (
    <CertificateDownloadLink
      onClick={() =>
        createRankCertificate({
          achievement: rank,
          ageGroup: ageGroupName,
          eventName: name,
          participantName,
          isVirtual,
          extendedAwards: true,
        })
      }
      text={localize("Download Rank Certificate")}
    />
  );

  return (
    <div
      className="ChampionshipResultItem event-item"
      key={`${participantId}-${name}`}
    >
      <div className="d-flex align-items-center justify-content-between">
        <h6 className="event-name">{language === "en" ? name : chineseName}</h6>
      </div>
      <li className="list-group-item d-flex justify-content-between">
        <span className="mr-4">
          {localize("Rank")}: <span className="event-item-label">{rank}</span>
        </span>
        <span>
          {localize("Total Score")}:{" "}
          <span className="event-item-label">{totalScore}</span>
        </span>
      </li>
      {rankCertificateLink}
    </div>
  );
}

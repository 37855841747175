import { GET, POST, PUT, DELETE } from './config';

const WebsiteMessageAPI = {
  async getWebsiteMessages() {
    const { websiteMessages } = await GET('websiteMessages');

    return websiteMessages;
  },
  add({ text }) {
    return POST('websiteMessages', { text });
  },
  remove(id) {
    return DELETE(`websiteMessages/${id}`);
  },
  update({ id, text }) {
    return PUT(`websiteMessages/${id}`, { text });
  },
};

export { WebsiteMessageAPI };

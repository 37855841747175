import { GET } from '../config';

const UserRoleAPI = {
  async getAll() {
    const { userRoles } = await GET('userRoles');

    return userRoles;
  },
};

export { UserRoleAPI };

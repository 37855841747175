import React from 'react';
import { Link } from 'react-router-dom';
import './PDFActions.css';

function PDFActions({ isValidForm }) {
  return (
    <div className="pdfActions menu-display">
      {
        !isValidForm &&
        <div className="warning"><span className="ion-alert-circled"></span> Warning: your form is incomplete.<br />Make sure to complete all validations in red or risk having an incomplete form.</div>
      }
      <Link to="print" className="action">Print Nandu Form</Link>
    </div>
  );
}


export default PDFActions;
export default function splitSentence(s) {
  let middle = Math.floor(s.length / 2);
  const before = s.lastIndexOf(' ', middle);
  const after = s.indexOf(' ', middle + 1);

  if (before === -1 || (after !== -1 && middle - before >= after - middle)) {
    middle = after;
  } else {
    middle = before;
  }

  return [s.substr(0, middle), s.substr(middle + 1)];
}

import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { I18nSelectors } from "redux/i18n";
import Table from "components/admin/Table";
import useEventParticipationForResults from "hooks/eventParticipation/useEventParticipationForResults";
import useUpdateEventParticipationClaimedAward from "hooks/eventParticipation/useUpdateEventParticipationClaimedAward";
import useLocalize from "hooks/useLocalize";
import { AuthSelectors } from "redux/auth";
import "./AllResults.scss";

function AllResults({ competitionId, isVirtual }) {
  const isAdmin = useSelector(AuthSelectors.isAdmin);
  const history = useHistory();
  const language = useSelector(I18nSelectors.getLanguage);
  const {
    data: eventParticipation,
    isLoading,
  } = useEventParticipationForResults(competitionId);
  const localize = useLocalize();
  const [
    updateEventParticipationClaimedAward,
  ] = useUpdateEventParticipationClaimedAward();
  let headingData = {};

  if (language === "en") {
    headingData.eventName = localize("Event");
  } else {
    headingData.eventChineseName = localize("Event");
  }

  headingData = {
    ...headingData,
    code: localize("Code"),
    ageGroup: localize("Age Group"),
    gender: localize("Gender"),
    participantName: localize("Name"),
    participantId: localize("ID"),
    score: localize("Score"),
    rank: localize("Rank"),
    award: localize("Award"),
    // votes: localize("Votes"),
    teamName: localize("Team"),
  };

  if (isVirtual) {
    headingData.link = localize("Video");
  }

  if (isAdmin) {
    headingData.claimed = "Claimed Award";
  }

  if (eventParticipation) {
    eventParticipation.forEach((ep) => {
      if (isVirtual && ep.videoUrl) {
        ep.link = (
          <Link
            className="video-link"
            to={`/video/${ep.eventParticipationId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {localize("Watch Video")}
          </Link>
        );
      }

      if (isAdmin) {
        ep.claimed = {
          isCheckbox: true,
          props: {
            onChange: () => {
              updateEventParticipationClaimedAward({
                id: ep.eventParticipationId,
                claimedAward: !ep.hasClaimed,
              });
            },
            checked: !!ep.hasClaimed,
          },
        };
      }
    });
  }

  const tableData = {
    itemName: "Item",
    filters: ["categoryName", "ageGroup", "gender"],
    headingData,
    items: eventParticipation || [],
    searchableColumns: [
      "eventName",
      "eventChineseName",
      "code",
      "gender",
      "participantName",
      "participantId",
      "score",
      "award",
      "teamName",
    ],
    defaultSort: "score",
    defaultSortAscending: false,
  };

  return isLoading ? (
    <div className="spinner-border"></div>
  ) : (
    <div className="AllResults">
      <Table
        data={tableData}
        pageTotal={100}
        itemClick={({ participantId }) => {
          history.push(`/schedule/${participantId}/events`);
        }}
      />
    </div>
  );
}

export default AllResults;

import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toHTMLDate } from "helpers";
import defaultImage from "assets/default.gif";
import AddTeamModal from "components/AddTeamModal";
import CardTab from "components/CardTab";
import Date from "components/Date";
import EventsPane from "components/EventsPane";
import ImageInput from "components/ImageInput";
import Select from "components/Select";
import TabPane from "components/TabPane";
import TextInput from "components/TextInput";
import USAWKFMembershipField from "components/USAWKFMembershipField";
import CONFIRM_DELETE from "helpers/confirmDelete";
import dispatchToast from "helpers/dispatchToast";
import useLocalize from "hooks/useLocalize";
import useRemoveParticipant from "hooks/participants/useRemoveParticipant";
import useUpdateParticipant from "hooks/participants/useUpdateParticipant";
import useTeams from "hooks/teams/useTeams";
import useTournament from "hooks/tournament/useCurrentTournament";
import getCountries from "helpers/countries";
import ParticipantSchedule from "scenes/Schedule/components/ParticipantSchedule";
import "./Participant.scss";

function Participant({
  participant,
  onDelete = () => {},
  onUpdate = () => {},
  handleUndoDelete,
  isOpen,
}) {
  const {
    id,
    firstName,
    lastName,
    phone,
    mobilePhone,
    address,
    address2,
    city,
    state,
    zip,
    country,
    usawkfId,
    gender,
    birthDate,
    teamId,
    image,
    email,
  } = participant;
  const localize = useLocalize();
  const [removeParticipant] = useRemoveParticipant();
  const [updateParticipant] = useUpdateParticipant();
  const { data: teams } = useTeams();
  const [chevronDown, setChevronDown] = useState(isOpen);
  const [isAddingTeam, setIsAddingTeam] = useState();
  const [hasShownBirthdateError, setHasShownBirthdateError] = useState(false);
  const defaultLabel = localize("Choose athlete image");
  const [label, setLabel] = useState(defaultLabel);
  const infoTabId = `info-tab-${id}`;
  const eventsTabId = `events-tab-${id}`;
  const scheduleTabId = `schedule-tab-${id}`;
  const [showTab, setShowTab] = useState(infoTabId);
  const chevronDirection = chevronDown ? "down" : "up";
  const countries = getCountries();
  const countryOptions = countries.map((country, i) => (
    <option key={i} value={country}>
      {country}
    </option>
  ));
  const teamOptions = teams
    ? teams.map(({ name, id }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))
    : [];
  const { data: tournament } = useTournament();
  const competitions = tournament?.competitions || [];
  const numOfCompetitions = competitions.length;
  const showCollapse = isOpen ? "collapse show" : "collapse";
  const imageSource = image ? `/public/images/${image}` : defaultImage;

  if (birthDate === "0000-00-00" && !hasShownBirthdateError) {
    setHasShownBirthdateError(true);
    dispatchToast({
      message: `Invalid birth date for ${firstName} ${lastName}. Please contact your administrator.`,
      type: "error",
    });
  }

  return (
    <div className="Participant card">
      {isAddingTeam && <AddTeamModal close={() => setIsAddingTeam(false)} />}
      <div
        className="card-header"
        data-toggle="collapse"
        data-target={`#card-body-${id}`}
        onClick={() => setChevronDown(!chevronDown)}
      >
        <h5 className="name">
          {firstName} {lastName} (ID: {id})
        </h5>
        <span className={`ion-chevron-${chevronDirection} float-right`}></span>
      </div>
      <div id={`card-body-${id}`} className={showCollapse}>
        <div className="card-body">
          <ul className="nav nav-tabs nav-justified">
            <CardTab
              id={`#${infoTabId}`}
              text={localize("Info")}
              onClick={() => setShowTab(infoTabId)}
              selected={showTab === infoTabId}
            />
            {!!numOfCompetitions && (
              <CardTab
                id={`#${eventsTabId}`}
                onClick={() => setShowTab(eventsTabId)}
                selected={showTab === eventsTabId}
                text={localize("Events")}
              />
            )}
            <CardTab
              id={`#${scheduleTabId}`}
              text={localize("Schedule")}
              onClick={() => setShowTab(scheduleTabId)}
              selected={showTab === scheduleTabId}
            />
          </ul>
          {chevronDown && (
            <div className="tab-content">
              <TabPane id={infoTabId} show={showTab === infoTabId}>
                <div className="row">
                  <div className="col">
                    <Formik
                      initialValues={{
                        firstName,
                        lastName,
                        gender,
                        birthDate: toHTMLDate(birthDate),
                        country,
                        address,
                        address2,
                        city,
                        state,
                        zip,
                        phone,
                        mobilePhone,
                        usawkfId,
                        image,
                        teamId: teamId || "",
                        email,
                      }}
                      validationSchema={Yup.object({
                        firstName: Yup.string()
                          .max(45, localize("Must be 45 characters or less"))
                          .required(localize("Required")),
                        lastName: Yup.string()
                          .max(45, localize("Must be 45 characters or less"))
                          .required(localize("Required")),
                        gender: Yup.string()
                          .oneOf(
                            ["male", "female"],
                            localize("Please select a gender.")
                          )
                          .required(localize("Required")),
                        birthDate: Yup.date().required(localize("Required")),
                        country: Yup.string()
                          .oneOf(
                            countries,
                            localize("Please select a country.")
                          )
                          .required(localize("Required")),
                        address: Yup.string().max(
                          100,
                          localize("Must be 100 characters or less")
                        ),
                        address2: Yup.string().max(
                          100,
                          localize("Must be 100 characters or less")
                        ),
                        city: Yup.string().max(
                          45,
                          localize("Must be 45 characters or less")
                        ),
                        state: Yup.string().max(
                          45,
                          localize("Must be 45 characters or less")
                        ),
                        zip: Yup.string().max(
                          10,
                          localize("Must be 10 characters or less")
                        ),
                        phone: Yup.string().max(
                          45,
                          localize("Must be 45 characters or less")
                        ),
                        mobilePhone: Yup.string().max(
                          45,
                          localize("Must be 45 characters or less")
                        ),
                        usawkfId: Yup.string().max(
                          45,
                          localize("Must be 45 characters or less")
                        ),
                        teamId: Yup.string(),
                        image: Yup.mixed(),
                        email: Yup.string()
                          .email("Must be a valid email")
                          .max(45, "Must be 45 characters or less"),
                      })}
                      onSubmit={async (values) => {
                        values.id = id;
                        updateParticipant({
                          participantInput: values,
                          onSuccess: () => {
                            setLabel(defaultLabel);
                            onUpdate();
                          },
                        });
                      }}
                    >
                      {({ setFieldValue }) => (
                        <Form className="needs-validation" noValidate>
                          <div className="row">
                            <div className="col-md-2">
                              <img
                                src={imageSource}
                                alt="default-user"
                                className="card-img"
                              ></img>
                            </div>
                            <div className="col-md-10">
                              <div className="row">
                                <div className="col-md-6">
                                  <ImageInput
                                    label={label}
                                    footnote="For best results, please use .png or .jpg files."
                                    processFile={(image) => {
                                      setLabel(image?.name);
                                      setFieldValue("image", image);
                                    }}
                                    name="image"
                                    uid={id}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <TextInput
                                  label={`${localize("First Name")}*`}
                                  name="firstName"
                                />
                                <TextInput
                                  label={`${localize("Last Name")}*`}
                                  name="lastName"
                                />
                              </div>
                              <div className="row">
                                <Select
                                  label={`${localize("Gender")}*`}
                                  name="gender"
                                >
                                  <option value="">
                                    {localize("Select a gender")}
                                  </option>
                                  <option value="male">
                                    {localize("Male")}
                                  </option>
                                  <option value="female">
                                    {localize("Female")}
                                  </option>
                                </Select>
                                <Date
                                  label={`${localize("Birth Date")}*`}
                                  name="birthDate"
                                />
                                <Select
                                  label={`${localize("Country")}*`}
                                  name="country"
                                >
                                  <option value="">
                                    {localize("Select a country")}
                                  </option>
                                  {countryOptions}
                                </Select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <TextInput
                              label={localize("Address")}
                              name="address"
                              size="col-sm-12 col-lg-9"
                            />
                            <TextInput
                              label={localize("Address 2")}
                              name="address2"
                            />
                          </div>
                          <div className="row">
                            <TextInput label={localize("City")} name="city" />
                            <TextInput label={localize("State")} name="state" />
                            <TextInput label={localize("Zip")} name="zip" />
                          </div>
                          <div className="row">
                            <TextInput label={localize("Phone")} name="phone" />
                            <TextInput
                              label={localize("Mobile")}
                              name="mobilePhone"
                            />
                            <TextInput label={localize("Email")} name="email" />
                          </div>
                          <USAWKFMembershipField />
                          <div className="row">
                            <Select
                              label={`${localize("Team")} (${localize(
                                "If you are competing as an individual, registering under a team is optional."
                              )})`}
                              name="teamId"
                            >
                              <option value="">
                                {localize("Select a team")}
                              </option>
                              {teamOptions}
                            </Select>
                          </div>
                          <div className="row">
                            <div className="col d-flex justify-content-between">
                              <span>
                                {localize(
                                  `Don't see your team? Click to add a new team.`
                                )}
                              </span>
                              <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsAddingTeam(true);
                                }}
                              >
                                {localize("Add Team")}
                              </button>
                            </div>
                          </div>
                          <hr />
                          <button
                            type="submit"
                            className="btn btn-success float-right"
                          >
                            {localize("Update")}
                          </button>
                        </Form>
                      )}
                    </Formik>
                    <button
                      className="btn btn-danger float-left"
                      onClick={() =>
                        CONFIRM_DELETE(() => {
                          removeParticipant({
                            id,
                            onSuccess: () => onDelete(id),
                            handleUndoDelete,
                          });
                        })
                      }
                    >
                      {localize("Delete Athlete")}
                    </button>
                  </div>
                </div>
              </TabPane>
              <TabPane id={eventsTabId} show={showTab === eventsTabId}>
                <EventsPane
                  competitions={competitions}
                  participant={{ id, firstName, lastName, gender, birthDate }}
                />
              </TabPane>
              <TabPane id={scheduleTabId} show={showTab === scheduleTabId}>
                <ParticipantSchedule participantId={id} />
              </TabPane>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Participant;

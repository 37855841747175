/* eslint-disable no-underscore-dangle */
import * as actionTypes from './actionTypes';
import { hasAllRequiredMovements, hasDuplicates } from './helpers/calculateScore';
import { NANDU } from 'helpers/constants';
import { NanduFormAPI } from 'api/nanduForm';

function _changeMovement(movement) {
  return {
    type: actionTypes.CHANGE_MOVEMENT,
    movement,
  };
}
function _calculateNanduScore() {
  return {
    type: actionTypes.CALCULATE_NANDU_SCORE,
  };
}
function _calculateScoreWithRemovedConnection(removedConnection) {
  return {
    type: actionTypes.CALCULATE_NANDU_SCORE,
    removedConnection,
  };
}
function _removeSectionInput({ sectionId, nanduIndex }) {
  return {
    type: actionTypes.REMOVE_SECTION_INPUT,
    sectionId,
    nanduIndex,
  };
}
function _verifyAllRequiredMovements(hasRequiredMovements) {
  return {
    type: actionTypes.VERIFY_ALL_REQUIRED_MOVEMENTS,
    hasRequiredMovements,
  };
}
function setNanduFormData(data) {
  return {
    type: actionTypes.FETCH_NANDU_DATA,
    data,
  };
}
function _addSectionInput(sectionId) {
  return {
    type: actionTypes.ADD_SECTION_INPUT,
    sectionId,
  };
}

const NanduFormActions = {
  fetchNanduFormData() {
    return async(dispatch) => {
      const nandus = await NanduFormAPI.getNanduFormData();
      dispatch(setNanduFormData(nandus));
    };
  },
  changeNanduFormType(nanduFormType) {
    return {
      type: actionTypes.CHANGE_NANDU_FORM_TYPE,
      nanduFormType,
    };
  },
  changeWushuStyle(wushuStyle) {
    return {
      type: actionTypes.CHANGE_WUSHU_STYLE,
      wushuStyle,
    };
  },
  savePDF(userInfo, filename) {
    return {
      type: actionTypes.SAVE_PDF,
      userInfo,
      filename,
    };
  },
  addSectionInput({ sectionId, nanduIndex }) {
    return (dispatch) => {
      dispatch(_addSectionInput(sectionId));
      dispatch(this.openNanduModal({ sectionId, nanduIndex }));
    };
  },
  openNanduModal({ sectionId, nanduIndex }) {
    return {
      type: actionTypes.OPEN_NANDU_MODAL,
      sectionId,
      nanduIndex,
    };
  },
  changeNandu(movement) {
    return (dispatch) => {
      dispatch(_changeMovement(movement));
      dispatch(_calculateNanduScore());
    };
  },
  closeNanduModal() {
    return {
      type: actionTypes.CLOSE_NANDU_MODAL,
    };
  },
  changeMovement(movement) {
    return (dispatch, getState) => {
      let { sections } = getState().nanduForm;
      const movementFormHasDuplicates = hasDuplicates({ movement, sections });
      if (!movementFormHasDuplicates) {
        dispatch(_changeMovement(movement));
        sections = getState().nanduForm.sections;
        const { movementCodes } = getState().nanduForm;
        dispatch(_verifyAllRequiredMovements(hasAllRequiredMovements({ movementCodes, sections })));
      } else {
        // display error
      }
    };
  },
  removeSectionInput({ sectionId, nanduIndex, nandu }) {
    return (dispatch, getState) => {
      const { name } = getState().nanduForm.nanduFormType;
      dispatch(_removeSectionInput({ sectionId, nanduIndex }));
      if (name === NANDU) {
        if (nandu.code === '+') {
          const removedConnection = { sectionId, nanduIndex };
          dispatch(_calculateScoreWithRemovedConnection(removedConnection));
        } else {
          dispatch(_calculateNanduScore());
        }
      } else {
        const { sections } = getState().nanduForm;
        const { movementCodes } = getState().nanduForm;
        dispatch(_verifyAllRequiredMovements(hasAllRequiredMovements({ movementCodes, sections })));
      }
    };
  },
  dropSectionInput() {
    return (dispatch, getState) => {
      let { sections } = getState().nanduForm;
      const { name } = getState().nanduForm.nanduFormType;
      if (name === 'NANDU') {
        dispatch(_calculateNanduScore());
      } else {
        sections = getState().nanduForm.sections;
        const { movementCodes } = getState().nanduForm;
        dispatch(_verifyAllRequiredMovements(hasAllRequiredMovements({ movementCodes, sections })));
      }
    };
  }
}

export { NanduFormActions };
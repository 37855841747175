import { useQuery } from "react-query";
import { CompetitorAPI } from "api/admin/competitor";
import { COMPETITORS } from "helpers/constants";

export default function useCompetitorsWithoutUSAWKFId(competitionId) {
  return useQuery(
    [COMPETITORS, "missing-usawkfId", competitionId],
    () => CompetitorAPI.getAllWithoutUSAWKFIdButNeedIt(competitionId),
    { enabled: competitionId }
  );
}

import { GET } from "api/config";

const ScheduleAPI = {
  async getSchedule(ringId) {
    const { schedule } = await GET(`schedule/ring/${ringId}`);

    return schedule;
  },
  async getScheduleForParticipant(participantId) {
    const { schedule } = await GET(`schedule/participant/${participantId}`);

    return schedule;
  },
};

export { ScheduleAPI };

import { useMutation, useQueryCache } from 'react-query';
import { VideoSubmissionAPI } from 'api/videoSubmission';
import { EVENT_PARTICIPATION } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useUpdateVideoSubmissionDetails() {
  const queryCache = useQueryCache();

  return useMutation(VideoSubmissionAPI.updateVideoSubmissionDetails, {
    onSuccess: ({ message }) => {
      dispatchToast({ message, type: 'success' });
      queryCache.invalidateQueries(EVENT_PARTICIPATION);
    },
  });
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18nActions, I18nSelectors } from 'redux/i18n';

export default function ChangeLanguageButton() {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(I18nSelectors.getLanguage);
  let text = '中文';
  let newLanguage = 'cn';

  if (currentLanguage === 'cn') {
    text = 'English';
    newLanguage = 'en';
  }

  return (
    <span
      className="ChaneLanguageButton nav-link"
      onClick={() => {
        dispatch(I18nActions.setLanguage(newLanguage));
      }}
    >
      {text}
    </span>
  );
}

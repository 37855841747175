import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ShareButtons from 'components/ShareButtons';
import useCreateCompetitionVote from 'hooks/competitionVotes/useCreateCompetitionVote';
import useRemoveCompetitionVote from 'hooks/competitionVotes/useRemoveCompetitionVote';
import useOneEventParticipation from 'hooks/eventParticipation/useOneEventParticipation';
import useLocalize from 'hooks/useLocalize';
import { AuthSelectors } from 'redux/auth';
import { I18nSelectors } from 'redux/i18n';
import './VideoPlayer.scss';

function VideoPlayer() {
  const localize = useLocalize();
  const language = useSelector(I18nSelectors.getLanguage);
  const user = useSelector(AuthSelectors.getUser);
  const { eventParticipationId } = useParams();
  const { data: ep } = useOneEventParticipation(eventParticipationId);
  const [createCompetitionVote] = useCreateCompetitionVote();
  const [removeCompetitionVote] = useRemoveCompetitionVote();
  const videoUrl = ep?.videoUrl
    .split('/')
    .map(path => {
      return encodeURIComponent(path);
    })
    .join('/');
  let displayName = `${ep?.groupName || ep?.name || ''}`;
  let eventName = ep?.eventName;

  if (ep?.rank && ep.rank < 4) {
    displayName += ` [Rank: ${ep?.rank}]`;
  }

  // if (ep?.award) {
  //   displayName += ` (${ep?.award})`;
  // }

  if (language !== 'en') {
    eventName = ep?.eventChineseName;
  }

  return (
    <div className="VideoPlayer container">
      <div className="video-container">
        {ep ? (
          <>
            <div className="d-flex justify-content-between align-items-end">
              <h1>{displayName}</h1>
              <h3>{eventName}</h3>
            </div>
            {ep?.groupMembers && (
              <h4>{ep?.groupMembers?.split(',').join(', ')}</h4>
            )}
            <video
              width="100%"
              controls
              src={`/videos/${videoUrl}`}
              type="video/mp4"
            ></video>
            <div className="bottom-container d-flex justify-content-between align-items-center">
              <h5>
                {localize('Score')}: {ep.score}
              </h5>
              {ep?.ageGroupName && (
                <h5>
                  {localize('Age Group')}: {ep.ageGroupName}
                </h5>
              )}
              <h5>
                {localize('Votes')}: {ep.votes || 0}{' '}
                {ep.voteRanking && `(Rank ${ep.voteRanking})`}
              </h5>
              {ep?.votingEnabled && (
                <>
                  <button
                    className={`btn ${
                      ep?.hasVoted ? 'btn-success' : 'btn-light'
                    }`}
                    onClick={() => {
                      if (ep?.hasVoted) {
                        removeCompetitionVote({
                          competitionId: ep.competitionId,
                          userId: user?.id,
                        });
                      } else {
                        createCompetitionVote({
                          competitionId: ep.competitionId,
                          eventParticipationId,
                          userId: user?.id,
                        });
                      }
                    }}
                  >
                    <span className="ion-thumbsup"></span>
                  </button>
                </>
              )}
            </div>
            <div className="share-container">
              <ShareButtons url={window.location.href} />
            </div>
          </>
        ) : (
          <h2 className="text-center">No video found.</h2>
        )}
      </div>
    </div>
  );
}

export default VideoPlayer;

import { GET, PUT } from "api/config";

const ChampionshipParticipationAPI = {
  async getAllForParticipant(participantId) {
    const { championshipParticipation } = await GET(
      `championshipParticipation/participant/${participantId}`
    );

    return championshipParticipation;
  },
  updateChampionshipParticipationClaimedAward({ id, claimedAward }) {
    return PUT(`championshipParticipation/${id}/claimedAward`, {
      claimedAward,
    });
  },
};

export { ChampionshipParticipationAPI };

import React from 'react';
import classnames from 'classnames';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Logout from 'components/Logout';
import { SideNavActions, SideNavSelectors } from 'redux/admin/sideNav';
import SideNavItem from '../SideNavItem';
import './SideNav.scss';

function SideNav() {
  const dispatch = useDispatch();
  const isCollapsed = useSelector(SideNavSelectors.getIsCollapsed);
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const userRoute = `${url}/users`;
  const tournamentRoute = `${url}/tournaments`;
  const wushuStyleRoute = `${url}/wushu-styles`;
  const websiteMessageRoute = `${url}/website-messages`;
  const errorMessagesRoute = `${url}/error-messages`;
  const eventCategoryRoute = `${url}/event-categories`;
  const eventRoute = `${url}/events`;
  const teamRoute = `${url}/teams`;
  const masterSheetRoute = `${url}/masterSheet`;
  const classes = classnames('sideNav-container', { collapsed: isCollapsed });
  const chevronDirectionClass = classnames(
    'chevron',
    { 'ion-chevron-right': isCollapsed },
    { 'ion-chevron-left': !isCollapsed }
  );

  return (
    <nav className={classes}>
      <ul>
        <li
          className="sideNav-item d-flex justify-content-between"
          onClick={() => dispatch(SideNavActions.setCollapsed(!isCollapsed))}
        >
          {!isCollapsed && <span>Admin</span>}
          <span className={chevronDirectionClass}></span>
        </li>
        <SideNavItem
          icon="ion-android-walk"
          isRouteMatch={pathname === url}
          title={!isCollapsed && 'Athletes'}
          url={url}
        />
        <SideNavItem
          icon="ion-trophy"
          isRouteMatch={pathname === tournamentRoute}
          title={!isCollapsed && 'Tournaments'}
          url={tournamentRoute}
        />
        <SideNavItem
          icon="ion-person"
          isRouteMatch={pathname === userRoute}
          title={!isCollapsed && 'Users'}
          url={userRoute}
        />
        <SideNavItem
          icon="ion-person-stalker"
          isRouteMatch={pathname === teamRoute}
          title={!isCollapsed && 'Teams'}
          url={teamRoute}
        />
        <SideNavItem
          icon="ion-alert-circled"
          isRouteMatch={pathname === errorMessagesRoute}
          title={!isCollapsed && 'Error Messages'}
          url={errorMessagesRoute}
        />
        <SideNavItem
          icon="ion-calendar"
          isRouteMatch={pathname === eventRoute}
          title={!isCollapsed && 'Events'}
          url={eventRoute}
        />
        <SideNavItem
          icon="ion-filing"
          isRouteMatch={pathname === eventCategoryRoute}
          title={!isCollapsed && 'Event Categories'}
          url={eventCategoryRoute}
        />
        <SideNavItem
          icon="ion-chatbox-working"
          isRouteMatch={pathname === websiteMessageRoute}
          title={!isCollapsed && 'Website Messages'}
          url={websiteMessageRoute}
        />
        <SideNavItem
          icon="ion-filing"
          isRouteMatch={pathname === wushuStyleRoute}
          title={!isCollapsed && 'Wushu Styles'}
          url={wushuStyleRoute}
        />
        <SideNavItem
          icon="ion-ios-list"
          isRouteMatch={pathname === masterSheetRoute}
          title={!isCollapsed && 'Master Sheet'}
          url={masterSheetRoute}
        />
        <SideNavItem
          icon="ion-clipboard"
          title={!isCollapsed && 'All Results'}
          url={'/all-results'}
        />
        <Logout className="sideNav-item" to="/adm">
          <span className="sideNav-icon ion-power"></span>
          {!isCollapsed && 'Logout'}
        </Logout>
      </ul>
    </nav>
  );
}

export default SideNav;

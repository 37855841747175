import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "components/Modal/ModalFields/Select";
import useCurrentTournament from "hooks/tournament/useCurrentTournament";
import useLocalize from "hooks/useLocalize";
import {
  CompetitionSelectActions,
  CompetitionSelectSelectors,
} from "redux/competitionSelect";

function CompetitionSelect({
  id,
  visibleCompetitionIds,
  onChange,
  virtualOnly,
  inPersonOnly,
}) {
  const { data: tournament } = useCurrentTournament();
  const localize = useLocalize();
  const [competition, setCompetition] = useState();
  const [competitions, setCompetitions] = useState([]);
  const savedCompetition = useSelector(
    CompetitionSelectSelectors.getCompetitionSelect
  )?.[id];
  const dispatch = useDispatch();

  useEffect(() => {
    if (tournament?.competitions) {
      const comps = [];

      tournament.competitions.forEach(({ id, name, isVirtual }) => {
        if (
          !visibleCompetitionIds ||
          (visibleCompetitionIds && visibleCompetitionIds.includes(id))
        ) {
          if (
            (virtualOnly && isVirtual) ||
            (inPersonOnly && !isVirtual) ||
            (!virtualOnly && !inPersonOnly)
          ) {
            comps.push({
              label: name,
              value: id,
            });
          }
        }
      });

      setCompetitions(comps);
    }
  }, [tournament, visibleCompetitionIds?.length]);

  useEffect(() => {
    const init = savedCompetition || competitions[0];

    setCompetition(init);

    if (init) {
      onChange(init);
    }
  }, [competitions.length]);

  return (
    <div className="CompetitionSelect">
      {competitions?.length > 1 || visibleCompetitionIds?.length > 1 ? (
        <Select
          displayName={localize("Select Competition")}
          items={competitions}
          onChange={(c) => {
            setCompetition(c);
            dispatch(CompetitionSelectActions.setCompetition(c, id));
            onChange(c);
          }}
          defaultValue={competition}
        />
      ) : (
        <h4>{competition?.label}</h4>
      )}
    </div>
  );
}

export default CompetitionSelect;

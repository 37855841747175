import React, { useEffect, useState } from "react";
import { toHTMLDate } from "helpers";
import Checkbox from "components/Checkbox";
import useUpdateTournament from "hooks/tournament/useUpdateTournament";
import "./TournamentEdit.css";

function TournamentEdit({ tournament }) {
  const [updateTournament] = useUpdateTournament();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [location, setLocation] = useState();
  const [isActive, setIsActive] = useState(false);
  const [ticketCost, setTicketCost] = useState();

  function edit() {
    const { id } = tournament;
    const updatedTournament = {
      startDate: startDate || null,
      endDate: endDate || null,
      isActive: isActive || null,
      location,
      ticketCost,
    };

    updateTournament({ id, tournament: updatedTournament });
  }

  useEffect(() => {
    setStartDate(toHTMLDate(tournament.startDate));
    setEndDate(toHTMLDate(tournament.endDate));
    setLocation(tournament.location || "");
    setIsActive(!!tournament.isActive);
    setTicketCost(tournament.ticketCost || 0);
  }, [tournament]);

  return (
    <div className="TournamentEdit card">
      <div className="card-body">
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="startDate">Start Date</label>
              <input
                type="date"
                className="form-control"
                id="startDate"
                value={startDate || ""}
                onChange={({ target: { value } }) => setStartDate(value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label htmlFor="endDate">End Date</label>
              <input
                type="date"
                className="form-control"
                id="endDate"
                value={endDate || ""}
                onChange={({ target: { value } }) => setEndDate(value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Checkbox
              checked={isActive}
              defaultValue={isActive}
              id="isActive"
              label={"Is Active"}
              onChange={setIsActive}
              useBootstrapClasses
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="location">Location</label>
              <input
                type="text"
                className="form-control"
                id="location"
                value={location || ""}
                onChange={({ target: { value } }) => setLocation(value)}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label htmlFor="cost">Ticket Cost</label>
              <input
                type="number"
                className="form-control"
                id="cost"
                value={ticketCost || 0}
                onChange={({ target: { value } }) => setTicketCost(value)}
              />
            </div>
          </div>
        </div>
        <button className="btn btn-success float-right" onClick={edit}>
          Save
        </button>
      </div>
    </div>
  );
}

export default TournamentEdit;

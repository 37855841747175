import { useQuery } from 'react-query';
import { CompetitionAPI } from 'api/admin/competition';
import { COMPETITORS } from 'helpers/constants';

export default function useCompetitor(competitionId) {
  return useQuery(
    [COMPETITORS, competitionId],
    () => CompetitionAPI.getCompetitors(competitionId),
    { enabled: competitionId }
  );
}

import React, { useState } from 'react';
import AddParticipant from 'components/AddParticipant';
import useLocalize from 'hooks/useLocalize';
import useCreateParticipant from 'hooks/participants/useCreateParticipant';
import useParticipants from 'hooks/participants/useParticipants';
import useRecoverParticipant from 'hooks/participants/useRecoverParticipant';
import Participant from './components/Participant';
import './Participants.scss';

function Participants() {
  let participantList = [];
  const localize = useLocalize();
  const { data: participants } = useParticipants();
  const [createParticipant] = useCreateParticipant();
  const [recoverParticipant] = useRecoverParticipant();
  const [isAdding, setIsAdding] = useState(false);

  function onSubmit(values) {
    createParticipant({
      participantInput: values,
      onSuccess: () => {
        setIsAdding(false);
      },
    });
  }

  if (participants && participants.length) {
    participants.forEach((p, i) => {
      participantList.push(
        <Participant
          participant={p}
          key={i}
          handleUndoDelete={id => recoverParticipant(id)}
        />
      );
    });
  } else {
    participantList = <div>{localize('No athletes yet')}.</div>;
  }

  return (
    <div className="Participants container">
      <div className="row">
        <div className="col">
          <h2>{localize('Athletes')}</h2>
        </div>
        <div className="col">
          {isAdding ? (
            <button
              className="float-right btn btn-danger"
              onClick={() => setIsAdding(false)}
            >
              {localize('Cancel')}
            </button>
          ) : (
            <button
              className="float-right btn btn-primary"
              onClick={() => setIsAdding(true)}
            >
              {localize('Add athlete')}
            </button>
          )}
        </div>
      </div>
      {isAdding && (
        <div className="row addParticipant">
          <div className="col">
            <AddParticipant onSubmit={onSubmit} />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col">{participantList}</div>
      </div>
    </div>
  );
}

export default Participants;

import { GET, POST, PUT } from '../config';

const TournamentAPI = {
  async fetchTournaments() {
    const { tournaments } = await GET('tournaments');

    return tournaments;
  },
  async fetchTournament(id) {
    const { tournament } = await GET(`tournaments/${id}`);

    return tournament;
  },
  addTournament(tournament) {
    return POST('tournaments', { tournament });
  },
  updateTournament({ id, tournament }) {
    return PUT(`tournaments/${id}`, { tournament });
  },
};

export { TournamentAPI };

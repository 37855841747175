import { useMutation } from 'react-query';
import { ErrorMessageAPI } from 'api/errorMessage';
import dispatchToast from 'helpers/dispatchToast';

export default function useCreateErrorMessage() {
  return useMutation(ErrorMessageAPI.add, {
    onSuccess: ({ message }, { onSuccess = () => {} }) => {
      dispatchToast({ message, type: 'success' });

      onSuccess();
    },
  });
}

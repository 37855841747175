import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ucFirst } from 'helpers';
import { NANDU } from 'helpers/constants';
import { NanduFormActions, NanduFormSelectors } from 'redux/nanduForm';
import NanduFormSelect from '../NanduFormSelect';
import NanduScores from '../NanduScores';
import './NanduFormOptions.css';

function NanduFormOptions() {
  const dispatch = useDispatch();
  const formType = useSelector(NanduFormSelectors.getFormType);
  const styles = useSelector(NanduFormSelectors.getWushuStyles);
  const wushuStyle = useSelector(NanduFormSelectors.getWushuStyle);
  const formTypes = useSelector(NanduFormSelectors.getFormTypes);
  const nanduScores = useSelector(NanduFormSelectors.getNanduScores);
  const allRequiredMovements = useSelector(NanduFormSelectors.getAllRequiredMovements);
  const displayedFormType = formType ? ucFirst(formType.name) : 'Select form type';
  const displayedWushuStyle = wushuStyle ? wushuStyle.name : 'Select wushu style';
  const scoreDisplay = formType.name === NANDU ? composeNanduScores() : composeMovementScores();

  function composeNanduScores() {
    const {
      totalScore,
      totalScoreIsMaxed,
      jumpScore,
      jumpScoreIsMaxed,
      connectionScore,
      connectionScoreIsMaxed,
      threeSectionsNotEmpty,
    } = nanduScores;
    return [
      {
        validator: jumpScoreIsMaxed,
        description: `Difficulty of movements: ${jumpScore}/1.4`,
      },
      {
        validator: connectionScoreIsMaxed,
        description: `Connections: ${connectionScore}/0.6`,
      },
      {
        validator: totalScoreIsMaxed,
        description: `Total: ${totalScore}/2.0`,
      },
      {
        validator: threeSectionsNotEmpty,
        failDescription: 'Needs nandu in 3 different sections',
        successDescription: 'Has nandu in 3 different sections',
      },
    ];
  }

  function composeMovementScores() {
    return [
      {
        validator: allRequiredMovements,
        failDescription: 'Needs all required movements',
        successDescription: 'Has all required movements',
      },
    ];
  }
  
  function selectFormType(formType) {
    dispatch(NanduFormActions.changeNanduFormType(formType));
  }

  function selectStyle(style) {
    dispatch(NanduFormActions.changeWushuStyle(style));
  }

  useEffect(() => {
    dispatch(NanduFormActions.fetchNanduFormData());
  }, [dispatch]);

  return (
    <>
      <div className="nanduFormOptions">
        <NanduFormSelect 
          formLabel="Form Type"
          displayProperty="name"
          defaultValue={displayedFormType}
          callback={selectFormType}
          options={formTypes}
        />
        <NanduFormSelect 
          formLabel="Wushu Style"
          displayProperty="name"
          defaultValue={displayedWushuStyle}
          callback={selectStyle}
          options={styles}
        />
      </div>
      {
        formType && wushuStyle &&
        <NanduScores validators={scoreDisplay}/>
      }
    </>
  )
}

export default NanduFormOptions;

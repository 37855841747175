import React, { useEffect, useState } from "react";
import { ExportToCsv } from "export-to-csv";
import { Button } from "react-bootstrap";
import Table from "components/admin/Table";
import BackToTop from "components/BackToTop";
import CollapseGroup from "components/CollapseGroup";
import RingSelect from "components/RingSelect";
import generateEventLabel from "helpers/generateEventLabel";
import useCheckIns from "hooks/checkIns/useCheckIns";
import useCreateCheckIn from "hooks/checkIns/useCreateCheckIn";
import useRemoveCheckIn from "hooks/checkIns/useRemoveCheckIn";
import useUpdateEventParticipationCheckInNote from "hooks/eventParticipation/useUpdateEventParticipationCheckInNote";
import useRing from "hooks/rings/useRing";
import "./CheckInHome.scss";

function TextArea({ eventParticipationId, text }) {
  const [value, setValue] = useState(text);
  const [updateCheckInNote] = useUpdateEventParticipationCheckInNote();

  return (
    <textarea
      className="form-control no-print"
      onBlur={({ target: { value } }) => {
        updateCheckInNote({ eventParticipationId, note: value });
      }}
      onChange={({ target: { value } }) => {
        setValue(value);
      }}
      type="text"
      value={value || ""}
    />
  );
}

function exportToCSV(csvData) {
  const csvExporter = new ExportToCsv({
    showLabels: true,
    useKeysAsHeaders: true,
    ...csvData.options,
  });

  csvExporter.generateCsv(csvData.data);
}

function checkInRow({
  NO,
  ID,
  Name,
  Phone,
  Group,
  checkIn1,
  checkIn2,
  checkIn3,
  Note,
}) {
  return {
    NO: NO || "",
    ID: ID || "",
    Name: Name || "",
    Phone: Phone || "",
    Group: Group || "",
    "Check In 1": checkIn1 || "",
    "Check In 2": checkIn2 || "",
    "Check In 3": checkIn3 || "",
    Note: Note || "",
  };
}

function CheckInHome({ isAdmin }) {
  const [ringId, setRingId] = useState();
  const { data: checkIns } = useCheckIns(ringId);
  const { data: ring } = useRing(ringId);
  const [createCheckIn] = useCreateCheckIn();
  const [removeCheckIn] = useRemoveCheckIn();
  const [items, setItems] = useState([]);
  const [exportToCSVData, setExportToCSVDataData] = useState({});

  useEffect(() => {
    const csvData = {
      options: {
        filename: "master-schedule",
      },
      data: [
        checkInRow({
          NO: `Ring Name: ${ring?.name}`,
          ID: `Ring Desc: ${ring?.description}`,
        }),
      ],
    };
    const tempItems = checkIns?.events.map(
      ({
        code,
        gender,
        ageGroup,
        ageGroupName,
        eventName,
        competitionEventId,
        participants,
      }) => {
        csvData.data.push(
          checkInRow({
            NO: `Gender: ${gender}`,
            ID: `Age Group: ${ageGroup} (${ageGroupName})`,
            Name: `Event: ${eventName}`,
          })
        );

        const participantData = participants?.map((p) => {
          p.checkIn1 = {
            isCheckbox: true,
            props: {
              onChange: () => {
                const checkInData = {
                  eventParticipationId: p.eventParticipationId,
                  count: 1,
                };

                if (p.checkIns[1]) {
                  removeCheckIn(checkInData);
                } else {
                  createCheckIn(checkInData);
                }
              },
              checked: p.checkIns[1],
            },
          };

          p.checkIn2 = {
            isCheckbox: true,
            props: {
              onChange: () => {
                const checkInData = {
                  eventParticipationId: p.eventParticipationId,
                  count: 2,
                };

                if (p.checkIns[2]) {
                  removeCheckIn(checkInData);
                } else {
                  createCheckIn(checkInData);
                }
              },
              checked: p.checkIns[2],
            },
          };

          p.checkIn3 = {
            isCheckbox: true,
            props: {
              onChange: () => {
                const checkInData = {
                  eventParticipationId: p.eventParticipationId,
                  count: 3,
                };

                if (p.checkIns[3]) {
                  removeCheckIn(checkInData);
                } else {
                  createCheckIn(checkInData);
                }
              },
              checked: p.checkIns[3],
            },
          };

          p.checkInNote = (
            <TextArea
              eventParticipationId={p.eventParticipationId}
              text={p.checkInNote}
            />
          );

          csvData.data.push(
            checkInRow({
              NO: p.lotOrder,
              ID: p.participantId,
              Name: p.name,
              Phone: p.phone,
              Group: p.teamName,
            })
          );

          return p;
        });
        const data = {
          headingData: {
            // lotOrder: "Order",
            participantId: "ID",
            name: "Name",
            teamName: "Group",
            checkIn1: "Check",
            checkIn2: "Check",
            checkIn3: "Check",
            checkInNote: "Note",
          },
          items: participantData || [],
        };
        const ageGroupString =
          ageGroup && ageGroupName ? `${ageGroup} (${ageGroupName})` : "";
        const label = generateEventLabel(
          gender,
          ageGroupString,
          eventName,
          code
        );

        csvData.data.push(checkInRow({}));

        return {
          content: (
            <div className="EventCheckIn">
              <div className="EventCheckInInfo d-flex justify-content-between mb-2">
                {gender && <span>Gender: {gender}</span>}
                {ageGroup && (
                  <span>
                    Ages: {ageGroup} ({ageGroupName})
                  </span>
                )}
              </div>
              <Table data={data} />
            </div>
          ),
          label,
          id: competitionEventId,
        };
      }
    );

    setItems(tempItems);
    setExportToCSVDataData(csvData);
  }, [checkIns]);

  return (
    <div className="CheckInHome">
      <div className="content">
        <RingSelect
          id={"CheckInHome"}
          onChange={({ ringId }) => setRingId(ringId)}
          inPersonOnly
        />
        {isAdmin && (
          <div>
            <Button
              onClick={() => exportToCSV(exportToCSVData)}
              className="btn-success mb-3 no-print"
            >
              Export Ring Data to CSV
            </Button>
          </div>
        )}
        {
          <div className="print-only">
            <h3>{ring?.name}</h3>
            <h5>{ring?.description}</h5>
          </div>
        }
        <CollapseGroup id={`checkIn-${ringId}`} items={items} />
        <BackToTop />
      </div>
    </div>
  );
}

export default CheckInHome;

import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextInput from "components/TextInput";
import TextArea from "components/TextArea";
import calculateFinalScore from "helpers/calculateFinalScore";
import useEventFeedbackAll from "hooks/eventFeedback/useEventFeedbackAll";
import useCreateEventFeedback from "hooks/eventFeedback/useCreateEventFeedback";
import useUpdateEventFeedback from "hooks/eventFeedback/useUpdateEventFeedback";
import useUpdateEventParticipationFeedback from "hooks/eventParticipation/useUpdateEventParticipationFeedback";
import "./ScoreTable.scss";

function createKey(feedbackId, judgeId, suffix) {
  return feedbackId
    ? `feedback-${feedbackId}-${suffix}`
    : `judge-${judgeId}-${suffix}`;
}

function ScoreTable({ eventParticipationId, judgeId }) {
  const { data: eventFeedback } = useEventFeedbackAll({
    judgeId,
    eventParticipationId,
  });
  const [createEventFeedback] = useCreateEventFeedback(true);
  const [updateEventFeedback] = useUpdateEventFeedback(true);
  const [
    updateEventParticipationFeedback,
  ] = useUpdateEventParticipationFeedback();
  const [isEditing, setIsEditing] = useState();
  const [final, setFinal] = useState();
  const initialValues = {};
  let validationSchema = {};
  let scores = [];
  let headers = [<th key="judge">Judge</th>];
  let scoresRow = [<td key="score">Score</td>];
  let commentsRow = [<td key="notes">Adjustment/Notes</td>];
  let editRow = [<td key="edit">Edit</td>];
  let allComplete = true;

  function createCells({ score, comment, key, header, editCell, isFinal }) {
    let scoreCell = score;
    let commentCell = comment;

    if (isEditing && key === isEditing) {
      const scoreInputName = `${key}-score`;
      const commentInputName = `${key}-comment`;

      initialValues[scoreInputName] = score || "";
      initialValues[commentInputName] = comment || "";
      validationSchema = {
        ...validationSchema,
        [scoreInputName]: Yup.number()
          .typeError("Score must be a number")
          .min(0, "Score must be at least 0")
          .max(10, "Score must be less than 10"),
        [commentInputName]: Yup.string(),
      };
      scoreCell = <TextInput name={scoreInputName} />;
      commentCell = <TextArea name={commentInputName} />;
      editCell = (
        <>
          <button className="btn btn-success mr-1" type="submit">
            Save
          </button>
          <button
            className="btn btn-danger"
            onClick={(e) => {
              e.preventDefault();
              setIsEditing(false);
              setFinal(null);
            }}
            type="button"
          >
            Cancel
          </button>
        </>
      );

      if (allComplete && isFinal) {
        scoreCell = (
          <>
            <TextInput name={scoreInputName} />
            <button
              className="btn btn-info mt-1"
              onClick={(e) => {
                e.preventDefault();
                setFinal(calculateFinalScore(scores));
              }}
              type="button"
            >
              Calculate Final Score
            </button>
          </>
        );
      }
    }

    headers.push(<th key={key}>{header}</th>);
    scoresRow.push(<td key={key}>{scoreCell}</td>);
    commentsRow.push(<td key={key}>{commentCell}</td>);
    editRow.push(<td key={key}>{editCell}</td>);
  }

  if (eventFeedback) {
    eventFeedback.forEach(
      (
        { comment, email, feedbackId, judgeId, isHeadJudge, score, username },
        index
      ) => {
        if (isHeadJudge) {
          const key = createKey(feedbackId, judgeId, "head");

          createCells({
            score,
            comment,
            key,
            header: `Head Judge (ID: ${judgeId})`,
            editCell: (
              <button
                className="btn btn-warning"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditing(key);
                }}
                type="button"
              >
                Edit
              </button>
            ),
          });

          if (!score) {
            allComplete = false;
          } else {
            scores.push(score);
          }
        } else if (index === eventFeedback.length - 1) {
          const key = createKey(null, judgeId, "final");

          createCells({
            score: final || score,
            comment,
            key,
            header: "Final Score",
            editCell: (
              <button
                className="btn btn-warning"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditing(key);
                }}
                type="button"
              >
                Edit
              </button>
            ),
            isFinal: true,
          });
        } else {
          const key = createKey(feedbackId, judgeId, index);

          createCells({
            score,
            comment,
            key,
            header: `${username || email}`,
            editCell: (
              <button
                className="btn btn-warning"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditing(key);
                }}
                type="button"
              >
                Edit
              </button>
            ),
          });

          if (!score) {
            allComplete = false;
          } else {
            scores.push(score);
          }
        }
      }
    );
  }

  useEffect(() => {
    setFinal(null);
    setIsEditing(false);
  }, [eventParticipationId, judgeId]);

  return (
    <div className="ScoreTable">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchema)}
        onSubmit={(values) => {
          const vals = Object.entries(values).reduce((acc, [key, val]) => {
            if (key.includes(isEditing)) {
              const [
                feedbackOrJudge,
                id,
                headOrFinal,
                feedbackType,
              ] = key.split("-");

              if (headOrFinal === "final") {
                if (!acc[headOrFinal]) {
                  acc[headOrFinal] = {
                    eventParticipationId,
                  };
                }

                acc[headOrFinal][feedbackType] = val;
              } else {
                if (!acc[headOrFinal]) {
                  acc[headOrFinal] = {};

                  if (feedbackOrJudge === "feedback") {
                    acc[headOrFinal].id = id;
                  } else {
                    acc[headOrFinal].judgeId = id;
                  }
                }

                acc[headOrFinal][feedbackType] = val;
              }
            }

            return acc;
          }, {});

          Object.entries(vals).forEach(
            ([key, { id, judgeId, comment, score }]) => {
              if (key === "final") {
                if (comment || score) {
                  updateEventParticipationFeedback({
                    id: eventParticipationId,
                    score,
                    comment,
                  });
                }
              } else if (comment || score) {
                if (id) {
                  updateEventFeedback({
                    id,
                    score,
                    comment,
                  });
                } else {
                  createEventFeedback({
                    judgeId,
                    eventParticipationId,
                    score,
                    comment,
                  });
                }
              }
            }
          );

          setIsEditing(false);
        }}
      >
        <Form className="needs-validation" noValidate>
          <table className="table">
            <thead className="thead-dark">
              <tr>{headers}</tr>
            </thead>
            <tbody>
              <tr>{scoresRow}</tr>
              <tr>{commentsRow}</tr>
              <tr>{editRow}</tr>
            </tbody>
          </table>
        </Form>
      </Formik>
    </div>
  );
}

export default ScoreTable;

import React, { useState } from 'react';
import './Accordion.scss';

function Accordion({ children, id, isOpen, title }) {
  const [chevronDown, setChevronDown] = useState(isOpen);
  const chevronDirection = chevronDown ? 'down' : 'up';
  const showCollapse = isOpen ? 'collapse show' : 'collapse';

  return (
    <div className="Accordion card">
      <div
        className="card-header"
        data-toggle="collapse"
        data-target={`#card-body-${id}`}
        onClick={() => setChevronDown(!chevronDown)}
      >
        <h5 className="name">{title}</h5>
        <span className={`ion-chevron-${chevronDirection} float-right`}></span>
      </div>
      <div id={`card-body-${id}`} className={showCollapse}>
        <div className="card-body">{chevronDown && children}</div>
      </div>
    </div>
  );
}

export default Accordion;

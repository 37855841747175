import React, { useRef, useEffect } from "react";
import classNames from "classnames";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import useLocalize from "hooks/useLocalize";
import "./DraggableTableItem.scss";

const CARD = "CARD";

export default function DraggableTableItem(props) {
  const ref = useRef(null);
  const localize = useLocalize();

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: CARD },
    begin: (monitor) => {
      const { item: draggedCard } = props;
      let cards;
      if (props.selectedCards.find((card) => card.id === props.id)) {
        cards = props.selectedCards;
      } else {
        props.clearItemSelection();
        cards = [draggedCard];
      }
      const otherCards = cards.concat();
      otherCards.splice(
        cards.findIndex((c) => c.id === props.id),
        1
      );
      const cardsDragStack = [draggedCard, ...otherCards];
      const cardsIDs = cards.map((c) => c.id);
      return { cards, cardsDragStack, draggedCard, cardsIDs };
    },
    isDragging: (monitor) => {
      return monitor.getItem().cardsIDs.includes(props.id);
    },
    end: (item, monitor) => {
      props.rearrangeCards(item);
      // props.clearItemSelection();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ hovered }, drop] = useDrop({
    accept: CARD,
    // drop: () => ({
    //               boxType: "Picture"
    //   }),
    hover: (item, monitor) => {
      const dragIndex = item.draggedCard.index;
      const hoverIndex = props.index;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get horizontal middle
      const midX =
        hoverBoundingRect.left +
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const pointerOffset = monitor.getClientOffset();
      const newInsertIndex =
        pointerOffset.x < midX ? hoverIndex : hoverIndex + 1;
      props.setInsertIndex(dragIndex, hoverIndex, newInsertIndex);
    },
    collect: (monitor) => ({
      hovered: monitor.isOver(),
    }),
  });

  drag(drop(ref));

  const onClick = (e) => {
    props.onSelectionChange(props.index, e.metaKey, e.shiftKey);
  };

  useEffect(() => {
    // This gets called after every render, by default
    // (the first one, and every one after that)

    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    preview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true,
    });
    // If you want to implement componentWillUnmount,
    // return a function from here, and React will call
    // it prior to unmounting.
    // return () => console.log('unmounting...');
  }, []);

  const opacity = isDragging ? 0.4 : 1;

  return (
    <tr
      key={"tr"}
      ref={ref}
      className={classNames("DraggableTableItem", {
        selected: props.isSelected,
        insertLineAbove: props.insertLineAbove && hovered,
        insertLineBelow: props.insertLineBelow && hovered,
      })}
      onClick={onClick}
      style={{ opacity }}
    >
      {Object.keys(props.headingData).map((key) => {
        const cellData = props.item[key] || "-";

        return (
          <td key={key}>
            <span>{localize(cellData)}</span>
          </td>
        );
      })}
    </tr>
  );
}

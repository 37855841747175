import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "components/Modal/ModalFields/Select";
import Badges from "./Badges";
import "./PrintingStation.scss";

function PrintingStation({ competitionId }) {
  const [view, setView] = useState("badges");
  const views = [
    {
      label: "Badges",
      value: "badges",
    },
    {
      label: "Check In/Roll Call",
      value: "checkIn",
    },
  ];

  return (
    <div className="PrintingStation">
      <h5>Select Printing View</h5>
      <Select items={views} onChange={(c) => setView(c.value)} />
      <hr />
      {view === "badges" && <Badges competitionId={competitionId} />}
      {view === "checkIn" && (
        <div className="CheckIn">
          <Link to={`/check-in`}>
            <div className="btn btn-success">Go to check-in/roll call</div>
          </Link>
        </div>
      )}
    </div>
  );
}

export default PrintingStation;

import React from 'react';
import { POST, DELETE } from 'api/config';
import dispatchToast from 'helpers/dispatchToast';

const CompetitionVoteAPI = {
  async add({ competitionId, eventParticipationId, userId }) {
    return await POST(
      `competitionVote`,
      {
        competitionId,
        eventParticipationId,
        userId,
      },
      message => {
        const [baseMessage, id] = message.split('|');
        const errorMessage = (
          <>
            {baseMessage}{' '}
            <a
              style={{
                'text-decoration': 'underline',
                color: 'blue',
              }}
              href={`${window.location.origin}/video/${id}`}
            >
              Click here to visit the video you voted for.
            </a>
          </>
        );

        dispatchToast({
          message: errorMessage,
        });
      }
    );
  },
  async remove({ competitionId, userId }) {
    return await DELETE(`competitionVote`, {
      competitionId,
      userId,
    });
  },
};

export { CompetitionVoteAPI };

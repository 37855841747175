import React, { useRef } from "react";
import classnames from "classnames";
import { useField } from "formik";
import "./ImageInput.scss";

function ImageInput({
  disabled,
  footnote,
  isUploading,
  processFile,
  uid,
  label,
  ...props
}) {
  const labelClasses = classnames("custom-file-label", { disabled });
  const inputGroupClasses = classnames("ImageInput", "form-group");
  const ref = useRef();
  const [, meta] = useField(props);
  const isInvalid = meta.touched && meta.error ? "is-invalid" : "";

  return (
    <div className={inputGroupClasses}>
      <div className="custom-file">
        {isUploading && <div className="spinner-border"></div>}
        {!isUploading && (
          <>
            <input
              accept="image/*"
              className={`form-control ${isInvalid}`}
              disabled={disabled}
              id={`inputGroupFile-${uid}`}
              onChange={() => {
                const [file] = ref.current.files;

                processFile(file);
              }}
              ref={ref}
              type="file"
              {...props}
            />
            <label className={labelClasses} htmlFor={`inputGroupFile-${uid}`}>
              {label}
            </label>
            {meta.touched && meta.error ? (
              <div className="invalid-feedback">{meta.error}</div>
            ) : null}
          </>
        )}
      </div>
      {footnote && <div>{footnote}</div>}
    </div>
  );
}

export default ImageInput;

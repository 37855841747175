import { useMutation } from 'react-query';
import { UserAPI } from 'api/user';
import dispatchToast from 'helpers/dispatchToast';

export default function useForgotPassword() {
  return useMutation(
    UserAPI.forgotPassword,
    {
      onSuccess: ({ message }) => {
        dispatchToast({ message, type: 'success' });
      },
    }
  );
}

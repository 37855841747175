import React, { useEffect, useState } from "react";
import calculateFinalScore from "helpers/calculateFinalScore";
import useUpdateEventParticipationScores from "hooks/eventParticipation/useUpdateEventParticipationScores";
import Number from "./components/Number";
import TextArea from "./components/TextArea";
import Text from "./components/Text";
import "./ScoreTableRow.scss";

function ScoreTableRow({ competitionEventId, eventParticipation, index }) {
  const eventParticipationId = eventParticipation?.id;
  const [updateEventParticipationScores] = useUpdateEventParticipationScores({
    competitionEventId,
  });
  const [final, setFinal] = useState("");
  const [comment, setComment] = useState("");
  const [adjustment, setAdjustment] = useState("");
  const [time, setTime] = useState("");
  const [scores, setScores] = useState({});
  let headJudgeScore = "";
  let localScores = [];
  let scoresCells = [];

  useEffect(() => {
    const tempScores = eventParticipation.scores.reduce(
      (acc, { feedbackId, judgeId, score }) => {
        if (judgeId && score) {
          // init scores for save scores button
          acc[judgeId] = {
            score,
            id: feedbackId,
            judgeId,
          };
        }

        return acc;
      },
      {}
    );

    setScores(tempScores);
    setComment(eventParticipation.finalComment || "");
    setFinal(eventParticipation.finalScore || "");
    setAdjustment(eventParticipation.adjustment || "");
    setTime(eventParticipation.time || "");
  }, []);

  if (eventParticipation.scores) {
    eventParticipation.scores.forEach(
      ({ feedbackId, judgeId, isHeadJudge, score }, index) => {
        // final score is included in the eventFeedback API, so we ignore it
        // TODO: this should be decoupled from the online tournament fetch call
        if (judgeId) {
          // render html
          const numberInput = (
            <Number
              name={`score-${judgeId}`}
              onChange={(newScore) => {
                setScores({
                  ...scores,
                  [judgeId]: {
                    score: +newScore,
                    id: feedbackId,
                    judgeId,
                  },
                });
              }}
              text={scores?.[judgeId]?.score || ""}
            />
          );

          if (isHeadJudge) {
            headJudgeScore = numberInput;
          } else {
            scoresCells.push(
              <td key={`${feedbackId}-${index}`}>{numberInput}</td>
            );
          }

          // store scores for local final score calculation
          if (score) {
            localScores.push(score);
          }
        }
      }
    );
  }

  return (
    <tr>
      <td>{index}</td>
      <td>{eventParticipation.participantId}</td>
      <td style={{ maxWidth: "200px" }}>{eventParticipation.name}</td>
      {scoresCells}
      <td>{headJudgeScore}</td>
      <td>
        <Number
          name={`adjustment-${eventParticipationId}`}
          onChange={setAdjustment}
          text={adjustment}
        />
      </td>
      <td>
        <Text
          name={`time-${eventParticipationId}`}
          onChange={setTime}
          text={time}
        />
      </td>
      <td>
        <Number
          name={`final-${eventParticipationId}`}
          onChange={setFinal}
          text={final}
        />
        {true && (
          <button
            className="btn btn-info mt-1 no-print"
            onClick={(e) => {
              e.preventDefault();
              const localScores = Object.values(scores)
                .filter(({ score }) => !!score)
                .map(({ score }) => score);

              setFinal(calculateFinalScore(localScores, +adjustment) || "");
            }}
            type="button"
          >
            Calculate Final Score
          </button>
        )}
      </td>
      <td>{eventParticipation.rank}</td>
      <td>
        <TextArea
          name={`comment-${eventParticipationId}`}
          onChange={setComment}
          text={comment}
        />
      </td>
      <td className="no-print">
        <button
          className="btn btn-success"
          onClick={(e) => {
            e.preventDefault();
            updateEventParticipationScores({
              id: eventParticipationId,
              feedbackInput: {
                scores: Object.values(scores).map((s) => s),
                comment,
                finalScore: final || null,
                adjustment: adjustment || null,
                time,
              },
            });
          }}
          type="button"
        >
          Save
        </button>
      </td>
    </tr>
  );
}

export default ScoreTableRow;

import React, { useState, useEffect } from "react";
import { ExportToCsv } from "export-to-csv";
import { Button } from "react-bootstrap";
import ReactSelect from "react-select";
import camelCaseToSentenceCase from "helpers/camelCaseToSentenceCase";
import CONFIRM_DELETE from "helpers/confirmDelete";
import useLocalize from "hooks/useLocalize";
import TableHeading from "../TableHeading";
import DragDropContainer from "components/DragDropContainer";
import TableItem from "./TableItem";
import "./Table.scss";

function compare(a, b, keys, index = 0) {
  if (!keys[index]) {
    return 0;
  }

  const { sortBy, sortAscending } = keys[index];
  const aSort = a[sortBy];
  const bSort = b[sortBy];

  if (!aSort) {
    if (!bSort) {
      return 0;
    }

    return 1;
  }

  if (!bSort) {
    return -1;
  }

  let comparison;

  if (typeof aSort === "string" || typeof bSort === "string") {
    comparison = aSort.toString().localeCompare(bSort.toString(), undefined, {
      numeric: true,
      sensitivity: "base",
    });
  } else {
    comparison = aSort - bSort;
  }

  if (comparison < 0) {
    return sortAscending ? -1 : 1;
  }

  if (comparison > 0) {
    return sortAscending ? 1 : -1;
  }

  return compare(a, b, keys, index + 1);
}

function Table({
  addItemClick,
  data,
  disableSort,
  exportToCSVData,
  id = "drag-and-drop-id",
  isIndexed = true,
  isSelectable,
  itemClick,
  onDeleteSelected,
  onAddSelected,
  onReorder,
  pageTotal,
}) {
  const {
    items,
    childTableData,
    filters,
    searchableColumns,
    defaultSort,
    defaultSortAscending,
    itemName,
  } = data;
  const [search, setSearch] = useState("");
  const [sorts, setSorts] = useState([]);
  const [filter, setFilter] = useState({});
  const [visibleItems, setVisibleItems] = useState(items);
  const [selected, setSelected] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isReordering, setIsReordering] = useState(false);
  const [reorderedItems, setReorderedItems] = useState();
  const localize = useLocalize();
  const filterMap =
    filters?.reduce((acc, filter) => {
      acc[filter] = {};

      items.forEach((item) => {
        const value = item[filter];

        if (!acc[filter][value]) {
          acc[filter][value] = {
            count: 0,
            label: value || localize("None"),
            value: value || "",
          };
        }

        acc[filter][value].count += 1;
      });

      return acc;
    }, {}) || {};
  const filtersHTML = [];
  let { headingData } = data;
  let tableHeadings = [];
  let pageNumbers;

  function checkboxOnChange({ index }) {
    const idx = index - 1;
    const newSelected = {
      ...selected,
      [idx]: !selected[idx],
    };

    setSelected(newSelected);
  }

  function exportToCSV() {
    const csvExporter = new ExportToCsv({
      showLabels: true,
      useKeysAsHeaders: true,
      ...exportToCSVData.options,
    });

    csvExporter.generateCsv(exportToCSVData.data);
  }

  // Insert columns in front of provided header data
  if (isSelectable?.columnCheckbox && !isReordering) {
    headingData = {
      select: "Select",
      ...headingData,
    };
  }

  if (isIndexed === true) {
    headingData = {
      index: "#",
      ...headingData,
    };
  }

  for (const [propertyName, displayName] of Object.entries(headingData)) {
    const sortIndex = sorts.findIndex(({ sortBy }) => sortBy === propertyName);
    const sort = sorts[sortIndex];
    let onClick = () => {};

    if (propertyName !== "checkable" && !disableSort) {
      onClick = () => {
        if (sortIndex !== -1) {
          if (sorts[sortIndex].sortAscending) {
            setSorts([
              ...sorts.slice(0, sortIndex),
              {
                ...sorts[sortIndex],
                sortAscending: false,
              },
              ...sorts.slice(sortIndex + 1, sorts.length),
            ]);
          } else {
            setSorts([
              ...sorts.slice(0, sortIndex),
              ...sorts.slice(sortIndex + 1, sorts.length),
            ]);
          }
        } else {
          setSorts([...sorts, { sortBy: propertyName, sortAscending: true }]);
        }
      };
    }

    tableHeadings.push(
      <TableHeading
        key={propertyName}
        onClick={onClick}
        text={displayName}
        showArrow={!!sort}
        up={sort?.sortAscending}
      />
    );
  }

  Object.entries(filterMap).forEach(([key, filterGroup]) => {
    const options =
      Object.values(filterGroup).sort((a, b) => {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }) || [];

    filtersHTML.push(
      <div className="form-group" key={key}>
        <ReactSelect
          displayName={headingData[key]}
          isMulti
          name={key}
          options={options}
          onChange={(val) =>
            setFilter({
              ...filter,
              [key]: val,
            })
          }
          placeholder={localize(camelCaseToSentenceCase(key))}
        />
      </div>
    );
  });

  if (isSelectable && typeof isSelectable === "boolean") {
    itemClick = ({ index }) => {
      const idx = index - 1;
      const newSelected = {
        ...selected,
        [idx]: !selected[idx],
      };

      setSelected(newSelected);
    };
  }

  if (pageTotal) {
    pageNumbers = [];

    for (let i = 0; i < numPages; i++) {
      const active = currentPage === i ? "active" : "";

      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${active}`}
          onClick={() => setCurrentPage(i)}
        >
          <button className="page-link">{i + 1}</button>
        </li>
      );
    }
  }

  // remove current selections
  useEffect(() => {
    setSelectAll(false);
    setSelected({});
  }, [filter, search]);

  // set selected item state
  useEffect(() => {
    let selectedItems = {};

    if (selectAll) {
      selectedItems = visibleItems.reduce((acc, { index }) => {
        const idx = index - 1;

        acc[idx] = true;

        return acc;
      }, {});
    }

    setSelected(selectedItems);
  }, [selectAll]);

  // set default sorts
  useEffect(() => {
    if (defaultSort) {
      setSorts([
        {
          sortBy: defaultSort,
          sortAscending: defaultSortAscending,
        },
      ]);
    }
  }, [defaultSort, defaultSortAscending]);

  // update visible items
  useEffect(() => {
    let visible = items;

    if (filters) {
      visible = visible.filter((item) => {
        let include = Object.entries(filter).reduce((bool, [key, val]) => {
          if (val) {
            bool =
              bool &&
              val.reduce((acc, { value }) => {
                if (item[key] === null) {
                  // this needs to be inside, because null is a special case
                  if (value === "") {
                    return true;
                  }
                } else if (item[key] === value) {
                  return true;
                }

                return acc;
              }, false);
          }

          return bool;
        }, true);

        return include;
      });
    }

    if (searchableColumns) {
      visible = visible.filter((item) => {
        const searchTerm = search.toLowerCase();
        for (const searchable of searchableColumns) {
          if (
            item?.[searchable]?.toString().toLowerCase().includes(searchTerm)
          ) {
            return true;
          }
        }
        return false;
      });
    }

    if (!disableSort) {
      visible.sort((a, b) => {
        return compare(a, b, sorts);
      });
    }

    if (isIndexed === true) {
      visible = visible.map((item, index) => {
        item.index = index + 1;

        return item;
      });
    }

    if (pageTotal) {
      const pageOffset = currentPage * pageTotal;
      const newOffset = pageOffset + pageTotal;
      const pages = Math.ceil(visible.length / pageTotal);

      visible = visible.slice(pageOffset, newOffset);
      setNumPages(pages);
    }

    setVisibleItems(visible);
    setReorderedItems(visible);
  }, [
    filter,
    setVisibleItems,
    searchableColumns,
    search,
    items,
    JSON.stringify(sorts),
    currentPage,
  ]);

  // set default page
  useEffect(() => {
    setCurrentPage(0);
  }, [numPages]);

  // reset when reordering
  useEffect(() => {
    if (isReordering) {
      setFilter({});
      setSearch("");
    }
  }, [isReordering]);

  return (
    <div className="Table adminTable-container">
      <div className="button-row d-flex justify-content-between">
        {exportToCSVData && (
          <Button onClick={exportToCSV} className="mb-3">
            Export to CSV
          </Button>
        )}
        {onReorder && (
          <div>
            {isReordering && (
              <Button
                onClick={() => {
                  if (reorderedItems) {
                    onReorder(reorderedItems);
                  }

                  setIsReordering(false);
                }}
                className="mb-3 mr-1 btn-success"
              >
                Save Order
              </Button>
            )}
            <Button
              onClick={() => setIsReordering(!isReordering)}
              className="mb-3 btn-warning"
            >
              {isReordering ? "Cancel Reorder" : "Reorder Items"}
            </Button>
          </div>
        )}
      </div>
      {isReordering && (
        <p className="float-right">
          Hold <b>CMD</b> or <b>SHIFT</b> to select multiple
        </p>
      )}
      {!isReordering && (
        <>
          <div className="top-row d-flex justify-content-between">
            <div className="search">
              {!!searchableColumns && (
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={search}
                    onChange={({ target: { value } }) => setSearch(value)}
                    placeholder={`${localize("Search")}...`}
                  />
                </div>
              )}
            </div>
            <div className="buttons d-flex flex-row">
              {!!isSelectable && (
                <div className="form-group">
                  <button
                    className="btn btn-primary"
                    onClick={() => setSelectAll(!selectAll)}
                  >
                    {selectAll ? "Unselect All" : "Select All"}
                  </button>
                </div>
              )}
              {onDeleteSelected && (
                <div className="form-group ml-1">
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      CONFIRM_DELETE(() => {
                        const selectedItems = Object.entries(selected).reduce(
                          (acc, [key, val]) => {
                            if (val) {
                              acc.push(visibleItems[key]);
                            }

                            return acc;
                          },
                          []
                        );

                        onDeleteSelected(selectedItems);
                        setSelectAll(false);
                        setSelected({});
                      });
                    }}
                  >
                    Remove Selected
                  </button>
                </div>
              )}
              {onAddSelected && (
                <div className="form-group ml-1">
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      const selectedItems = Object.entries(selected).reduce(
                        (acc, [key, val]) => {
                          if (val) {
                            acc.push(visibleItems[key]);
                          }

                          return acc;
                        },
                        []
                      );

                      onAddSelected(selectedItems);
                      setSelectAll(false);
                      setSelected({});
                    }}
                  >
                    Add Selected
                  </button>
                </div>
              )}
              {addItemClick && (
                <div className="form-group ml-1">
                  <button className="btn btn-success" onClick={addItemClick}>
                    Add {itemName}
                  </button>
                </div>
              )}
            </div>
          </div>
          {filters && (
            <>
              <h6>{localize("Filters")}</h6>
              <div className="filters d-flex">{filtersHTML}</div>
            </>
          )}
        </>
      )}
      {pageTotal && (
        <nav aria-label="navigation">
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => {
                  if (currentPage > 0) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {pageNumbers}
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => {
                  if (currentPage < numPages - 1) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      )}
      <div className="table-container">
        <table className="adminTable table table-hover text-left">
          <thead className="thead-dark">
            <tr>{tableHeadings}</tr>
          </thead>
          <tbody>
            {isReordering ? (
              <DragDropContainer
                items={visibleItems}
                headingData={headingData}
                onDrop={setReorderedItems}
              />
            ) : (
              visibleItems.map((item, index) => {
                return (
                  <TableItem
                    key={index}
                    item={item}
                    itemClick={itemClick}
                    headingData={headingData}
                    selected={selected[item.index - 1]}
                    checkboxOnChange={checkboxOnChange}
                    childTableData={childTableData}
                  />
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;

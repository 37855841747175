import React from "react";
import { useSelector } from "react-redux";
import Logout from "components/Logout";
import { AuthSelectors } from "redux/auth";
import "./Header.scss";

function Header() {
  const user = useSelector(AuthSelectors.getUser);

  return (
    <nav className="Header navbar navbar-dark navbar-expand-lg no-print">
      <div className="container-fluid">
        <h1 className="navbar-brand">Athlete Check In</h1>
        {user && <Logout className="nav-link" to="/check-in" />}
      </div>
    </nav>
  );
}

export default Header;

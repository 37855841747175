import React, { useState } from "react";
import Accordion from "components/Accordion";
import CompetitionSelect from "components/CompetitionSelect";
import useCompetition from "hooks/competitions/useCompetition";
import useEventParticipationForMyResults from "hooks/eventParticipation/useEventParticipationForMyResults";
import useLocalize from "hooks/useLocalize";
import ResultCard from "./ResultCard";
import "./MyResults.scss";

function MyResults() {
  const localize = useLocalize();
  const [competitionId, setCompetitionId] = useState();
  const { data: competition } = useCompetition(competitionId);
  const { data: eventParticipation } = useEventParticipationForMyResults(
    competitionId
  );

  return (
    <div className="MyResults container">
      <h2>{localize("My Results")}</h2>
      <div className="row">
        <div className="col">
          <CompetitionSelect
            id={"my-results"}
            onChange={({ value }) => setCompetitionId(value)}
          />
        </div>
      </div>
      {eventParticipation &&
        Object.entries(eventParticipation)?.map(([participantId, epList]) => (
          <Accordion
            id={`results-${participantId}`}
            key={participantId}
            title={epList[0].participantName}
          >
            <ResultCard
              eventParticipation={epList}
              isVirtual={competition?.isVirtual}
            />
          </Accordion>
        ))}
    </div>
  );
}

export default MyResults;

import { PUT, GET, DELETE } from "./config";

const UserAPI = {
  async getUsers(filter) {
    let url = `users`;

    if (filter === "payment") {
      url = `${url}/registered`;
    } else if (filter === "videoSubmissions") {
      url = `${url}/videoSubmissions`;
    } else if (filter === "admin") {
      url = `${url}/adminUsers`;
    }

    const { users } = await GET(url);

    return users;
  },
  async getUserById(id) {
    const { user } = await GET(`users/${id}`);

    return user;
  },
  async removeUser(id) {
    return await DELETE(`users/${id}`);
  },
  async adminRemoveUser(id) {
    return await DELETE(`users`, { id });
  },
  async updateEmail({ email, id }) {
    return await PUT(`users/${id}/email`, { email });
  },
  async adminUpdateEmail({ email, id }) {
    return await PUT(`users/email`, { email, id });
  },
  async updatePassword({ password, confirmedPassword, id }) {
    return await PUT(`users/${id}/password`, { password, confirmedPassword });
  },
  async adminUpdatePassword({ password, confirmedPassword, id }) {
    return await PUT(`users/password`, { password, confirmedPassword, id });
  },
  async updateRole({ roleId, id }) {
    return await PUT(`users/role`, { roleId, id });
  },
  async forgotPassword(email) {
    return await GET(`users/forgot/${email}`);
  },
  async resetPassword({ password, confirmedPassword, token }) {
    debugger;
    return await PUT(`users/resetPassword`, {
      password,
      confirmedPassword,
      token,
    });
  },
  async getUserParticipants(id) {
    const { participants } = await GET(`users/${id}/participants`);

    return participants;
  },
};

export { UserAPI };

import { useMutation, useQueryCache } from 'react-query';
import { RingAPI } from 'api/admin/ring';
import { COMPETITIONS } from 'helpers/constants';
import dispatchToast from 'helpers/dispatchToast';

export default function useUpdateRing() {
  const queryCache = useQueryCache();

  return useMutation(
    ({ id, description, name }) => RingAPI.updateRing(id, { description, name }),
    {
      onSuccess: async ({ message }) => {
        queryCache.invalidateQueries(COMPETITIONS);
        dispatchToast({ message, type: 'success' });
      },
    }
  );
}

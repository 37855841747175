import { useQuery } from "react-query";
import { EventFeedbackAPI } from "api/admin/eventFeedback";
import { EVENT_FEEDBACK } from "helpers/constants";

export default function useEventFeedbackAll({ judgeId, eventParticipationId }) {
  return useQuery(
    [EVENT_FEEDBACK, "all", judgeId, eventParticipationId],
    () =>
      EventFeedbackAPI.getAllForEventParticipant({
        judgeId,
        eventParticipationId,
      }),
    { enabled: judgeId && eventParticipationId }
  );
}

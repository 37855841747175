import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import Login from 'components/admin/AdminLogin';
import AdminHome from 'scenes/Admin/scenes/AdminHome';
import { AuthSelectors } from 'redux/auth';
import NotFound from "scenes/NotFound";

function AdminRoute({ children, path }) {
  const isAuthorized = useSelector(AuthSelectors.isAdmin);

  return (
    <Route path={path}>
      { isAuthorized
        ? children
        : <Redirect to={{ pathname: "/" }} />
      }
    </Route>
  );
}

function Admin() {
  const { path } = useRouteMatch();

  return (
    <div className="Admin">
      <Switch>
        <Route path={`${path}`} exact><Login/></Route>
        <AdminRoute path={`${path}/home`}><AdminHome/></AdminRoute>
        <Route><NotFound/></Route>
      </Switch>
    </div>
  );
}

export default Admin;

import React from "react";
// import download from "downloadjs";
import { Button } from "react-bootstrap";
import useGenerateBadgePDFsForTeam from "hooks/badges/useGenerateBadgePDFsForTeam";
// import useTeamBadgesDownload from "hooks/badges/useTeamBadgesDownload";
import useTeamParticipantsForCompetition from "hooks/teams/useTeamParticipantsForCompetition";

function GenerateButton({ id }) {
  const [generateBadgePDFs, { isLoading }] = useGenerateBadgePDFsForTeam();

  return isLoading ? (
    <div className="spinner-border"></div>
  ) : (
    <Button onClick={() => generateBadgePDFs(id)}>Create Badges</Button>
  );
}

function TeamItem({ competitionId, id, name }) {
  // const [downloadBadgesPDF] = useTeamBadgesDownload();

  return (
    <li className="TeamItem list-group-item d-flex justify-content-between">
      <span>{name}</span>
      <div>
        <GenerateButton id={id} />
        {/* <Button
          className="ml-1 btn-success"
          onClick={async () => {
            // TODO: implement download PDF
            // const res = await downloadBadgesPDF({ competitionId, teamId: id });
            // const blob = await res.blob();
            // download(blob);
            // // console.log(blob);
            // const url = window.URL.createObjectURL(new Blob([blob]));
            // const link = document.createElement("a");
            // link.href = url;
            // link.setAttribute("download", `FileName.pdf`);
            // // Append to html link element page
            // document.body.appendChild(link);
            // // Start download
            // link.click();
            // // Clean up and remove the link
            // link.parentNode.removeChild(link);
          }}
        >
          Download PDF
        </Button> */}
      </div>
    </li>
  );
}

function Badges({ competitionId }) {
  const { data: teams } = useTeamParticipantsForCompetition(competitionId);

  return (
    <>
      <h3>Badge Printing</h3>
      <ul className="list-group">
        <TeamItem
          key={"others"}
          name={"Athletes Without Team"}
          id={null}
          competitionId={competitionId}
        />
        {teams?.map(({ id, teamName }, index) => (
          <TeamItem
            key={index}
            name={teamName}
            id={id}
            competitionId={competitionId}
          />
        ))}
      </ul>
    </>
  );
}

export default Badges;

import React from 'react';

function TimeInput({ name, disabled, displayName, defaultValue, onChange }) {
  return (
    <div className="form-group">
      <label htmlFor={name}>{displayName}</label>
      <input
        type="time"
        id={name}
        disabled={disabled}
        className="form-control"
        value={defaultValue}
        onChange={({ target: { value } }) => onChange(value)}
      />
    </div>
  );
}

export default TimeInput;

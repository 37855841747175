import React, { useState } from "react";
import CONFIRM_DELETE from "helpers/confirmDelete";
import useToggleHeadJudge from "hooks/judges/useToggleHeadJudge";
import "./JudgeItem.scss";

function JudgeItem({ deleteItem = () => {}, email, id, isHeadJudge }) {
  const [isHovering, setIsHovering] = useState();
  const [toggleHeadJudge] = useToggleHeadJudge();
  const toggleText = isHeadJudge ? "Unset head judge" : "Set head judge";

  return (
    <li
      className="JudgeItem list-group-item d-flex justify-content-between"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <span>
        {email} [ID: {id}]{!!isHeadJudge && " - (Head Judge)"}
      </span>
      {isHovering && (
        <div>
          <button
            className="btn btn-info mr-1"
            onClick={() => toggleHeadJudge({ id })}
          >
            {toggleText}
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              CONFIRM_DELETE(() => {
                deleteItem();
              });
            }}
          >
            Remove Judge
          </button>
        </div>
      )}
    </li>
  );
}

export default JudgeItem;

import React, { useState, useEffect } from "react";
import formatPSTDate from "helpers/formatPSTDate";
import hasActiveLotDraw from "helpers/hasActiveLotDraw";
import useLocalize from "hooks/useLocalize";
import DrawLotForm from "./DrawLotForm";

function DrawLotPane({
  competitions = [],
  eventParticipationMap,
  participantId,
}) {
  const localize = useLocalize();
  const [competition, setCompetition] = useState({});
  const competitionsWithActiveLotDraw = competitions.filter(hasActiveLotDraw);
  const events = competition?.events || [];
  const lotDrawEndDate = competition?.lotDrawEndDate;
  const showFinalDraw =
    competition?.lotDrawStartDate &&
    new Date() > new Date(competition?.lotDrawStartDate);

  function selectCompetition({ target: { value } }) {
    setCompetition(
      competitionsWithActiveLotDraw.find(({ id }) => id === +value)
    );
  }

  useEffect(() => {
    setCompetition(competitionsWithActiveLotDraw[0]);
  }, [competitionsWithActiveLotDraw.length]);

  return (
    <div className="DrawLotPane">
      {competitionsWithActiveLotDraw.length > 1 && (
        <div className="form-group row">
          <label className="form-label col-4">
            <h5>{localize("Select Competition")}</h5>
          </label>
          <div className="col-8">
            <select className="form-control" onChange={selectCompetition}>
              {competitionsWithActiveLotDraw.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <div className="row">
        <label className="col-4">
          <h5>{localize("Deadline")}</h5>
        </label>
        <div className="col-8">
          <h6>{formatPSTDate(lotDrawEndDate)}</h6>
        </div>
      </div>
      <DrawLotForm
        events={events}
        eventParticipationMap={
          eventParticipationMap?.[participantId]?.[competition?.id]
        }
        showFinalDraw={showFinalDraw}
      />
    </div>
  );
}

export default DrawLotPane;

import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import NumberInput from "components/NumberInput";
import useLocalize from "hooks/useLocalize";
import useUserTicketPurchases from "hooks/ticketPurchases/useUserTicketPurchases";
import useCreateTicketPurchases from "hooks/ticketPurchases/useCreateTicketPurchases";
import useRemoveTicketPurchases from "hooks/ticketPurchases/useRemoveTicketPurchases";
import useCurrentTournament from "hooks/tournament/useCurrentTournament";

export default ({ userId }) => {
  const { data: tournament } = useCurrentTournament();
  const { data: ticketPurchases } = useUserTicketPurchases({
    userId: userId,
    tournamentId: tournament?.id,
  });
  const showForm = tournament?.startDate
    ? new Date(tournament?.startDate) > new Date()
    : false;
  const [createTicketPurchases] = useCreateTicketPurchases();
  const [removeTicketPurchases] = useRemoveTicketPurchases();
  const localize = useLocalize();
  return (
    <div className="TicketsView container">
      <h2 className="text-lg-left text-sm-center">
        {localize("Spectator Tickets")}
      </h2>
      <h4 className="text-lg-left text-sm-center">
        {localize("Cost")}: ${tournament?.ticketCost}
      </h4>
      {showForm && (
        <Formik
          initialValues={{ count: 0 }}
          validationSchema={Yup.object({
            count: Yup.number()
              .min(1, "You must include at least 1 ticket.")
              .required(localize("Required")),
          })}
          onSubmit={({ count }, { resetForm }) => {
            createTicketPurchases({
              count,
              tournamentId: tournament?.id,
              userId: userId,
            });
            resetForm();
          }}
        >
          <Form className="needs-validation" noValidate>
            <div className="row">
              <NumberInput name="count" size="col-sm col-md-5" step={1} />
              <div>
                <button type="submit" className="btn btn-primary col-12">
                  {localize("Add to cart")}
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
      <hr></hr>
      <h5>
        {localize("Current number of tickets")}: {ticketPurchases?.length}
      </h5>
      {!!ticketPurchases?.length && showForm && (
        <Formik
          initialValues={{ count: 0 }}
          validationSchema={Yup.object({
            count: Yup.number()
              .min(1, "You must include at least 1 ticket.")
              .required(localize("Required")),
          })}
          onSubmit={({ count }, { resetForm }) => {
            removeTicketPurchases({
              count,
              tournamentId: tournament?.id,
              userId: userId,
            });
            resetForm();
          }}
        >
          <Form className="needs-validation" noValidate>
            <div className="row">
              <NumberInput
                name="count"
                size="col-sm col-md-5"
                step={1}
                max={ticketPurchases?.length}
              />
              <div>
                <button type="submit" className="btn btn-danger">
                  {localize("Remove from cart")}
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};
